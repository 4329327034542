import { IController, IScope } from 'angular';

import { MediaResourceModel, CultureModel, EmailTemplateModel, EmailTemplateTypeEnum } from '@base/models';
import {
	CertificateTemplateLocalized,
	CertificateSettingsModel,
	CertificateTemplateSettingsApiModel,
} from '../CertificateTemplateModels';
import { AdminService } from '../../AdminApiService';
import { ComponentRegistration } from '~root/commons';

export const EditCertificateSettingsComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			// registrations
			.component('appEditCertificateSettings', {
				template: require('./view.html'),
				controller: EditCertificateTemplatesController,
				bindings: {
					onSave: '&',
					settings: '<',
					cultures: '<',
					isActivity: '<',
				},
			});
	},
	name: 'appEditCertificateSettings',
	style: require('./style.scss'),
};

const emptyEmailTemplate = new EmailTemplateModel('none', -1);

export class EditCertificateTemplatesController implements IController {
	public isActivity = true;
	public settings: CertificateSettingsModel;
	// called whenever a change is saved in the entire input 'settings' object
	public onSave: (event: { settings: CertificateTemplateSettingsApiModel }) => void;

	public cultures: CultureModel[];

	public emailTemplates: Array<EmailTemplateModel>;
	public isChanged = false;

	public view = {
		templates: new Array<CertificateTemplateLocalized>(),
		originalTemplates: {},
		emailTemplate: emptyEmailTemplate,
		enableAutomaticSending: false,
		enableCertificateCreation: false,
		validityYears: 0,
		validityMonths: 0,
		validityDays: 0,
	};

	static $inject = ['$scope', 'jediAdminService', '$stateParams'];
	constructor(private $scope: IScope, private jediAdminService: AdminService, private $stateParams) {}

	public emitUpdate() {
		this.isChanged = true;
	}
	private convertViewToModel(view): CertificateTemplateSettingsApiModel {
		const model: CertificateTemplateSettingsApiModel = {
			certificateTemplates: view.templates.map(template => ({
				certificateId: template.certificate ? template.certificate.id : null,
				cultureId: template.culture ? template.culture.id : null,
			})),
			emailTemplateId: this.isActivity ? (view.emailTemplate ? view.emailTemplate.id : null) : null,
			enableAutomaticSending: this.isActivity ? view.enableAutomaticSending : null,
			enableCertificateCreation: this.isActivity ? view.enableCertificateCreation : null,
			validity: this.isActivity
				? {
						years: view.validityYears ? +view.validityYears : 0,
						months: view.validityMonths ? +view.validityMonths : 0,
						days: view.validityDays ? +view.validityDays : 0,
				  }
				: null,
		};

		return model;
	}

	public async $onInit() {
		this.settings = this.settings || {};
		this.cultures = this.cultures || [];

		this.emailTemplates = await this.getEmailTemplates();

		this.view.emailTemplate = angular.copy(this.settings.emailTemplate || this.emailTemplates[0]);

		this.view.enableAutomaticSending = this.settings.enableAutomaticSending;
		this.view.enableCertificateCreation = this.settings.enableCertificateCreation;

		if (this.settings.validity) {
			this.view.validityYears = this.settings.validity.years ? this.settings.validity.years : 0;
			this.view.validityMonths = this.settings.validity.months ? this.settings.validity.months : 0;
			this.view.validityDays = this.settings.validity.days ? this.settings.validity.days : 0;
		}

		let initialTemplates = new Array<CertificateTemplateLocalized>();
		if (this.settings.certificateTemplates) {
			initialTemplates = this.settings.certificateTemplates;
		}
		this.view.templates = this.cultures
			.filter(c => c && c.id)
			.map(culture => {
				const entry = initialTemplates.find(c => c.culture && c.culture.id === culture.id);
				const view: CertificateTemplateLocalized = {
					certificate: entry ? entry.certificate : null,
					culture: culture,
				};
				return view;
			});
		// As the method is async we need to tell Angular framework the method did finish
		this.$scope.$apply();
	}

	public updateLocalized(localized: CertificateTemplateLocalized, resource: MediaResourceModel) {
		const culture = localized && localized.culture && localized.culture.id;
		if (!culture) {
			return;
		}
		const template = this.view.templates.find(t => t.culture && t.culture.id == culture);
		const original = this.settings.certificateTemplates.find(t => t.culture && t.culture.id == culture);
		this.view.originalTemplates[culture] = original ? original.certificate : null;

		if (template) {
			template.certificate = angular.copy(resource);

			this.emitUpdate();
		}
	}

	public saveChanges() {
		if (typeof this.onSave === 'function') {
			const model = this.convertViewToModel(this.view);
			this.onSave({ settings: model });
			this.isChanged = false;
		}
	}

	public updateSelectedEmailTemplate(selected: EmailTemplateModel) {
		if (!this.settings) {
			return;
		}
		if (selected !== emptyEmailTemplate) {
			this.settings.emailTemplate = angular.copy(selected);
		} else {
			this.settings.emailTemplate = null;
		}
		this.emitUpdate();
	}

	public getOriginal(localized: CertificateTemplateLocalized) {
		const cultureId = localized && localized.culture && localized.culture.id;
		const originalTemplate = this.view && this.view.originalTemplates && this.view.originalTemplates[cultureId];
		return originalTemplate;
	}

	private async getEmailTemplates() {
		const organisationId = this.$stateParams.organisationId;

		const userEmails = await this.jediAdminService.getAllEmailTemplates(organisationId);
		const templates = userEmails.filter(
			t => t.group && t.group.groupType === EmailTemplateTypeEnum.PerActivityCertificate
		);

		const emails = [emptyEmailTemplate, ...templates];
		return emails;
	}
}
