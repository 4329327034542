import * as angular from 'angular';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DoBootstrap, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { providers } from './ajs-upgraded-providers';
import { AdminModule } from './admin/admin.module';
import { PortalModule } from './portal/portal.module';
import { RouteParamsService } from './route-params.service';
import { AppComponent } from './app.component';
import { BootstrapLegacyService } from './bootstrap-legacy.service';
import { CoreModule } from './core/core.module';
import { LoadingProgressHttpInterceptor } from './interceptors/loading-progress.interceptor';
import { PublicModule } from './public/public.module';

setAngularJSGlobal(angular);

@NgModule({
	declarations: [AppComponent],
	imports: [
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		UpgradeModule,
		AdminModule,
		PortalModule,
		PublicModule,
		CommonModule,
		UIRouterUpgradeModule.forRoot({ states: [{ name: 'app', url: '/', component: AppComponent }] }),
		CoreModule,
		BrowserAnimationsModule,
	],

	providers: [
		...providers,
		RouteParamsService,
		BootstrapLegacyService,
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingProgressHttpInterceptor, multi: true },
	],
})
export class AppModule implements DoBootstrap {
	constructor(private bootstrapService: BootstrapLegacyService) {}

	ngDoBootstrap(): void {
		this.bootstrapService.doBootstrap();
	}
}
