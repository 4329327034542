import { Component, ContentChildren, ElementRef, Input, QueryList, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { entityEquals } from '@base/models';

import { DropdownSelectOptionComponent } from '../dropdown-select-option/dropdown-select-option.component';
import { DropdownSelectPanelComponent } from './dropdown-select-panel.component';
import { DropdownSelectService } from '../dropdown-select.service';
import { IDropdownSelect } from './IDropdownSelect';
import { DropdownSelectType } from '../types';

/**
 * adaptation taken from https://prideparrot.com/blog/archive/2019/3/how_to_create_custom_dropdown_cdk
 */
@Component({
	selector: 'app-dropdown-select',
	templateUrl: './dropdown-select.component.html',
	styleUrls: ['./dropdown-select.component.scss'],
	providers: [
		DropdownSelectService,
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => DropdownSelectComponent),
			multi: true,
		},
	],
})
export class DropdownSelectComponent<T> implements IDropdownSelect<T>, ControlValueAccessor {
	value: T;

	constructor(private dropdownService: DropdownSelectService<T>) {
		this.dropdownService.register(this);
	}

	public equals: (a: T, b: T) => boolean = entityEquals;

	@Input() public selectType: DropdownSelectType = 'default';

	@Input() public disabled = false;

	@ViewChild('input')
	public input: ElementRef;

	@ViewChild(DropdownSelectPanelComponent)
	public dropdown: DropdownSelectPanelComponent;

	@ContentChildren(DropdownSelectOptionComponent)
	public options: QueryList<DropdownSelectOptionComponent<T>>;

	public selectedOption: DropdownSelectOptionComponent<T>;

	public displayText: string;

	public onChangeFn: (selected: T) => void = () => {};

	public onTouchedFn = () => {};

	public registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	public writeValue(obj: any): void {
		this.value = obj;
		this.updateSelectedOption(obj, false);
	}

	public showDropdown() {
		this.dropdown.show();
	}

	public onDropMenuIconClick(event: UIEvent) {
		event.stopPropagation();
		setTimeout(() => {
			this.input.nativeElement.focus();
			this.input.nativeElement.click();
		}, 10);
	}

	private updateSelectedOption(item: T, propagate = true) {
		if (!this.options) {
			this.displayText = 'none';
			return;
		}
		const options = this.options.toArray();

		this.selectedOption = options.find(option => this.equals(option.value, item)); // || options.find(o => o.value.id === item.id) || options.find(o => o.value.name === item.name);
		this.displayText = this.selectedOption ? this.selectedOption.viewValue : 'none';
		if (propagate) {
			this.onChangeFn(this.selectedOption?.value);
			this.onTouchedFn();
		}
	}

	public selectOption(option: DropdownSelectOptionComponent<T>) {
		this.updateSelectedOption(option.value);
		this.hideDropdown();
		this.input.nativeElement.focus();
	}

	public hideDropdown() {
		this.dropdown.hide();
	}
}
