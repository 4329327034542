<div class="edit-notification-container">
    <h3 mat-dialog-title class="modal-title">{{dialogHeader}}</h3>
    <mat-dialog-content>
        <section class="content-item-details" *ngIf="loaded">
            <div class="form-group">
                <label>name</label>
                <input [(ngModel)]="notificationName" type="text" class="form-control" required>
            </div>
            <div class="form-group">
                <label>event type</label>
                <app-dropdown [(ngModel)]="selectedEventType" selectType="form" [options]="eventTypes"
                    [options]="eventTypes">
                </app-dropdown>

            </div>
            <div class="form-group">
                <label>email template</label>
                <app-dropdown selectType="form" [(ngModel)]="selectedEmailTemplate" [options]="emailTemplates">
                </app-dropdown>
            </div>
            <div class="form-group">
                <div *ngIf="showTriggerDays()">
                    <label>days to trigger notification</label>
                    <input [(ngModel)]="this.triggerPeriod" class="form-control" type="number" min="1" />
                </div>
                <div *ngIf="showScheduleAt()">
                    <app-datepicker [formControl]="scheduledAt" label="schedule notification at"
                        [includeTime]="true"></app-datepicker>
                </div>
            </div>

        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="btn btn-primary big" (click)="save()">save</button>
        <button class="btn btn-secondary" (click)="cancel()">cancel</button>
    </mat-dialog-actions>
</div>