// filter used for localizing static strings in partial views
const LocalizeFilter = [
	'jediLocalization',
	function(jediLocalization) {
		var localizeFunc: any = function(resourceName) {
			return jediLocalization.getLocalizedText(resourceName);
		};
		localizeFunc.$stateful = true;
		return localizeFunc;
	},
];

export default LocalizeFilter;
