import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

const ApiDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?(\+\d{2}:\d{2})?$/;

/**
 * Process JSON response body and convert ISO Date values to Date() instances
 */
export class JsonDateParserInterceptor implements HttpInterceptor {
	private dateFormatPattern = ApiDateFormat;

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			map((val: HttpEvent<any>) => {
				if (val instanceof HttpResponse) {
					const { body } = val;
					this.convert(body);
				}
				return val;
			})
		);
	}

	isIsoDateString(value: any): boolean {
		if (value === null || value === undefined) {
			return false;
		}
		if (typeof value === 'string') {
			return this.dateFormatPattern.test(value);
		}
		return false;
	}

	convert(body: any) {
		if (body === null || body === undefined) {
			return body;
		}

		if (typeof body !== 'object') {
			return body;
		}

		Object.keys(body).forEach(key => {
			const value = body[key];
			if (this.isIsoDateString(value)) {
				const m = moment.utc(value);
				// eslint-disable-next-line no-param-reassign
				body[key] = m.local().toDate();
			} else if (typeof value === 'object') {
				this.convert(value);
			}
		});
		return body;
	}
}
