const JediConfirmComponent = {
	register: function(ngModule) {
		ngModule.directive('jediConfirm', JediConfirmDirective);
	}
};

export default JediConfirmComponent;

const JediConfirmDirective = [
	'$rootScope',
	'$location',
	'$filter',
	function($rootScope, $location, $filter) {
		return {
			require: 'ngModel',
			restrict: 'A',
			//replace: true,
			link: function(scope, element, attributes) {
				scope.title = attributes.jediConfirmTitle || 'are you sure? (default)';
				scope.textYes = attributes.jediConfirmTextYes || 'yes (d)';
				scope.textNo = attributes.jediConfirmTextNo || 'no (d)';
				$(element).click(function() {
					if (confirm(scope.title)) {
						scope.callbackYes();
					} else {
						scope.callbackNo();
					}
				});
				if (attributes.jediConfirmCallbackYes) {
				}
				if (attributes.jediConfirmCallbackNo) {
				}
				scope.callbackYes = function() {
					scope.$eval(attributes.jediConfirmCallbackYes);
				};
				scope.callbackNo = function() {
					scope.$eval(attributes.jediConfirmCallbackNo);
				};
			}
		};
	}
];
