// registrations
import JediConfirmComponent from './directives/jediConfirm';
import DateTimeFormatComponent from './directives/jediDateFormat';
import JediElements from './directives/jediElements';
import JediValidation from './directives/jediValidation';

import ContentTruncateComponent from './contentTruncate/contentTruncateComponent';

import ExceptionHandler from './exceptionHandler/exceptionHandlerDecorator';

import HttpComponent from './jediHttp/jediHttpComponent';
import LocalizationComponent from './jediLocalization/jediLocalizationComponent';
//import MultiselectComponent from './jediMultiselect/jediMultiselectComponent';
import JediMultiselectComponent from './jediMultiselect/jediMultiselectComponent';
import { JediNotificationsComponent } from './jediNotifications/jediNotificationsComponent';
import OrientationWarningComponent from './jediOrientationWarning/jediOrientationWarningComponent';
import LoggerDecorator from './logger/logDecorator';

import { VideoPlayerComponent, VideoPlayerContainerComponent, VideoPlayerServiceRegistration } from './VideoPlayer';
import { JediClientStorageComponentRegistration } from './services/ClientStorage';

import { JediCustomSettingsRegistration } from './services/jediCustomSettings';
import ErrorHandlerService from './services/jediErrorHandler';
import HelperService from './services/jediHelper';
import { NavigationServiceRegistration } from './services/NavigationService';
import { JediPackageActionsRegistration } from './services/jediPackageActions';
import { JediStatementGeneratorRegistration } from './services/jediStatementGenerator';
import { JediTinCanClientRegistration } from './services/jediTinCanClient';
import { JediUserSettingsComponentRegistration } from './services/jediUserSettings';
import WindowMessenger from './services/jediWindowMessenger';
import RouteResolver from './services/routeResolver';
import { UiStateStorageServiceRegistration } from './services/uiStateService';

import { JediHierarchyRegistration, UserHierarchyMapperServiceRegistration } from './jediHierarchy';
import { ContextManagerServiceRegistration } from './jediContextService';
import { JediAuthServiceRegistration } from './jediAuth/jediAuthService';
import { JediAuthStoreRegistration } from './jediAuth/jediAuthStore';
import { LoadingPanelComponent } from 'app-legacy/ajs-interceptors/jediLoadingPanel/jediLoadingPanelComponent';

export default [
	ContextManagerServiceRegistration,
	ContentTruncateComponent,
	DateTimeFormatComponent,
	JediConfirmComponent,
	JediElements,
	JediValidation,
	JediNotificationsComponent,
	//MultiselectComponent,
	JediMultiselectComponent,
	OrientationWarningComponent,

	VideoPlayerComponent,
	VideoPlayerContainerComponent,
	VideoPlayerServiceRegistration,

	LocalizationComponent,
	JediAuthStoreRegistration,
	JediAuthServiceRegistration,
	JediClientStorageComponentRegistration,
	JediCustomSettingsRegistration,
	ErrorHandlerService,
	HelperService,
	NavigationServiceRegistration,
	HttpComponent,
	JediPackageActionsRegistration,
	JediStatementGeneratorRegistration,
	JediTinCanClientRegistration,
	JediUserSettingsComponentRegistration,
	RouteResolver,
	WindowMessenger,
	ExceptionHandler,
	LoggerDecorator,
	UiStateStorageServiceRegistration,

	JediHierarchyRegistration,
	UserHierarchyMapperServiceRegistration,

	LoadingPanelComponent,
];
