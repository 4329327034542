import * as utils from '~root/commons';
import { ConvertFilesizeFilterRegistration } from './convertFilesizeFilter';
import { FileUploadDirectiveRegistration } from './fileUploadDirective';
import { FileUploadComponentRegistration } from './fileUploadService';
import { EditResourceComponentRegistration } from './editFileComponent/EditFileResourceComponent';
import { ViewResourceComponentResgistration } from './viewResourceComponent/ViewResourceComponent';

export const MediaResourcesModuleRegistration = utils.createModule({
	name: 'jedi.admin.mediaResource',
	registrations: [
		ConvertFilesizeFilterRegistration,
		FileUploadDirectiveRegistration,
		FileUploadComponentRegistration,
		EditResourceComponentRegistration,
		ViewResourceComponentResgistration,
	],
});
