/* eslint-disable class-methods-use-this */
import { diff } from 'deep-diff';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { from, Observable, Subscription } from 'rxjs';

import { EmailTemplateModel, EmailTemplateTypeEnum, EntityModel, UiLocalizedContent } from '@base/models';
import { AngularJsLogger } from 'app/ajs-logger';
import { RouteParamsService } from 'app/route-params.service';
// use explicit imports to avoid circular dependencies
import { AdminService } from 'app-legacy/admin/commons/AdminApiService';
import { CertificateSettingsModel } from 'app-legacy/admin/commons/certificateTemplates/CertificateTemplateModels';

import { CertificatesLogicService } from '../certificates-logic.service';
import { EditCertificateSettingsLocalizedComponent } from './edit-certificate-settings-localized.component';

@Component({
	selector: 'app-edit-certificate-settings',
	templateUrl: './edit-certificate-settings.component.html',
	styleUrls: ['./edit-certificate-settings.component.scss'],
})
export class EditCertificateSettingsComponent implements OnInit, OnDestroy {
	model: CertificateSettingsModel;

	disabled = false;

	hasChanges = false;

	@Input()
	isActivity = true;

	@Input()
	entity: EntityModel;

	localizedEditorType = EditCertificateSettingsLocalizedComponent;

	localizedCertificateTemplates: UiLocalizedContent = null;

	emailTemplates$: Observable<EmailTemplateModel[]>;

	editSettingsFg: FormGroup;

	formSubscription: Subscription;

	defaultModel: CertificateSettingsModel;

	constructor(
		private adminService: AdminService,
		private routeParams: RouteParamsService,
		private fb: FormBuilder,
		private certificateService: CertificatesLogicService,
		private logger: AngularJsLogger
	) {}

	async ngOnInit() {
		this.emailTemplates$ = from(this.getEmailTemplates());

		this.editSettingsFg = this.fb.group({
			enableAutomaticIssuing: '',
			enableCertificateCreation: '',
			enableAutomaticSending: '',
			emailTemplate: null,
			validity: this.fb.group({
				years: '',
				months: '',
				days: '',
			}),
		});

		this.model = await this.resolveSettings();

		this.defaultModel = JSON.parse(JSON.stringify(this.model)) as CertificateSettingsModel;

		this.localizedCertificateTemplates = this.getLocalizedContent(this.model);
		this.editSettingsFg.patchValue(this.model);

		this.conditionallyEnableAndSetFormElement(
			this.editSettingsFg,
			'enableAutomaticIssuing',
			'enableCertificateCreation'
		);
		this.conditionallyEnableAndSetFormElement(
			this.editSettingsFg,
			'enableAutomaticSending',
			'enableAutomaticIssuing'
		);

		this.formSubscription = this.editSettingsFg.valueChanges.subscribe(changes => {
			this.model = { ...this.model, ...changes };
			const actualChanges = diff(this.model, this.defaultModel);
			this.hasChanges = typeof actualChanges !== 'undefined';

			this.conditionallyEnableAndSetFormElement(
				this.editSettingsFg,
				'enableAutomaticIssuing',
				'enableCertificateCreation'
			);

			this.conditionallyEnableAndSetFormElement(
				this.editSettingsFg,
				'enableAutomaticSending',
				'enableAutomaticIssuing'
			);

			dev.info('edit-certificates: changes', changes);
		});
	}

	private conditionallyEnableAndSetFormElement(formGroup: FormGroup, targetName: string, enabledBy: string) {
		const target = formGroup.get(targetName);
		const enabledByControl = formGroup.get(enabledBy);
		if (enabledByControl.value === true) {
			target.enable({ emitEvent: false });
		} else {
			if (target.value === true) {
				target.setValue(false);
			}
			target.disable({ emitEvent: false });
		}
	}

	ngOnDestroy() {
		this.formSubscription?.unsubscribe();
	}

	private async resolveSettings() {
		let settings: CertificateSettingsModel = null;
		if (this.isActivity) {
			settings = await this.certificateService.getActivitySettings(+this.entity.id);
		} else {
			settings = await this.certificateService.getOrganisationSettings(+this.entity.id);
		}

		if (settings && !settings.validity) {
			settings.validity = { years: 0, months: 0, days: 0 };
		}

		return settings;
	}

	public async save() {
		const settings = this.model;
		if (this.isActivity) {
			await this.certificateService.saveActivitySettings(+this.entity.id, settings);
		} else {
			const apiModel = this.certificateService.mapToApiModel(settings);
			await this.certificateService.saveOrganisationSettings(+this.entity.id, apiModel);
		}
		this.logger.info('certificate settings saved');
	}

	private getLocalizedContent(settings: CertificateSettingsModel): UiLocalizedContent {
		const map = new UiLocalizedContent();
		settings?.certificateTemplates?.forEach(ct => {
			map.set(ct.culture?.id, {
				culture: ct.culture,
				data: ct.certificate,
				enabled: true,
			});
		});
		return map;
	}

	private async getEmailTemplates() {
		const { organisationId } = this.routeParams.params();

		const userEmails = await this.adminService.getAllEmailTemplates(organisationId);
		const templates = userEmails.filter(
			t => t.group && t.group.groupType === EmailTemplateTypeEnum.PerActivityCertificate
		);
		const emailTemplates = [null, ...templates];

		return emailTemplates;
	}

	onUpdateCertificateTemplates($event: UiLocalizedContent) {
		dev.log('onUpdateParent', $event);
		if (!this.model) {
			this.model = {};
		}

		this.model.certificateTemplates = [];

		$event.forEach(e => {
			this.model.certificateTemplates.push({ certificate: e.data, culture: e.culture });
		});
		// merge maps
		this.localizedCertificateTemplates = new Map([...this.localizedCertificateTemplates, ...$event]);
		this.hasChanges = true;
	}

	public cancel() {
		// eslint-disable-next-line no-restricted-globals
		history.back();
	}
}
