import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import {
	MatNativeDateModule,
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	MAT_RIPPLE_GLOBAL_OPTIONS,
	RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import {
	DropdownSelectComponent,
	DropdownSelectPanelComponent,
	DropdownSelectOptionComponent,
	DropdownComponent,
} from './dropdown';
import { TablePaginatorComponent } from './table-paginator/table-paginator.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadService } from './file-upload/file-upload.service';
import { SearchComponent } from './search/search.component';
import { EditCollectionComponent } from './edit-collection/edit-collection.component';
import { LocalizedEditorComponent } from './localized-editor/localized-editor.component';
import { LocalizedComponentHostDirective } from './localized-editor/localized-component-host.directive';
import { EditTextComponent } from './edit-text/edit-text.component';

import { EditTextService } from './edit-text/edit-text.service';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { FileEditComponent } from './file-edit/file-edit.component';
import { SectionComponent } from './section/section.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DialogService } from './dialog.service';
import { FileViewComponent } from './file-view/file-view.component';
import { UrlRequiredDirective } from './UrlRequiredDirective';
import { NotEmptyDirective } from './not-empty/not-empty.directive';

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
};

@NgModule({
	declarations: [
		DatepickerComponent,
		TablePaginatorComponent,
		DropdownComponent,
		DropdownSelectComponent,
		DropdownSelectPanelComponent,
		DropdownSelectOptionComponent,
		TimepickerComponent,
		FileUploadComponent,
		SearchComponent,
		EditCollectionComponent,
		LocalizedEditorComponent,
		LocalizedComponentHostDirective,
		EditTextComponent,
		MultiselectComponent,
		FileEditComponent,
		SectionComponent,
		ConfirmComponent,
		FileViewComponent,
		UrlRequiredDirective,
		NotEmptyDirective,
	],
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		PortalModule,
		OverlayModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatIconModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		MatTabsModule,
		MatSelectModule,
		ScrollingModule,
		NgxMaterialTimepickerModule,
		EditorModule,
	],

	exports: [
		MatDatepickerModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatInputModule,
		MatNativeDateModule,
		BrowserAnimationsModule,
		DatepickerComponent,
		TimepickerComponent,
		TablePaginatorComponent,
		DropdownComponent,
		DropdownSelectComponent,
		DropdownSelectOptionComponent,
		DropdownSelectPanelComponent,
		SearchComponent,
		EditCollectionComponent,
		LocalizedEditorComponent,
		EditTextComponent,
		MultiselectComponent,
		FileUploadComponent,
		FileEditComponent,
		SectionComponent,
		UrlRequiredDirective,
		NotEmptyDirective,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [
		DialogService,
		EditTextService,
		FileUploadService,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{
			provide: TINYMCE_SCRIPT_SRC,
			useValue: '/dist/base-app/tinymce/tinymce.min.js',
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: globalRippleConfig,
		},
	],
})
export class AdminSharedModule {}
