<div class="modal-container">
<h3 mat-dialog-title class="modal-title">confirm sending certificates</h3>
<mat-dialog-content>
    <section class="content-item-details" >
        <ng-container *ngIf="activity.isCertificateEnabled === true">
            <p>
                Only users with certificates issued are entitled to receive a certificate
            </p>
            <p>
                Below you see the number of selected users, and also the number of entitled users. </p>
            <p>
                Only the entitled users will receive the certificate.</p>        
            <p>
                selected users: {{selectedUsers.length}}
            </p>
            <p>
                entitled users: {{ applicableUsers.length}}
            </p>
        </ng-container>
        <ng-container *ngIf="activity.isCertificateEnabled === false">
            <p>
                Certificates not enabled for activity <b>{{activity.name}}</b> with id = <b>{{activity.id}}</b>
            </p>
        </ng-container>
    </section>

</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" class="btn btn-primary big" (click)="send()" [disabled]="!enableSendCertificates()"  >
        confirm
    </button>
    <a class="btn btn-secondary" (click)="cancel()">cancel</a>
</mat-dialog-actions>
</div>