// define the secondary level of acces for the applications, the permissions; the value for the role property defines the hierarchy
export const RolePermissions = {
	systemAdministrator: {
		name: 'SystemAdministrator',
		value: 5,
		label: 'admin application - system administrator',
	},
	platformAdministrator: {
		name: 'PlatformAdministrator',
		value: 4,
		label: 'admin application - platform administrator',
	},
	administrator: {
		name: 'Administrator',
		value: 3,
		label: 'admin user - corporate administrator',
	},
	//unitAdministrator: {
	//    name: 'UnitAdministrator',
	//    value: 2,
	//    label: 'admin user - OU administrator'
	//},
	adminApplicationUser: {
		name: 'AdminApplicationUser',
		value: 1,
		label: 'admin application - user',
	},
	participant: {
		name: 'Participant',
		value: 0,
		label: 'regular user',
	},
};

// UI label mapping for all the roles existing in the application
export const RoleLabels = [
	{ name: 'SystemAdministrator', label: 'sysadmin' },
	{ name: 'PlatformAdministrator', label: 'platform admin' },
	{ name: 'Administrator', label: 'administrator' },
	{ name: 'AdminApplicationUser', label: 'operator' },
	{ name: 'Participant', label: 'regular user' },
];
