/* eslint-disable prefer-rest-params */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import * as angular from 'angular';
import * as logger from '~root/logger';
import { registerDowngradedComponents } from './downgrade';
import { fastHash } from './helpers';
import { invokeFn } from './invokeFn';
import { CreateModuleOptions, ModuleRegistration, RequiredDependency } from './types';

let moduleId = 0;
export function createModule(settings: CreateModuleOptions): ModuleRegistration {
	settings.registrations = (settings.registrations || []).filter(r => r);
	settings.requires = (settings.requires || []).filter(r => r);

	const ngModule = createNgModule(settings);
	moduleId += 1;
	ngModule.id = moduleId;
	// settings.registrations.forEach(registration => {
	//   const mod = wrapNgModule(ngModule, registration);
	//   // invoke registration
	//   invokeFn(mod, registration.register, mod, true);
	//   ngModule.declarations.push(...registration.declarations);
	//   ngModule.registrations.push(registration);
	// });

	// run ComponentRegistration 'register' functions
	settings.registrations.forEach(registration => {
		// invoke component registration
		invokeFn(ngModule, registration.register, ngModule);

		// adjust registration name

		if (!registration.name) {
			if (registration.register) {
				registration.name = fastHash(registration.register.toString());
			} else {
				registration.name = null;
			}
		}
		// console.log(`registration: ${registration.name}`);
	});

	ngModule.routing = settings.routing;

	ngModule.routes = settings.routes;

	// ngModule.styles = createModuleStyles(ngModule, settings);

	ngModule.settings = settings;

	registerDowngradedComponents(ngModule, settings);

	return ngModule;
}

// /**
//  * enable storing all registered compoenents/directives from a ComponentRegistration
//  * can be used to detect component name
//  * @param ngModule
//  * @param registration
//  */
// function wrapNgModule(ngModule: ModuleRegistration, registration: ComponentRegistration): ModuleRegistration {
// 	registration.declarations = [];

// 	const wrapper = ['component', 'directive', 'factory', 'service', 'controller'];
// 	// clone ngModule so wrapping is applied only once
// 	const wrapped = { ...ngModule };
// 	wrapper.forEach(key => {
// 		const original = ngModule[key];
// 		wrapped[key] = (...args) => {
// 			registration.declarations.push({ type: key, args, registration });
// 			invokeFn(ngModule, original, args);
// 			return wrapped;
// 		};
// 	});
// 	return wrapped;
// }

function createNgModule(settings: CreateModuleOptions): ModuleRegistration {
	const { name } = settings;
	logger.info(`create module: ${name}`);
	const ngRequires = settings.requires.map(mapRequires);

	// settings.registrations.forEach(registration => {
	// 	const regRequires = (registration.requires || []).filter(r => r).map(mapRequires) || [];
	// 	ngRequires = ngRequires.concat(regRequires.filter(r => r));
	// });

	const ngModule = angular.module(name, ngRequires) as ModuleRegistration;
	ngModule.declarations = [];
	ngModule.registrations = [];
	return ngModule;
}

function mapRequires(required: RequiredDependency): string {
	if (typeof required === 'string') {
		return required;
	}
	if (typeof required.name === 'string') {
		return required.name;
	}
	logger.warn('unable to map require entry', required);

	return undefined;
}

export function decorateInjectable(ngModule, invokable, injectionFactoryMap) {
	const originalInvokable = invokable;
	const dependencies = getFnDependencies(invokable, false);
	const newInvokable = () => {
		const params = arguments;
		const injectionMap = getInjectionMap(params, dependencies);
		Object.keys(injectionFactoryMap).forEach(injectable => {
			const original = injectionMap[injectable];
			const factory = injectionFactoryMap[injectable];
			const decorated = factory(original);
			injectionMap[injectable] = decorated;
		});
		const decoratedParams = dependencies.map(dependency => injectionMap[dependency]);
		invokeFn(ngModule, originalInvokable, decoratedParams);
	};
	newInvokable.$inject = dependencies;
	return newInvokable;
}

function getFnDependencies(fn, strictDi) {
	if (typeof fn === 'undefined') {
		return [];
	}
	if (typeof fn === 'function') {
		if (fn.$inject && fn.$inject.forEach) {
			return fn.$inject;
		}
		const params = getParamNames(fn);
		if (params && params.length) {
			if (strictDi) {
				logger.warn(`DI unusable when minification is applied for: ${fn}`);
			}
			return [];
		}
	}
	if (fn.slice) {
		const params = fn.slice(0, fn.length - 1);
		return params;
	}
	return [];
}

// function getAllDependencies(registrations, injections) {
// 	const dependencyMap = {};
// 	registrations.forEach(reg => reg.dependencies.forEach(dep => (dependencyMap[dep] = true)));
// 	if (injections && injections.forEach) {
// 		injections.forEach(injection => (dependencyMap[injection] = true));
// 	}
// 	const dependencies = Object.keys(dependencyMap);
// 	return dependencies;
// }

function getInjectionMap(injections, injectionNames) {
	const injectionMap = {};
	injectionNames.forEach((name, i) => {
		injectionMap[name] = injections[i];
	});
	return injectionMap;
}
/**
 * source: https://stackoverflow.com/questions/1007981/how-to-get-function-parameter-names-values-dynamically#answer-9924463
 */
const STRIP_COMMENTS =
	/(\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s*=[^,)]*(('(?:\\'|[^'\r\n])*')|("(?:\\"|[^"\r\n])*"))|(\s*=[^,)]*))/gm;

const ARGUMENT_NAMES = /([^\s,]+)/g;
function getParamNames(func) {
	const fnStr = func.toString().replace(STRIP_COMMENTS, '');
	let result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
	if (result === null) result = [];
	return result;
}
