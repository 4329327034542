import moment from 'moment';

const JediValidationComponent = {
	register: function (ngModule) {
		// validation for a field minimum value
		// error message is set as value to the 'minValue' attribute
		ngModule.directive('minDate', [
			'$rootScope',
			'$timeout',
			function ($rootScope, $timeout) {
				return {
					require: '^ngModel',
					restrict: 'A',
					link: function (scope, elm, attrs, ctrl) {
						dateValidationFactory(
							$rootScope,
							$timeout,
							scope,
							elm,
							attrs,
							ctrl,
							'minDate',
							minDateValidator
						);
					},
				};
			},
		]);

		ngModule.directive('maxDate', [
			'$rootScope',
			'$timeout',
			function ($rootScope, $timeout) {
				return {
					require: '^ngModel',
					restrict: 'A',
					link: function (scope, elm, attrs, ctrl) {
						dateValidationFactory(
							$rootScope,
							$timeout,
							scope,
							elm,
							attrs,
							ctrl,
							'maxDate',
							maxDateValidator
						);
					},
				};
			},
		]);
	},
};

export default JediValidationComponent;

function dateValidationFactory($rootScope, $timeout, scope, elm, attrs, ctrl, validatorName, validator) {
	$timeout(function () {
		var modelName = attrs.name;
		var errorMessage = attrs.minErrorMessage;
		var date = (attrs[validatorName] || '').replace(/'/gi, '').replace(/"/gi, '');

		ctrl.$setValidity(validatorName, false);
		if ($rootScope.errors) {
			if (!$rootScope.errors[modelName]) $rootScope.errors[modelName] = {};
		}
		scope.$watch(attrs.ngModel, function (value) {
			var valid = validator(value, date);
			ctrl.$setValidity(attrs.ngModel, valid);
			ctrl.$setValidity(validatorName, valid);
			if (modelName && errorMessage && errorMessage.length > 0) {
				var message = errorMessage.replace('{0}', date);
				if ($rootScope.errors && $rootScope.errors[modelName]) {
					$rootScope.errors[modelName][validatorName] = valid ? '' : message;
				}
			}
		});
	}, 0);
}

function minDateValidator(value, minimumDate) {
	if (typeof value == 'undefined' || value == null || value == '') return true;
	return moment(value).startOf('day') >= moment(minimumDate).startOf('day');
}
function maxDateValidator(value, minimumDate) {
	if (typeof value == 'undefined' || value == null || value == '') return true;
	return moment(value).startOf('day') <= moment(minimumDate).startOf('day');
}
