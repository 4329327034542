/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, ElementRef, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-jedi-maintenance-messages-component' })
export class Ng1JediMaintenanceMessagesWrapper extends UpgradeComponent {
	@Input() showMessage: boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('jediMaintenanceMessages', elementRef, injector);
	}
}

@Directive({ selector: 'ng1-ui-view-wrapper' })
export class Ng1UiViewWrapper extends UpgradeComponent {
	constructor(elementRef: ElementRef, injector: Injector) {
		super('uiViewWrapper', elementRef, injector);
	}
}

@NgModule({
	declarations: [Ng1JediMaintenanceMessagesWrapper, Ng1UiViewWrapper],
	exports: [Ng1JediMaintenanceMessagesWrapper, Ng1UiViewWrapper],
})
export class Ng1PortalComponentsModule {}
