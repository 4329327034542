<div class="resource-file-view" >

	<ng-container *ngIf="!resource">
		<div class="details">
			<span>{{placeholder}}</span>
		</div>
	</ng-container>

	<ng-container *ngIf="resource">
		<div class="details">
			<label> filename </label>
			<span> {{ resource.originalFilename }} </span>
			<button
				type="button"
				class="btn btn-secondary"
				*ngIf="enableDetails"
				(click)="showDetails = !showDetails"
			>
				{{ !showDetails ? 'show' : 'hide'}} details
			</button>
		</div>
		<div *ngIf="showDetails">
			<div class="details">
				<label> internal filename </label>
				<span> {{resource.filename}} </span>
				<ng-container *ngIf="resource.uploader">
					<label> uploaded by </label>
					<span> {{resource.uploader.userName}} </span>
				</ng-container>				
				<label> checksum </label>
				<span> {{resource.mD5Checksum}} </span>
				<label> last update</label>
				<span> {{ resource.updated | date: 'medium' }}</span>
			</div>
			<a
				class="btn btn-secondary"
				[download]="resource.originalFilename"
				[href]="resource.virtualPath"
			>
				download
			</a>
		</div>
	</ng-container>

</div>
