/* eslint-disable max-classes-per-file */
import { VersionState } from './VersionState';
import { ActivationState } from './EntityModel';
import { ContainerPolicyModel } from './ContainerPolicyModel';
import { ProgressState } from './ParticipantModel';
import { LocalizationTable } from './LocalizationTable';
import { DisplaySettings } from './DisplaySettings';

export class BaseActivityModel {
	resetUserActivityState = false;

	organisation_Id: number;

	version?: number;

	group_Id?: number | string;

	activationState: ActivationState;

	/**
	 * relative URI for accessing the item in portal/player
	 */
	hierarchyPath: string;

	/**
	 * 	- `true` for content items
	 *  - `false` for containers
	 */
	isActivity: boolean;

	isCertificateEnabled = false;

	policyModel?: ContainerPolicyModel;

	name: string;

	displayName?: string;

	genericInfo?: string;

	description?: string;

	creationDate: Date;

	lastUpdate: Date;

	cultureId?: string;

	finalStates?: ProgressState | ProgressState[] | string;

	// use ascending numerical order to display
	displayOrder = 0;

	isQuiz?: boolean;

	/**
	 * Activity Id
	 */
	id: string | number;

	localizationSettings?: LocalizationTable;

	displaySettings?: DisplaySettings = null;

	containerTincanId?: string;

	availabilitySettings: AvailabilitySettings;
}

export class AvailabilitySettings {
	periodEnabled: boolean;

	startDate: Date;

	endDate: Date;

	displayPolicy: AvailabilityDisplayPolicy;
}

export enum AvailabilityDisplayPolicy {
	None = 'None',
	HiddenWhenUnavailable = 'HiddenWhenUnavailable',
	VisibleNotAccessibleWhenUnavailable = 'VisibleNotAccessibleWhenUnavailable',
}

export class ActivityModel extends BaseActivityModel {
	state: VersionState;

	hasChildren?: boolean;

	existsInFilter?: boolean;
}

export enum ProviderTypeEnum {
	None = 'None',
	Articulate = 'Articulate',
	Captivate = 'Captivate',
	Lectora = 'Lectora',
	VideoFile = 'VideoFile',
	VideoVimeo = 'VideoVimeo',
	Document = 'Document',
	Rise = 'Rise',
	PhilQ = 'PhilQ',
	Courseware = 'Courseware',
	// casing is provided by API
	iSpring = 'ISpring',
	LocalWebsite = 'LocalWebsite',
	LTI_V1_1 = 'LTI_V1_1',
}

export enum ProviderCategoryEnum {
	None = 'None',
	Video = 'VideoProviderType',
	Document = 'DocumentProviderType',
	LocalXApi = 'LocalXapiModuleProviderType',
	ExternalXApi = 'ExternalXapiModuleProviderType',
	StaticWebsite = 'LocalWebsiteProviderType',
	LTI = 'LTIProviderType',
}

export class ActivityProviderTincanDetails {
	startupEndpoint: string;

	id?: string;

	name?: string;

	description?: string;
}
