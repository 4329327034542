import serverSettings from '../jediServerSettings';
import ConfigApi from './config.api';
import ConfigFields from './config.fields';
import ConfigICALT from './config.icalt';
import { tincan as ConfigTinCan } from './config.tincan';

const customClass = 'web-tutorial';

// TODO: JediConfig has no absolute dependency on the AngularJS runtime, should be converted to a ES6 class
export const JediConfig = {
	defaults: {
		emptyGuid: '00000000-0000-0000-0000-000000000000',
		customClass: customClass,
	},

	now() {
		return new Date();
	},
	serverSettings: serverSettings,
	fileUpload: {
		maxFileSize: 1024 * 1024 * 750, // 750 MB
		extensions: {
			image: '.svg,.jpg,.jpeg,.png,.gif',
			archive: '.zip',
			csv: '.csv',
			video: '.mp4',
			document: '.pdf,.xlsx,.pptx,.docx',
			import: '.csv,.json,.xlsx',
		},
	},
	app: {
		cookies: {
			languagePreference: 'jedi.language',
			// stores the date at which messages were closed
			maintenanceMessages: 'jedi.messages',
			version: 'jedi.version',
			showIntroduction(user): string {
				return 'jedi.showIntroduction.' + user?.id;
			},
		},
	},
	api: ConfigApi,
	tincan: ConfigTinCan,
	fields: ConfigFields,
	events: {
		currentUserLoaded: 'jedi:user:load',
		currentUserLoadStart: 'jedi:user:load:start',
		currentUserLoadComplete: 'jedi:user:load:complete',
		contextLoadStart: 'jedi:context:load:start',
		contextLoadComplete: 'jedi:context:load:complete',
		learningActivitiesLoaded: 'jedi:activities:load:complete',
		portalIntroductionShow: 'jedi:portal:introduction:show',
		portalIntroductionHide: 'jedi:portal:introduction:hide',
		clearFilter: 'jedi:filter:clear',
		localeChange: 'jedi:locale:change',
		dataLoadStart: 'data:load:start',
		dataLoadComplete: 'data:load:complete',
		navigationChange: 'jedi:navigation:change',
		menuShowHide: 'jedi:menu:show:hide',
		notificationMenuShow: 'jedi:notification:show',
		notificationMenuHide: 'jedi:notification:hide',
		progressProp: 'jedi:progress:properties',
		progressChanged: 'jedi:progress:changed',
		userLogout: 'jedi:user:logout',
		resumeApp: 'jedi:resume:app',
	},
	faq: [
		{
			'en-US':
				'<p><h3>Q: Can I close the browser at any moment?</h3></p><p> A: Yes, all your settings and data are saved automatically.</p>',
			'nl-NL':
				'<p>Q: Kan ik de browser op elk moment afsluiten?</p><p> A: Ja, alle instellingen en gegevens worden automatisch opgeslagen.</p>',
		},
	],

	// representation of API provided data for localized FAQ items
	localizedFaq: [
		{
			id: 0,
			order: 0,
			organisationId: 0,
			localizations: [
				{
					itemId: 0,
					culture: 'en-US',
					question: 'Can I close the browser at any moment?',
					answer: 'Yes, all your settings and data are saved automatically.',
				},
				{
					itemId: 0,
					culture: 'nl-NL',
					question: 'Kan ik de browser op elk moment afsluiten?',
					answer: 'Ja, alle instellingen en gegevens worden automatisch opgeslagen.',
				},
			],
		},
	],

	icalt: ConfigICALT,
};
