import { IHttpService } from 'angular';

import * as ngCookies from 'angular-cookies';
import * as Videogular from 'videogular';
import * as VideogularControls from 'videogular-controls';
import * as VideogularPoster from 'videogular-poster';
import '~root/vendor/modified/isteven-multi-select.js';

import * as utils from '~root/commons';

import CommonsModuleRegistrations from './commons.module.registrations';
import CommonsModuleConfig from './commons.config';

export const CommonsModule = utils.createModule({
	name: 'jedi.commons',
	requires: [
		ngCookies,
		'ApplicationInsightsModule',
		Videogular,
		VideogularControls,
		VideogularPoster,
		'isteven-multi-select',
	],
	styles: [require('~root/vendor/modified/isteven-multi-select.css')],
	registrations: CommonsModuleRegistrations,
});

CommonsModule.config(CommonsModuleConfig);

CommonsModule.run([
	'$http',
	function ($http: IHttpService) {
		// enable CORS
		$http.defaults.withCredentials = false;
	},
]);
