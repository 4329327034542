<div class="modal-content" >
	<div class="modal-header">
		<h1 class="modal-title">Select a learning activity type</h1>
		<div class="provider-subtitle">please select one of the following Philpaper supported learning activity types</div>
	</div>
	<div class="modal-body">
		<section class="content-item-details">
			<div class="provider-content-box" *ngFor="let providerType of providerTypes" (click)="createActivity(providerType)">
				<div class="row" >
					<div class="col-md-2 provider-image" [ngClass]="providerType.cssImgClass">
					</div>
					<div class="col-md-10">
						<h4>{{ providerType.name }}</h4>
						<p>{{ providerType.description }}</p>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="modal-footer"><button class="btn btn-secondary" (click)="cancel()">Cancel</button></div>
</div>
