import { Injectable } from '@angular/core';
import { JediConfig } from 'app-legacy/commons';
import { INotificationsUriService } from 'app/admin/notifications/INotificatiosUriService';
import { RouteParamsService } from 'app/route-params.service';

@Injectable()
export class NotificationsLearningUriService implements INotificationsUriService {
	private baseUri = JediConfig.api.base.replace(/\/$/g, '');

	constructor(private route: RouteParamsService) {}

	public getNotificationsTypeUri(): string {
		return `${this.baseUri}/notifications/activity/types`;
	}

	public getNotificationsSettingsUri(): string {
		return `${this.baseUri}/notifications/activity/${this.route.params().learningActivityId}`;
	}
}
