import { JediAuthService } from 'app-legacy/commons';

export interface TinyMceOptions {
	baseURL?: string;
	selector: string;
	plugins: string[];
	toolbar: string;
	menubar: boolean;
	height: number;
	resize: boolean;
	base_url?: string;
	suffix?: string;
}

export class TinyMceOptionModel {
	defaultOptions: TinyMceOptions;

	genericInfoOptions: TinyMceOptions;
}

export function getTinyMceOptions(jediAuth: JediAuthService): TinyMceOptionModel {
	const tinymceOptions: TinyMceOptions = {
		suffix: '.min',
		selector: 'textarea',
		plugins: ['lists'],
		// for the definition of the toolbar a " " (space) is required at the end of the sting in order for the last element to be displayed
		toolbar:
			'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
		menubar: false,
		height: 200,
		resize: false,
	};

	const tinymceGenericInfoOptions: TinyMceOptions = {
		suffix: '.min',
		selector: 'textarea',
		plugins: [],
		toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
		menubar: false,
		height: 155,
		resize: false,
	};

	if (jediAuth.isSysadmin()) {
		tinymceOptions.plugins.push('code');
		tinymceOptions.toolbar += '| code ';
		tinymceGenericInfoOptions.plugins.push('code');
		tinymceGenericInfoOptions.toolbar += '| code ';
	}

	return {
		defaultOptions: tinymceOptions,
		genericInfoOptions: tinymceGenericInfoOptions,
	};
}
