import { ProgressState } from './ParticipantModel';

export class ActivityProgress {
	progressState: ProgressState = ProgressState.None;

	progressDate: Date;

	score?: string = null;

	duration?: string = null;
}
