const newline = '<br/>';
// parse API errors responses into friendly messages
// parse APP /json/account error responses into friendly messages

/**
 * Convert JSON error models for both API and APP /json/account HTTP response errors
 * @param responseBody body of HTTP reponse
 * @param additionalMessage optional prefix string to the returned message
 */
export function getErrorResponse(responseBody: any, additionalMessage?: string): string {
	let message = '';
	if (typeof responseBody !== 'undefined' && responseBody) {
		if (responseBody.fieldErrors) {
			Object.keys(responseBody.fieldErrors).forEach(field => {
				const errorEntry = responseBody.fieldErrors[field];
				if (Array.isArray(errorEntry)) {
					message += errorEntry.join(`, ${field}` === 'NO_FIELD' ? newline : '');
				} else {
					message += errorEntry;
				}
				message += `${newline}`;
			});
		}

		if (responseBody.modelState) {
			Object.keys(responseBody.modelState).forEach(key => {
				const propertyErrors = responseBody.modelState[key];
				if (Array.isArray(propertyErrors)) {
					// message = message + " " + p + " - " + propertyErrors.join(", ");
					message = `${message} ${propertyErrors.join(', ')}`;
				}
			});
		}

		if ((responseBody.exceptionMessage || '').trim().length > 0) {
			message = `server error: ${responseBody.exceptionMessage}${newline}${message}`;
		}

		if (Array.isArray(responseBody.errors)) {
			message += responseBody.errors.join(`, ${newline}`);
		} else if ((responseBody.errors || '').trim().length > 0) {
			message += `${responseBody.errors}${newline}`;
		}

		if (message.trim().length === 0) {
			if (responseBody.message && responseBody.message.trim().length > 0) {
				message = responseBody.message;
			}
		}
	}

	if ((additionalMessage || '').trim().length > 0) {
		message = additionalMessage + message;
	}

	return message;
}
