/* eslint-disable class-methods-use-this */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { cleanStylesheet } from './styleHelpers';
import {
	ComponentRegistration,
	ModuleRegistration,
	RequiredDependency,
	StyleDeclaration,
	IStyleLoader,
	IStyleMap,
} from './types';

export class ModuleStyleLoader implements IStyleLoader {
	private styleMap: Array<IStyleMap> = [];

	private distinctStyles = [];

	constructor(private ngModule: Partial<ModuleRegistration>) {}

	public load() {
		const results = this.loadModuleStyles(this.ngModule);
		return results;
	}

	private loadModuleStyles(ngModule: Partial<ModuleRegistration>): string[] {
		const options = ngModule.settings;
		if (!options) {
			return [];
		}

		this.addStyles(options, extract(options.style));
		this.addStyles(options, extract(options.styles));

		if (Array.isArray(options.requires)) {
			this.loadModuleDependencyStyles(options.requires);
		}

		if (Array.isArray(options.registrations)) {
			this.loadModuleDeclarationStyle(options.registrations);
		}

		const styles = this.mergeStyleMap();
		return styles;
	}

	private loadComponentStyle(obj: { style?: StyleDeclaration; styles?: StyleDeclaration }) {
		if (!obj) {
			return [];
		}
		const styles = [...extract(obj.style), ...extract(obj.styles)];
		return styles.filter(s => s);
	}

	private loadModuleDeclarationStyle(registrations: ComponentRegistration[]) {
		registrations.forEach(reg => {
			if (reg.style || reg.styles) {
				const cs = this.loadComponentStyle(reg);
				this.addStyles(reg, cs);
			}
		});
	}

	private loadModuleDependencyStyles(requires: RequiredDependency[]) {
		requires.forEach(req => {
			if (typeof req === 'string') {
				// references are not supported
				return;
			}
			const modreq = req as ModuleRegistration;
			if (modreq.name) {
				const ms = this.loadModuleStyles(modreq);
				this.addStyles(modreq, ms);
			}
		});
	}

	private mergeStyleMap(): string[] {
		const styles = this.styleMap
			.map(s => s.styles)
			.reduce((a, i) => a.concat(...i), [])
			.filter(s => s)
			// reduce style size, disables source mapping
			.map(cleanStylesheet);
		const uniqueStyles = unique(styles);
		return uniqueStyles;
	}

	private addStyles(source: any, styles: string[]) {
		const cleanStyles = styles.map(cleanStylesheet);
		const uniqueStyles = unique(cleanStyles).filter(s => !this.distinctStyles.includes(s));
		this.distinctStyles.push(...uniqueStyles);

		const existingSource = this.styleMap.find(s => s.source === source);
		if (existingSource) {
			existingSource.styles.push(...uniqueStyles);
		} else {
			this.styleMap.push({ source, styles: uniqueStyles });
		}
	}
}

function unique(array: any[]): any[] {
	const seen = {};
	return array.filter(item => (seen.hasOwnProperty(item) ? false : (seen[item] = true)));
}

function extract(style: StyleDeclaration): string[] {
	if (!style) {
		return [];
	}
	if (typeof style === 'string') {
		return [style];
	}
	if (Array.isArray(style)) {
		const styles = [];
		style.map(entry => styles.push(...extract(entry)));
		return styles;
	}
	if (style.default && typeof style.default === 'string') {
		return [style.default];
	}
	return [];
}
