import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, HostListener, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'app-dropdown-select-panel',
	template: `<ng-template cdk-portal=""><ng-content></ng-content></ng-template> `,
})
export class DropdownSelectPanelComponent {
	@Input()
	public reference: HTMLElement;

	@ViewChild(CdkPortal)
	public contentTemplate: CdkPortal;

	constructor(protected overlay: Overlay, protected scrollOptions: ScrollStrategyOptions) {}

	protected overlayRef: OverlayRef;

	public showing = false;

	public show() {
		this.overlayRef = this.overlay.create(this.getOverlayConfig());
		this.overlayRef.attach(this.contentTemplate);
		this.syncWidth();
		this.overlayRef.backdropClick().subscribe(() => this.hide());
		this.showing = true;
	}

	@HostListener('window:resize')
	public onWinResize() {
		this.syncWidth();
	}

	protected getOverlayConfig(): OverlayConfig {
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.reference)
			.withPush(false)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
				{
					originX: 'start',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom',
				},
			]);

		return new OverlayConfig({
			scrollStrategy: this.scrollOptions.reposition(),
			positionStrategy,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
	}

	public hide() {
		this.overlayRef.detach();
		this.showing = false;
	}

	private syncWidth() {
		if (!this.overlayRef) {
			return;
		}

		const refRect = this.reference.getBoundingClientRect();
		this.overlayRef.updateSize({ width: refRect.width });
	}
}
