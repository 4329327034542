export class UserCredentials {
	Username = '';

	Password = '';

	RememberMe = false;

	LanguageType = '';

	constructor(username: string = '', password: string = '', rememberMe: boolean = false, languageType: string = '') {
		this.Username = username;
		this.Password = password;
		this.RememberMe = rememberMe;
		this.LanguageType = languageType;
	}
}
