import * as toastr from 'toastr';


export default {
	configure: [
		'$provide',
		function ($provide) {
			$provide.decorator('$log', [
				'$delegate',
				function ($delegate) {
					const original = {
						debug: $delegate.debug,
						error: $delegate.error,
						info: $delegate.info,
						log: $delegate.log,
						warn: $delegate.warn
					};
					$delegate.info = toastr.info;
					$delegate.log = toastr.info;
					$delegate.warn = toastr.warning || original.warn;
					return $delegate;
				}
			]);
		}
	]
};
