import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdminSharedModule } from '../../shared';
import { CreateUsersCreateTaskComponent } from './create-users-create-task/create-users-create-task.component';
import { CreateUsersValidateTaskComponent } from './create-users-validate-task/create-users-validate-task.component';
import { ImportUsersTaskDetailsComponent } from './import-users-task-details/import-users-task-details.component';
import { UpdateUsersCreateTaskComponent } from './update-users-create-task/update-users-create-task.component';
import { UpdateUsersValidateTaskComponent } from './update-users-validate-task/update-users-validate-task.component';
import { ImportUsersPageComponent } from './import-users-page/import-users-page.component';

@NgModule({
	declarations: [
		CreateUsersCreateTaskComponent,
		CreateUsersValidateTaskComponent,
		ImportUsersTaskDetailsComponent,
		UpdateUsersCreateTaskComponent,
		UpdateUsersValidateTaskComponent,
		ImportUsersPageComponent,
	],

	imports: [CommonModule, RouterModule, FormsModule, AdminSharedModule, MatProgressBarModule],
})
export class ImportUsersModule {}
