/* eslint-disable prettier/prettier */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { RouteStateNode } from './RouteStateNode';
import { IRouteDefinition } from './types';

export function convertComponentNameToHtmlTemplate(componentName: string) {
	const hn = componentName.replace(/([A-Z][a-z])/g, '-$1').toLowerCase();
	return `<${hn}></${hn}>`;
}

/**
 * converts input into a single word with camel case
 * @param str words separated by whitespace
 */
export function camelize(str: string): string {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
}

export function convertHtmlTemplateToComponentName(htmlName: string): string {
	const partialName = htmlName
		.split(/[<>]/gi)
		.filter(s => s)[0]
		.replace(/-/gi, ' ');
	return camelize(partialName);
}

export function graphTraverseInDepth(
	roots: IRouteDefinition[],
	getChildren: (n: IRouteDefinition) => IRouteDefinition[],
	fn: (node: RouteStateNode) => void
): void {
	const stack: Array<RouteStateNode> = [];
	stack.push(...roots.map(r => new RouteStateNode(r, null)));
	while (stack.length > 0) {
		const node = stack.pop();
		try {
			fn(node);
		} catch (error) {
			console.warn(error);
		}
		const children = getChildren(node.route);
		if (children?.length > 0) {
			node.children = children.map(c => new RouteStateNode(c, node));
			stack.push(...node.children);
		}
	}
}

let textFile = null;

function makeTextFile(text) {
	const data = new Blob([text], { type: 'text/plain' });

	// If we are replacing a previously generated file we need to
	// manually revoke the object URL to avoid memory leaks.
	if (textFile !== null) {
		window.URL.revokeObjectURL(textFile);
	}

	// eslint-disable-next-line compat/compat
	textFile = window.URL.createObjectURL(data);

	// returns a URL you can use as a href
	return textFile;
}

export function downloadText(text) {
	const create = document.getElementsByTagName('app-portal-layout')[0];

	create.addEventListener(
		'click',
		function clickHandler() {
			const link = document.createElement('a');
			link.setAttribute('download', 'info.txt');
			link.href = makeTextFile(text);
			document.body.appendChild(link);

			// wait for the link to be added to the document
			window.requestAnimationFrame(function nextFrame() {
				const event = new MouseEvent('click');
				link.dispatchEvent(event);
				document.body.removeChild(link);
			});
		},
		false
	);
}

/**
 * Non-secure hash function for comparing strings
 * @param input input string to hash
 * @param seed optional seed
 * @returns 53bit hash
 */
export function fastHash(input: string) {
	return extractRegistrationTokens(input);
	// return numericFashHash(input, 0);
}
function extractRegistrationTokens(input: string) {
	const replace = [
		/\/\/([^\n])*\n/gi,
		/\s+/gi,
		/function/gi,
		/ngModule/gi,
		/register[()]*/gi,
		/\(|\)/gi,
		/\{|\}/gi,
		'.',
		/,(.)*/gi,
	];
	let output = input;
	replace.forEach(token => {
		output = output.replace(token, ' ');
	});
	output = output.replace(/\s+/gi, ' ');
	if (output.indexOf('WEBPACK') > -1) return output;
	output = output.substring(0, 200);

	return output;
}
export function numericFashHash(input: string, seed = 0) {
	let h1 = 0xdeadbeef ^ seed;
	let h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < input.length; i++) {
		ch = input.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
	const hash = 4294967296 * (2097151 & h2) + (h1 >>> 0);
	return hash.toString(16);
}

function getDirectChild(obj, path) {
	if (!path.includes('[')) {
		return obj[path];
	}

	const arrayExpression = /(.*)\[([0-9]+)\]$/gi;
	const parts = arrayExpression.exec(path);
	const name = parts[1];
	const idx = parts[2];
	return obj[name][idx];
}

export function deepGet(obj: any, path: string) {
	if (!obj) return false;

	const parts = path.split('.');
	let current = obj;
	// last part is used separately to change the reference
	for (let i = 0; i < parts.length - 1; i += 1) {
		current = getDirectChild(current, parts[i]);
	}
	const last = parts[parts.length - 1];
	return current[last];
}

export function deepSet(obj: any, path: string, value: any): boolean {
	if (!obj) return false;

	const parts = path.split('.');
	let current = obj;
	// last part is used separately to change the reference
	for (let i = 0; i < parts.length - 1; i += 1) {
		current = getDirectChild(current, parts[i]);
	}
	const last = parts[parts.length - 1];
	current[last] = value;
	return true;
}

export function getFilesizeLabel(size): string {
	let number = +size;
	if (!number) {
		return '';
	}

	number >>= 10;

	if (number < 1024) {
		return `${number} KB`;
	}

	number >>= 10;

	if (number < 1024) {
		return `${number} MB`;
	}

	number >>= 10;
	return `${number} GB`;
}

export function uuidv4(): string {
	return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c =>
	  ((+c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (+c) / 4).toString(16)
	);
  }
  
let downloadableFile = null;
function createDownloadableFile(fileContent: Blob): string {
	if (downloadableFile !== null) {
		window.URL.revokeObjectURL(downloadableFile);
	}
	// eslint-disable-next-line compat/compat
	downloadableFile = window.URL.createObjectURL(fileContent);

	// returns a URL you can use as a href
	return downloadableFile;
}

export function downloadFile(fileContent: Blob, filename: string) {
	const link = document.createElement('a');
	link.download = filename;
	link.href = createDownloadableFile(fileContent);
	document.body.appendChild(link);
	link.click();
}

export function isEmpty(obj: any): boolean {
	if (!obj) return true;
	if (Array.isArray(obj)) {
		return obj.length <= 0;
	}
	return !Object.keys(obj).some(k => obj[k]);
}