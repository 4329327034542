import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { IRouteDefinition } from './types';

export class RouteStateNode {
	public fullUrl: string;

	public state: Ng1StateDeclaration;

	public children: RouteStateNode[];

	constructor(public route: IRouteDefinition, public parent: RouteStateNode) {}
}
