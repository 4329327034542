import * as utils from '../utils';
import serverSettings from '../jediServerSettings';

let base: string = serverSettings.APIBasePath || '';

// https support
if (location.protocol == 'https:') {
	base = base.replace('http://', 'https://');
}

const configApi = {
	base: base,

	anonymousPaths: [
		{ uri: '/api/platform/languages', method: 'GET' },
		{ uri: '/api/platform/messages', method: 'GET' },
		{ uri: '/api/platform/identityProvider/samples', method: 'GET' },
		{ uri: '/api/metadata', method: 'GET' },
	],

	account: base + 'account/details',
	accountChangePassword: base + 'account/changePassword',
	accountChangeLanguage: function (language) {
		return base + 'account/language?language=' + language;
	},
	logout: base + 'logout',
	hasLoggedIn: base + 'account/hasLoggedIn',
	users: {
		base: base + 'users/',
		item: function (userId) {
			return base + 'users/' + userId;
		},
		details: function (userId) {
			return base + 'users/' + userId + '/details';
		},
		resetPassword: function (userId) {
			return base + 'users/' + userId + '/resetPassword';
		},
		suspend: base + 'users/changeActivation?newState=Suspended',
		activate: base + 'users/changeActivation?newState=Active',
		deleteUsers: base + 'users/delete',
		sendActivation: base + 'users/sendActivation',
		validate: base + 'users/validate',
		sendEmailNotification: function () {
			return base + 'users/' + '/sendEmailNotification';
		},
	},
	organisation: {
		base: base + 'organisations/',
		item: function (organisationId) {
			return base + 'organisations/' + organisationId;
		},
		suggestions: function (query?) {
			return `${base}organisations/suggestions${utils.toQueryString({ query: query })}`;
		},
		billingPlan: function (organisationId) {
			return base + 'organisations/' + organisationId + '/billingPlan';
		},
		activationState: function (organisationId) {
			return base + 'organisations/' + organisationId + '/activation';
		},
		updateParent: function (organisationId) {
			return base + 'organisations/' + organisationId + '/updateParent';
		},
		getUserEmailTemplates: function (organisationId) {
			return base + 'organisations/' + organisationId + '/emailTemplates';
		},
		users: {
			base: function (organisationId) {
				return base + 'organisations/' + organisationId + '/users';
			},
			userSuggestions: function (organisationId, query?) {
				return `${base}organisations/${organisationId}/suggestions/users${utils.toQueryString({
					query: query,
				})}`;
			},
			groupSuggestions: function (organisationId, query = null) {
				return `${base}organisations/${organisationId}/suggestions/groups${utils.toQueryString({
					query: query,
				})}`;
			},
			tagSuggestions: function (organisationId, query?) {
				return `${base}organisations/${organisationId}/suggestions/tags${utils.toQueryString({
					query: query,
				})}`;
			}
		},
		getParentOrganisations: function () {
			return base + 'organisations/parents';
		},
	},
	auditLog: {
		base: base + 'audit/',
	},

	learning: {
		base: base + 'learning/',
		activities: {
			base: base + 'learning/activities',
			userRootContainersAuth: base + 'learning/userRootContainersAuth',
			item: function (activityId) {
				return `${base}learning/activities/${activityId}`;
			},
			deleteLearningActivity: function (activityId, deleteParent) {
				return `${base}learning/activities/${activityId}/delete?deleteParent=${deleteParent}`;
			},
			validateLearningActivityDeletion: function (activityId) {
				return `${base}learning/activities/${activityId}/delete/validate`;
			},
			itemsForUser: function (userId) {
				return `${base}learning/${userId}/hierarchy`;
			},
			progressChangedForUser: function (userId, timestamp) {
				return `${base}learning/${userId}/progress${utils.toQueryString({ timestamp: timestamp })}`;
			},
			categories: function (organisationId, query) {
				return `${base}organisations/${organisationId}/categories${utils.toQueryString({ query: query })}`;
			},
			itemForUser: function (userId, activityPath) {
				if (activityPath.indexOf('/') != 0) {
					return base + 'learning/' + userId + '/relation/path/' + activityPath;
				}
				return base + 'learning/' + userId + '/relation/path' + activityPath;
			},
			ltiRequest: function (userId, activityPath) {
				if (activityPath.indexOf('/') != 0) {
					return base + 'learning/launch/lti/' + userId + '/relation/path/' + activityPath;
				}
				return base + 'learning/launch/lti/' + userId + '/relation/path' + activityPath;
			},
			// todo: should be renamed to categorySuggestions
			itemVersions: function (activityId) {
				return `${base}learning/activities/${activityId}/versions`;
			},
			suggestions: function (organisationId, query?) {
				return `${base}organisations/${organisationId}/categories/suggestions${utils.toQueryString({
					query: query,
				})}`;
			},
			dropdownView: function (organisationId: number | string, showContainers: boolean | string) {
				return `${base}learning/activities/organisations/${organisationId}/versions?showContainers=${showContainers}`;
			},
			assignCategories: function () {
				return `${base}learning/activities/categories`;
			},
			removeCategories: function () {
				return `${base}learning/activities/categories/delete`;
			},
			nameSuggestions: function (organisationId, query?) {
				return `${base}organisations/${organisationId}/learning/activities/suggestions${utils.toQueryString({
					query: query,
				})}`;
			},
			// post method
			changeState: function () {
				return base + 'learning/activities/activationState';
			},
		},
		participants: {
			base: function (userId, activityId) {
				return `${base}participants${utils.toQueryString({ userId: userId, activityId: activityId })}`;
			},
			reports: base + 'participants/reports',
			exportStatements: function (activityId, activityVersion) {
				return (
					base +
					'participants/reports/exportstatements/' +
					activityId +
					(activityVersion ? '/' + activityVersion : '')
				);
			},
			exportStatementsForSelectedUser: function (userId) {
				return base + 'participants/reports/exportstatementsforuser/' + userId;
			},
			//exportStatementsForUsers: function (organisationId) { return base + 'participants/exportAllStatements/' + organisationId },
		},
	},

	groups: {
		base: base + 'groups',
		itemDetails: function (groupId) {
			return `${base}groups/${groupId}/details`;
		},
		item: function (groupId) {
			return `${base}groups/${groupId}`;
		},
		userAssociations: function (userId) {
			return `${base}groups/users/${userId}`;
		},
		users: `${base}groups/users`,
		deleteGroups: `${base}groups/delete`,
		children: `${base}groups/children`,
		parents: `${base}groups/parents`,
	},

	emailTemplates: {
		base: base + 'emailTemplates/',
		item: function (emailTemplateId) {
			return base + 'emailTemplates/' + emailTemplateId;
		},
		macros: function (organisationId) {
			return base + 'emailTemplates/macros?organisationId=' + organisationId;
		},
		sendTestEmail: base + 'emailTemplates/test',
		groups: base + 'emailTemplates/groups',
		updateDetails: base + 'emailTemplates/update',
	},

	utilities: {
		base: base + 'utilities/',
		roles: base + 'metadata/roles',
		areas: base + 'utilities/areas',
		metadata: {
			getEnum: function (enumTypeName) {
				return base + 'metadata/enum?type=' + enumTypeName;
			},
		},
		// TODO: should be moved to the serverSettings config
		uploadPath: base + 'upload',
		uploadPathCertificate: base + 'upload/certificate',
		uploadGraphic: base + 'upload/graphic',
		uploadPortalLogo: base + 'upload/logo',
		uploadLearningPackage: function (validate, currentTinCanId, providerCategory, providerType) {
			const query = {
				ResourceType: 'LearningPackage',
				Validate: validate,
				currentTinCanId: currentTinCanId,
				providerCategory: providerCategory,
				providerType: providerType,
			};
			return `${base}upload${utils.toQueryString(query)}`;
		},
	},
	platform: {
		base: base + 'platform/',
		languages: base + 'platform/languages?useReferrer=true',
		notificationMessages: function (clientDate, organisationId) {
			const query = { clientDate: clientDate, organisationId: organisationId };
			return `${base}platform/messages${utils.toQueryString(query)}`;
		},
		identityProviderSamples: base + 'platform/identityProviders/samples',
		activityHierarchyImport: function (organisationId) {
			return base + 'platform/organisation/' + organisationId + '/hierarchy';
		},
	},
	tasks: {
		base: base + 'tasks',
		item: function (taskId) {
			// task details are used for polling, client should not cache requests
			return base + 'tasks/' + taskId + `?timestamp=${new Date().toISOString()}`;
		},
		changeState: function (taskId, state) {
			return `${base}tasks/${taskId}/state${utils.toQueryString({ state: state })}`;
		},
	},
};
export default configApi;
