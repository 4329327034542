import serverSettings from './jediServerSettings';

const CommonsModuleConfig = [
	'$locationProvider',
	'jediHttpInterceptorProvider',
	'$httpProvider',
	'applicationInsightsServiceProvider',
	function($locationProvider, jediHttpInterceptorProvider, $httpProvider, insightsProvider) {
		$locationProvider.html5Mode({
			enabled: true,
			requireBase: true,
			rewriteLinks: true
		});

		var insightsKey = (serverSettings.ApplicationInsightsKey || '').trim();
		var insightsEnabled = insightsKey.length > 0;
		var insightsOptions = {
			applicationName: location.toString(),
			autoPageViewTracking: insightsEnabled,
			autoLogTracking: insightsEnabled,
			autoExceptionTracking: insightsEnabled,
			sessionInactivityTimeout: 1800000
		};

		jediHttpInterceptorProvider.options({ enableQueryAuth: false });

		insightsProvider.configure(insightsKey, insightsOptions);

		// set the interceptors
		// if (appFolderName !== 'public') {
		$httpProvider.interceptors.push('jediHttpInterceptor');
		// }

		delete $httpProvider.defaults.headers.common['X-Requested-With'];
	}
];
export default CommonsModuleConfig;
