/* eslint-disable */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-prototype-builtins */
import { RouteStateNode } from './RouteStateNode';
import { ComponentRegistration, ModuleRegistration, RequiredDependency, StyleDeclaration } from './types';

function unique(array: any[]): any[] {
	const seen = {};
	return array.filter(item => (seen.hasOwnProperty(item) ? false : (seen[item] = true)));
}

function getStyleId(name: string): string {
	const styleName = (name || '').replace(/\./gi, '-');
	const styleId = `dynamic-style-${styleName}`;
	return styleId;
}

function extract(style: StyleDeclaration): string[] {
	if (!style) {
		return [];
	}
	if (typeof style === 'string') {
		return [style];
	}
	if (Array.isArray(style)) {
		const styles = [];
		style.map(entry => styles.push(...extract(entry)));
		return styles;
	}
	if (style.default && typeof style.default === 'string') {
		return [style.default];
	}
	return [];
}

export function createStyle(style: string, name: string, insertLast = false) {
	if ((style || '').trim().length < 1) {
		return;
	}
	// custom server-side styling is injected, it should take precedence over frontend styles
	const serverStyleElement = document.querySelector('head link#server-styling');
	if (!serverStyleElement) {
		throw new Error('link tag for server styling is required');
	}

	const styleId = getStyleId(name);
	const dynamicStyleElement = document.getElementById(styleId) || document.createElement('style');

	dynamicStyleElement.setAttribute('id', styleId);
	dynamicStyleElement.setAttribute('class', 'dynamic-style');

	if (dynamicStyleElement.innerHTML === style) {
		return;
	}
	if (insertLast) {
		document.head.appendChild(dynamicStyleElement);
	} else {
		document.head.insertBefore(dynamicStyleElement, serverStyleElement);
	}

	dynamicStyleElement.innerHTML = style;
}

export function removeStyle(name: string) {
	const styleId = getStyleId(name);
	const styleElement = document.getElementById(styleId);
	if (styleElement) {
		styleElement.parentElement.removeChild(styleElement);
	}
}

function loadComponentStyle(obj: { style?: StyleDeclaration; styles?: StyleDeclaration }) {
	if (!obj) {
		return [];
	}
	const styles = [...extract(obj.style), ...extract(obj.styles)];
	return styles.filter(s => s);
}

export function createRouteStyle(node: RouteStateNode): void {
	const styles = loadComponentStyle(node.route.component);
	const style = styles.join('\r\n');
	const { name } = node.state;
	createStyle(style, name);
}

export function removeRouteStyle(node: RouteStateNode): void {
	removeStyle(node?.state?.name);
}

const styleMap: Array<{ source: any; styles: string[] }> = [];

const distinctStyles = [];
// @ts-ignore
window.styleMap = styleMap;
// @ts-ignore
window.distinctStyles = distinctStyles;
function addStyles(source: any, styles: string[]) {
	const cleanStyles = styles.map(cleanStylesheet);
	const uniqueStyles = unique(cleanStyles).filter(s => !distinctStyles.includes(s));
	distinctStyles.push(...uniqueStyles);

	const existingSource = styleMap.find(s => s.source === source);
	if (existingSource) {
		existingSource.styles.push(...uniqueStyles);
	} else {
		styleMap.push({ source, styles: uniqueStyles });
	}
}

export function loadModuleStyles(ngModule: ModuleRegistration): string[] {
	const options = ngModule.settings;
	if (!options) {
		return [];
	}
	addStyles(options, extract(options.style));
	addStyles(options, extract(options.styles));

	if (Array.isArray(options.requires)) {
		loadModuleDependencyStyles(options.requires);
	}

	if (Array.isArray(options.registrations)) {
		loadModuleDeclarationStyle(options.registrations);
	}

	const styles = mergeStyleMap(styleMap);
	return styles;
}

const regexMultiline = /\/\*[^*]*\*\//gi;
export function cleanStylesheet(style: string): string {
	if (!style.replace) return style;
	return (
		style
			// remove multiline comments
			.replace(regexMultiline, '')
			// reduce whitespace
			.replace(/(\s)+/gi, '$1')
			.replace(/\n}/gi, ' }')
	);
}

function mergeStyleMap(map: Array<{ source: any; styles: string[] }>): string[] {
	const styles = styleMap
		.map(s => s.styles)
		.reduce((a, i) => a.concat(...i), [])
		.filter(s => s)
		// reduce style size, disables source mapping
		.map(cleanStylesheet);
	const uniqueStyles = unique(styles);
	return uniqueStyles;
}

function loadModuleDeclarationStyle(registrations: ComponentRegistration[]) {
	registrations.forEach(reg => {
		if (reg.style || reg.styles) {
			const cs = loadComponentStyle(reg);
			addStyles(reg, cs);
		}
	});
}

function loadModuleDependencyStyles(requires: RequiredDependency[]) {
	requires.forEach(req => {
		const modreq = req as ModuleRegistration;
		if (modreq.name) {
			const ms = loadModuleStyles(modreq);
			addStyles(modreq, ms);
		}
		const comreq = req as ComponentRegistration;

		if (comreq.style || comreq.styles) {
			const cs = loadComponentStyle(comreq);
			addStyles(comreq, cs);
		}
	});
}
