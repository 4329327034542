import * as angular from 'angular';
import { IHttpService, ILocationService, ILogService, IPromise } from 'angular';
import { GroupModel, GroupModelExtended } from '@base/models';
import { JediConfig, ContextManagerService } from 'app-legacy/commons';

export const GroupActionsServiceRegistration = {
	register: function (ngModule) {
		ngModule.service('groupActionsService', GroupActionsService);
	},
};

export class GroupActionsService {
	static $inject = ['$http', '$log', '$location', 'jediContext'];
	constructor(
		private $http: IHttpService,
		private $log: ILogService,
		private $location: ILocationService,
		private jediContext: ContextManagerService
	) {}

	createGroup(groupModel: GroupModel): IPromise<any> {
		if (!groupModel || (groupModel.name || '').trim().length == 0) {
			this.$log.warn('group name is mandatory');
			return Promise.reject('group name is mandatory');
		}
		const ctx = this.jediContext.getCurrentContext();
		const organisation = ctx.organisation;
		const group = angular.copy(groupModel);
		group.organisationId = organisation.id;
		return this.$http.post(JediConfig.api.groups.base, group).then(response => response.data || groupModel);
	}

	async deleteGroups(groups: Array<GroupModelExtended>): Promise<string> {
		if (!groups || groups.length <= 0) {
			this.$log.warn('no groups provided for deletion');
			return Promise.reject('no groups provided for deletion');
		}

		if (groups.some(g => g.activities && g.activities.length > 0)) {
			const error =
				'Cannot delete groups with activities assigned. Groups can be removed from the activities edit page.';
			this.$log.warn(error);
			return Promise.reject(error);
		}

		if (groups.some(e => e.children && e.children.length > 0)) {
			const error = 'Cannot delete groups with children assigned. Remove association first.';
			this.$log.warn(error);
			return Promise.reject(error);
		}
		if (groups.some(e => e.parents && e.parents.length > 0)) {
			const error = 'Cannot delete groups with parents assigned. Remove association first.';
			this.$log.warn(error);
			return Promise.reject(error);
		}

		const message =
			groups.length > 1
				? 'Are you sure you want to delete these groups?'
				: 'Are you sure you want to delete this group?';
		if (confirm(message)) {
			const groupIds = groups.map(e => e.id);
			const response = await this.$http.post<string>(JediConfig.api.groups.deleteGroups, groupIds); //.then(result => );
			this.$log.info('group(s) deleted');
			return response.data;
		}
		return Promise.reject('cancelled');
	}

	editGroup(group: GroupModel) {
		if (group && group.id) {
			const url = `/Administration/organisations/${group.organisationId}/groups/edit/${group.id}`;
			this.$location.url(url);
		}
	}

	async addParent(sources, targets): Promise<any> {
		const groupAssociation = { targetIds: [], sourceIds: [] };

		groupAssociation.sourceIds = sources.map(g => g.id);
		groupAssociation.targetIds = targets.map(g => g.id);

		const response = await this.$http({
			method: 'put',
			url: JediConfig.api.groups.parents,
			data: groupAssociation,
		});
		return response.data;
	}

	async addChild(sources, targets): Promise<any> {
		const groupAssociation = { sourceIds: [], targetIds: [] };
		groupAssociation.sourceIds = sources.map(s => s.id);
		groupAssociation.targetIds = targets.map(t => t.id);

		const response = await this.$http({
			method: 'put',
			url: JediConfig.api.groups.children,
			data: groupAssociation,
		});
		return response.data;
	}

	async removeChild(parent: GroupModel, child: GroupModel): Promise<any> {
		const groupAssociation = { targetIds: [child.id], sourceIds: [parent.id] };

		const response = await this.$http({
			method: 'post',
			url: JediConfig.api.groups.children,
			data: groupAssociation,
		});
		return response.data;
	}
	async getGroupDetails(groupId: number): Promise<GroupModelExtended> {
		const response = await this.$http.get<GroupModelExtended>(JediConfig.api.groups.itemDetails(groupId));
		return response.data;
	}

	async updateGroupDetails(group: GroupModel): Promise<GroupModel> {
		const response = await this.$http.patch<GroupModel>(JediConfig.api.groups.item(group.id), group);
		return response.data;
	}
}
