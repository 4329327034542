import { HttpResponse } from '@angular/common/http';
import { GenericResponseModel } from 'app/public/account/models/GenericResponseModel';
import { ValidationResult } from '../../models/ValidationResult';

export class AccountServiceResponseAdapter {
	transformError(response: HttpResponse<any>): GenericResponseModel {
		const result = new GenericResponseModel();
		result.isSuccess = false;

		if (response.status > 0) {
			result.errors = ValidationResult.GenericError(`request ${response.status} - ${response.statusText}`);
		} else {
			result.errors = ValidationResult.GenericError(`unknonw error for request ${response.url}`);
		}

		return result;
	}

	transformSuccess(response: HttpResponse<any>): GenericResponseModel {
		const result = new GenericResponseModel();

		if (!response) {
			result.isSuccess = false;
			return result;
		}

		result.isSuccess = true;

		const viewResult = response.body;

		result.redirect = viewResult.RedirectUrl;
		result.model = viewResult.Model;
		result.errors = this.getErrorModel(viewResult);

		result.isSuccess = !result.errors.hasErrors;

		if (result.isSuccess) {
			if (result.model) {
				if (result.model.ChangePassword === true) {
					result.isRedirect = true;
					result.redirect = `/Account/ChangePassword?userId=${result.model.UserId || ''}`;
				}
			}

			result.isRedirect = (result.redirect || '').trim().length > 0;
			result.redirect = (result.redirect || '').replace('/json/', '/');
		}
		return result;
	}

	private mapValidationError(field: string, error): { field: any | null; message: string; exception: any } {
		const model = {
			field: (field || '').trim().length > -1 ? field : null,
			message: error.ErrorMessage || null,
			exception: error.Exception || null,
		};
		if (model.message === null && error.Exception) {
			model.message = error.Exception.Message;
		}
		return model;
	}

	private getErrorModel(viewResult): ValidationResult {
		let errors: { field: any | null; message: string; exception: any | null }[] = [];
		if (viewResult.Validation) {
			Object.keys(viewResult.Validation).forEach(key => {
				const entry = viewResult.Validation[key];
				if (entry) {
					const messages = entry.map(error => this.mapValidationError(key, error));
					errors = errors.concat(messages);
				}
			});
		}
		if (viewResult.Model) {
			if (viewResult.Model.Errors) {
				viewResult.Model.Errors.forEach(error => {
					errors.push({ field: null, message: error, exception: null });
				});
			}
		}
		if (viewResult.ErrorCode) {
			let errorText = viewResult.ErrorMessage;
			if (!errorText) {
				errorText = `A generic error has occured with error code: ${viewResult.ErrorCode}`;
			}
			errors.push({ field: null, message: errorText, exception: null });
		}

		const fieldErrors = {};

		const genericErrors: typeof errors = [];

		errors.forEach(error => {
			if (!!error.field && (error.field || '').trim().length > 0) {
				fieldErrors[error.field] = (fieldErrors[error.field] || []).concat(error);
			} else {
				genericErrors.push(error);
			}
		});
		return {
			generic: genericErrors,
			fields: fieldErrors,
			hasErrors: errors.some(e => e.message?.length || e.exception?.length),
		};
	}
}
