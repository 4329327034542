<div class="paginator-container" >
    <div class="paginator-navigation" *ngIf="totalPages.length > 1">
        <button class="paginator-button" [ngClass]="{'disabled': selectedPage === 1}" (click)="previousPage()">
            &laquo;
        </button>

        <button class="paginator-button" [ngClass]="{'disabled': selectedPage === 1}" (click)="paginateData(1)">
            1
        </button>

        <button class="paginator-button disabled" *ngIf="more.left">
            &#8230;
        </button>

        <button class="paginator-button" [ngClass]="{'disabled': selectedPage === number}" (click)="paginateData(number)"
            *ngFor="let number of shownPageNumbers">
            {{number}}
        </button>

        <button class="paginator-button disabled" *ngIf="more.right">
            &#8230;
        </button>

        <button class="paginator-button" [ngClass]="{'disabled': selectedPage === lastPage}" (click)="paginateData(lastPage)">
            {{lastPage}}
        </button>

        <button class="paginator-button" [ngClass]="{'disabled': selectedPage === lastPage}" (click)="nextPage()">
            &raquo;
        </button>
        
    </div>
    <div class="paginator-select-container">
        <p>show results</p>
        <app-dropdown 
            [ngModel]="selectedSizeOption" 
            (ngModelChange)="updatePageSize($event)"
            [options]="resultSizeOptions"
            [viewValue]="setViewValue"
        ></app-dropdown>
    </div>
</div>
