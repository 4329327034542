export enum VersionState {
	None = 'None',
	Created = 'Created',
	Deleted = 'Deleted',
	Archived = 'Archived',
	Readonly = 'Readonly',
	Unchanged = 'Unchanged',
	Processing = 'Processing',
	Inactive = 'Inactive',
	Staged = 'Staged',
	Invalid = 'Invalid',
	Updating = 'Updating',
}
