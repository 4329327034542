/* eslint-disable max-classes-per-file */
import { VersionState } from './VersionState';

export class ApiQueryModel<T> {
	filter: T = {} as T;

	sorting: T = {} as T;

	states: VersionState[] = [];

	itemsPerPage?: number | null = 10;

	skip?: number | null;

	take?: number | null = 10;

	useDecoding?: boolean;
}

export class ApiListResponseModel<T> {
	public count: number;

	public items: Array<T>;

	public options: ApiQueryModel<any>;
}

export class ApiResultModel {
	warnings: string[];

	errors: string[];

	errorType: string;

	fieldErrors: { [key: string]: string[] };

	message: string;

	static hasError(result: any) {
		return (
			(result.warnings && result.warnings.length > 0) ||
			(result.errors && result.errors.length > 0) ||
			result.errorType ||
			result.fieldErrors ||
			result.message
		);
	}
}
