import { TransitionService } from '@uirouter/angularjs';
import { IController } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { ContextManagerService, ContextType, JediAuthService } from 'app-legacy/commons';

export const ContextManagerComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('jediContextManager', {
			controller: ContextManagerMenuController,
			template: require('./jediContextManager.html'),
		});
	},
	name: 'jediContextManager',
	style: require('./style.scss'),
};

class ContextManagerMenuController implements IController {
	static $inject = ['jediContext', 'jediAuth', '$transitions'];

	isContextMenuVisible = false;
	showPortalLink: boolean;
	transitionCallback: any;
	constructor(
		private jediContext: ContextManagerService,
		private jediAuth: JediAuthService,
		private $transitions: TransitionService
	) {}
	$onInit(): void {
		this.transitionCallback = this.$transitions.onSuccess({}, () => {
			this.$onChanges();
		});
	}
	$onDestroy(): void {
		if (typeof this.transitionCallback === 'function') {
			this.transitionCallback();
		}
	}
	get context(): any {
		return this.jediContext?.getCurrentContext();
	}
	get organisation(): any {
		return this.context?.organisation;
	}
	get parentOrganisation(): any {
		return this.context?.organisation?.parentOrganisation;
	}

	$onChanges() {
		this.showPortalLink =
			(this.currentUserIsCorporateUser() || this.currentUserIsCorporateAdmin()) &&
			!this.currentUserIsPlatformAdmin();
	}

	toggleContextMenu() {
		this.isContextMenuVisible = !this.isContextMenuVisible;
		this.$onChanges();
	}

	changeContext(type: string) {
		if (type == 'participant') {
			this.jediContext.changeContext({ type: ContextType.Participant });
		} else if (type === 'platform') {
			this.jediContext.changeContext({ type: ContextType.Platform });
		} else if (type === 'parent' && this.parentOrganisation?.id) {
			this.jediContext.changeContext({ type: ContextType.Corporate, organisation: this.parentOrganisation });
		}
		this.isContextMenuVisible = false;
		this.$onChanges();
	}

	currentUserIsSysadmin() {
		return this.jediAuth.isSysadmin();
	}
	currentUserIsPlatformAdmin() {
		return this.jediAuth.isPlatformAdmin();
	}
	currentUserIsCorporateAdmin() {
		return this.jediAuth.isCorporateAdmin();
	}

	currentUserIsCorporateUser() {
		return this.jediAuth.isCorporateUser();
	}

	currentUserIsParent() {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		const organisation = this.organisation;

		if (user && user.organisation && organisation) {
			if (organisation.parentId === user.organisation.id) {
				return true;
			}
			const isCorporate = this.jediAuth.isCorporateAdmin() || this.jediAuth.isCorporateUser();
			if (
				organisation.id == user.organisation.id &&
				(organisation.organisationUnitCount > 0 || organisation.oUs.length)
			) {
				if (isCorporate) {
					return true;
				}
			}
		}
		return false;
	}

	showParentOrganisationChange() {
		const parent = this.parentOrganisation;
		const organisation = this.organisation;
		if (this.context.type !== 'platform') {
			if (parent || (organisation && organisation.parentId)) {
				if (this.currentUserIsParent() || this.currentUserIsPlatformAdmin()) {
					return true;
				}
			}
		}
		return false;
	}

	showPlatformChange() {
		const isPlatformAdmin = this.jediAuth.isPlatformAdmin();
		const context = this.jediContext.getCurrentContext();
		return context?.type !== 'platform' && isPlatformAdmin;
	}
}
