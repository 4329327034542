import { ComponentRegistration } from '~root/commons';
import { getFilesizeLabel } from '~root/commons/helpers';

export const ConvertFilesizeFilterRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.filter('convertFilesize', ConvertFilesizeFilter);
	},
};

const ConvertFilesizeFilter = [
	function () {
		var convertFilesize: any = function (size) {
			return getFilesizeLabel(size);
		};
		convertFilesize.$stateful = true;
		return convertFilesize;
	},
];
