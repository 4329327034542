import { ComponentRegistration } from '~root/commons';
import { IController } from 'angular';

export const LoadingPanelComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			.component('appLoadingPanel', {
				controller: LoadingPanelController,
				template: require('./loadingPanelView.html'),
			})
			.service('jediLoadingPanel', LoadingPanelService);
	},
	style: require('./style.scss'),
};
export class LoadingPanelService {
	private handlersShow = new Array<(message: string) => void>();
	private handlersHide = new Array<() => void>();
	private showCount = 0;
	private hideCount = 0;

	show(message: string = null) {
		this.showCount++;
		this.handlersShow.forEach(fn => fn(message));
	}

	hide(force = false) {
		this.hideCount++;

		if (force || this.hideCount >= this.showCount) {
			this.handlersHide.forEach(fn => fn());
			this.hideCount = 0;
			this.showCount = 0;
		}
	}

	onShow(fn: (m: string) => void) {
		if (typeof fn === 'function') {
			this.handlersShow.push(fn);
		}
	}

	onHide(fn: () => void) {
		if (typeof fn === 'function') {
			this.handlersHide.push(fn);
		}
	}
}
class LoadingPanelController implements IController {
	static $inject = ['jediLoadingPanel'];
	public message = 'loading...';
	isVisible = false;
	start = new Date().getTime();
	constructor(private loadingPanelService: LoadingPanelService) {
		this.loadingPanelService.onShow(message => this.show(message));
		this.loadingPanelService.onHide(() => this.hide());
	}
	show(message) {
		// console.log(`show @${this.start}  d:${new Date().getTime() - this.start}`);
		this.isVisible = true;
		this.message = message || 'loading ...';
	}
	hide() {
		// console.log(`hide @${this.start}  d:${new Date().getTime() - this.start}`);
		this.isVisible = false;
	}
}
