/* eslint-disable max-classes-per-file */
import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export class HttpErrorRule {
	name: string;

	rule: (request: HttpRequest<any>, error: HttpErrorResponse) => boolean;
}

@Injectable()
export class HttpErrorRulesService {
	private ignoreRules: HttpErrorRule[] = [];

	public AddRule(httpErrorRule: HttpErrorRule): void {
		this.ignoreRules.push(httpErrorRule);
	}

	public GetRules(): HttpErrorRule[] {
		return this.ignoreRules;
	}

	public DeleteRule(ruleName: string): void {
		this.ignoreRules.splice(
			this.ignoreRules.findIndex(r => r.name === ruleName),
			1
		);
	}
}
