import { IStorage } from './IStorage';
const jsonpack = require('~root/vendor/vanilla/jsonpack/main');

export class JsonPackStorageWrapper implements IStorage {
	constructor(private _store: IStorage) {}
	get(key: string): any {
		const value = this._store.get(key);
		if (value) {
			const raw = jsonpack.unpack(value);
			return raw;
		}
		return null;
	}
	set(key: string, value: any) {
		if (value) {
			const storeValue = jsonpack.pack(value);
			return this._store.set(key, storeValue);
		}
		return false;
	}
	remove(key: string) {
		return this._store.remove(key);
	}
	clear() {
		return this._store.clear();
	}
	getKeys(filter: string) {
		return this._store.getKeys(filter);
	}
}
