import { Injectable } from '@angular/core';
import { IPromise } from 'angular';
import { HttpClient } from '@angular/common/http';
import { ServiceHandler } from 'app/public/account/services/ServiceHandler';
import { toQueryString } from 'app-legacy/commons';
import { GenericResponseModel } from 'app-legacy/public/account/models/ResponseModel';
import { AccountServiceResponseAdapter } from './AccountServiceResponseAdapter';
import { UserCredentials } from '../../models/UserCredentials';
import { ValidationResult } from '../../models/ValidationResult';
import { ChangeEmailModel } from '../../models/ChangeEmail';

@Injectable({
	providedIn: 'root',
})
export class AccountApiService extends ServiceHandler {
	private adapter = new AccountServiceResponseAdapter();

	search: string[];

	params: { [index: string]: string };

	constructor(private http: HttpClient) {
		super();
		this.search = window.location.search.replace('?', '').split('&');
		this.params = this.selectQueryParams(this.search);
	}

	private wrap(request: IPromise<any>): IPromise<GenericResponseModel> {
		this.handleStart();
		return request.then(
			success => {
				const result = this.adapter.transformSuccess(success);
				this.handleResult(result);
				return result;
			},
			error => {
				const result = this.adapter.transformError(error);
				this.handleError(result);
				return result;
			}
		);
	}

	getLogin(): IPromise<GenericResponseModel> {
		return this.wrap(
			this.http.get(`/json/Account/Login${toQueryString(this.params)}`, { observe: 'response' }).toPromise()
		);
	}

	login(model: UserCredentials): IPromise<GenericResponseModel> {
		const result = this.wrap(
			this.http
				.post(`/json/Account/Login${toQueryString(this.params)}`, model, { observe: 'response' })
				.toPromise()
		);
		return result;
	}

	logout(): IPromise<GenericResponseModel> {
		const result = this.wrap(this.http.get('/json/Account/Logoff', { observe: 'response' }).toPromise());
		return result;
	}

	private selectQueryParams(search: string[]): { [index: string]: string } {
		const result: { [index: string]: string } = {};

		search.forEach(x => {
			const index = x.slice(0, x.indexOf('=')).toLowerCase();
			const value = x.slice(x.indexOf('=') + 1);
			result[index] = value;
		});

		return result;
	}

	public changeEmail(model: ChangeEmailModel): IPromise<GenericResponseModel> {
		const errorPromise = this.toPromiseError(this.validateChangeEmailModel(model));
		if (errorPromise !== null) {
			return errorPromise;
		}
		const query = toQueryString(model);
		return this.wrap(
			this.http.post(`/json/Account/ConfirmEmailAddressChange${query}`, null, { observe: 'response' }).toPromise()
		);
	}

	private toPromiseError(validation: ValidationResult): IPromise<GenericResponseModel> {
		if (validation.hasErrors) {
			const q = new Promise<GenericResponseModel>((resolve, reject) => {
				if (validation.hasErrors) {
					resolve({ errors: validation, isSuccess: false });
					return;
				}
				reject();
			});

			return q;
		}
		return null;
	}

	private validateChangeEmailModel(model: ChangeEmailModel): ValidationResult {
		if (!model) {
			return ValidationResult.GenericError('change email model is required');
		}
		if (!model.userId) {
			return ValidationResult.FieldError('userId', 'userId is required');
		}
		if (!model.code) {
			return ValidationResult.FieldError('code', 'verification code is required');
		}
		if (!model.newEmail) {
			return ValidationResult.FieldError('newEmail', 'new email address is required');
		}
		return ValidationResult.NoError();
	}
}
