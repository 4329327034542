/* eslint-disable @typescript-eslint/no-use-before-define */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JediConfig, toQueryString } from 'app-legacy/commons';
import { RouteParamsService } from 'app/route-params.service';
import moment from 'moment';
import { map, publishReplay, refCount, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
	ReportingStatistic,
	IMetricData,
	MonthlyReportingStatistic,
	IReportingStatistic,
	IMonthlyReportOfType,
} from './api-models';
import { StatisticType } from './statistic-type';

interface CacheEntryData {
	currentStatistics$: Observable<ReportingStatistic>;
	monthlyStatistics$: Observable<MonthlyReportingStatistic[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ReportingStatisticsService {
	private baseUrl = `${JediConfig.api.base.replace(/\/+$/gi, '')}`;

	private cache = new Map<number, CacheEntryData>();

	constructor(private httpClient: HttpClient, private routeParams: RouteParamsService) {}

	private getCacheEntry() {
		const { organisationId } = this.routeParams.params();
		if (!this.cache.has(organisationId)) {
			this.cache.set(organisationId, { currentStatistics$: null, monthlyStatistics$: null });
		}
		const entry = this.cache.get(organisationId);
		return {
			organisationId,
			...entry,
		};
	}

	getHistoricalStatistics() {
		const entry = this.getCacheEntry();
		if (!entry.monthlyStatistics$) {
			entry.monthlyStatistics$ = this.requestHistoricalStatistics(entry.organisationId).pipe(
				publishReplay(5),
				refCount()
			);
		}
		return entry.monthlyStatistics$;
	}

	getHistoricalStatisticsOfType<T extends IMetricData>(type: StatisticType) {
		return this.getHistoricalStatistics().pipe(map(reports => getMonthlyOfType<T>(reports, type)));
	}

	getLatestStatistics(): Observable<ReportingStatistic> {
		const entry = this.getCacheEntry();
		if (!entry.currentStatistics$) {
			entry.currentStatistics$ = this.requestLatestStatistics(entry.organisationId).pipe(shareReplay(1));
		}
		return entry.currentStatistics$;
	}

	getLatestStatisticsOfType<T extends IMetricData>(type: StatisticType) {
		return this.getLatestStatistics().pipe(map(m => getInstance<T>(m, type)));
	}

	private requestHistoricalStatistics(organisationId: number) {
		const getReportingStatisticsQuery = {
			organisationId,
			from: moment().subtract(12, 'months').utc().format(),
			until: moment().utc().format(),
		};

		return this.httpClient.get<MonthlyReportingStatistic[]>(
			`${this.baseUrl}/reporting/statistics/history${toQueryString(getReportingStatisticsQuery)}`
		);
	}

	private requestLatestStatistics(organisationId: number) {
		return this.httpClient.get<ReportingStatistic>(
			`${this.baseUrl}/reporting/statistics/current?organisationId=${organisationId}`
		);
	}
}

function getMonthlyOfType<T extends IMetricData>(
	monthly: MonthlyReportingStatistic[],
	type: StatisticType
): IMonthlyReportOfType<T>[] {
	const typedReport: IMonthlyReportOfType<T>[] = monthly.map(entry => ({
		month: entry.month,
		statistics: getInstance<T>(entry, type),
	}));
	return typedReport;
}

function getInstance<T extends IMetricData>(statistic: IReportingStatistic, type: StatisticType) {
	const instance = statistic.metrics.find(m => m.metricsName === type);
	if (instance) {
		return instance.metricsData as T;
	}
	return null;
}

export const monthLabels: string[] = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'June',
	'July',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
