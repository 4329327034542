/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';
import { MediaResourceModel } from '@base/models';
import { LocalizedComponent } from 'app/admin/shared';

@Component({
	selector: 'app-edit-certificate-settings-localized',
	templateUrl: './edit-certificate-settings-localized.component.html',
})
export class EditCertificateSettingsLocalizedComponent
	extends LocalizedComponent<MediaResourceModel>
	implements OnInit
{
	getDefaultState(): MediaResourceModel {
		return null;
	}

	ngOnInit(): void {
		dev.log(`edit-certificate localized ${this.id} initialized`);
	}

	writeValue(obj: MediaResourceModel): void {
		dev.log(`edit-certificate localized ${this.id} changed localization`, obj);
		this.currentValue = obj;
	}

	onUpload($event: MediaResourceModel) {
		this.currentValue = $event;
		this.onChangeValue($event);
	}
}
