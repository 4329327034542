import * as angular from 'angular';
import { ComponentRegistration } from '~root/commons';
import { JediConfig } from '../config/config';

const JediMultiselectComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			//registrations
			.directive('jediMultiselect', JediMultiselectDirective)
			.directive('compile', JediMultiselectCompileOverride);
	},
	styles: [require('./style.scss')],
};
export default JediMultiselectComponent;

const JediMultiselectCompileOverride = [
	'$compile',
	function ($compile) {
		return function (scope, element, attrs) {
			scope.$watch(
				function (scope) {
					// watch the 'compile' expression for changes
					return scope.$eval(attrs.compile);
				},
				function (value) {
					// when the 'compile' expression changes
					// assign it into the current DOM
					element.html(value);
					// compile the new DOM and link it to the current
					// scope.
					// NOTE: we only compile .childNodes so that
					// we don't get into infinite loop compiling ourselves
					$compile(element.contents())(scope);
				}
			);
		};
	},
];

const JediMultiselectDirective = [
	'$http',
	function ($http) {
		return {
			restrict: 'E',
			scope: {
				items: '=',
				initial: '<',
			},
			template: require('./jediMultiselect.html'),

			link: function (scope, element, attributes) {
				let initialValues: string[] = [];
				if (Array.isArray(scope.initial)) {
					initialValues = scope.initial
						.filter(e => typeof e === 'string' || e.selected)
						.map(e => (typeof e === 'string' ? e : e.name));
				} else {
					initialValues = (attributes.initialValue || '').split(',');
				}
				var url = attributes.url;
				scope.selection = [];

				if (scope.items && !url) {
					scope.availableItems = scope.items.map(mapItem);
				} else if (attributes.get == false) {
					scope.availableItems = [];
				} else if (typeof url === 'string') {
					$http.get(url).then(function (res) {
						scope.availableItems = res.data.items.map(mapItem);
					});
				}

				scope.$on(JediConfig.events.clearFilter, clearFilter);

				scope.buttonLabel = '<strong>' + attributes.label + '</strong>';

				scope.onSelect = function (reset) {
					if (reset === true) {
						clearFilter();
					}
					if (attributes.select) {
						scope.$parent.$eval(attributes.select, { item: scope.selection });
					}
				};

				function mapItem(item) {
					const mapped = angular.copy(item);
					mapped.selected = mapped.selected || initialValues.some(value => item.name === value);
					return mapped;
				}

				function clearFilter(event?) {
					scope.selection = [];
					scope.availableItems.forEach(item => (item.selected = false));
				}
			},
		};
	},
];
