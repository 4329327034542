import { DeviceOrientationType, DeviceType, OSType, UserAgentModel } from './UserAgentModel';

export class UserAgentDetector {
	public static Bootstrap(document: Document) {
		const detector = new UserAgentDetector();
		const ua = detector.getUserAgent();

		if (!document.body.classList.contains(ua.device)) {
			document.body.classList.add(ua.device);
		}
		if (!document.body.classList.contains(ua.os)) {
			document.body.classList.add(ua.os);
		}
	}

	public static Detect(): UserAgentModel {
		return new UserAgentDetector().getUserAgent();
	}

	ua: string;
	constructor() {
		this.ua = navigator.userAgent.toLowerCase();
	}
	getUserAgent() {
		const model = new UserAgentModel();
		model.device = this.device();
		model.name = this.name();
		model.orientation = this.orientation();
		model.os = this.os();
		model.version = this.version();
		return model;
	}

	name() {
		const ua = this.ua;
		let tem;
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return 'IE'; // + (tem[1] || '');
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\bOPR\/(\d+)/);
			if (tem != null) {
				return 'Opera';
			}
			tem = ua.match(/\bEdge\/(\d+)/);
			if (tem != null) {
				return 'Edge';
			}
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) {
			M.splice(1, 1, tem[1]);
		}
		return M[0];
	}
	version() {
		const ua = this.ua;
		let tem;
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			// return 'IE ' + (tem[1] || '');
			return tem[1] || '';
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\bOPR\/(\d+)/);
			if (tem != null) {
				return tem[1];
			}
			tem = ua.match(/\bEdge\/(\d+)/);
			if (tem != null) {
				return tem[1];
			}
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) {
			M.splice(1, 1, tem[1]);
		}
		return M[1];
	}
	// (ios, android, windows, linux, osx, unknown)
	os(): OSType {
		const ua = this.ua;
		if (/iphone|ipod|ipad|iphone/i.test(ua)) {
			return OSType.IOS;
		}
		if (/android/i.test(ua)) {
			return OSType.Android;
		}
		if (/windows/i.test(ua)) {
			return OSType.Windows;
		}
		if (/mac/i.test(ua)) {
			return OSType.OSX;
		}
		if (/linux/i.test(ua)) {
			return OSType.Linux;
		}
		return OSType.Unknown;
	}
	// (tablet, mobile, desktop, unknown)
	device(): DeviceType {
		const ua = this.ua;
		if (/ipad/i.test(ua) || (/android/i.test(ua) && !/mobile/i.test(ua))) {
			return DeviceType.Tablet;
		}
		if (/iphone|android/i.test(ua) && /mobile/i.test(ua)) {
			return DeviceType.Mobile;
		}
		if (/windows|mac/i.test(ua)) {
			return DeviceType.Desktop;
		}

		if (/linux/i.test(ua)) {
			return DeviceType.Desktop;
		}
		return DeviceType.Unknown;
	}
	type() {
		return this.name() + ' ' + this.version();
	}

	orientation(): DeviceOrientationType {
		return window
			? window.innerHeight > window.innerWidth
				? DeviceOrientationType.Portrait
				: DeviceOrientationType.Landscape
			: DeviceOrientationType.Unkown;
	}
}
