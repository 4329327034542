import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { OperationResult } from 'models';
import { DialogService } from '../shared';
import {
	GenerateStatementDialogComponent,
	GenerateStatementDialogComponentOptions,
} from './generate-statement-dialog/generate-statement-dialog.component';

@Injectable()
export class UserProgressActionsService {
	constructor(private dialog: DialogService) {}

	showGenerateStatements(options: GenerateStatementDialogComponentOptions) {
		const config: MatDialogConfig<GenerateStatementDialogComponentOptions> = {
			data: options,
			panelClass: 'app-panel-dialog-md',
		};

		const dialogRef = this.dialog.open<
			GenerateStatementDialogComponent,
			GenerateStatementDialogComponentOptions,
			OperationResult
		>(GenerateStatementDialogComponent, config);
		return dialogRef.afterClosed().toPromise();
	}
}
