import { IController, IScope } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { JediTablePagerService } from './TablePagerService';

export const TablePagerComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('appTablePager', {
			controller: TablePagerController,
			template: require('./view.html'),
			bindings: {
				tableParams: '<',
			},
		});

		ngModule.service('jediTablePagerService', JediTablePagerService);
	},
	style: require('./style.scss'),
};

export class TablePagerController implements IController {
	// ngTableParams binding
	public tableParams: any;

	public pages: any;
	public dropdownOptions = [];
	public selectedPageCounts: { text: string; value: number };
	public pagingCounts = [];
	private tableReloadWatch: any;

	static $inject = ['$scope', 'jediTablePagerService'];

	constructor(public $scope: IScope, public pagerService: JediTablePagerService) {
		const options = this.pagerService.getCurrent();
		this.selectedPageCounts = { text: `${options.pageSize}`, value: options.pageSize };
	}

	$doCheck(): void {
		if (!this.tableReloadWatch) {
			if (this.tableParams.settings().$scope) {
				this.tableReloadWatch = this.tableParams.settings().$scope.$on('ngTableAfterReloadData', () => {
					this.pages = this.getPages();
				});
			}
		}
	}
	$onDestroy(): void {
		if (typeof this.tableReloadWatch === 'function') {
			this.tableReloadWatch();
		}
	}

	$onInit(): void {
		this.pagingCounts = this.tableParams.settings().counts;
		this.dropdownOptions = this.pagingCounts.map(item => ({ text: `${item}`, value: item }));
	}

	public changeItemsPerPage(selected: { value: number }): void {
		this.selectedPageCounts = { text: `${selected.value}`, value: selected.value };
		this.pagerService.setCurrent({ pageSize: selected.value });
		this.tableParams.count(selected.value);
	}

	private getPages(): any {
		this.pages = this.tableParams.generatePagesArray(
			this.tableParams.page(),
			this.tableParams.total(),
			this.tableParams.count()
		);
		return this.pages;
	}
}
