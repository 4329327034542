import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { AccountApiService } from 'app/public/account/services/accountService/account-api.service';
import { AccountUiService } from 'app/public/account/services/accountService/account-ui.service';
import { ApiPlatformService } from 'app/public/api-platform.service';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { InputFieldComponent } from './account/input-field/input-field.component';
import { LoginComponent } from './account/login/login.component';
import { ClickOutsideDirective } from './language-selector/click-outside.directive';
import { ChangeEmailComponent } from './account/change-email/change-email.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';

@NgModule({
	declarations: [
		LoginComponent,
		InputFieldComponent,
		LanguageSelectorComponent,
		ClickOutsideDirective,
		ChangeEmailComponent,
		AppLayoutComponent,
	],
	imports: [CommonModule, SharedModule, RouterModule.forRoot([], { enableTracing: true }), FormsModule],
	providers: [AccountApiService, AccountUiService, ApiPlatformService],
	exports: [LanguageSelectorComponent, ClickOutsideDirective],
})
export class PublicModule {}
