import { UserActivityProgressNode, ViewDisplayType } from '@base/models';

export class HierarchyValidationService {
	/// returns a list of error messages for the provided hierarchy node
	validateNodeDisplaySettings = function (node: UserActivityProgressNode) {
		// validation rule
		// view configuration validations:
		//// portalViewSettings.MasterViewSettings.ViewDisplay == 'ExtendedGrid'
		////  - only for second to last level containers
		////  - all children have to be content items
		//// portalViewSettings.ChildViewSettings.ViewDisplay == 'ExtendedGrid'
		////  - only for containers which have only content items
		//// on any level all items must have the same ViewDisplay configuration
		//// any level can contain only containers or content items but not both types
		//// content items can only have ViewDisplay of type 'Default' (List), or 'SimpleGrid'

		const errors = [];
		const firstChild = node.members[0] || null;

		if (node.isActivity) {
			const childView = node.displaySettings.childDisplay.viewDisplay;
			if (childView != ViewDisplayType.Default && childView != ViewDisplayType.SimpleGrid) {
				errors.push(
					'[node: ' +
						node.id +
						'] content items can only be configured with viewDisplay of types ' +
						ViewDisplayType.Default +
						', ' +
						ViewDisplayType.SimpleGrid
				);
			}
		}
		if (errors.length > 0) {
			return errors;
		}
		for (let i = 0; i < node.members.length; i++) {
			if (
				firstChild != null &&
				firstChild.displaySettings.childDisplay.viewDisplay !=
					node.members[i].displaySettings.childDisplay.viewDisplay
			) {
				errors.push(
					'[node: ' + node.id + '] children should all have the same [childDisplay].[viewDisplay] setting'
				);
				break;
			}

			if (firstChild != null && firstChild.isActivity != node.members[i].isActivity) {
				errors.push(
					'[node: ' +
						node.id +
						'] inconsistent child elements types, only items of the same type (container/content) are supported'
				);
				break;
			}

			if (node.displaySettings.masterDisplay.viewDisplay == ViewDisplayType.ExtendedGrid) {
				if (
					node.members[i].isActivity ||
					!node.members[i].members ||
					node.members[i].members.length <= 0 ||
					!onlyActivities(node.members[i].members)
				) {
					errors.push(
						'[node: ' +
							node.id +
							'] ExtendedGrid master configuration can be applied only on containers with activity nephews'
					);
					break;
				}
			}
			if (node.displaySettings.childDisplay.viewDisplay == ViewDisplayType.ExtendedGrid) {
				if (!node.members[i].isActivity) {
					errors.push(
						'[node: ' +
							node.id +
							'] ExtendedGrid child configuration can be applied on containers with children'
					);
					break;
				}
			}
		}
		return errors;
	};
}

function onlyActivities(members) {
	return members.some(function (a) {
		return !a.isActivity;
	});
}
