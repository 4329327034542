<div class="modal-container">
<h3 mat-dialog-title class="modal-title">
    {{ (action === 'issue' || action === 'reissue') ? 'issue certificates' :action == 'revoke' ? 'revoke certificates' : 'issue/revoke certificates'}}
</h3>
<mat-dialog-content>
    <section class="content-item-details" >
        <ng-container *ngIf="!activity.isCertificateEnabled">
            <p>
                Certificates not enabled for activity <b>{{activity.name}}</b> with id = <b>{{activity.id}}</b>
            </p>
        </ng-container>
        <ng-container *ngIf="activity.isCertificateEnabled === true">
            <ng-container *ngIf="action === 'issue'">
                <span class="warning"><span class="number">{{selectedUsers.length}}</span> user(s) selected</span>
                <label>Issue certificates with selected date</label>
                <app-datepicker [(ngModel)]="selectedIssueDate" [includeTime]="false"></app-datepicker>
            </ng-container>

            <ng-container *ngIf="action === 'revoke'">
                <span class="warning"><span class="number">{{selectedUsers.length}}</span> user(s) selected</span>
                <span>Revoke certificates for selected users</span>
            </ng-container>

            <ng-container *ngIf="action === 'reissue'">
                <span class="warning"><span class="number">{{selectedUsers.length}}</span> user(s) selected</span>
                <label>Re-issue certificates with selected date</label>
                <app-datepicker [(ngModel)]="selectedIssueDate" [includeTime]="false"></app-datepicker>
            </ng-container>

            <ng-container *ngIf="uiError">
                <span>Invalid selection</span>
                <span>{{uiError}}</span>
            </ng-container>
            
        </ng-container>

    </section>

</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" class="btn btn-primary big" (click)="issueRevokeCertificates()" [disabled]="!isChangeEnabled()"  >
        {{ (action === 'issue' || action === 'reissue') ? 'issue' : action == 'revoke' ? 'revoke' : 'invalid'}}
    </button>
    <a class="btn btn-secondary" (click)="cancel()">cancel</a>
</mat-dialog-actions>
</div>