import TinCan from '~root/vendor/tincanWrapper';

import moment from 'moment';
import { StatementDetails, ActivityTinCanModel } from '@base/models';
import { ComponentRegistration } from '~root/commons';
import { JediTinCanClientService } from './jediTinCanClient';
import { LrsCredentials } from 'models/LrsCredentials';

export const JediStatementGeneratorRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediStatementGenerator', JediStatementGeneratorService);
	},
};

export class JediStatementGeneratorService {
	static $inject = ['jediTinCanClient', '$log'];
	constructor(private jediTinCanClient: JediTinCanClientService, private $log) {}

	sendStatement(lrsCredentials: LrsCredentials, statementDetails: StatementDetails): Promise<any> {
		const statement = this.createStatement(statementDetails);
		return this.jediTinCanClient.sendStatement(lrsCredentials, statement);
	}

	// TODO: refactor: method should only be used internally, since it's used to setup the call to "sendStatement"
	getLrsCredentials(lrsEndpoint, authorizationToken): LrsCredentials {
		const token = atob(authorizationToken).split(':');
		if (token.length !== 2) {
			this.$log.warn('authorization token is not valid');
			return null;
		}
		return {
			tinCanEndpoint: lrsEndpoint,
			tinCanEndpointKey: token[0],
			tinCanEndpointSecret: token[1],
		};
	}
	private validateStatementDetails(statementDetails: StatementDetails): void {
		if (!statementDetails) {
			throw new Error('invalid details');
		}
		if (!statementDetails.rootContainer) {
			throw new Error('root container required');
		}
		if (!statementDetails.container && statementDetails.isActivity) {
			throw new Error('parent container required');
		}

		if (!statementDetails.tinCanId) {
			throw new Error('no tincanId set for statement details');
		}
	}

	private createStatement(statementDetails: StatementDetails) {
		this.validateStatementDetails(statementDetails);
		const actor = new TinCan.Agent({
			account: new TinCan.AgentAccount({
				name: statementDetails.userId,
				homePage: statementDetails.actorHomepage.replace(/\/$/, ''),
			}),
			name: statementDetails.userId,
		});
		const tincanActivity = new TinCan.Activity({
			id: statementDetails.tinCanId,
			definition: new TinCan.ActivityDefinition({
				type: statementDetails.activityType,
				name: {
					und: statementDetails.activityName,
				},
			}),
		});

		const result = new TinCan.Result({
			completion: false,
		});
		const completionState = (statementDetails.state.value || '').toLowerCase().trim();

		if (['completed', 'passed', 'waived'].indexOf(completionState) >= 0) {
			result.completion = true;
		}
		if (['passed', 'waived'].indexOf(completionState) >= 0) {
			result.success = true;
		}
		const statement = new TinCan.Statement({
			actor: actor,
			timestamp: moment.utc(),
			target: tincanActivity,
			context: this.getContext(
				statementDetails.participantId,
				statementDetails.rootContainer,
				statementDetails.container
			),
			result: result,
			verb: {
				id: statementDetails.state.id,
				display: {
					'en-US': completionState,
				},
			},
		});
		return statement;
	}

	private getContext(registrationId: string, root: ActivityTinCanModel, parent: ActivityTinCanModel) {
		const containerType = 'http://adlnet.gov/expapi/activities/module';

		const context = new TinCan.Context({
			registration: registrationId,
		});

		if (parent?.tinCanId) {
			context.contextActivities = new TinCan.ContextActivities({
				grouping: [
					{
						id: root.tinCanId,
						definition: new TinCan.ActivityDefinition({
							type: containerType,
							name: { und: root.name },
						}),
					},
				],
				parent: [
					{
						id: parent.tinCanId,
						definition: new TinCan.ActivityDefinition({
							type: containerType,
							name: { und: parent.name },
						}),
					},
				],
			});
			return context;
		}

		context.contextActivities = new TinCan.ContextActivities({
			grouping: [
				{
					id: root.tinCanId,
					definition: new TinCan.ActivityDefinition({
						type: containerType,
						name: { und: root.name },
					}),
				},
			],
		});
		return context;
	}
}
