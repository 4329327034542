import { IController } from 'angular';

import { ComponentRegistration, UserAgentDetector } from '~root/commons';
import * as logger from '~root/logger';

import { serverSettings } from '../../jediServerSettings';

import { VideoPlayerService } from '../VideoPlayerService';
import { VideoPlayerOptions } from '../VideoPlayerOptions';
import moment from 'moment';

export const VideoPlayerComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('videoPlayer', {
			controller: VideoPlayerController,
			template: require('./VideoPlayerView.html'),
			bindings: {
				videoTitle: '<',
				videoUri: '<',
			},
		});
	},
	styles: [
		// included styles
		require('videogular-themes-default/videogular.css'),
		require('./style.scss'),
	],
};

/**
 * VideoPlayerController *
 * - acts as a wrapper for Videogular, using events and actions prefixed with `vg` (ie: `onVgPlayerPlay()`)
 * - emits events
 */

// tslint:disable-next-line: max-classes-per-file
class VideoPlayerController implements IController {
	static $inject = ['$scope', '$sce', '$timeout', 'vgFullscreen', 'VG_STATES', 'videoPlayerService'];

	public videoTitle: string;
	public videoUri: string;

	public hasError = false;
	public showModal = true;

	public vgConfig;
	public isSmallDevice: boolean;
	public showOverlayPlay = true;

	private vgPlayer: any = null;
	private vgMediaElement: HTMLMediaElement = null;

	// event handler for events generated by videogular within the <videogular> element
	private vgBoundHandler: any;
	// event handler for events generated by videogular on the Document element
	private vgDocumentHandler: any;

	/**
	 * declared events that are bound from the MediaElement contained in the VgPlayer
	 */
	private vgMediaElementEvents: Array<keyof HTMLMediaElementEventMap> = ['play', 'pause'];

	/**
	 * DI requires proper casing for injecting registered services
	 * @param $sce Angular external HTML validation service
	 * @param $timeout Angular timeout service, used to ensure DOM changes within $digest()
	 * @param vgFullscreen Videogular service for controlling fullscreen
	 * @param VG_STATES Videogular constants for acceptable states
	 */
	constructor(
		public $scope,
		public $sce,
		public $timeout: ng.ITimeoutService,
		public vgFullscreen,
		public VG_STATES,
		public videoPlayerService: VideoPlayerService
	) {
		const ua = UserAgentDetector.Detect();
		this.isSmallDevice = ua.isMobileOrTablet();
	}

	$onInit() {
		// NOTE: event casing
	}

	$onChanges() {
		if (!this.showModal) {
			return;
		}

		let uri = (this.videoUri || '').trim();
		if (uri.indexOf('/') === 0) {
			uri = serverSettings.AppBasePath.substring(0, serverSettings.AppBasePath.length - 1) + uri;
		}

		this.vgConfig = {
			sources: [
				{
					src: this.$sce.trustAsResourceUrl(uri),
					// the MIME type of the video from the url - platform currntly only support mp4
					type: 'video/mp4',
				},
			],
		};
	}

	$onDestroy() {
		if (this.vgMediaElement && this.vgBoundHandler) {
			this.vgMediaElementEvents.forEach(event =>
				this.vgMediaElement.removeEventListener(event, this.vgBoundHandler)
			);
		}
		if (this.vgDocumentHandler) {
			document.removeEventListener(this.vgFullscreen.onchange, this.vgDocumentHandler);
		}
	}

	onVgPlayerReady($API) {
		if ($API) {
			this.vgPlayer = $API;
			this.vgMediaElement = $API.mediaElement[0] as HTMLMediaElement;

			if (this.vgMediaElement) {
				this.vgBoundHandler = this.handleVgEvent.bind(this);
				this.vgMediaElementEvents.forEach(event =>
					this.vgMediaElement.addEventListener(event, this.vgBoundHandler)
				);
			}
		}
	}

	onVgPlayerCanPlay($event) {
		this.videoPlayerService.initVideo($event);
	}

	onVgPlayerComplete($event) {
		this.videoPlayerService.completeVideo($event);
	}

	onVgPlayerError($event) {
		this.hasError = true;
		this.videoPlayerService.error($event);
	}

	public handleVgEvent($event: Event) {
		if ($event && $event.type) {
			const type = $event.type as keyof HTMLMediaElementEventMap;
			if (type === 'error') {
				this.hasError = true;
				this.videoPlayerService.error($event);
			}
			if (type === 'loadedmetadata' || type === 'canplay') {
				this.videoPlayerService.initVideo($event);
			}
			if (type === 'play') {
				this.videoPlayerService.playVideo($event);
				this.toggleOverlayPlayButton(false);
			}
			if (type === 'pause') {
				this.videoPlayerService.pauseVideo($event);
				this.toggleOverlayPlayButton(true);
			}
		}
	}

	public vgPlay() {
		logger.debug('vgPlay');
		this.$timeout(() => this.vgPlayer && this.vgPlayer.play && this.vgPlayer.play());
	}

	public vgPause() {
		logger.debug('vgPause');
		this.$timeout(() => this.vgPlayer && this.vgPlayer.pause && this.vgPlayer.pause());
	}

	public vgStop() {
		this.$timeout(() => this.vgPlayer && this.vgPlayer.stop && this.vgPlayer.stop());
	}

	public closePlayer($event) {
		if ($event && $event.stopPropagation) {
			$event.stopPropagation();
		}

		this.vgStop();

		this.showModal = false;
		this.videoPlayerService.closePlayer($event);
	}

	public showPlayer(options: VideoPlayerOptions) {
		this.$timeout(() => {
			this.videoPlayerService.showPlayer(options);
		});
	}

	public parseDuration(time: number) {
		const duration = moment.duration;
		const hours = duration(time).hours();
		const minutes = duration(time).minutes();
		const seconds = duration(time).seconds();

		//the momentjs library does not support formatting for duration
		return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
			seconds > 9 ? seconds : `0${seconds}`
		}`;
	}

	public clickOverlayPlay() {
		this.$timeout(() => {
			this.vgPlayer && this.vgPlayer.playPause && this.vgPlayer.playPause();
		});
	}
	private toggleOverlayPlayButton(show: boolean) {
		this.showOverlayPlay = show;
		// on IE11, explicit scope apply is required
		this.$scope.$apply();
	}
}
