import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { DropdownSelectService } from '../dropdown-select.service';
import { IDropdownSelect } from '../dropdown-select/IDropdownSelect';

@Component({
	selector: 'app-dropdown-select-option',
	templateUrl: './dropdown-select-option.component.html',
	styleUrls: ['./dropdown-select-option.component.scss'],
})
export class DropdownSelectOptionComponent<T> {
	@Input()
	public value: T;

	@Input()
	public viewValue: string;

	@HostBinding('class.selected')
	public get selected(): boolean {
		return this.select.selectedOption === this;
	}

	@HostBinding('class.bordered')
	public get bordered(): boolean {
		return this.select.selectType === 'form';
	}

	private select: IDropdownSelect<T>;

	constructor(private dropdownService: DropdownSelectService<T>) {
		this.select = this.dropdownService.getSelect();
	}

	@HostListener('click', ['$event'])
	public onClick(event: UIEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.select.selectOption(this);
	}
}
