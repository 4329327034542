/* eslint-disable compat/compat */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ProgressState,
	ProviderTypeEnum,
	ProviderCategoryEnum,
	VersionState,
	ActivationState,
	ApiResultModel,
	OperationResult,
	Operation,
	AvailabilityDisplayPolicy,
	AvailabilitySettings,
} from '@base/models';
import { JediConfig, serverSettings } from 'app-legacy/commons';
import { DialogService } from 'app/admin/shared';

import { ActivityApiEditModel, ActivityProviderEditModel, Range, UiActivityEditModel } from './models';
import { ActivityMapper } from './activity-mapper';
import { ControllerMode } from '../ControllerMode';

function promiseTimeout<T>(ms, promise: Promise<T>, timeout: Symbol) {
	let timeoutId;
	const timeoutPromise = new Promise<Symbol>(res => {
		timeoutId = window.setTimeout(() => res(timeout), ms);
	});
	const result = Promise.race([promise, timeoutPromise]).finally(() => clearTimeout(timeoutId));
	return result;
}

// eslint-disable-next-line no-useless-escape
@Injectable()
export class ActivityLogicService {
	private baseUrl = JediConfig.api.learning.activities.base.replace(/\/+$/gi, '');

	constructor(private httpClient: HttpClient, private dialog: DialogService, private mapper: ActivityMapper) {}

	public async getActivity(id: number): Promise<UiActivityEditModel> {
		const response = await this.httpClient.get<ActivityApiEditModel>(`${this.baseUrl}/${id}`).toPromise();
		const activity = this.mapper.fromApiEditModel(response);
		return activity;
	}

	public async createActivity(activity: UiActivityEditModel, timeout: Symbol): Promise<Symbol | ApiResultModel> {
		const apiModel = this.mapper.toApiEditModel(activity);
		const request = this.httpClient.post<ApiResultModel>(`${this.baseUrl}`, apiModel).toPromise();
		const result = await promiseTimeout(1000, request, timeout);
		return result;
	}

	public async updateActivity(activity: UiActivityEditModel, timeout: Symbol): Promise<Symbol | ApiResultModel> {
		const apiModel = this.mapper.toApiEditModel(activity);
		const request = this.httpClient.patch<ApiResultModel>(`${this.baseUrl}/${activity.id}`, apiModel).toPromise();
		const result = await promiseTimeout(1000, request, timeout);
		return result;
	}

	public generateDefaultAvailabilitySettings() {
		return {
			periodEnabled: false,
			startDate: null,
			endDate: null,
			displayPolicy: AvailabilityDisplayPolicy.None,
		};
	}

	public getNewActivity(
		organisationId: number,
		providerType: ProviderTypeEnum,
		providerCategory: ProviderCategoryEnum
	): UiActivityEditModel {
		const activityBase: ActivityApiEditModel = {
			name: '',
			description: '',
			graphicUrl: '',
			group: null,
			certificateSettings: null,
			state: VersionState.Created,
			activationState: ActivationState.Active,
			hierarchyPath: null,
			isActivity: true,
			creationDate: new Date(),
			lastUpdate: new Date(),
			displayOrder: 0,
			id: 0,
			organisation_Id: organisationId,
			categories: [],
			customBackground: null,
			finalStates: ProgressState.None,
			// initialization needed for subcomponents
			activeVersion: {
				provider: {
					tinCan: {
						startupEndpoint: '',
					},
					providerType,
					providerCategory,
				},
			},
			resetUserActivityState: false,
			isCertificateEnabled: false,
			availabilitySettings: this.generateDefaultAvailabilitySettings(),
		};
		const mapped = this.mapper.fromApiEditModel(activityBase);
		return mapped;
	}

	public async validateChange(
		activity: UiActivityEditModel,
		defaultActivity: UiActivityEditModel,
		mode: ControllerMode
	): Promise<OperationResult> {
		const validate = this.validateProperties(activity, mode);
		if (validate.isError()) return validate;

		// validations only for content items
		if (!activity.isActivity) {
			return validate;
		}

		const providerValidation = this.validateProvider(
			defaultActivity.activeVersion.provider,
			activity.activeVersion.provider
		);
		if (providerValidation.isError()) {
			return providerValidation;
		}

		const tincanValidation = await this.validateTinCanId(
			defaultActivity.activeVersion?.provider,
			activity.activeVersion?.provider
		);

		if (tincanValidation) {
			return Operation.success();
		}

		return Operation.cancelled();
	}

	private validateProperties(activity: UiActivityEditModel, mode: ControllerMode): OperationResult {
		if (!activity) {
			return Operation.error('activity not provided');
		}

		if (!(activity.name || '').trim().length) {
			return Operation.error('The Name field is mandatory');
		}

		const availabilityValidation = this.validateAvailability(activity.availabilitySettings);
		if (availabilityValidation.isError()) {
			return availabilityValidation;
		}

		const limit = serverSettings.LearningActivityGenericInfoMaximumLength || 100;
		if (activity.genericInfo.length > limit) {
			return Operation.error(`genericInfo field character limit is ${limit}`);
		}

		if (!activity.isActivity && mode !== ControllerMode.Edit) {
			return Operation.error('containers cannot be created');
		}

		return Operation.success();
	}

	public validateProvider(
		oldProvider: ActivityProviderEditModel,
		newProvider: ActivityProviderEditModel
	): OperationResult {
		if (!newProvider) return Operation.error('content details must be specified');

		if (!oldProvider) return Operation.error('provider invalid');

		if (oldProvider?.providerCategory !== newProvider.providerCategory) {
			return Operation.error('provider category cannot be changed');
		}

		if (newProvider.providerCategory === ProviderCategoryEnum.None)
			return Operation.error('provider category must be specified');

		if (newProvider.providerType === ProviderTypeEnum.None)
			return Operation.error('provider type must be specified');

		// categories that can have local content
		const shouldHaveContent = [
			ProviderCategoryEnum.Document,
			ProviderCategoryEnum.LocalXApi,
			ProviderCategoryEnum.StaticWebsite,
			ProviderCategoryEnum.Video,
		].includes(newProvider.providerCategory);

		const hasContent = !!newProvider?.activityContent;
		if (shouldHaveContent && !hasContent) {
			// type that doesn't have local content
			if (newProvider.providerType !== ProviderTypeEnum.VideoVimeo) {
				return Operation.error('content must be uploaded');
			}
		}

		const ltiSettingsValidation = this.validateLtiSettings(newProvider);
		if (ltiSettingsValidation.isError()) {
			return ltiSettingsValidation;
		}

		// mandatory startupendpoint:
		let isEndpointManadatory =
			newProvider.providerCategory === ProviderCategoryEnum.Video &&
			newProvider.providerType === ProviderTypeEnum.VideoVimeo;
		isEndpointManadatory =
			isEndpointManadatory ||
			newProvider.providerCategory === ProviderCategoryEnum.ExternalXApi ||
			newProvider.providerCategory === ProviderCategoryEnum.LTI;

		if (isEndpointManadatory) {
			if (newProvider.tinCan?.startupEndpoint?.trim().length < 1) {
				return Operation.error('launch endpoint is mandatory');
			}
		}

		return Operation.success();
	}

	public validateAvailability(settings: AvailabilitySettings): OperationResult {
		if (!settings.periodEnabled) {
			return Operation.success();
		}

		if (!settings.displayPolicy || settings.displayPolicy === AvailabilityDisplayPolicy.None) {
			return Operation.error(
				`Scheduling: display policy cannot be empty or 'none' when LA scheduling is enabled.`
			);
		}

		if (!settings.startDate && !settings.endDate) {
			return Operation.error(
				`Scheduling: one or both of the date fields must be defined when LA scheduling is enabled.`
			);
		}

		if (settings.startDate && settings.endDate && settings.startDate.getTime() > settings.endDate.getTime()) {
			return Operation.error(
				'Scheduling: Date start cannot be later than end date ' +
					'when both date fields are defined and LA scheduling is enabled.'
			);
		}

		return Operation.success();
	}

	private validateLtiSettings(provider: ActivityProviderEditModel): OperationResult {
		if (provider.providerType !== ProviderTypeEnum.LTI_V1_1) {
			return Operation.success();
		}

		if (provider.ltiSettings?.contentKey == null || provider.ltiSettings?.contentSecret == null) {
			return Operation.error('contentKey and contentSecret must be specified');
		}

		const outcomes = provider.ltiSettings.outcomesSettings;

		if (!outcomes?.isEnabled) {
			return Operation.success();
		}

		if (outcomes.isQuiz) {
			if (this.containsNull(outcomes.passedThreshold))
				return Operation.error('passed and failed threshold fields must have values when module is quiz');
			if (!this.containsNull(outcomes.completedThreshold))
				return Operation.error('when module is quiz compleated threshold should be empty');
		} else {
			if (this.containsNull(outcomes.completedThreshold))
				return Operation.error('completed threshold fields must have values when module is not quiz');
			if (!this.containsNull(outcomes.passedThreshold) || !this.containsNull(outcomes.failedThreshold))
				return Operation.error('when module is not quiz passed and failed thresholds should be empty');
		}

		return Operation.success();
	}

	containsNull(threshold: Range): boolean {
		return threshold.minValue == null || threshold.maxValue == null;
	}

	private async validateTinCanId(oldProvider: ActivityProviderEditModel, newProvider: ActivityProviderEditModel) {
		if (
			oldProvider.providerCategory === ProviderCategoryEnum.LocalXApi &&
			oldProvider.tinCan?.id?.length > 0 &&
			oldProvider.tinCan.id !== newProvider.tinCan.id
		) {
			const result = await this.dialog.showConfirm({
				message:
					`Are you sure you want to save this LA?<br/>` +
					`TinCan Activity Id is changed and along with a new LA version, new participant entries will be created.<br/>` +
					`Old TinCan Id: ${oldProvider.tinCan.id}<br/>` +
					`New TinCan Id: ${newProvider.tinCan.id}<br/>`,
			});
			return result.confirmed;
		}
		return true;
	}
}
