/* eslint-disable @typescript-eslint/no-use-before-define */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserActivityProgressNode } from '../../models';
import { JediAuthService, JediConfig } from '../../app-legacy/commons';
import { ContentLaunchResponseModel } from './activity-portal/activity-hierarchy/content-launch-response.model';

@Injectable({
	providedIn: 'root',
})
export class ActivityService {
	constructor(private http: HttpClient, private jediAuth: JediAuthService) {}

	private map = {};

	async loadParticipantDetails(activityPath: string): Promise<Array<UserActivityProgressNode>> {
		if (this.map[activityPath]) {
			return this.map[activityPath];
		}

		const user = await this.jediAuth.loadUser();
		const url = JediConfig.api.learning.activities.itemForUser(user.id, activityPath);
		const response = await this.http.get<Array<UserActivityProgressNode>>(url).toPromise();

		this.map[activityPath] = response;
		return response;
	}

	public async getLaunchActivityLaunchUrl(activity: UserActivityProgressNode): Promise<string> {
		const baseUri = JediConfig.api.base.replace(/\/$/, '');
		const user = await this.jediAuth.getLoggedInUser();
		// trim leading slash
		const hierarchyPath = activity?.parentRelation?.hierarchyPath?.replace(/^\//gi, '');
		const returnUrl = window.location.href;

		const url = `${baseUri}/learning/launch/endpoint/${user.id}/relation/path/${hierarchyPath}?returnUrl=${returnUrl}`;
		const response = await this.http.get<ContentLaunchResponseModel>(url).toPromise();
		return response?.launchUri;
	}

	public getFilteredAndOrderedActivities(activities: UserActivityProgressNode[]): UserActivityProgressNode[] {
		if (!activities) return [];
		const filtered = activities.filter(activity => activity.satisfiesPageFilter);
		const ordered = filtered.sort(sortOnPropsAscending);
		return ordered;
	}
}

/* value used by sort function when "a" should be first in the sorted array */
const showAfirst = -1;
/* value used by sort function when "b" should be first in the sorted array */
const showBfirst = 1;

function sortOnPropsAscending(a: UserActivityProgressNode, b: UserActivityProgressNode): number {
	try {
		// where `displayOrder` is not defined, item should be last
		const aOrder = Number.isSafeInteger(a.displayOrder) ? a.displayOrder : Number.MAX_SAFE_INTEGER;
		const bOrder = Number.isSafeInteger(b.displayOrder) ? b.displayOrder : Number.MAX_SAFE_INTEGER;

		if (aOrder < bOrder) return showAfirst;
		if (aOrder > bOrder) return showBfirst;

		const aName = (a.title || '').toLowerCase();
		const bName = (b.title || '').toLowerCase();
		// case-insensitive alphabetical order
		return aName.localeCompare(bName);
	} catch (err) {
		throw new Error(`error sorting ${JSON.stringify({ a, b })}. reason: ${err}`);
	}
}
