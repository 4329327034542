/* eslint-disable @typescript-eslint/no-use-before-define */
import { StateDeclaration } from '@uirouter/angularjs';
import { ModuleStyleLoader } from './ModuleStyleLoader';
import { createRouteStyle, createStyle, removeRouteStyle } from './styleHelpers';
import { ModuleRegistration, IStyleLoader } from './types';
import { UiRouterNgRouteConverter } from './UiRouterNgRouteConverter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createStyleLoader(ngModule: ModuleRegistration, _router: UiRouterNgRouteConverter): IStyleLoader {
	return new ModuleStyleLoader(ngModule);
}

export class ModuleStyleManager {
	private styleLoader: IStyleLoader;

	constructor(private ngModule: ModuleRegistration, private adapter: UiRouterNgRouteConverter) {}

	bootstrap(): void {
		this.styleLoader = createStyleLoader(this.ngModule, this.adapter);

		const styles = this.styleLoader.load();
		const style = styles.join('\r\n');
		createStyle(style, 'base.module.styles');
	}

	// todo: use dictionary[fromPath+toPath]=styles to cache calculations and improve loading
	transitionStyles(from: StateDeclaration, to: StateDeclaration): void {
		const nodes = this.adapter.getNodes();
		const fromPath = nodes.filter(n => from.name.startsWith(n.state.name));
		const toPath = nodes.filter(n => to.name.startsWith(n.state.name));

		const onlyTo = toPath.filter(t => !fromPath.includes(t));
		const onlyFrom = fromPath.filter(f => !toPath.includes(f));
		// apply style changes of routes have differences
		if (onlyTo && onlyTo.length && onlyFrom && onlyFrom.length) {
			// onlyFrom will be removed
			onlyFrom.forEach(fromNode => removeRouteStyle(fromNode));
			// onlyTo will be added
			onlyTo.forEach(toNode => createRouteStyle(toNode));
		}
	}
}
