import { Injectable } from '@angular/core';
import { deepSet, deepGet } from '~root/commons/helpers';

/**
 * Service bridge for model binding between angular 10 and angularJS
 */
@Injectable()
export class EditTextService {
	private registrations = new Array<any>();

	/**
	 * adds event handler on provided $scope for deep changes in $scope object
	 * @param $scope scope used for change detection
	 */
	register($scope: any) {
		/**
		 * @param id id in simplified JSON path notation
		 * @param value new value
		 */
		// eslint-disable-next-line no-param-reassign
		$scope.onTextChanged = (id, value) => {
			const current = deepGet($scope, id);
			if (current !== value) {
				deepSet($scope, id, value);
			}
		};

		$scope.$on('$destroy', () => {
			this.unregister($scope);
		});

		this.registrations.push($scope);
	}

	unregister(controller: any) {
		const idx = this.registrations.findIndex(c => c === controller);
		if (idx > -1) {
			this.registrations.splice(idx, 1);
		}
	}

	emit(id: string, $event) {
		this.registrations.forEach(registration => {
			if (typeof registration.onTextChanged === 'function') {
				registration.onTextChanged(id, $event);
			}
		});
	}
}
