import { UserActivityProgressNode, ActivityProgress, ProviderCategoryEnum, ProgressState } from '@base/models';

export class ActivityProgressMapper {
	updateProgressMapping(model: UserActivityProgressNode, progress: ActivityProgress): UserActivityProgressNode {
		if (!progress) {
			return model;
		}
		model.progress = progress;
		// default progressState
		model.state = progress.progressState;
		model.displayState = progress.progressState;

		// custom progress state
		if (progress.progressState === ProgressState.Completed || progress.progressState === ProgressState.Waived) {
			if (model.providerCategory === ProviderCategoryEnum.Video) {
				model.displayState = ProgressState.Played;
			}
			if (model.providerCategory === ProviderCategoryEnum.Document) {
				model.displayState = ProgressState.Experienced;
			}
		}
		model.isActive = this.isActive(model);
		model.isSucceded = this.isCompleted(model) || this.isPassed(model) || this.isWaived(model);
		model.isFailed = this.isFailed(model);
		model.isNotStarted = !model.isActive && !model.isSucceded && !model.isFailed;

		if (model.isActive) {
			model.progressStateInfo = {
				className: 'active',
				label: 'PORTAL_IN_PROGRESS_FLAG',
			};
		} else if (model.isSucceded) {
			model.progressStateInfo = {
				className: 'done',
				label: 'PORTAL_COMPLETED_FLAG',
			};
		} else if (model.isFailed) {
			model.progressStateInfo = {
				className: 'failed',
				label: 'PORTAL_FAILED_FLAG',
			};
		} else {
			model.progressStateInfo = {
				className: 'notstarted',
				label: 'PORTAL_TO_DO_FLAG',
			};
		}

		return model;
	}

	getProgress(activity: UserActivityProgressNode) {
		if (!activity || !activity.progress) {
			return null;
		}
		return activity.progress.progressState.toLowerCase();
	}

	isActive(activity: UserActivityProgressNode): boolean {
		return ['attempted', 'launched'].indexOf(this.getProgress(activity)) >= 0;
	}

	isCompleted(activity: UserActivityProgressNode): boolean {
		return ['completed'].indexOf(this.getProgress(activity)) >= 0;
	}

	isPassed(activity: UserActivityProgressNode): boolean {
		return ['passed'].indexOf(this.getProgress(activity)) >= 0;
	}

	isFailed(activity: UserActivityProgressNode): boolean {
		return ['failed'].indexOf(this.getProgress(activity)) >= 0;
	}

	isWaived(activity: UserActivityProgressNode): boolean {
		return ['waived'].indexOf(this.getProgress(activity)) >= 0;
	}

	isNone(activity: UserActivityProgressNode): boolean {
		return ['none'].indexOf(this.getProgress(activity)) >= 0;
	}
}
