import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GenerateStatementDialogComponent } from './generate-statement-dialog/generate-statement-dialog.component';
import { UserProgressActionsService } from './user-progress-actions.service';
import { AdminSharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [GenerateStatementDialogComponent],
	imports: [CommonModule, HttpClientModule, AdminSharedModule],
	providers: [UserProgressActionsService],
})
export class ReportingUserProgressModule {}
