import * as angular from 'angular';
import moment from 'moment';

import jediServerSettings from '../jediServerSettings';

//// simple query builder for .NET Jedi API filtering support
//// integrates parameters provided by ngTable via ngTableParams
export class JediQueryBuilder {
	private dateFormats = ['YYYY-MM-DD'];
	encodeParameters: any;
	paging: any;
	ngTableParams: any;
	public params = {
		take: 'take',
		skip: 'skip',
		itemsPerPage: 'itemsPerPage',
		format: 'filter',
		orderBy: 'sorting',
	};

	public defaultParamValues = {
		take: 2,
		skip: 0,
		inlineCount: true,
	};
	tableParams: any;
	parameters: any = {};
	filters: any = {};
	sorting: any = {};
	paramValues: any = { take: 10, skip: 0 };
	skipPaging = false;

	constructor(ngTableParams = null, encodeParameters = null, paging = null, skipPaging = null, filters: any = null) {
		this.ngTableParams = ngTableParams;
		this.skipPaging = skipPaging;
		this.encodeParameters = encodeParameters || true;
		this.paging = paging || { count: 10, page: 1 };
		angular.copy(this.ngTableParams, this.tableParams);

		if (!ngTableParams) {
			angular.copy(paging, this.parameters);
		} else {
			const ngTableParameters = ngTableParams.parameters();

			angular.copy(ngTableParameters.filter, this.filters);
			angular.copy(ngTableParameters.sorting, this.sorting);
			angular.copy(ngTableParameters, this.parameters);
		}

		this.paramValues = {
			take: this.parameters.count,
			skip: (this.parameters.page - 1) * this.parameters.count,
		};
		this.filters = filters || this.filters;
	}

	encodeQueryParameter(input) {
		if (jediServerSettings.DisableQueryEncoding || !this.encodeParameters) {
			return encodeURIComponent(input);
		}
		return btoa(input);
	}

	convertFilterValueToQueryValue(filterValue) {
		if (filterValue instanceof Date) {
			const label = moment(filterValue).format(this.dateFormats[0]);
			return label;
		}
		if (Array.isArray(filterValue)) {
			return filterValue.join(',');
		}
		return `${filterValue}`;
	}

	build(baseUrl) {
		let queryParams = [...this.buildFilterParams(), ...this.buildSortingParams()];

		if (!this.skipPaging) {
			queryParams = [...queryParams, ...this.buildMappedParams()];
		}

		const query = queryParams.join('&');

		if (typeof baseUrl === 'string' && baseUrl.trim().length > 0) {
			return baseUrl + '?' + query;
		}
		return query;
	}

	buildMappedParams() {
		const params = Object.keys(this.params)
			.filter(key => key && this.paramValues.hasOwnProperty(key))
			.filter(key => this.params[key] && this.paramValues[key])
			.map(key => `${this.params[key]}=${this.paramValues[key]}`);
		return params;
	}
	buildFilterParams() {
		const filterProperties = Object.keys(this.filters)
			// properties with value 'false' should be kept
			.filter(key => typeof this.filters[key] !== 'undefined' && this.filters[key] != null)
			.map(key => {
				const queryParamValue = this.convertFilterValueToQueryValue(this.filters[key]);
				return `filter.${key}=${this.encodeQueryParameter(queryParamValue)}`;
			});
		return filterProperties;
	}
	buildSortingParams() {
		const sortingProperties = Object.keys(this.sorting)
			.filter(key => key && this.sorting[key])
			.map(key => {
				return `sorting.${key}=${this.encodeQueryParameter(this.sorting[key])}`;
			});
		return sortingProperties;
	}
}

// return JediQueryBuilder;
