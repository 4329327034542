import { IServiceHandler } from './IServiceHandler';

export class ServiceHandler implements IServiceHandler {
	private handlers = new Array<IServiceHandler>();

	public addHandler(handler: IServiceHandler) {
		const index = this.handlers.indexOf(handler);
		if (index < 0) {
			this.handlers.push(handler);
		}
	}

	public removeHandler(handler: IServiceHandler) {
		const index = this.handlers.indexOf(handler);
		if (index > -1) {
			this.handlers = this.handlers.slice(index, 1);
		}
	}

	handleResult(result) {
		this.handlers.forEach(handler => (handler.handleResult ? handler.handleResult(result) : null));
	}

	handleError(error) {
		this.handlers.forEach(handler => (handler.handleError ? handler.handleError(error) : null));
	}

	handleStart() {
		this.handlers.forEach(handler => (handler.handleStart ? handler.handleStart() : null));
	}
}
