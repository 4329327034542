import * as angular from 'angular';
import { IController, route } from 'angular';

export abstract class BaseOverviewController<TFilter> implements IController {
	tableParams: any;
	toggleUserSelection: boolean;
	filters: TFilter;

	constructor(public $route: route.IRouteService, public $stateParams, public ngTableParams) {
		this.tableParams = new this.ngTableParams(
			{
				page: 1, // show first page
				count: 10, // count per page
				sorting: { name: 'asc' },
			},
			{
				total: 0, // length of data
				getData: this.loadTableData.bind(this),
			}
		);
	}

	abstract loadTableData($deferred, params);

	changePage(pageCount) {
		if (pageCount && pageCount.value) {
			const params: any = {};
			angular.copy(this.tableParams.parameters(), params);
			params.page = 1;
			params.count = pageCount.value;
			this.tableParams.parameters(params);
		}
	}

	reload() {
		this.tableParams.resetPaging(true);
	}

	reset() {
		this.$route.reload();
	}

	filterTable(filterName: string, item, value, label) {
		this.filters[filterName] = { item: item, value: value, label: label };
		this.tableParams.resetPaging(true);
	}

	reloadFilter(filter) {
		if (this.filters[filter]) {
			this.filters[filter] = undefined;
		}
		this.tableParams.resetPaging(true);
	}

	selectAll() {
		this.toggleUserSelection = !this.toggleUserSelection;
		this.tableParams.data.forEach(item => item && (item.selected = this.toggleUserSelection));
	}

	getSelectedItems() {
		const selected = ((this.tableParams || {}).data || []).filter(e => e && e.selected);
		this.toggleUserSelection = selected && selected.length > 0;
		return selected || [];
	}
}
