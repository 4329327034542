import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { JediTablePagerService } from 'app-legacy/admin/commons/table-pager/TablePagerService';

export interface TablePagingOptionsMap {
	[path: string]: TablePagingOptions;
}

export interface TablePagingOptions {
	pageSize: number;
}

@Component({
	selector: 'app-table-paginator',

	templateUrl: './table-paginator.component.html',

	styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent<T> implements OnInit {
	private tableSourceData: Array<T>;

	@Input() set tableData(value: Array<T>) {
		this.tableSourceData = value;
		this.getTotalPageNumbers();
		this.paginateData(this.selectedPage);
	}

	get tableData(): Array<T> {
		return this.tableSourceData;
	}

	@Output() paginatedTableData = new EventEmitter<Array<T>>();

	public paginatedData: Array<T>;

	public resultSizeOptions: number[] = [10, 25, 50, 100];

	public selectedSizeOption = 10;

	public selectedPage = 1;

	public lastPage: number;

	public totalPages: number[];

	public shownPageNumbers: number[];

	public more = { left: false, right: false };

	constructor(private pagerService: JediTablePagerService) {
		const current = this.pagerService.getCurrent();
		this.selectedSizeOption = current.pageSize;
	}

	getTotalPageNumbers() {
		const pageNumber = Math.ceil(this.tableSourceData.length / this.selectedSizeOption);

		this.totalPages = Array(pageNumber)
			.fill(0)

			.map((x, i) => i + 1);

		this.lastPage = this.totalPages[this.totalPages.length - 1];
	}

	public setViewValue = page => page;

	paginateData(page: number) {
		this.selectedPage = page;

		const offset = (page - 1) * this.selectedSizeOption;

		this.paginatedData = this.tableSourceData.slice(
			offset,
			Math.min(page * this.selectedSizeOption, this.tableSourceData.length)
		);
		this.updatePaginatedTableData(this.paginatedData);
		this.calculateShownPageNumbers(page);
	}

	nextPage() {
		const nextPage = Math.min(this.selectedPage + 1, this.totalPages.slice(-1)[0]);

		this.paginateData(nextPage);
	}

	previousPage() {
		const previousPage = Math.max(this.selectedPage - 1, 1);

		this.paginateData(previousPage);
	}

	updatePageSize(newValue: number) {
		if (!newValue) return;
		this.selectedSizeOption = newValue;
		this.pagerService.setCurrent({ pageSize: this.selectedSizeOption });
		this.getTotalPageNumbers();
		this.paginateData(1);
	}

	updatePaginatedTableData(newData: Array<T>) {
		this.paginatedTableData.emit(newData);
	}

	calculateShownPageNumbers(selectedPage: number) {
		if (selectedPage > 6 && this.totalPages.length > 9) {
			if (selectedPage < this.lastPage - 3) {
				this.shownPageNumbers = this.totalPages.slice(selectedPage - 3, selectedPage + 2);

				this.more.right = true;
			} else {
				this.shownPageNumbers = this.totalPages.slice(-7, -1);

				this.more.right = false;
			}

			this.more.left = true;
		} else if (this.totalPages.length > 9) {
			this.shownPageNumbers = this.totalPages.slice(1, 7);

			this.more.right = true;

			this.more.left = false;
		} else {
			this.shownPageNumbers = this.totalPages.slice(1, -1);

			this.more.left = false;

			this.more.right = false;
		}
	}

	ngOnInit(): void {
		this.getTotalPageNumbers();
		this.paginateData(this.selectedPage);
	}
}
