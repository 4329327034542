<div class="datepicker-container">
    <label *ngIf="label"> {{ label }} </label>

    <div class="datepicker-fields-container">
        <mat-form-field (click)="picker.open()" class="datepicker-date-field" appearance="fill">
            <mat-label [dir]="'ltr'" *ngIf="!date">Choose a date</mat-label>
            <input readonly matInput [matDatepicker]="picker" (dateChange)="onShortDateChange($event)"
                [value]="shortDateValue" />
            <mat-datepicker-toggle #el matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button class="datepicker-clear-btn" mat-icon-button (click)="onClear($event)">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <app-timepicker *ngIf="includeTime" [(date)]="date" (dateChange)="onDateChange($event)"></app-timepicker>
    </div>
</div>