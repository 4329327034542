export function toQueryString(obj: any): string {
	if (!obj) return '';
	var query = '';
	Object.keys(obj).forEach(function (key) {
		if (!!key && !!obj[key]) {
			query += key + '=' + obj[key] + '&';
		}
	});
	query = query.substr(0, query.length - 1);
	return query.length > 0 ? '?' + query : '';
}
