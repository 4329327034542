import { ApiHttpCache } from './ApiHttpCache';

export class JediCacheFactory {
	static $inject = ['$cacheFactory'];
	constructor(private $cacheFactory) {}
	private apiCache: ApiHttpCache = null;

	get(): ApiHttpCache {
		if (this.apiCache === null) {
			const innerCache = this.$cacheFactory('apiHttpInternalCache', { capacity: 100 });
			this.apiCache = new ApiHttpCache(innerCache, 60 * 100);
		}
		return this.apiCache;
	}
}
