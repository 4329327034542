import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { NgxPopperModule } from 'ngx-popper';
import { ActivityOpenButtonComponent } from './activity-open-button/activity-open-button.component';
import { ActivityInfoOverlayComponent } from './activity-info-overlay/activity-info-overlay.component';
import { ExtendedGridViewChildComponent } from './extended-grid-view/extended-grid-view-child/extended-grid-view-child.component';
import { ExtendedGridViewComponent } from './extended-grid-view/extended-grid-view.component';
import { ListViewComponent } from './list-view/list-view.component';
import { SimpleGridViewComponent } from './simple-grid-view/simple-grid-view.component';
import { NavigationWidgetComponent } from './navigation-widget/navigation-widget.component';
import { BreadcrumbsWidgetComponent } from './breadcrumbs-widget/breadcrumbs-widget.component';
import { ActivityFilterComponent } from './activity-filter/activity-filter.component';
import { ActivityPageComponent } from './activity-page/activity-page.component';
import { ActivityHierarchyVideoPlayerComponent } from './activity-hierarchy-video-player/activity-hierarchy-video-player.component';
import { ActivityToggleTooltipComponent } from './activity-toggle-tooltip/activity-toggle-tooltip.component';
import { ActivityCertificateDownloadComponent } from './activity-certificate-download/activity-certificate-download.component';
import { ActivityAvailabilityComponent } from './activity-availability/activity-availability.component';

@NgModule({
	declarations: [
		ActivityOpenButtonComponent,
		ActivityInfoOverlayComponent,
		ExtendedGridViewChildComponent,
		ExtendedGridViewComponent,
		ListViewComponent,
		SimpleGridViewComponent,
		NavigationWidgetComponent,
		BreadcrumbsWidgetComponent,
		ActivityFilterComponent,
		ActivityPageComponent,
		ActivityToggleTooltipComponent,
		ActivityCertificateDownloadComponent,
		ActivityHierarchyVideoPlayerComponent,
		ActivityAvailabilityComponent,
	],
	imports: [CommonModule, SharedModule, MatTooltipModule, NgxPopperModule.forRoot({ trigger: 'click' })],
})
export class ActivityHierarchyModule {}
