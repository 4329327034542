import { ILocationService } from 'angular';
import { ActivityModel } from '@base/models';
import { ComponentRegistration } from '~root/commons';
import { JediUserSettingsService } from './jediUserSettings';

export const NavigationServiceRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('navigationService', NavigationService);
	},
};

export class NavigationService {
	static $inject = ['$location', 'jediUserSettings'];
	constructor(private $location: ILocationService, private jediUserSettings: JediUserSettingsService) {}

	/**
	 * For administraion/reportingOverview. Navigates to the provided activity.
	 *
	 * @param activty ActivityModel
	 */
	adminReportingNavToActivity(activity: ActivityModel = undefined): void {
		const activityPath = !activity ? `/reporting/progress` : `/reporting/root${activity.hierarchyPath}`;
		const path: string = this.$location.path();
		const activityLink = `${path.replace(/\/reporting\/.*/, activityPath)}`;
		this.$location.path(activityLink);
	}

	navigateToLocation(location: string): void {
		this.$location.url(location);
	}

	navigateToPreviousLocation(): void {
		// absolute URL
		const absoluteLocation = this.jediUserSettings.getLocation();
		const relativeLocation = (absoluteLocation || '').replace(window.location.origin, '');
		// relativeURL
		this.$location.url(relativeLocation);
	}
}
