/* eslint-disable prettier/prettier */
import { ProviderCategoryEnum } from '@base/models';

// key = ProviderCategoryEnum
// value = TinCan Activity Type Id
export declare type TinCanProviderCategoryIdMap = {
	[k in ProviderCategoryEnum]: string;
};
const tinCanProviderTypes: TinCanProviderCategoryIdMap = {
	[ProviderCategoryEnum.None]: '',
	[ProviderCategoryEnum.LocalXApi]: 'http://adlnet.gov/expapi/activities/course',
	[ProviderCategoryEnum.ExternalXApi]: 'http://adlnet.gov/expapi/activities/course',
	[ProviderCategoryEnum.Video]: 'https://w3id.org/xapi/video/activity-type/video',
	[ProviderCategoryEnum.Document]: 'http://adlnet.gov/expapi/activities/file',
	[ProviderCategoryEnum.StaticWebsite]: 'http://adlnet.gov/expapi/activities/course',
	[ProviderCategoryEnum.LTI]: 'http://adlnet.gov/expapi/activities/course',
};

export interface TinCanVerbState {
	text: string;
	value: string;
	id: string;
}

export declare type TinCanProviderCategoryStateMap = {
	[k in ProviderCategoryEnum]: TinCanVerbState[];
};

export const VerbStates = {
	Passed: { text: 'passed', value: 'passed', id: 'http://adlnet.gov/expapi/verbs/passed' },
	Completed: { text: 'completed', value: 'completed', id: 'http://adlnet.gov/expapi/verbs/completed' },
	Waived: { text: 'waived', value: 'waived', id: 'http://adlnet.gov/expapi/verbs/waived' },
	Failed: { text: 'failed', value: 'failed', id: 'http://adlnet.gov/expapi/verbs/failed' },
	Initialized: { text: 'initialized', value: 'initialized', id: 'http://adlnet.gov/expapi/verbs/initialized' },
	Played: { text: 'played', value: 'played', id: 'https://w3id.org/xapi/video/verbs/played' },
	Experienced: { text: 'experienced', value: 'experienced', id: 'http://adlnet.gov/expapi/verbs/experienced' },
	Launched: { text: 'launched', value: 'launched', id: 'http://adlnet.gov/expapi/verbs/launched' },
	Attempted: { text: 'attempted', value: 'attempted', id: 'http://adlnet.gov/expapi/verbs/attempted'}
};

const tinCanProviderTypeSuccessStates: TinCanProviderCategoryStateMap = {
	[ProviderCategoryEnum.None]: [],
	[ProviderCategoryEnum.LocalXApi]: [VerbStates.Passed, VerbStates.Completed, VerbStates.Failed, VerbStates.Waived],
	[ProviderCategoryEnum.ExternalXApi]: [
		VerbStates.Passed,
		VerbStates.Completed,
		VerbStates.Failed,
		VerbStates.Waived,
	],
	[ProviderCategoryEnum.Video]: [VerbStates.Completed, VerbStates.Waived],
	[ProviderCategoryEnum.Document]: [VerbStates.Completed, VerbStates.Waived],
	[ProviderCategoryEnum.StaticWebsite]: [VerbStates.Completed, VerbStates.Waived],
	[ProviderCategoryEnum.LTI]: [VerbStates.Passed, VerbStates.Completed, VerbStates.Failed, VerbStates.Waived],
};

const tinCanProviderStateVerbs = {
	[ProviderCategoryEnum.Video]: {
		initialized: VerbStates.Initialized,
		played: VerbStates.Played,
		completed: VerbStates.Completed,
	},
	[ProviderCategoryEnum.Document]: {
		experienced: VerbStates.Experienced,
		completed: VerbStates.Completed,
	},
	[ProviderCategoryEnum.StaticWebsite]: {
		completed: VerbStates.Completed,
	},
};

export const tincan = {
	// should be generated from the server-side setting that represents the host of the LMS providing user accounts for learning activities (host of Jedi.APP/Administration)
	actorHomepage: 'https://jedi.splintt.nl/',
	tinCanProviderTypeSuccessStates,
	tinCanProviderTypes,
	tinCanProviderStateVerbs,
};
