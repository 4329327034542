import Auth from '../../routeGuard';

const RedirectComponent = {
	register: function (ngModule) {
		ngModule.controller('authRedirectController', RedirectController);
	},
	routeDef: {
		controller: 'authRedirectController',
		template: '',
		resolve: Auth.guard(),
	},
};
const RedirectController = [
	'$location',
	'jediAuth',
	function ($location, jediAuth) {
		jediAuth.getLoggedInUser().then(
			function (user) {
				const path = $location.path();

				if (path === '/') {
					const userRole = user.maxRole.name.toLowerCase();
					if (userRole.indexOf('system') > -1) {
						$location.path('/Administration/organisations');
					} else if (userRole.indexOf('platform') > -1) {
						$location.path('/Administration/organisations');
					} else if (userRole.indexOf('admin') > -1 && user.organisationId) {
						$location.path(`/Administration/organisations/${user.organisationId}/users/overview`);
					} else {
						$location.path('/Administration/error');
					}
				}
			},
			function (error) {
				$location.path('/Administration/error');
			}
		);
	},
];
export default RedirectComponent;
