import moment from 'moment';

const DateTimeFormatComponent = {
	register: function (ngModule) {
		ngModule.directive('jediDateFormat', DateTimeFormatDirective);
	},
};

export default DateTimeFormatComponent;

const DateTimeFormatDirective = [
	'$rootScope',
	'$location',
	'$filter',
	function ($rootScope, $location, $filter) {
		return {
			require: 'ngModel',
			restrict: 'A',
			//replace: true,
			link: function (scope, element, attributes, ngModelController) {
				ngModelController.$parsers.push(function (data) {
					// convert from view format to model format
					if (data) {
						return moment(data).toDate();
					}
					return undefined;
				});

				ngModelController.$formatters.push(function (data) {
					// convert from model format to view format
					var format = attributes.jediDateFormat || 'YYYY-MM';
					if (data) {
						return moment(data).format(attributes.jediDateFormat);
					}
					return undefined;
				});
			},
		};
	},
];
