import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminSharedModule } from '../shared';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { ReportingStatisticsService } from './reporting-statistics.service';
import {
	GeneralMetricsComponent,
	TopVisitedLearningActivitiesComponent,
	HistoricalLearningActivitieisProgressComponent,
	CurrentUserProgressComponent,
	HistoricalActiveUsersComponent,
} from './widgets';
import {
	BarChartHorizontalComponent,
	ChartTooltipComponent,
	BarChartVerticalComponent,
	PieChartComponent,
} from './charts';

@NgModule({
	declarations: [
		DashboardPageComponent,
		GeneralMetricsComponent,
		TopVisitedLearningActivitiesComponent,
		HistoricalLearningActivitieisProgressComponent,
		CurrentUserProgressComponent,
		BarChartHorizontalComponent,
		ChartTooltipComponent,
		BarChartVerticalComponent,
		PieChartComponent,
		HistoricalActiveUsersComponent,
	],
	imports: [CommonModule, ChartsModule, AdminSharedModule, MatProgressSpinnerModule],
	providers: [ReportingStatisticsService],
})
export class ReportingDashboardModule {}
