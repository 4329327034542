import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { JediTutorialComponent } from './jedi-tutorial/jedi-tutorial.component';

@NgModule({
	declarations: [UserMenuComponent, LanguageSwitchComponent, JediTutorialComponent],
	imports: [CommonModule, SharedModule],
	exports: [UserMenuComponent],
})
export class SharedPortalComponentsModule {}
