import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ActivityPortalModule } from './activity-portal/activity-portal.module';
import { ActivityPlayerModule } from './activity-player/activity-player.module';
import { ActivityService } from './activity.service';
import { IntrojsService } from './introjs.service';
import { PortalAppLayoutComponent } from './portal-app-layout/portal-app-layout.component';

@NgModule({
	declarations: [PortalAppLayoutComponent],
	imports: [CommonModule, ActivityPlayerModule, ReactiveFormsModule, ActivityPortalModule, SharedModule],
	providers: [ActivityService, IntrojsService],
})
export class PortalModule {}
