import { Injectable } from '@angular/core';

import { NotificationViewModel } from '@base/models';
import { DialogService } from 'app/admin/shared';

import { EditNotificationsDialogComponent } from './edit-notifications-dialog/edit-notifications-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class NotificationsDialogService {
	constructor(private dialog: DialogService) {}

	showNotificationsDialog(item: NotificationViewModel | null) {
		const result = this.dialog.open<EditNotificationsDialogComponent, NotificationViewModel>(
			EditNotificationsDialogComponent,
			{
				data: item,
				panelClass: 'app-panel-dialog-md',
				position: { top: '30px' },
			}
		);
		return result;
	}
}
