import * as angular from 'angular';
import $ from 'jquery';
import { ComponentRegistration } from '~root/commons';
import { JediConfig } from 'app-legacy/commons';

export const FileUploadDirectiveRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.directive('jediFileModel', FileUploadDirective);
	},
};

/*#region Single-File browser controlled file upload (source: http://uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs )  */

// jedi-file-model - the javascript object bound to the file
// jedi-file-change - function(file)
// jedi-file-type   - type of file to be uploaded ('image', 'archive')
// jedi-file-extensions - comma separated list of accepted extensions, eg: '.zip,.jpg'
// jedi-file-extensions-invalid - error message to display in case extension is invalid
// jedi-file-max-size - maximum size in bytes allowed for files
// jedi-file-invalid-size - error message to display in case size is invalid

const FileUploadDirective = [
	'$parse',
	'$log',
	function ($parse, $log) {
		const defaults = {
			jediFileMaxSize: JediConfig.fileUpload.maxFileSize,
			jediMessageInvalidExtension: 'invalid file type',
			jediMessageInvalidSize: 'invalid file size',
			extensions: angular.copy(JediConfig.fileUpload.extensions.image),
		};
		const extensionMap = JediConfig.fileUpload.extensions;

		function getSettings(attrs) {
			var settings = {
				maxFileSize: attrs.jediFileMaxSize || defaults.jediFileMaxSize,
				messages: {
					invalidExtension: attrs.jediFileExtensionsInvalid || defaults.jediMessageInvalidExtension,
					invalidSize: attrs.jediFileInvalidSize || defaults.jediMessageInvalidSize,
				},
				fileChange: attrs.jediFileChange,
				extensions: defaults.extensions,
			};

			if (typeof attrs.jediFileExtensions != 'undefined') {
				settings.extensions = attrs.jediFileExtensions;
			} else if (typeof attrs.jediFileType != 'undefined') {
				settings.extensions = extensionMap[attrs.jediFileType];
			}

			if (!settings.extensions || settings.extensions.length <= 0) {
				if (typeof attrs.jediFileExtensions != 'undefined') {
					settings.extensions = attrs.jediFileExtensions;
				} else if (typeof attrs.jediFileType != 'undefined') {
					settings.extensions = extensionMap[attrs.jediFileType];
				}
			}

			return settings;
		}
		return {
			restrict: 'A',
			link: function (scope, $element, attrs) {
				var model = $parse(attrs.jediFileModel);
				var modelSetter = model.assign;
				var domElement = $element.length == 1 ? $element[0] : undefined;

				$element.bind('change', function () {
					scope.$apply(updateFile);
				});

				/// solution to clearing the file selected in an input[type='file'] taken from http://stackoverflow.com/a/13351234
				function resetFormElement(e) {
					if (!e.jquery) {
						e = $(e);
					}
					e.wrap('<form>').closest('form').get(0).reset();
					e.unwrap();
				}
				const clearFile = function () {
					resetFormElement(domElement);
				};

				const updateFile = function () {
					const settings = getSettings(attrs);

					var file = domElement.files[0];
					if (!(file instanceof File)) {
						clearFile();
						return;
					}
					if (file.size > settings.maxFileSize) {
						$log.warn(settings.messages.invalidSize);
						clearFile();
						return;
					}
					const extensions = (settings.extensions || '')
						.split(',')
						.filter(e => (e || '').trim().length > 0)
						.map(e => e.toLowerCase());

					if (extensions.length > 0) {
						const normalizedFilename = (file.name || '').trim().toLowerCase();
						if (!extensions.some(ext => normalizedFilename.endsWith(ext))) {
							$log.warn(settings.messages.invalidExtension);
							clearFile();
							return;
						}
					}

					modelSetter(scope, file);
					if (settings.fileChange) {
						scope.$eval(settings.fileChange, { file: file, clearFile: clearFile });
					}
				};
			},
		};
	},
];
export default FileUploadDirective;
