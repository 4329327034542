import { IController, ILocationService } from 'angular';
import { TransitionService } from '@uirouter/core';
import { ComponentRegistration } from '~root/commons';
import { ContextManagerService, JediAuthService } from 'app-legacy/commons';

export const NavigationComponent: ComponentRegistration = {
	register: function (ngModule) {
		// ngModule.directive('jediNavigationMenu', NavigationMenuController);
		ngModule.component('jediNavigationMenu', {
			controller: NavigationController,
			template: require('./jediNavigationMenu.html'),
		});
	},
};

class NavigationController implements IController {
	static $inject = ['jediContext', '$location', 'jediAuth', '$transitions'];
	public _context: any;
	private deregister: any;
	constructor(
		private jediContext: ContextManagerService,
		private $location: ILocationService,
		private jediAuth: JediAuthService,
		private $transitions: TransitionService
	) {}
	$onInit() {
		this.deregister = this.$transitions.onSuccess({}, () => {
			this.$onChanges();
		});
	}
	$onDestroy() {
		if (typeof this.deregister === 'function') {
			this.deregister();
		}
	}
	$onChanges() {
		this._context = this.jediContext.getCurrentContext() || {};
	}

	userIsNotOperator() {
		return !this.jediAuth.isCorporateUser();
		// user.roles.length && (user.roles | filter : {name: 'AdminApplicationUser'})[0] == undefined
	}

	userIsPlatformAdmin() {
		return this.jediAuth.isPlatformAdmin();
	}

	showAccountMenu() {
		const enable = this._context.type === 'account';
		return enable;
	}

	showPlatformMenu() {
		const enable = this._context.type === 'platform';
		return enable;
	}
	showCorporateMenu() {
		const enable = this._context.type === 'corporate';
		return enable;
	}

	active(...args): string {
		const currentPath = this.$location.path();
		if (args.some(arg => currentPath.includes(arg))) {
			return 'active';
		}
		if (args.some(arg => new RegExp(arg).test(currentPath))) {
			return 'active';
		}

		return '';
	}

	routeContains(path) {
		const currentRoute = this.$location.path();
		if (currentRoute) {
			const op = (currentRoute || '').toLowerCase();
			const cp = (path || '').toLowerCase();
			if (op.indexOf(cp) > -1) {
				return true;
			}
		}

		return false;
	}

	showOrganisationUnitMenu() {
		if (this._context.type == 'corporate') {
			const org = this._context.organisation;
			if (org) {
				if ((org.oUs && org.oUs.length) || org.organisationUnitCount || org.parentId) {
					if (this.jediAuth.isPlatformAdmin()) {
						return true;
					}
					if (this.jediContext.currentUserIsParent()) {
						return true;
					}
				}
			}
		}
		return false;
	}
}
