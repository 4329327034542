import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';

import { GroupsModule } from './groups/groups.module';
import { CertificatesModule } from './certificates/certificates.module';
import { OrganisationsModule } from './organisations/organisations.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ActivitiesModule } from './activities/activities.module';
import { AdminSharedModule } from './shared/shared.module';
import { ReportingUserProgressModule } from './reporting-user-progress/reporting-user-progress.module';
import { ReportingDashboardModule } from './reporting-dashboard/reporting-dashboard.module';
import { ImportUsersModule } from './users/import-users/import-users.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		GroupsModule,
		ActivitiesModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		CertificatesModule,
		OrganisationsModule,
		NotificationsModule,
		AdminSharedModule,
		ReportingUserProgressModule,
		ReportingDashboardModule,
		ImportUsersModule,
	],
	exports: [GroupsModule],
})
export class AdminModule {}
