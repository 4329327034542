import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PlayerComponent } from './player/player.component';
import { PlayerLayoutComponent } from './player-layout/player-layout.component';
import { SharedPortalComponentsModule } from '../shared-portal-components/shared-portal-components.module';

@NgModule({
	declarations: [PlayerComponent, PlayerLayoutComponent],
	imports: [CommonModule, SharedModule, ReactiveFormsModule, SharedPortalComponentsModule],
})
export class ActivityPlayerModule {}
