const SortableTableComponent = {
	register: function(ngModule) {
		ngModule.directive('sortableTable', SortableTableController);
	}
};
export default SortableTableComponent;

const SortableTableController = [
	'$rootScope',
	function($rootScope) {
		return {
			restrict: 'A',
			link: function(scope, element, attributes) {
				$rootScope.$on('ngTableAfterReloadData', function(evt) {
					if (typeof scope.tableParams == 'undefined') {
						return;
					}

					setTimeout(function() {
						var sorting = scope.tableParams.sorting();
						var cells = $(element).find('td');
						cells.removeClass('sorted');
						cells.each(function() {
							for (var i in sorting) {
								if (sorting.hasOwnProperty(i) && $(this).attr('sortable') == "'" + i + "'") {
									$(this).addClass('sorted');
									return;
								}
							}
						});
					}, 100);
				});
			}
		};
	}
];
