import { Injectable, OnDestroy } from '@angular/core';
import { LocalizationProvider } from 'app-legacy/commons';
import { GenericResponseModel } from 'app/public/account/models/GenericResponseModel';
import { GenericErrorModel } from 'app/public/account/models/GenericErrorModel';
import { UserCredentials } from 'app/public/account/models/UserCredentials';
import { ValidationResult } from 'app/public/account/models/ValidationResult';
import { IServiceHandler } from '../IServiceHandler';
import { AccountApiService } from './account-api.service';

@Injectable({
	providedIn: 'root',
})
export class AccountUiService implements IServiceHandler, OnDestroy {
	isSuccess = true;

	private isSubmitting = false;

	private model: UserCredentials = new UserCredentials('', '', false, null);

	private uiErrors: ValidationResult = new ValidationResult();

	private apiErrors: ValidationResult = new ValidationResult();

	localizationChangeUnsubscriber: any;

	constructor(private accountApiService: AccountApiService, private jediLocalization: LocalizationProvider) {
		this.accountApiService.addHandler(this);
		this.localizationChangeUnsubscriber = this.jediLocalization.onChange(this.changeLocalization.bind(this));
	}

	private changeLocalization() {
		this.uiErrors = this.getCurrentLocaleErrors(this.apiErrors, this.model);
	}

	private getCurrentLocaleErrors(originalErrors: ValidationResult, ...args) {
		const viewErrors = angular.copy(originalErrors);
		const data = Object.assign({}, ...args);
		if (viewErrors?.fields) {
			Object.keys(viewErrors.fields).forEach(key => {
				if (viewErrors.fields[key].errors) {
					viewErrors.fields[key].errors = viewErrors.fields[key].errors.map(e =>
						this.jediLocalization.getLocalizedText(e, data)
					);
				}
			});
		}
		if (viewErrors?.generic) {
			viewErrors.generic = this.localizeErrors(viewErrors.generic, data);
		}
		return viewErrors;
	}

	localizeErrors(genericErrors: GenericErrorModel[], data: any): GenericErrorModel[] {
		const genericErrorCopy = genericErrors;
		genericErrorCopy.forEach(x => this.jediLocalization.getLocalizedText(x.message, data));

		return genericErrorCopy;
	}

	ngOnDestroy() {
		if (typeof this.localizationChangeUnsubscriber === 'function') {
			this.localizationChangeUnsubscriber();
		}
		this.accountApiService.removeHandler(this);
	}

	handleStart() {
		this.isSubmitting = true;
	}

	handleResult(result: GenericResponseModel) {
		this.isSuccess = result.isSuccess;
		this.uiErrors = this.getCurrentLocaleErrors(result.errors, this.model, result.model);
		this.apiErrors = angular.copy(result.errors);
		this.isSubmitting = false;
		return result;
	}

	handleError(error: string | GenericErrorModel) {
		this.isSubmitting = false;
		this.setGenericError(error);
		return error;
	}

	setGenericError(error: string | GenericErrorModel) {
		this.isSuccess = false;
		const genericError: ValidationResult =
			typeof error === 'string'
				? { hasErrors: true, generic: [{ message: error }] }
				: { hasErrors: true, generic: [error] };
		this.uiErrors = this.getCurrentLocaleErrors(genericError, this.model);
		this.apiErrors = genericError;
	}

	getSubmitionState() {
		return this.isSubmitting;
	}

	setSubmitionState(isSubmitting: boolean) {
		this.isSubmitting = isSubmitting;
	}

	getModel() {
		return this.model;
	}

	getUiErrors() {
		return this.uiErrors;
	}

	updateModel(change: { field: string; value: string }) {
		this.model[change.field] = change.value;
	}

	selectQueryParams(search: string[]): { [index: string]: string } {
		const params: { [index: string]: string } = {};

		search.forEach(x => {
			const index = x.slice(0, x.indexOf('='));
			const value = x.slice(x.indexOf('=') + 1);
			params[index] = value;
		});

		return params;
	}
}
