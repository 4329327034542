import { Injectable } from '@angular/core';
import { UserActivityProgressNode } from 'models';

import { Subject } from 'rxjs';

// TODO: remove when broadcast functionality is no longer needed to comunicate with angular js

@Injectable()
export class BroadcastBridgeService {
	public currentContainer$: Subject<UserActivityProgressNode> = new Subject<UserActivityProgressNode>();

	public laIsLoaded$: Subject<boolean> = new Subject<boolean>();
}
