/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable class-methods-use-this */

import { IHttpResponse } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { getErrorResponse } from './getErrorResponse';

export const JediErrorHandlerRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('jediErrorHandler', ['$location', '$log', JediErrorHandlerService]);
	},
};

export default JediErrorHandlerRegistration;
const GenericErrorMessage = '';
const newline = '<br/>';
const emptyObjectJSON = JSON.stringify({});

function merge(prefix, array, postfix) {
	if (array && array.join) return `${prefix} ${array.join(',')} ${postfix}`;
	return '';
}

export class JediErrorHandlerService {
	private inlineMessage: string;

	constructor(private $location, private $log) {}

	getSanitizationMessage(item) {
		// additional injection can occur with these outputs, especially if the HTML input isn't valid

		let message = '';
		if (item.commentsRemoved) {
			message += `removed comments ${newline}`;
		}
		message += merge('removed tags: ', item.removedTags, newline);
		message += merge('removed attributes: ', item.removedAttributes, newline);
		message += merge('removed invalid attribute values', item.invalidAttributesValues, newline);
		message += merge('removed invalid urls', item.invalidUrls, newline);

		return message;
	}

	getValidationMessage(validationMessages) {
		if (validationMessages && validationMessages.length > 0) {
			const messages =
				typeof validationMessages === 'string' ? JSON.parse(validationMessages) : validationMessages;

			const reduce = messages.reduce((agg, item) => {
				const im = typeof item === 'string' ? item : this.getSanitizationMessage(item);
				return `${agg}${im}`;
			}, '');
			return reduce;
		}
		return null;
	}

	handleError(error: IHttpResponse<any>): void {
		if (!error) {
			this.$log.warn(GenericErrorMessage);
		} else if (!error.status) {
			const json = JSON.stringify(error);
			if (json !== emptyObjectJSON) {
				this.$log.warn(json);
				return;
			}
			this.$log.warn(error);
		} else if (error.xhrStatus) {
			if (error.xhrStatus) {
				this.handleHttpResponseError(error);
			}
		} else {
			this.handleHttpResponseError(error);
		}
	}

	private handleHttpResponseError(error) {
		const { status, data } = error;
		const message: string = getErrorResponse(data, this.inlineMessage) || GenericErrorMessage;

		if (status === 400) {
			this.$log.warn(`Unable to process request${newline}${message}`);
			return;
		}

		if (status === 503) {
			this.$log.warn(`A request is already in progress, please try again later${newline}${message}`);
			return;
		}

		if (status === 404) {
			this.$location.path('/notfound');
			return;
		}

		this.$log.warn(message);
	}

	handleErrorFactory(message) {
		this.inlineMessage = message;
		return this.handleError.bind(this);
	}
}
