// eslint-disable-next-line max-classes-per-file
export class FilterOptionModel {
	label: string;

	selected: boolean;
}

export class FilterChangeModel {
	states: FilterOptionModel[];

	categories: FilterOptionModel[];

	constructor(states: FilterOptionModel[], categories: FilterOptionModel[]) {
		this.categories = categories;
		this.states = states;
	}
}
