/* eslint-disable max-classes-per-file */
import { UserActivityAuthorization } from './UserActivityAuthorization';

export enum ActivityType {
	Course = 'http://adlnet.gov/expapi/activities/course',
	Module = 'http://adlnet.gov/expapi/activities/module',
}

export class Verb {
	id?: string;

	value?: string;
}

export class ActivityTinCanModel {
	constructor(public tinCanId: string, public name: string) {}
}

export class StatementDetails {
	userId: string;

	participantId: string;

	actorHomepage: string;

	// xAPI Activity ID
	tinCanId: string;

	// xAPI Activity Type ID
	activityType: ActivityType = ActivityType.Course;

	activityName: string;

	container: ActivityTinCanModel;

	rootContainer: ActivityTinCanModel;

	state: Verb;

	lrsEndpoint: string;

	versionId: number;

	participantAuthorization: UserActivityAuthorization;

	activityPath: string;

	isActivity: boolean;
}

export class ExportStatementsTemplateModel {
	path: string;

	name: string;
}

export class ExportStatementsOptions {
	activityId: number;

	versionId?: string | number;

	userIds: Array<string>;

	exportTemplate: ExportStatementsTemplateModel;
}
