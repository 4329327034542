// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro';
import { IntroOptions, UserActivityProgressNode } from 'models';

@Injectable({
	providedIn: 'root',
})
export class IntrojsService {
	private introJs;

	constructor() {
		this.introJs = introJs();
	}

	startTutorial() {
		this.introJs?.start();
	}

	getDefaultOptions(userFirstName: string): IntroOptions {
		return {
			steps: [
				{
					intro: {
						'en-US': `<div class='intro-js-title'>Hello ${userFirstName}!</div><div class='intro-js-content-text'>Welcome to this online learning platform. Follow my quick tour to see your different options.</div>`,
						'nl-NL': `<div class='intro-js-title'>Hallo ${userFirstName}!</div><div class='intro-js-content-text'>Welkom op dit online leerplatform. Volg mijn quick tour om de opties te bekijken.</div>`,
						'de-DE': `<div class='intro-js-title'>Hallo ${userFirstName}!</div><div class='intro-js-content-text'>Willkommen auf dieser Online-Lernplattform. Folgen Sie meiner kurzen Tour, um Ihre verschiedenen Optionen zu sehen.</div>`,
						'fr-FR': `<div class='intro-js-title'>Bonjour ${userFirstName}!</div><div class='intro-js-content-text'>Bienvenue sur cette plateforme d'apprentissage en ligne. Suivez ma présentation rapide pour découvrir vos différentes options.</div>`,
					},
				},
				{
					element: '.icon-settings',
					intro: {
						'en-US':
							'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Check your settings to select a language, see a FAQ, open this quick tour and logout.</div>',
						'nl-NL':
							'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Bekijk je settings om een taal te selecteren, een FAQ en deze quick tour te openen en om uit te loggen.</div>',
						'de-DE':
							'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Überprüfen Sie Ihre Einstellungen, um eine Sprache auszuwählen, sehen Sie sich eine FAQ an, öffnen Sie diese Kurztour und melden Sie sich ab.</div>',
						'fr-FR':
							'<div class="settings-logo"></div><div class="intro-js-title">Paramètres</div><div class="intro-js-content-text">Vérifiez vos paramètres pour sélectionner une langue, consulter une FAQ, ouvrir cette présentation rapide et vous déconnecter.</div>',
					},
					position: 'left',
				},
				{
					element: '#learning-activity-display',
					intro: {
						'en-US':
							"<div class='intro-js-title'>Learning Activities</div><div class='intro-js-content-text'>These learning activities are selected for you. You can open and close them whenever you want. I will show you your progress.</div>",
						'nl-NL':
							"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Deze leeractiviteiten zijn voor jou geselecteerd. Je kunt ze openen en sluiten wanneer je wilt. Ik laat je zien hoe ver je bent.</div>",
						'de-DE':
							"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Diese Lernaktivitäten werden für Sie ausgewählt. Sie können sie jederzeit öffnen und schließen. Ich zeige dir deine Fortschritte.</div>",
						'fr-FR':
							"<div class='intro-js-title'>Activités d'apprentissage</div><div class='intro-js-content-text'>Ces activités d'apprentissage sont sélectionnées pour vous. Vous pouvez les ouvrir et les fermer à votre convenance. Je vous montrerai votre progression.</div>",
					},
					position: 'right',
					tooltipClass: 'customIntro customIntro-small',
				},
				{
					element: '.filter-wrapper',
					intro: {
						'en-US':
							"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>You can filter your learning activities by selecting a category and/or your status.</div>",
						'nl-NL':
							"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>Je kunt je leeractiviteiten filteren door een categorie en/of status te selecteren.</div>",
						'de-DE':
							"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>Sie können Ihre Lernaktivitäten filtern, indem Sie eine Kategorie und/oder Ihren Status auswählen.</div>",
						'fr-FR':
							"<div class='intro-js-title'>Filtrer</div><div class='intro-js-content-text'>Vous pouvez filtrer vos activités d'apprentissage en sélectionnant une catégorie et/ou votre statut.</div>",
					},
					position: 'bottom',
					tooltipClass: 'customIntro customIntro-small',
				},
				{
					intro: {
						'en-US':
							"<div class='finish-logo'></div><div class='intro-js-title'>You are ready to start now</div><div class='intro-js-content-text'>I wish you a pleasant learning experience!</div>",
						'nl-NL':
							"<div class='finish-logo'></div><div class='intro-js-title'>Je bent nu klaar om te starten</div><div class='intro-js-content-text'>Ik wens je prettige leerervaringen toe!</div>",
						'de-DE':
							"<div class='finish-logo'></div><div class='intro-js-title'>Je bent nu klaar om te starten</div><div class='intro-js-content-text'>Ich wünsche Ihnen ein angenehmes Lernerlebnis!</div>",
						'fr-FR':
							"<div class='finish-logo'></div><div class='intro-js-title'>Vous êtes prêt à commencer maintenant</div><div class='intro-js-content-text'>Je vous souhaite une agréable expérience d'apprentissage !</div>",
					},
				},
			],
			showStepNumbers: false,
			exitOnOverlayClick: true,
			exitOnEsc: true,
			nextLabel: '',
			prevLabel: '',
			skipLabel: '',
			doneLabel: '',
			overlayOpacity: 0.9,
			tooltipClass: 'customIntro',
			highlightClass: 'customIntroOverlay',
		};
	}

	setOptions(options) {
		this.introJs.setOptions(options);
	}

	init(
		userFirstName: string,
		currentContainer: UserActivityProgressNode,
		currentUserCulture: string,
		defaultCulture: string
	) {
		const defaultOptions = this.getDefaultOptions(userFirstName);

		const introOptions = this.processIntroSteps(
			defaultOptions,
			currentContainer,
			currentUserCulture,
			defaultCulture
		);

		this.setOptions(introOptions);
	}

	private processIntroSteps(
		defaultOptions: IntroOptions,
		currentContainer: UserActivityProgressNode,
		currentUserCulture: string,
		defaultCulture: string
	) {
		const introOptions = { ...defaultOptions };
		const htmlIntroWrapper = content => content;
		const showFilterStep = currentContainer?.displaySettings?.masterDisplay?.showFilter;
		introOptions.steps = defaultOptions.steps.filter(
			step => !(step.element === '.filter-wrapper' && !showFilterStep)
		);

		introOptions.steps.forEach(step => {
			const s = step;
			if (s.intro[currentUserCulture]) {
				s.intro = htmlIntroWrapper(step.intro[currentUserCulture]);
			} else {
				s.intro = htmlIntroWrapper(step.intro[defaultCulture]);
			}
			if (typeof s.element === 'string') {
				if (!this.isElementVisible(step.element)) {
					s.element = undefined;
				}
			}
		});

		return introOptions;
	}

	private isElementVisible(elementSelector): boolean {
		const element = document.querySelector(elementSelector);
		if (!element) {
			return false;
		}
		const computedStyle = getComputedStyle(element);
		if (computedStyle.display === 'none' || computedStyle.visibility === 'hidden') {
			return false;
		}
		return true;
	}
}
