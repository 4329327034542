// const buildConfig = require('./build.config');

/// declared as per https://stackoverflow.com/a/31090240/2101026
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');
const isNode = new Function('try {return this===global;}catch(e){return false;}');

const consoleLogger = {
    debug: function(message, ...params) {
        console.debug(message, ...params);
    },
    log: function(message, ...params) {
        console.log(message, ...params);
    },
    info: function(message, ...params) {
        console.info(message, ...params);
    },
    warn: function(message, ...params) {
        console.warn(message, ...params);
    },
    error: function(message, ...params) {
        console.error(message, ...params);
    },
    fatal: function(message, ...params) {
        console.error(message, ...params);
        throw message;
    },
};
let logger = consoleLogger;
const empty = () => {};
const logLevels = {
    none: 999,
    fatal: 100,
    error: 90,
    warn: 80,
    info: 70,
    debug: 60,
};

// const runtimeLogLevel = isBrowser()
// 	? process && process.env && process.env.JEDI_APP_LOG_LEVEL
// 	: isNode()
// 	? process && process.env && process.env.JEDI_BUILD_LOG_LEVEL
// 	: 'none';
const runtimeLogLevel = 'none';

let logLevel = logLevels[runtimeLogLevel || 'none'] || logLevels.none;
logLevel = logLevels.none;
logger = {
    debug: logLevel <= logLevels.debug ? logger.debug : empty,
    info: logLevel <= logLevels.info ? logger.info : empty,
    log: logLevel <= logLevels.info ? logger.log : empty,
    warn: logLevel <= logLevels.warn ? logger.warn : empty,
    error: logLevel <= logLevels.error ? logger.error : empty,
    fatal: logLevel <= logLevels.fatal ? logger.fatal : empty,
};

module.exports = logger;