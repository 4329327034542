/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import structuredClone from '@ungap/structured-clone';
import { ContextManagerService, LocalizationProvider } from 'app-legacy/commons';
import { ProgressState, ProviderCategoryEnum, ProviderTypeEnum } from '@base/models';

import { normalizeEditorText } from 'app/admin/shared';

import * as fields from './fields';

import {
	ActivityApiEditModel,
	ActivityApiListItem,
	ActivityTypeUiLabel,
	UiActivityListItem,
	UiActivityEditModel,
} from './models';

import {
	convertToAdjustedDateFromUtcDateValues,
	convertToAdjustedDateFromLocalDateValues,
} from '../../shared/dateHelpers';

function getUiDisplayType(activity): ActivityTypeUiLabel {
	if (!activity) {
		return 'none';
	}
	if (!activity.isActivity) {
		return 'container';
	}

	const category: ProviderCategoryEnum = activity?.activeVersion?.provider?.providerCategory || 'None';

	const field = fields.ActivityTypeCategoryMap[category];

	return field || 'none';
}

function getUiDisplayTypeTooltip(activity: UiActivityListItem) {
	if (!activity) {
		return 'none';
	}
	const providerType = activity?.activeVersion?.provider?.providerType;
	const providerCategory = activity?.activeVersion?.provider?.providerCategory;
	const found = fields.ProviderCategoryTypeMap[providerCategory]?.find(a => a.value === providerType);
	const label = found?.name;

	return label || providerType || activity.providerCategoryNameDisplay;
}

@Injectable()
export class ActivityMapper {
	constructor(private jediContext: ContextManagerService, private jediLocalization: LocalizationProvider) {}

	fromApiListView(activity: ActivityApiListItem): UiActivityListItem {
		// copying restrictions:
		// - deep copy
		// - keep original values intact (Date shouldn't be converted to string)
		const ui: UiActivityListItem = angular.copy<UiActivityListItem>(activity);

		ui.providerCategoryNameDisplay = getUiDisplayType(ui);
		ui.providerTooltip = getUiDisplayTypeTooltip(ui);
		return ui;
	}

	fromApiEditModel(apiActivity: ActivityApiEditModel): UiActivityEditModel {
		// clone the activity to ensure no references are misused
		const activity: ActivityApiEditModel = structuredClone(apiActivity);
		const mapped: UiActivityEditModel = { ...activity, uiType: 'none' };

		if (typeof activity.finalStates === 'string') {
			const values = activity.finalStates.split(',')?.map(s => s?.trim()) as ProgressState[];
			mapped.finalStates = values.filter(n => n !== ProgressState.None);
		} else if (Array.isArray(activity.finalStates)) {
			mapped.finalStates = activity.finalStates.filter(n => n !== 'None').join(',');
		}

		mapped.uiType = getUiDisplayType(activity);

		// values in localization table must be base64 decoded

		if (mapped.localizationSettings) {
			mapped.localizationSettings.localizations = this.jediLocalization.createDecodedLocalizations(
				activity.localizationSettings?.localizations,
				normalizeEditorText
			);
		}

		if (mapped.cultureId) {
			const cultures = this.jediContext.getAvailableCultures();
			mapped.culture = cultures.find(c => c.id === mapped.cultureId);
		} else {
			mapped.culture = null;
		}
		if (activity.philQSettings && mapped.activeVersion?.provider) {
			mapped.activeVersion.provider.philQSettings = { ...activity.philQSettings };
		}
		mapped.genericInfo = normalizeEditorText(activity.genericInfo);
		mapped.description = normalizeEditorText(activity.description);

		mapped.availabilitySettings.startDate = convertToAdjustedDateFromUtcDateValues(
			activity.availabilitySettings.startDate
		);
		mapped.availabilitySettings.endDate = convertToAdjustedDateFromUtcDateValues(
			activity.availabilitySettings.endDate
		);

		return mapped;
	}

	toApiEditModel(activity: UiActivityEditModel): ActivityApiEditModel {
		// clone the activity to ensure no references are misused
		const apiModel: UiActivityEditModel = structuredClone(activity);
		apiModel.group_Id = activity.group?.id || 0;
		apiModel.cultureId = activity.culture?.id;

		if (Array.isArray(activity.finalStates)) {
			if (!activity.finalStates.length) {
				apiModel.finalStates = ProgressState.None;
			} else {
				apiModel.finalStates = activity.finalStates.join(',');
			}
		}
		// values in localization table must be base64 encoded
		const localizations = this.jediLocalization.createEncodedLocalizations(
			activity.localizationSettings?.localizations,
			normalizeEditorText
		);

		apiModel.localizationSettings = { ...apiModel.localizationSettings, ...{ localizations } };

		if (activity.uiType === 'container') {
			return apiModel;
		}

		apiModel.availabilitySettings.startDate = convertToAdjustedDateFromLocalDateValues(
			activity.availabilitySettings.startDate
		);
		apiModel.availabilitySettings.endDate = convertToAdjustedDateFromLocalDateValues(
			activity.availabilitySettings.endDate
		);

		const result = this.mapProviderSettings(activity, apiModel);

		return result;
	}

	mapProviderSettings(activity: UiActivityEditModel, apiModel: ActivityApiEditModel): ActivityApiEditModel {
		const localApiModel = structuredClone(apiModel);

		if (activity.activeVersion == null || activity.activeVersion.provider == null) {
			return localApiModel;
		}

		if (activity.activeVersion.provider.providerType === ProviderTypeEnum.PhilQ) {
			localApiModel.philQSettings = activity.activeVersion.provider.philQSettings;
		} else {
			delete localApiModel.activeVersion.provider.philQSettings;
		}

		if (activity.activeVersion.provider.providerType === ProviderTypeEnum.LTI_V1_1) {
			localApiModel.activeVersion.provider.ltiSettings = activity.activeVersion.provider.ltiSettings;
		} else {
			delete localApiModel.activeVersion.provider.ltiSettings;
		}

		localApiModel.resetUserActivityState = activity.resetUserActivityState === true;
		return localApiModel;
	}
}
