/* eslint-disable max-classes-per-file */
import {
	ActivityModel,
	CategoryModel,
	MediaResourceModel,
	ProviderTypeEnum,
	ProviderCategoryEnum,
	GroupModel,
	CultureModel,
	UserBaseModel,
	ActivityProviderTincanDetails,
	AvailabilityDisplayPolicy,
} from '@base/models';
import { CertificateSettingsModel } from 'app-legacy/admin/commons';
import { UiLabel } from 'app-legacy/admin/models';

export declare type ActivityTypeUiLabel =
	| 'none'
	| 'container'
	| 'xAPI module'
	| 'video'
	| 'document'
	| 'static website'
	| 'LTI';

export type ActivityTypeProvideCategoryMap = {
	[k in ProviderCategoryEnum]: ActivityTypeUiLabel;
};

export declare type ProviderCategoryTypeMapModel = {
	[k in ProviderCategoryEnum]: UiLabel<ProviderTypeEnum>[];
};

export class ActivityApiListItem extends ActivityModel {
	activeVersion: ActivityVersionModel;
}

export class ActivityApiEditModel extends ActivityModel {
	graphicUrl: string;

	categories: CategoryModel[];

	customBackground: MediaResourceModel;

	activeVersion: ActivityVersionModel;

	group?: GroupModel;

	culture?: CultureModel;

	certificateSettings: CertificateSettingsModel;

	philQSettings?: PhilQSettingsModel;

	ltiSettings?: LTISettingsModel;
}

export class UiActivityListItem extends ActivityModel {
	activeVersion: ActivityVersionModel;

	providerCategoryNameDisplay?: string;

	providerTooltip?: string;
}

export class UiActivityEditModel extends ActivityApiEditModel {
	uiType: ActivityTypeUiLabel;
}

export class ActivityVersionModel {
	id?: number | string;

	isActive?: boolean;

	versionNumber?: number;

	description?: string;

	displayName?: string;

	versionName?: string;

	lmsActivityId?: string;

	provider?: ActivityProviderEditModel;
}

export class ActivityVersionDataModel extends ActivityModel {
	versions?: Array<ActivityVersionModel>;
}

export class ActivityProviderEditModel {
	tinCan: ActivityProviderTincanDetails;

	providerType: ProviderTypeEnum;

	providerCategory: ProviderCategoryEnum;

	activityContent?: MediaResourceModel;

	rowVersion?: number;

	creator?: UserBaseModel;

	editor?: UserBaseModel;

	creationDate?: Date;

	lastUpdate?: Date;

	philQSettings?: PhilQSettingsModel;

	ltiSettings?: LTISettingsModel;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class LTISettingsModel {
	contentKey?: string;

	contentSecret?: string;

	customParameters?: string;

	outcomesSettings?: LtiOutcomesSettingsModel;
}

export class LtiOutcomesSettingsModel {
	completedThreshold: Range;

	passedThreshold: Range;

	failedThreshold: Range;

	isEnabled: boolean;

	isQuiz: boolean;
}

export class Range {
	minValue: number = null;

	maxValue: number = null;
}

export class PhilQSettingsModel {
	adminEmail?: string;

	maxDuration?: number;

	maxAttempts?: number;
}

export interface UserActivityRelationModel {
	id: string;
	rootContainer?: any;
	parentContainer?: any;
	activity?: ActivityVersionDataModel;
	version?: ActivityVersionModel;
	authorization?: ParticipantAuthorizationModel;
}

export interface ParticipantAuthorizationModel {
	userId: string;
	learningActivityId: string;
	authorizationToken: string;
	actorHomePage: string;
	profileFetchUrl: string;
}

export interface AvailabilityDisplayPolicyOption {
	id: AvailabilityDisplayPolicy;
	name: string;
}
