import { ILocationService, IHttpService, IQService, IPromise } from 'angular';

import { StatementDetails, Verb, UserActivityProgressNode, ProviderCategoryEnum } from '@base/models';
import { ComponentRegistration } from '~root/commons';

import { LocalizationProvider } from '../jediLocalization/jediLocalizationProvider';
import { JediAuthService } from '../jediAuth/jediAuthService';

import { serverSettings } from '../jediServerSettings';
import { JediConfig } from '../config/config';

import { JediStatementGeneratorService } from './jediStatementGenerator';
import { JediClientStorageService } from './ClientStorage/jediClientStorage';
import { StorageType } from './ClientStorage/StorageType';

import { JediErrorHandlerService } from './jediErrorHandler';
import { JediPackageService } from './jediPackageActions';
import { VerbStates } from '../config/config.tincan';

export const JediUserSettingsComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediUserSettings', JediUserSettingsService);
	},
};

export class JediUserSettingsService {
	static $inject = [
		'$q',
		'$http',
		'$location',
		'jediAuth',
		'jediClientStorage',
		'jediLocalization',
		'jediStatementGenerator',
		'jediErrorHandler',
		'jediPackageActions',
	];

	private ignoredPaths = ['player', 'error', 'notfound', 'logoff'];
	private settingLocations = {
		location: 'jediLocation_',
		maintenanceMessages: 'jedi.messages',
		version: 'jedi.version',
	};

	constructor(
		private $q: IQService,
		private $http: IHttpService,
		private $location: ILocationService,
		private jediAuth: JediAuthService,
		private jediClientStorage: JediClientStorageService,
		private jediLocalization: LocalizationProvider,
		private jediStatementGenerator: JediStatementGeneratorService,
		private jediErrorHandler: JediErrorHandlerService,
		private jediPackageActions: JediPackageService
	) {}

	private isPathIgnored(route) {
		const routeCase = (route || '').toLowerCase();
		// don't save the ignored paths
		for (const path of this.ignoredPaths) {
			const ignoredPath = (path || '').trim();
			if (routeCase.indexOf(ignoredPath) >= 0) {
				return true;
			}
		}
		return false;
	}

	//#region User location preferences

	setLocation(): void {
		const route = (location.href || '').trim();
		if (this.isPathIgnored(route)) {
			return;
		}
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		this.jediClientStorage.set(
			this.settingLocations.location + this.getUserId(user),
			route,
			StorageType.CookieStorage
		);
	}

	clearLocation(): void {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		return this.jediClientStorage.remove(
			this.settingLocations.location + this.getUserId(user),
			StorageType.CookieStorage
		);
	}

	getLocation(): string {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		return this.jediClientStorage.get(
			this.settingLocations.location + this.getUserId(user),
			StorageType.CookieStorage
		);
	}

	private getUserId(user) {
		if (user) {
			return (user.id || '').replace(/-/gi, '');
		}
		return null;
	}
	//#endregion User location preferences

	setIntroductionShow(bool) {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		return this.jediClientStorage.set(
			JediConfig.app.cookies.showIntroduction(user),
			bool,
			StorageType.CookieStorage,
			365
		);
	}

	getIntroductionShow() {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		return Boolean(
			this.jediClientStorage.get(JediConfig.app.cookies.showIntroduction(user), StorageType.CookieStorage)
		);
	}

	//#region User language preferences

	/*
	 * returns the languages available for the current user
	 */
	getAvailableLanguages() {
		// get the available languages for the users' organisation

		const languages = [
			{
				name: 'English (static)',
				id: 'en-US',
			},
			// { name: "Nederlands", id: 'nl-NL' }
		];
		const user = this.jediAuth.jediAuthStore.getLoggedInUser() || {
			supportedCultures: languages,
		};
		return user.supportedCultures;
	}

	/*
	 * saves the language preference for a user
	 */
	setUserLanguage(culture) {
		this.jediLocalization.setCurrentUiCulture(culture);
		this.$http
			.post(JediConfig.api.accountChangeLanguage(culture), null)
			.then(() => {
				const newUser = this.jediAuth.jediAuthStore.getLoggedInUser();
				newUser.culture = culture;
				this.jediAuth.setLoggedInUser(newUser);
				this.jediLocalization.setCurrentUiCulture(newUser.culture);
			})
			.catch(this.jediErrorHandler.handleError);
	}

	// get the language saved for the current user
	getUserLanguage() {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		if (user) {
			return user.culture;
		}
		return undefined;

		// return self.jediLocalization.getCurrentUiCulture();
	}

	//#endregion User language preferences

	//#region Should be moved to more appropiate location
	createActorProperty(userId, providerType, tincanActorHomepage) {
		const actor: any = {};
		if (providerType === 'Lectora') {
			// this format of the 'actor' parameter is used by Lectora to get the information that the course needs on the launch URL
			actor.name = [userId];
			actor.account = [
				{
					accountName: userId,
					accountServiceHomePage: tincanActorHomepage,
				},
			];
			actor.objectType = 'Agent';
		} else {
			actor.name = userId;
			actor.account = {
				name: userId,
				homePage: tincanActorHomepage,
			};

			actor.objectType = 'Agent';
		}

		return actor;
	}

	/// apply package specific updates to the current browsing session
	setupPlayerBrowser(user, activity, userAccess) {
		if (serverSettings.IsWebDeployment) {
			this.jediPackageActions.cleanUpLectoraState();
		}
	}

	buildActivityEndpoint(user, activity, userAccess) {
		const endpointPath = userAccess.endpointPath;
		const authorizationToken = userAccess.authorizationToken;
		const registrationId = userAccess.registrationId;
		const actorHomepage = userAccess.actorHomepage.replace(/\/$/, '');

		const tinCanEndpoint = userAccess.lrsEndpoint;
		const userId = user.id;

		const tinCanId = activity.tinCanId;
		let joinParamsChar = '?';
		// const tinCanId = undefined;

		let url = endpointPath;

		if (this.$location.protocol() === 'https') {
			url = url.replace('http://', 'https://');
		}

		// TODO: HttpUtility.UrlEncode used on the API to generate the access URL is invalid
		// $scope.activityUrl = decodeURIComponent(decodeURIComponent(data.accessURL));

		//check if url already has querystring params
		if (url.includes(joinParamsChar)) {
			joinParamsChar = '&';
		}
		url += joinParamsChar + 'endpoint=' + encodeURIComponent(tinCanEndpoint);

		const auth = 'Basic ' + authorizationToken;

		url += '&auth=' + encodeURIComponent(auth);

		const actor = this.createActorProperty(userId, activity.providerType, actorHomepage);

		url += '&actor=' + encodeURIComponent(JSON.stringify(actor));
		url += '&registration=' + encodeURIComponent(registrationId);
		url += '&activity_id=' + encodeURIComponent(tinCanId);
		return url;
	}

	getUserActivityDetails(statementDetails: StatementDetails): IPromise<StatementDetails> {
		var deferred = this.$q.defer<StatementDetails>();
		if (statementDetails.participantId != JediConfig.defaults.emptyGuid) {
			deferred.resolve(statementDetails);
		} else {
			var userActivityAccessDetailsUrl = JediConfig.api.learning.activities.itemForUser(
				statementDetails.userId,
				statementDetails.activityPath
			);
			this.$http
				.get<Array<UserActivityProgressNode>>(userActivityAccessDetailsUrl)
				.then(response => {
					const participantAccess = response.data;
					statementDetails.participantId = participantAccess[participantAccess.length - 1].participantId;
					deferred.resolve(statementDetails);
				})
				.catch(this.jediErrorHandler.handleError);
		}

		return deferred.promise;
	}

	private generateStatement(statementDetails: StatementDetails, activityType, verb: Verb): Promise<any> {
		const data = statementDetails;
		data.state = verb;
		data.activityType = activityType;
		const lrsCredentials = this.jediStatementGenerator.getLrsCredentials(
			data.lrsEndpoint,
			data.participantAuthorization.authorizationToken
		);
		return this.jediStatementGenerator.sendStatement(lrsCredentials, data);
	}

	generateStatementsForVideoInitialized(statementDetails: StatementDetails): IPromise<any> {
		const verbs = JediConfig.tincan.tinCanProviderStateVerbs.VideoProviderType;
		return this.generateStatementsForVideo(statementDetails, [verbs.initialized]);
	}

	generateStatementsForVideoPlayed(statementDetails: StatementDetails): IPromise<any> {
		const verbs = JediConfig.tincan.tinCanProviderStateVerbs.VideoProviderType;
		return this.generateStatementsForVideo(statementDetails, [verbs.played, verbs.completed]);
	}

	private generateStatementsForVideo(statementDetails: StatementDetails, verbs: Verb[]): IPromise<any> {
		const deferred = this.$q.defer();
		const _verbs = verbs.slice();
		const activityType = JediConfig.tincan.tinCanProviderTypes.VideoProviderType;

		this.getUserActivityDetails(statementDetails).then(sd => {
			_verbs.forEach(verb => {
				// just start generating the statements
				this.generateStatement(sd, activityType, verb);
				deferred.resolve();
			});
		});
		return deferred.promise;
	}

	generateStatementsForDocument(statementDetails: StatementDetails): IPromise<any> {
		const deferred = this.$q.defer();
		const activityType = JediConfig.tincan.tinCanProviderTypes.DocumentProviderType;
		const verbs = JediConfig.tincan.tinCanProviderStateVerbs.DocumentProviderType;

		this.getUserActivityDetails(statementDetails).then(sd => {
			this.generateStatement(sd, activityType, verbs.experienced);
			this.generateStatement(sd, activityType, verbs.completed);
			deferred.resolve();
		});
		return deferred.promise;
	}

	generateStatementsForStaticWebsite(statementDetails: StatementDetails): IPromise<any> {
		const deferred = this.$q.defer();
		const activityType = JediConfig.tincan.tinCanProviderTypes.LocalWebsiteProviderType;
		const verbs = JediConfig.tincan.tinCanProviderStateVerbs.LocalWebsiteProviderType;

		this.getUserActivityDetails(statementDetails).then(sd => {
			this.generateStatement(sd, activityType, verbs.completed);
			deferred.resolve();
		});
		return deferred.promise;
	}

	generateLaunchedStatement(activity: UserActivityProgressNode): IPromise<any> {
		const deferred = this.$q.defer();
		const activityType = JediConfig.tincan.tinCanProviderTypes[activity.providerType];
		const verb = VerbStates.Launched;

		this.getUserActivityDetails(activity.statementDetails).then(statementDetails => {
			this.generateStatement(statementDetails, activityType, verb);
			deferred.resolve();
		});

		return deferred.promise;
	}

	//#endregion
}
