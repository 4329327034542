import { IWindowService } from 'angular';
import { ComponentRegistration } from '~root/commons';

import { StorageType } from './StorageType';
import { IStorage } from './IStorage';
import { WindowStorageAdapter } from './WindowStorageAdapter';
import { JsonPackStorageWrapper } from './JsonPackStorageWrapper';
import { CookieStorageAdapter } from './CookieStorageAdapter';

export const JediClientStorageComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediClientStorage', JediClientStorageService);
	},
};

export class JediClientStorageService {
	private stores = new Map<StorageType, IStorage>();
	public defaultStoreType: StorageType = StorageType.CookieStorage;

	static $inject = ['$window'];
	constructor($window: IWindowService) {
		this.stores.set(StorageType.CookieStorage, new CookieStorageAdapter());
		this.stores.set(StorageType.LocalStorage, new WindowStorageAdapter($window.localStorage));
		this.stores.set(
			StorageType.SessionStorage,
			new JsonPackStorageWrapper(new WindowStorageAdapter($window.sessionStorage))
		);
		this.stores.set(StorageType.SessionStorageNoPack, new WindowStorageAdapter($window.sessionStorage));
		this.defaultStoreType = StorageType.LocalStorage;
	}

	private getStore(type: StorageType) {
		const storeType = type || this.defaultStoreType;
		return this.stores.get(storeType);
	}

	get(key: string, type: StorageType) {
		if (!key) {
			return undefined;
		}
		const store = this.getStore(type);
		return store.get(key);
	}

	getObject<T>(key: string, type: StorageType): T {
		const source = this.get(key, type);
		if (!source) return undefined;
		return JSON.parse(source) as T;
	}

	set(key: string, value, type: StorageType, days?: number): void {
		if (!key) {
			return undefined;
		}
		const store = this.getStore(type);
		return store.set(key, value, days);
	}

	setObject<T>(key: string, value: T, type: StorageType, days?: number): void {
		if (!key) return undefined;
		const target = JSON.stringify(value);
		return this.set(key, target, type, days);
	}

	remove(keyFilter: string, type: StorageType) {
		if (!keyFilter) {
			return undefined;
		}
		const store = this.getStore(type);
		const keys: string[] = store.getKeys(keyFilter);
		return keys.forEach(k => store.remove(k));
	}

	clear(type: StorageType) {
		const store = this.getStore(type);
		return store.clear();
	}

	clearExcept(type: StorageType, keyFilter: string | string[]) {
		const store = this.getStore(type);
		const exceptKeys = Array.isArray(keyFilter) ? keyFilter : store.getKeys(keyFilter);
		const removeKeys = store.getKeys().filter(k => !exceptKeys.includes(k));
		return removeKeys.forEach(k => store.remove(k));
	}

	getKeys(type: StorageType, keyFilter: string): string[] {
		const store = this.getStore(type);
		return store.getKeys(keyFilter);
	}
}
