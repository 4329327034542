const ConfigICALT = {
	categories: {
		extra: 'extra',
		tests: 'toets'
	},
	customFieldName: 'customField1Name',
	customFieldValue: 'BRIN',
	icaltIntroStepsOptions: {
		steps: [
			{
				//screen 1
				intro: {
					'en-US':
						"<div class='intro-js-title'>Hallo {{user.firstName}}!</div><div class='intro-js-content-text'>Welkom in je persoonlijke ICALT-leeromgeving. Volg deze korte tour om kennis te maken met de verschillende mogelijkheden binnen deze omgeving.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Hallo {{user.firstName}}!</div><div class='intro-js-content-text'>Welkom in je persoonlijke ICALT-leeromgeving. Volg deze korte tour om kennis te maken met de verschillende mogelijkheden binnen deze omgeving.</div>"
				}
			},
			{
				//screen 2
				element: '#learning-activity-display',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Hier zie je jouw leeractiviteiten. Deze kun je in willekeurige volgorde en in je eigen tempo doorlopen. Per leeractiviteit is zichtbaar of je deze nog moet volgen, of je hier al aan bent begonnen of dat je deze hebt afgerond.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Hier zie je jouw leeractiviteiten. Deze kun je in willekeurige volgorde en in je eigen tempo doorlopen. Per leeractiviteit is zichtbaar of je deze nog moet volgen, of je hier al aan bent begonnen of dat je deze hebt afgerond.</div>"
				},
				position: 'right',
				tooltipClass: 'customIntro'
			},
			{
				//screen 3
				element: '.container-0',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Introductie</div><div class='intro-js-content-text'>De introductie modules geven je meer achtergrondinformatie over het ICALT-instrument. Hier ontdek je waarom het ICALT-instrument is ontwikkeld en hoe je het kunt gebruiken.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Introductie</div><div class='intro-js-content-text'>De introductie modules geven je meer achtergrondinformatie over het ICALT-instrument. Hier ontdek je waarom het ICALT-instrument is ontwikkeld en hoe je het kunt gebruiken.</div>"
				},
				position: 'right',
				tooltipClass: 'customIntro'
			},
			{
				//screen 4
				element: '.container-1',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Oefenen met observeren</div><div class='intro-js-content-text'>In deze modules kun je oefenen met het observeren. Je bekijkt steeds een les en gaat deze observeren met behulp van het ICALT-instrument.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Oefenen met observeren</div><div class='intro-js-content-text'>In deze modules kun je oefenen met het observeren. Je bekijkt steeds een les en gaat deze observeren met behulp van het ICALT-instrument.</div>"
				},
				position: 'right',
				tooltipClass: 'customIntro'
			},
			{
				//screen 5
				element: '.learning-activity-extra',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Extra oefenen</div><div class='intro-js-content-text'>In deze korte modules vind je extra informatie over de verschillende domeinen van het ICALT-instrument.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Extra oefenen</div><div class='intro-js-content-text'>In deze korte modules vind je extra informatie over de verschillende domeinen van het ICALT-instrument.</div>"
				},
				position: 'right',
				tooltipClass: 'customIntro'
			},
			{
				//screen 6
				element: '#pdf-content',
				intro: {
					'en-US':
						"<div class='intro-js-title'>ICALT-instrument</div><div class='intro-js-content-text'>Hier kun je het ICALT-instrument downloaden en uitprinten.</div>",
					'nl-NL':
						"<div class='intro-js-title'>ICALT-instrument</div><div class='intro-js-content-text'>Hier kun je het ICALT-instrument downloaden en uitprinten.</div>"
				},
				position: 'bottom',
				tooltipClass: 'customIntro'
			},
			{
				//screen 7
				element: '#icalt-tests',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Toets</div><div class='intro-js-content-text'>Als je genoeg hebt geoefend, kun je beginnen met de toets. Wanneer de door jou gegeven scores voldoende overeenstemming hebben met de gegeven scores door de expert, behaal je het ICALT-certificaat.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Toets</div><div class='intro-js-content-text'>Als je genoeg hebt geoefend, kun je beginnen met de toets. Wanneer de door jou gegeven scores voldoende overeenstemming hebben met de gegeven scores door de expert, behaal je het ICALT-certificaat.</div>"
				},
				position: 'left',
				tooltipClass: 'customIntro'
			},
			{
				//screen 8
				intro: {
					'en-US':
						"<div class='finish-logo'></div><div class='intro-js-title'></div><div class='intro-js-content-text'>Je bent nu klaar om aan de slag te gaan!</div>",
					'nl-NL':
						"<div class='finish-logo'></div><div class='intro-js-title'></div><div class='intro-js-content-text'>Je bent nu klaar om aan de slag te gaan!</div>"
				}
			}
		],

		showStepNumbers: false,
		exitOnOverlayClick: true,
		exitOnEsc: true,
		nextLabel: '',
		//prevLabel: '<span style="color:green">Previous</span>',
		prevLabel: '',
		skipLabel: '',
		doneLabel: '',
		tooltipClass: 'customIntro'
	}
};
export default ConfigICALT;
