import { UiLabel, UiSearchItem } from 'app-legacy/admin/models';
import { ActivationState, ProgressState, ProviderCategoryEnum, ProviderTypeEnum } from '@base/models';
import { ActivityTypeProvideCategoryMap, ProviderCategoryTypeMapModel } from './models';

export const ActivityFinalProgressStates = [
	ProgressState.Passed,
	ProgressState.Completed,
	ProgressState.Waived,
	ProgressState.Failed,
];

export const ActivityActivationStates: UiLabel<ActivationState>[] = [
	{ name: 'active', text: 'active', value: ActivationState.Active },
	{ name: 'inactive', text: 'inactive', value: ActivationState.Inactive },
	{ name: 'archived', text: 'archived', value: ActivationState.Archived },
];
// display labels for each ProviderCategory
export const ActivityTypeCategoryMap: ActivityTypeProvideCategoryMap = {
	[ProviderCategoryEnum.None]: 'none',
	[ProviderCategoryEnum.Document]: 'document',
	[ProviderCategoryEnum.LocalXApi]: 'xAPI module',
	[ProviderCategoryEnum.ExternalXApi]: 'xAPI module',
	[ProviderCategoryEnum.Video]: 'video',
	[ProviderCategoryEnum.StaticWebsite]: 'static website',
	[ProviderCategoryEnum.LTI]: 'LTI',
};

// available ProviderTypes for each ProviderCategory
export const ProviderCategoryTypeMap: ProviderCategoryTypeMapModel = {
	[ProviderCategoryEnum.None]: [{ name: 'none', value: ProviderTypeEnum.None }],
	[ProviderCategoryEnum.LocalXApi]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'Articulate Storyline', value: ProviderTypeEnum.Articulate },
		{ name: 'Articulate Rise', value: ProviderTypeEnum.Rise },
		{ name: 'Adobe Captivate', value: ProviderTypeEnum.Captivate },
		{ name: 'Lectora', value: ProviderTypeEnum.Lectora },
		{ name: 'Courseware', value: ProviderTypeEnum.Courseware },
		{ name: 'iSpring', value: ProviderTypeEnum.iSpring },
	],
	[ProviderCategoryEnum.ExternalXApi]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'Phil-Q', value: ProviderTypeEnum.PhilQ },
	],
	[ProviderCategoryEnum.StaticWebsite]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'Local static website', value: ProviderTypeEnum.LocalWebsite },
	],
	[ProviderCategoryEnum.Document]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'Document', value: ProviderTypeEnum.Document },
	],
	[ProviderCategoryEnum.Video]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'Video file', value: ProviderTypeEnum.VideoFile },
		{ name: 'Video Vimeo', value: ProviderTypeEnum.VideoVimeo },
	],
	[ProviderCategoryEnum.LTI]: [
		{ name: 'none', value: ProviderTypeEnum.None },
		{ name: 'LTI v1.1', value: ProviderTypeEnum.LTI_V1_1 },
	],
};

export const ActivityCategoryFilters: UiSearchItem[] = [
	{
		text: 'xAPI module',
		value: `${ProviderCategoryEnum.LocalXApi},${ProviderCategoryEnum.ExternalXApi}`,
		rawValue: 1,
		tooltip: '',
	},
	{ text: 'video', value: ProviderCategoryEnum.Video, rawValue: 2, tooltip: '' },
	{ text: 'document', value: ProviderCategoryEnum.Document, rawValue: 3, tooltip: '' },
	{ text: 'static website', value: ProviderCategoryEnum.StaticWebsite, rawValue: 4, tooltip: '' },
	{ text: 'LTI', value: ProviderCategoryEnum.LTI, rawValue: 5, tooltip: '' },
];
