import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CultureModel, CultureUiModel } from 'models';
import { JediConfig } from 'app-legacy/commons';

@Injectable({
	providedIn: 'root',
})
export class ApiPlatformService {
	constructor(private http: HttpClient) {}

	async getSupportedLanguages(): Promise<CultureUiModel[]> {
		const response = await this.http.get<CultureModel[]>(JediConfig.api.platform.languages).toPromise();
		const supportedLanguages = (response || []).map(language => ({
			...language,
			shortName: language.id.split('-')[0],
		}));
		return supportedLanguages;
	}
}
