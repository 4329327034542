/* eslint-disable no-prototype-builtins */
export class ResourceTranslationTable {
	[localizationKey: string]: ResourceTranslationValue;
}

export class ResourceTranslationValue {
	[cultureIso: string]: string;
}

export const ResourceTranslations: ResourceTranslationTable = {
	//#region Administration
	//#region Audit log
	ADMINISTRATION_AUDIT_LOG_HEADER: {
		'en-US': 'audit log',
		'nl-NL': 'audit log',
	},
	ADMINISTRATION_AUDIT_LOG_TITLE: {
		'en-US': 'activities',
		'nl-NL': 'activiteiten',
	},
	//#endregion Audit log

	ADMINISTRATION_IE11_WARNING_MESSAGE: {
		'en-US':
			'The Philpaper administration backend no longer works properly with Internet Explorer 11. We recommend using a modern browser, such as the latest version of Edge or Chrome.',
		'nl-NL':
			'The Philpaper administration backend no longer works properly with Internet Explorer 11. We recommend using a modern browser, such as the latest version of Edge or Chrome.',
	},
	//#endregion Administration

	//#region Portal/Player

	PORTAL_NAME: {
		'en-US': 'Online Academy',
		'de-DE': 'Online Schulung',
		'nl-NL': 'Online Academy',
		'fr-FR': 'Académie en ligne',
	},

	PORTAL_LEGEND_TEXT: {
		'en-US': 'Hover to see the states',
		'de-DE': 'Halten Sie den Mauszeiger länger über die Schaltfläche um den Status  sehen zu können.',
		'nl-NL': 'Hover om de statussen te zien',
		'fr-FR': 'Pointer pour voir les états',
	},
	PORTAL_TO_DO_FLAG: {
		'en-US': 'To do',
		'de-DE': 'Zu erledigen',
		'nl-NL': 'Te doen',
		'fr-FR': 'À faire',
	},
	PORTAL_IN_PROGRESS_FLAG: {
		'en-US': 'In progress',
		'de-DE': 'In Bearbeitung',
		'nl-NL': 'Mee bezig',
		'fr-FR': 'En cours',
	},
	PORTAL_COMPLETED_FLAG: {
		'en-US': 'Completed',
		'de-DE': 'Abgeschlossen',
		'nl-NL': 'Afgerond',
		'fr-FR': 'Terminé',
	},
	PORTAL_FAILED_FLAG: {
		'en-US': 'Failed',
		'de-DE': 'Fehlgeschlagen',
		'nl-NL': 'Niet geslaagd',
		'fr-FR': 'Échec',
	},
	PORTAL_MANDATORY_FLAG: {
		'en-US': 'Mandatory',
		'de-DE': 'Obligatorisch',
		'nl-NL': 'Verplicht',
		'fr-FR': 'Obligatoire',
	},
	PORTAL_MOTTO: {
		'en-US': 'Learning platform',
		'de-DE': 'Lernplattform',
		'nl-NL': 'Leerplatform',
		'fr-FR': "Plateforme d'apprentissage",
	},
	PORTAL_MENU_ITEM_ADMINISTRATION: {
		'en-US': 'Administration',
		'de-DE': 'Verwaltung',
		'nl-NL': 'Beheer',
		'fr-FR': 'Administration',
	},
	PORTAL_MENU_ITEM_OVERVIEW: {
		'en-US': 'Overview',
		'de-DE': 'Übersicht',
		'nl-NL': 'Overzicht',
		'fr-FR': "Vue d'ensemble",
	},
	PORTAL_MENU_ITEM_FAQ: {
		'en-US': 'FAQ',
		'de-DE': 'Häufig gestellte Fragen',
		'nl-NL': 'Veelgestelde vragen',
		'fr-FR': 'Foire aux questions',
	},
	FAQ_PAGE_TITLE: {
		'en-US': 'FAQ',
		'de-DE': 'Häufig gestellte Fragen',
		'nl-NL': 'Veelgestelde vragen',
		'fr-FR': 'Foire aux questions',
	},
	PORTAL_MENU_ITEM_QUICK_TOUR: {
		'en-US': 'Quick Tour',
		'de-DE': 'Schnelle Tour',
		'nl-NL': 'Korte rondleiding',
		'fr-FR': 'Visite rapide',
	},
	PORTAL_PLAYER_PROFILE: {
		'en-US': 'Profile',
		'de-DE': 'Profiel',
		'nl-NL': 'Profiel',
		'fr-FR': 'Profil',
	},
	PORTAL_PLAYER_LOGOUT: {
		'en-US': 'Logout',
		'de-DE': 'Abmelden',
		'nl-NL': 'Uitloggen',
		'fr-FR': 'Déconnexion',
	},
	PORTAL_PLAYER_LANGUAGE: {
		'en-US': 'Language',
		'de-DE': 'Sprache',
		'nl-NL': 'Taal',
		'fr-FR': 'Langue',
	},
	PORTAL_PLAYER_LOADING_MESSAGE: {
		'en-US': 'Loading, please wait ...',
		'de-DE': 'Laden, bitte warten ...',
		'nl-NL': 'Even geduld aub ...',
		'fr-FR': 'Chargement en cours, veuillez patienter ...',
	},
	PORTAL_LEARNING_ACTIVITIES_TITLE: {
		'en-US': 'Learning Activities',
		'de-DE': 'Lernaktivitäten',
		'nl-NL': 'Leeractiviteiten',
		'fr-FR': "Activités d'apprentissage",
	},
	PORTAL_INTRODUCTION_TITLE: {
		'en-US': 'Introduction',
		'de-DE': 'Einleitung',
		'nl-NL': 'Introductie',
		'fr-FR': 'Introduction',
	},
	PORTAL_INTRODUCTION_CONTENT: {
		'en-US': 'Welcome! All learning activities that are available for you can be found here. Have fun learning!',
		'de-DE': 'Willkommen! Hier finden Sie alle für Sie verfügbaren Übungen. Viel Spaß beim Lernen.',
		'nl-NL':
			'Welkom! Hier vind je de leeractiviteiten die voor jou beschikbaar zijn. We wensen je veel leerplezier.',
		'fr-FR':
			"Bienvenue! Vous trouverez ici toutes les activités d'apprentissage auxquelles vous pouvez accéder. Découvrez le plaisir d'apprendre!",
	},
	PORTAL_ADMINISTRATION: {
		'en-US': 'Administration',
		'nl-NL': 'Admin',
		'fr-FR': 'Administration',
	},
	PORTAL_FILTER: {
		'en-US': 'FILTER',
		'nl-NL': 'FILTER',
		'fr-FR': 'FILTRER',
	},
	PORTAL_FILTER_DEHEUS: {
		'en-US': 'Filter',
		'nl-NL': 'Filter',
	},
	PORTAL_NO_FILTER_RESULTS: {
		'en-US': 'No learning activities found with the specified filters',
		'de-DE': 'Keine Übungen für diesen Filter gefunden.',
		'nl-NL': 'Geen leeractiviteiten gevonden die voldoen aan de filters',
		'fr-FR': "Aucune activité d'apprentissage correspondant aux filtres spécifiés n'a été trouvée",
	},
	PORTAL_LOADING_LA: {
		'en-US': 'Loading learning activities...',
		'de-DE': 'Übungen werden geladen....',
		'nl-NL': 'Leeractiviteiten worden geladen...',
		'fr-FR': "Chargement des activités d'apprentissage...",
	},

	PORTAL_FILTER_CATEGORY: {
		'en-US': 'CATEGORY',
		'de-DE': 'Kategorie',
		'nl-NL': 'CATEGORIE',
		'fr-FR': 'CATÉGORIE',
	},
	PORTAL_FILTER_CATEGORY_DEHEUS: {
		'en-US': 'CATEGORY',
		'de-DE': 'Kategorie',
		'nl-NL': 'CATEGORIE',
	},
	PORTAL_FILTER_STATUS: {
		'en-US': 'STATUS',
		'nl-NL': 'STATUS',
		'fr-FR': 'STATUT',
	},
	PORTAL_FILTER_STATE_DEHEUS: {
		'en-US': 'STATE',
		'nl-NL': 'STATE',
	},
	PORTAL_ACTIVITY_NEW: {
		'en-US': 'NEW:',
		'de-DE': 'NEU:',
		'nl-NL': 'NIEUW:',
		'fr-FR': 'NOUVEAU:',
	},
	PORTAL_ACTIVITY_BUTTON_OPEN: {
		'en-US': 'OPEN',
		'de-DE': 'Öffnen',
		'nl-NL': 'OPEN',
		'fr-FR': 'OUVRIR',
	},
	PORTAL_START_BUTTON: {
		'en-US': 'START',
		'nl-NL': 'START',
		'fr-FR': 'DÉMARRER',
	},
	PORTAL_DONE_BUTTON: {
		'en-US': 'REVISIT',
		'de-DE': 'Erneut öffnen',
		'nl-NL': 'HEROPEN',
		'fr-FR': 'REVOIR',
	},
	PORTAL_ACTIVE_BUTTON: {
		'en-US': 'CONTINUE',
		'de-DE': 'Weiter',
		'nl-NL': 'GA VERDER',
		'fr-FR': 'CONTINUER',
	},
	PORTAL_VIDEO_BUTTON_PLAY: {
		'en-US': 'PLAY',
		'de-DE': 'Abspielen',
		'nl-NL': 'SPEEL AF',
		'fr-FR': 'LIRE',
	},
	PORTAL_DOCUMENT_BUTTON_DOWNLOAD: {
		'en-US': 'DOWNLOAD',
		'de-DE': 'DOWNLOADEN',
		'nl-NL': 'DOWNLOAD',
		'fr-FR': 'TÉLÉCHARGER',
	},
	PORTAL_SUPPORT_TITLE: {
		'en-US': 'Support',
		'de-DE': 'Unterstützung',
		'nl-NL': 'Ondersteuning',
		'fr-FR': 'Assistance',
	},
	PORTAL_NO_CONTENT: {
		'en-US': 'No learning activities available',
		'de-DE': 'Keine Übungen gefunden.',
		'nl-NL': 'Geen leeractiviteiten beschikbaar',
		'fr-FR': "Aucune activité d'apprentissage disponible",
	},
	PORTAL_SUPPORT_CONTENT: {
		'en-US':
			'<p>Do you have a question? We are here to help you. You can always e-mail us and we will get back to you shortly. During office hours you can also reach us by phone.</p><div class="portal-support-container"> <div class="portal-support-info"><p>support@splintt.nl</p></div><div class="portal-support-info"><p>+31 85 401 06 20</p></div></div>',
		'de-DE':
			'<p>Haben Sie Fragen? Wir helfen Ihnen gerne. Sie können uns während unserer Bürozeiten gerne anrufen.</p><div class="portal-support-container"> <div class="portal-support-info"><p>support@splintt.nl</p></div><div class="portal-support-info"><p>+31 85 401 06 20</p></div></div>',
		'nl-NL':
			'<p>Heb je vragen? We helpen je graag. Je kunt ons altijd per e-mail bereiken en dan reageren we zo snel mogelijk. Tijdens kantooruren kun je ons ook bellen.</p><div class="portal-support-container"> <div class="portal-support-info"><p>support@splintt.nl</p></div><div class="portal-support-info"><p>+31 85 401 06 20</p></div></div>',
		'fr-FR':
			'<p>Vous avez une question? Nous sommes là pour vous aider. Vous pouvez toujours nous envoyer un email, et nous vous répondrons rapidement. Pendant les heures de bureau, vous pouvez également nous joindre par téléphone.</p><div class="portal-support-container"> <div class="portal-support-info"><p>support@splintt.nl</p></div><div class="portal-support-info"><p>+31 85 401 06 20</p></div></div>',
	},
	PORTAL_NAME_CONTENT: {
		'en-US': '',
		'nl-NL': '',
		'fr-FR': '',
	},
	PORTAL_FOOTER_CONTENT: {
		'en-US': '',
		'nl-NL': '',
		'fr-FR': '',
	},
	PORTAL_CLOSE_BTN: {
		'en-US': 'CLOSE',
		'de-DE': 'Schließen',
		'nl-NL': 'CLOSE',
		'fr-FR': 'FERMER',
	},
	PLAYER_BACK: {
		'en-US': 'Back to overview',
		'de-DE': 'Zurück zur Übersicht',
		'nl-NL': 'Terug naar overzicht',
		'fr-FR': "Retour à l'aperçu",
	},
	PORTAL_FILTER_STATE_TODO: {
		'en-US': 'to do',
		'de-DE': 'zu erledigen',
		'nl-NL': 'to do',
		'fr-FR': 'à faire',
	},
	PORTAL_FILTER_STATE_IN_PROGRESS: {
		'en-US': 'in progress',
		'de-DE': 'in Bearbeitung',
		'nl-NL': 'bezig',
		'fr-FR': 'en cours',
	},
	PORTAL_FILTER_STATE_COMPLETED: {
		'en-US': 'completed',
		'de-DE': 'abgeschlossen',
		'nl-NL': 'afgerond',
		'fr-FR': 'terminé',
	},
	PORTAL_OTHER_COURSES: {
		'en-US': 'Other courses',
		'de-DE': 'Andere Programme',
		'nl-NL': 'Andere Programma’s',
		'fr-FR': 'Autres formations',
	},
	PORTAL_DOWNLOAD_CERTIFICATE: {
		'en-US': 'Certificate',
		'de-DE': 'Zertifikat',
		'nl-NL': 'Certificaat',
	},

	PORTAL_LA_AVAILABLE: {
		'en-US': 'Available until:<br /><strong>{{endDate}}</strong>',
		'de-DE': 'Verfügbar bis zum:<br /><strong>{{endDate}}</strong>',
		'nl-NL': 'Beschikbaar tot:<br /><strong>{{endDate}}</strong>',
		'fr-FR': 'Disponible jusqu’au:<br /><strong>{{endDate}}</strong>',
	},

	PORTAL_LA_AVAILABLE_SOON: {
		'en-US': 'Available from:<br /><strong>{{startDate}}</strong>',
		'de-DE': 'Verfügbar ab:<br /><strong>{{startDate}}</strong>',
		'nl-NL': 'Beschikbaar vanaf:<br /><strong>{{startDate}}</strong>',
		'fr-FR': 'Disponible à partir du:<br /><strong>{{startDate}}</strong>',
	},

	PORTAL_LA_FINAL_DAYS_AVAILABLE: {
		'en-US': 'Still available for <br /><strong>{{numberOfDays}} days</strong>',
		'de-DE': 'Noch <strong>{{numberOfDays}} Tage</strong><br />verfügbar',
		'nl-NL': 'Nog <strong>{{numberOfDays}} dagen</strong><br />beschikbaar',
		'fr-FR': 'Encore disponible pendant <strong>{{numberOfDays}} jours</strong>',
	},

	PORTAL_LA_LAST_DAY_AVAILABLE: {
		'en-US': '<strong>Last day</strong><br />available',
		'de-DE': '<strong>Letzter Tag</strong><br />verfügbar',
		'nl-NL': '<strong>Laatste dag</strong><br />beschikbaar',
		'fr-FR': '<strong>Dernier jour</strong><br />disponible',
	},

	//#endregion Portal/Player

	//#region Public pages

	//#region Login page
	PUBLIC_LOGIN_TITLE: {
		'en-US': 'Login',
		'de-DE': 'Anmelden',
		'nl-NL': 'Login ',
		'fr-FR': 'Connexion',
	},
	PUBLIC_SIGN_IN_BTN: {
		'en-US': 'sign in',
		'de-DE': 'anmelden',
		'nl-NL': 'inloggen',
		'fr-FR': 'ouvrir une session',
	},

	PUBLIC_LOGIN_BTN_LOGIN: {
		'en-US': 'LOGIN',
		'de-DE': 'Anmelden',
		'nl-NL': 'LOGIN ',
		'fr-FR': 'CONNEXION',
	},
	PUBLIC_LOGIN_USERNAME_PLACEHOLDER: {
		'en-US': 'username',
		'de-DE': 'benutzername',
		'nl-NL': 'gebruikersnaam ',
		'fr-FR': "nom d'utilisateur",
	},
	PUBLIC_LOGIN_USERNAME_PLACEHOLDER_DEHEUS: {
		'en-US': 'Username',
		'de-DE': 'Benutzername',
		'nl-NL': 'Gebruikersnaam ',
		'fr-FR': "Nom d'utilisateur",
	},
	PUBLIC_LOGIN_PASSWORD_PLACEHOLDER: {
		'en-US': 'password',
		'de-DE': 'passwort',
		'nl-NL': 'wachtwoord ',
		'fr-FR': 'mot de passe',
	},
	PUBLIC_LOGIN_PASSWORD_PLACEHOLDER_DEHEUS: {
		'en-US': 'Password',
		'de-DE': 'Passwort',
		'nl-NL': 'Wachtwoord ',
		'fr-FR': 'Mot de passe',
	},
	PUBLIC_LOGIN_FORGOT_PASSWORD: {
		'en-US': 'Forgot password?',
		'de-DE': 'Passwort vergessen?',
		'nl-NL': 'Wachtwoord vergeten? ',
		'fr-FR': 'mot de passe oublié?',
	},

	PUBLIC_LOGIN_FORGOT_PASSWORD_DEHEUS: {
		'en-US': 'Forgot password',
		'de-DE': 'Passwort vergessen',
		'nl-NL': 'Wachtwoord vergeten',
		'fr-FR': 'mot de passe oublié',
	},

	PUBLIC_LOGIN_REGISTER: {
		'en-US': 'Register',
		'de-DE': 'Registrieren',
		'nl-NL': 'Registreer',
		'fr-FR': 'S’inscrire',
	},

	PUBLIC_LOGIN_EXTERNAL_LOGIN_LABEL: {
		'en-US': 'external login',
		'de-DE': 'externe login',
		'nl-NL': 'externe login',
		'fr-FR': 'connexion externe',
	},

	//#endregion Login page

	PUBLIC_REGISTER_TITLE: {
		'en-US': 'Register',
		'de-DE': 'Registrieren',
		'nl-NL': 'Registreer',
		'fr-FR': 'S’inscrire',
	},

	PUBLIC_REGISTER_FIRSTNAME: {
		'en-US': 'First name',
		'de-DE': 'Vorname',
		'nl-NL': 'Voornaam',
		'fr-FR': 'Prénom',
	},

	PUBLIC_REGISTER_LASTNAME: {
		'en-US': 'Last name',
		'de-DE': 'Nachname',
		'nl-NL': 'Achternaam',
		'fr-FR': 'Nom',
	},

	PUBLIC_REGISTER_BRIN: {
		'en-US': 'BRIN',
		'nl-NL': 'BRIN-nummer',
		'fr-FR': 'BRIN',
	},

	PUBLIC_REGISTER_EMAIL: {
		'en-US': 'E-mail',
		'de-DE': 'E-Mail Adresse',
		'nl-NL': 'Je e-mailadres',
		'fr-FR': 'Email:',
	},

	PUBLIC_REGISTER_EMAIL_ICALT: {
		'en-US': 'E-mail',
		'de-DE': 'Ihre Schul-E-Mail-Adresse',
		'nl-NL': 'Je school e-mailadres',
	},

	PUBLIC_REGISTER_CONFIRM_EMAIL: {
		'en-US': 'Confirm e-mail',
		'de-DE': 'E-Mail Adresse bestätigen',
		'nl-NL': 'Bevestig je e-mailadres',
		'fr-FR': "Confirmer l'email",
	},
	ERROR_PUBLIC_REGISTER_CONFIRM_EMAIL_REQUIRED: {
		'en-US': 'The "confirm email" field is required.',
		'de-DE': 'Das Feld "E-Mailadresse bestätigen"ist obligatorisch',
		'nl-NL': 'Het "Bevestig je e-mailadres" veld is verplicht.',
		'fr-FR': "Le champ « confirmer l'email » est obligatoire.",
	},

	PUBLIC_REGISTER_AGREEMENT: {
		'en-US': 'I agree to the ',
		'de-DE': 'Ich stimme zu',
		'nl-NL': 'Ik ga akkoord met de ',
		'fr-FR': 'J’accepte les ',
	},

	PUBLIC_REGISTER_TERMS_AND_CONDITIONS: {
		'en-US': 'terms and conditions',
		'de-DE': 'bedingungen',
		'nl-NL': 'voorwaarden',
		'fr-FR': 'conditions générales',
	},

	PUBLIC_PRIVACY_TEXT: {
		'en-US': 'We process your personal data in accordance with our ',
		'de-DE': 'Wir verarbeiten Ihre personenbezogenen Daten wie in unserer ',
		'nl-NL': 'Wij verwerken uw persoonsgegevens zoals weergegeven in ons ',
		'fr-FR': 'Nous traitons vos données personnelles conformément à notre ',
	},

	PUBLIC_PRIVACY_LINK_TEXT: {
		'en-US': 'privacy statement',
		'de-DE': 'datenschutzrichtlinie beschrieben.',
		'nl-NL': 'privacyreglement',
		'fr-FR': 'déclaration de confidentialité',
	},

	AITRAP_REGISTER_TERMS_AND_CONDITIONS: {
		'en-US': 'terms of use',
		'de-DE': 'bedingungen',
		'nl-NL': 'voorwaarden',
	},
	DOWNLOAD_FILE_NOT_FOUND: {
		'en-US': 'File not found',
		'de-DE': 'Datei nicht gefunden',
		'nl-NL': 'Bestand niet gevonden',
		'fr-FR': 'Fichier introuvable',
	},

	PUBLIC_REGISTER_SUBMIT: {
		'en-US': 'Submit',
		'de-DE': 'Bestätigen Sie hier',
		'nl-NL': 'Bevestig',
		'fr-FR': 'envoyer',
	},

	REGISTER_EMAIL_LABEL: {
		'en-US': '',
		'de-DE':
			'Bitte verwenden Sie für die Anmeldung Ihre Schul-E-Mail-Adresse. Diese E-Mail-Adresse wird für folgende Mitteilungen verwendet',
		'nl-NL':
			'Maak gebruik van je school e-mailadres om te registreren. Dit mailadres zal gebruikt worden om te communiceren.',
		'fr-FR':
			"Utilisez l'adresse email de votre établissement pour vous inscrire. Il sera utilisé aux fins de communication.",
	},

	REGISTER_BRIN_LABEL: {
		'en-US': '',
		'nl-NL':
			'Vul hier het BRIN-nummer van je school in. Dit nummer wordt gebruikt voor verificatie van je e-mailadres. ',
	},
	ERROR_PUBLIC_FIRST_NAME_REQUIRED: {
		'en-US': 'The "First name" field is required.',
		'nl-NL': 'Het "Voornaam" veld is verplicht.',
		'fr-FR': 'Le champ « Prénom » est obligatoire.',
	},
	ERROR_PUBLIC_EMAIL_REQUIRED: {
		'en-US': 'The "Email" field is required.',
		'de-DE': 'Das Feld "Ihre E-Mail Adresse" ist obligatorisch.',
		'nl-NL': 'Het "Je e-mailadres" veld is verplicht.',
		'fr-FR': 'Le champ « Email » est obligatoire.',
	},
	ERROR_PUBLIC_EMAIL_INVALID: {
		'en-US': 'The "Email" field is not a valid e-mail address.',
		'de-DE': 'Dies ist keine gültige E-Mail Adresse.',
		'nl-NL': 'Dit is geen geldig e-mail adres.',
		'fr-FR': 'Le champ « Email » ne propose pas une adresse email valide.',
	},
	ERROR_PUBLIC_CONFIRM_EMAIL_REQUIRED: {
		'en-US': 'The "Confirm Email" field is required.',
		'de-DE': 'Das Feld "E-Mailadresse bestätigen"ist obligatorisch.',
		'nl-NL': 'Het "Bevestig je e-mailadres" veld is verplicht.',
		'fr-FR': 'Le champ « Confirmer l’email » est obligatoire.',
	},
	ERROR_PUBLIC_CONFIRM_EMAIL_INVALID: {
		'en-US': 'The "Confirm Email" field is not a valid e-mail address.',
		'de-DE': 'Dies ist keine gültige E-Mail Adresse.',
		'nl-NL': 'Dit is geen geldig e-mail adres.',
		'fr-FR': 'Le champ « Confirmer l’email » ne propose pas une adresse email valide.',
	},
	ERROR_PUBLIC_LAST_NAME_REQUIRED: {
		'en-US': 'The "Last name" field is required.',
		'de-DE': 'Das Feld "Nachname" ist obligatorisch.',
		'nl-NL': 'Het "Achternaam" veld is verplicht.',
		'fr-FR': 'Le champ « Nom » est obligatoire.',
	},
	ERROR_TERMS_REQUIRED: {
		'en-US': 'You need to agree the terms.',
		'em-US': 'Sie müssen den Bedingungen zustimmen.',
		'nl-NL': 'Je dient akkoord te gaan met de voorwaarden.',
		'fr-FR': 'Vous devez accepter les conditions générales.',
	},
	ERROR_BRIN_IS_REQUIRED: {
		'en-US': 'The "BRIN" field is required.',
		'nl-NL': 'Het "BRIN" veld is verplicht.',
	},

	ERROR_BRIN_IS_INVALID: {
		'en-US': 'Invalid BRIN number.',
		'nl-NL': 'Ongeldig BRIN nummer.',
	},

	ERROR_EMAIL_CONFIRMATION_MISMATCH: {
		'en-US': 'The "Email" field and "Confirm email" field do not match.',
		'de-DE': 'Das Feld "Ihre E-Mail-Adresse" und das Feld "E-Mail-Adresse bestätigen" stimmen nicht überein.',
		'nl-NL': 'Het "Je e-mailadres" veld en "Bevestig je e-mailadres" veld komen niet overeen.',
		'fr-FR': 'Les champs « Email » et « Confirmer l’email » ne correspondent pas.',
	},

	ERROR_RECAPTCHA_RESPONSE: {
		'en-US': 'You are a robot.',
		'de-DE': 'Sie sind ein Roboter.',
		'nl-NL': 'Je bent een robot.',
		'fr-FR': 'Vous êtes un robot.',
	},

	ERROR_RECAPTCHA_REQUIRED: {
		'en-US': 'Confirm that you are not a robot to be able to continue with your registration.',
		'de-DE': 'Bitte bestätigen Sie, dass Sie kein Roboter sind, um mit der Registrierung fortzufahren.',
		'nl-NL': 'Bevestig dat je geen robot bent om verder te gaan met de registratie.',
		'fr-FR': "Confirmez que vous n'êtes pas un robot afin de pouvoir poursuivre votre inscription.",
	},
	ERROR_CUSTOM_FIELD_1_REQUIRED: {
		'en-US': 'Custom Field 1 is required',
		'de-DE': 'Benutzerdefiniertes Feld 1 ist erforderlich',
		'nl-NL': 'Custom Field 1 is required',
		'fr-FR': 'Le champ personnalisé 1 est obligatoire',
	},
	ERROR_CUSTOM_FIELD_2_REQUIRED: {
		'en-US': 'Custom Field 2 is required',
		'de-DE': 'Benutzerdefiniertes Feld 2ist erforderlich',
		'nl-NL': 'Custom Field 2 is required',
		'fr-FR': 'Le champ personnalisé 2 est obligatoire',
	},
	ERROR_CUSTOM_FIELD_3_REQUIRED: {
		'en-US': 'Custom Field 3 is required',
		'de-DE': 'Benutzerdefiniertes Feld 3 ist erforderlich',
		'nl-NL': 'Custom Field 3 is required',
		'fr-FR': 'Le champ personnalisé 3 est obligatoire',
	},
	ERROR_CUSTOM_FIELD_4_REQUIRED: {
		'en-US': 'Custom Field 4 is required',
		'de-DE': 'Benutzerdefiniertes Feld 4 ist erforderlich',
		'nl-NL': 'Custom Field 4 is required',
		'fr-FR': 'Le champ personnalisé 4 est obligatoire',
	},
	ERROR_CUSTOM_FIELD_5_REQUIRED: {
		'en-US': 'Custom Field 5 is required',
		'de-DE': 'Benutzerdefiniertes Feld 5 ist erforderlich',
		'nl-NL': 'Custom Field 5 is required',
		'fr-FR': 'Le champ personnalisé 5 est obligatoire',
	},

	ERROR_BLACKLISTED_EMAIL: {
		'en-US': 'This email address is blacklisted, and can’t be used. Please contact support for more information.',
		'de-DE':
			'Diese E-Mail-Adresse wurde auf die schwarze Liste gesetzt und kann nicht verwendet werden. Für weitere Informationen wenden Sie sich bitte an den Support',
		'nl-NL':
			'Dit e-mailadres staat op de zwarte lijst, en kan daarom niet worden gebruikt. Neem contact op met support voor meer informatie.',
		'fr-FR':
			'Cette adresse email est sur liste noire et elle ne peut pas être utilisée. Veuillez contacter l’assistance pour plus d’informations.',
	},

	PUBLIC_CONFIRMATION_TITLE: {
		'en-US': 'Confirmation',
		'de-DE': 'Bestätigung',
		'nl-NL': 'Bevestiging',
		'fr-FR': 'Confirmation',
	},

	PUBLIC_CONFIRMATION_DATA_TEXT: {
		'en-US': 'You registered with the following information:',
		'de-DE': 'Sie sind jetzt mit den folgenden Angaben registriert',
		'nl-NL': 'Je bent nu geregistreerd met onderstaande gegevens:',
		'fr-FR': 'Vous vous êtes inscrit en fournissant les informations suivantes:',
	},

	PUBLIC_CONFIRMATION_SUPPORT_TEXT: {
		'en-US':
			'After your data will be checked, you will receive a confirmation and further instructions via e-mail. If you did not receive a confirmation within one working day you can contact ',
		'de-DE':
			'Nach Prüfung Ihrer Angaben erhalten Sie eine Bestätigung und weitere Anweisungen per E-Mail. Wenn Sie innerhalb eines Arbeitstages keine Nachricht erhalten haben, wenden Sie sich bitte an',
		'nl-NL':
			'Nadat je gegevens zijn gecontroleerd, ontvang je een bevestiging en verdere instructies via e-mail. Als je binnen een werkdag nog geen bericht hebt ontvangen, kan je contact opnemen met ',
		'fr-FR':
			'Après la vérification de vos données, vous recevrez une confirmation et des instructions supplémentaires par email. Si vous n’avez pas reçu de confirmation dans un délai d’un jour ouvrable, vous pouvez contacter ',
	},

	PUBLIC_CONFIRMATION_CLOSE: {
		'en-US': 'Close',
		'de-DE': 'Schließen',
		'nl-NL': 'Sluit',
		'fr-FR': 'Fermer',
	},

	//#region Forgot password page
	PUBLIC_FORGOT_PASSWORD_TITLE: {
		'en-US': 'Forgot password',
		'de-DE': 'Passwort vergessen',
		'nl-NL': 'Wachtwoord vergeten',
		'fr-FR': 'Mot de passe oublié',
	},
	PUBLIC_FORGOT_PASSWORD_HEADER: {
		'en-US': 'Forgot password',
		'de-DE': 'Passwort vergessen',
		'nl-NL': 'Wachtwoord vergeten',
		'fr-FR': 'Mot de passe oublié',
	},
	PUBLIC_FORGOT_PASSWORD_DESCRIPTION: {
		'en-US':
			'Please enter your username and press "reset". You will receive an confirmation email shortly with further instructions. Please make sure that you enter your username. This is not necessarily the same as your e-mail address! If you forgot your username, please contact support.',
		'de-DE':
			'Geben Sie Ihren Benutzernamen ein und drücken Sie "Zurücksetzen". Sie erhalten dann eine Bestätigungs-E-Mail mit weiteren Anweisungen. Vergewissern Sie sich, dass Sie Ihren Benutzernamen eingeben. Dies ist nicht unbedingt mit Ihrer E-Mail-Adresse identisch! Falls Sie Ihren Benutzernamen vergessen haben, wenden Sie sich bitte an den Support.',
		'nl-NL':
			'Vul je gebruikersnaam in en druk op "reset". Je ontvangt een bevestigingsmail met verdere instructies. Let op dat je je gebruikersnaam invult. Deze is niet noodzakelijkerwijs gelijk aan je e-mail adres! Mocht je je gebruikersnaam vergeten zijn, neem dan contact op met support.',
		'fr-FR':
			"Veuillez entrer votre nom d'utilisateur et appuyer sur « réinitialiser ». Vous recevrez sous peu un email de confirmation contenant des instructions supplémentaires. Veillez à saisir votre nom d'utilisateur. Il n'est pas nécessairement identique à votre adresse email! Si vous avez oublié votre nom d'utilisateur, veuillez contacter l'assistance.",
	},
	PUBLIC_FORGOT_PASSWORD_USERNAME_PLACEHOLDER: {
		'en-US': 'username',
		'de-DE': 'benutzername',
		'nl-NL': 'gebruikersnaam',
		'fr-FR': "nom d'utilisateur",
	},
	//#endregion Forgot password page

	//#region Forgot password confirmation page

	PUBLIC_FORGOT_PASSWORD_CONFIRMATION_TITLE: {
		'en-US': 'Reset Password Confirmation',
		'de-DE': 'Bestätigung der Passwortrücksetzung',
		'nl-NL': 'Bevestiging resetten wachtwoord',
		'fr-FR': 'Confirmer la réinitialisation du mot de passe',
	},
	PUBLIC_FORGOT_PASSWORD_CONFIRMATION_HEADER: {
		'en-US': 'Reset Password Confirmation',
		'de-DE': 'Bestätigung der Passwortrücksetzung',
		'nl-NL': 'Bevestiging resetten wachtwoord',
		'fr-FR': 'Confirmer la réinitialisation du mot de passe',
	},
	PUBLIC_FORGOT_PASSWORD_CONFIRMATION_MESSAGE: {
		'en-US':
			'If your username is known in the system, you will receive a confirmation email with further instructions. Please note: For security reasons you will not receive feedback if your username is unknown in the system. You can always contact support.',
		'de-DE':
			'Wenn uns Ihr Benutzername bekannt ist, erhalten Sie eine Bestätigungs-E-Mail mit weiteren Anweisungen. Bitte beachten Sie, dass Sie aus Sicherheitsgründen kein Feedback erhalten, wenn wir Ihren Benutzernamen nicht kennen. Sie können sich jederzeit an den Support wenden.',
		'nl-NL':
			'Als je gebruikersnaam bekend is bij ons, dan ontvang je een bevestiging via e-mail met verdere instructies. Let op: om veiligheidsredenen ontvang je geen feedback als je gebruikersnaam niet bij ons bekend is. Je kunt altijd contact opnemen met support.',
		'fr-FR':
			"Si votre nom d'utilisateur est connu dans le système, vous recevrez un email de confirmation proposant des instructions supplémentaires. Veuillez noter : Pour des raisons de sécurité, vous ne recevrez pas de retour si votre nom d'utilisateur est inconnu dans le système. Vous pouvez toujours contacter l'assistance.",
	},

	//#endregion Forgot password confirmation page

	//#region Change password page
	PUBLIC_CHANGE_PASSWORD_TITLE: {
		'en-US': 'Change password',
		'de-DE': 'Passwort ändern',
		'nl-NL': 'Wijzig wachtwoord',
		'fr-FR': 'Changer le mot de passe',
	},
	PUBLIC_CHANGE_PASSWORD_HEADER: {
		'en-US': 'Change password',
		'de-DE': 'Passwort ändern',
		'nl-NL': 'Wijzig wachtwoord',
		'fr-FR': 'Changer le mot de passe',
	},

	PUBLIC_CHANGE_PASSWORD_OLD_PASSWORD_PLACEHOLDER: {
		'en-US': 'old/temporary password',
		'de-DE': 'altes/vorläufiges Passwort',
		'nl-NL': 'oude/tijdelijke wachtwoord',
		'fr-FR': 'mot de passe ancien/temporaire',
	},
	PUBLIC_CHANGE_PASSWORD_NEW_PASSWORD_PLACEHOLDER: {
		'en-US': 'new password',
		'de-DE': 'neues Passwort',
		'nl-NL': 'nieuw wachtwoord',
		'fr-FR': 'nouveau mot de passe',
	},

	PUBLIC_CHANGE_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: {
		'en-US': 'confirm password',
		'de-DE': 'passwort bestätigen',
		'nl-NL': 'bevestig wachtwoord',
		'fr-FR': 'confirmer le mot de passe',
	},

	PUBLIC_CHANGE_PASSWORD_BTN_SUBMIT: {
		'en-US': 'LOGIN',
		'de-DE': 'Anmelden',
		'nl-NL': 'LOGIN',
		'fr-FR': 'CONNEXION',
	},

	//#endregion Change password page

	//#region Confirm create account page

	PUBLIC_CONFIRM_CREATE_ACCOUNT_TITLE: {
		'en-US': 'Activate account',
		'de-DE': 'Account aktivieren',
		'nl-NL': 'Activeer account',
		'fr-FR': 'Activer le compte',
	},

	PUBLIC_CONFIRM_CREATE_ACCOUNT_HEADER: {
		'en-US': 'Activate account',
		'de-DE': 'Account aktivieren',
		'nl-NL': 'Activeer account',
		'fr-FR': 'Activer le compte',
	},

	PUBLIC_CONFIRM_CREATE_ACCOUNT_BTN_SUBMIT: {
		'en-US': 'ACTIVATE',
		'de-DE': 'Aktivieren',
		'nl-NL': 'ACTIVEER',
		'fr-FR': 'ACTIVER',
	},

	PUBLIC_CONFIRM_CREATE_ACCOUNT_FIRST_NAME_PLACEHOLDER: {
		'en-US': 'first name',
		'de-DE': 'vorname',
		'nl-NL': 'voornaam',
		'fr-FR': 'prénom',
	},

	PUBLIC_CONFIRM_CREATE_ACCOUNT_LAST_NAME_PLACEHOLDER: {
		'en-US': 'last name',
		'de-DE': 'nachname',
		'nl-NL': 'achternaam',
		'fr-FR': 'nom',
	},
	PUBLIC_CONFIRM_CREATE_ACCOUNT_USERNAME_PLACEHOLDER: {
		'en-US': 'username',
		'de-DE': 'benutzername',
		'nl-NL': 'gebruikersnaam',
		'fr-FR': "nom d'utilisateur",
	},
	PUBLIC_CONFIRM_CREATE_ACCOUNT_PASSWORD_PLACEHOLDER: {
		'en-US': 'password',
		'de-DE': 'passwort',
		'nl-NL': 'wachtwoord',
		'fr-FR': 'mot de passe',
	},
	PUBLIC_CONFIRM_CREATE_ACCOUNT_CONFIRM_PASSWORD_PLACEHOLDER: {
		'en-US': 'confirm password',
		'de-DE': 'passwort bestätigen',
		'nl-NL': 'bevestig wachtwoord',
		'fr-FR': 'confirmer le mot de passe',
	},

	'field activationState errors: active users can only be changed to suspended;': {
		'en-US': 'Your account is already activated.',
		'de-DE': 'Ihr Account wurde bereits aktiviert.',
		'nl-NL': 'Je account is al geactiveerd.',
		'fr-FR': 'Votre compte est déjà activé.',
	},

	//#endregion Confirm create account page

	//#region Confirm email address change

	PUBLIC_CONFIRM_EMAIL_ADDRESS_CHANGE_TITLE: {
		'en-US': 'Change email address',
		'de-DE': 'E-Mail Adresse ändern',
		'nl-NL': 'Wijzig emailadres',
		'fr-FR': "Changer l'adresse email",
	},

	PUBLIC_CONFIRM_EMAIL_ADDRESS_CHANGE_HEADER: {
		'en-US': 'Change email address',
		'de-DE': 'E-Mail Adresse ändern',
		'nl-NL': 'Wijzig emailadres',
		'fr-FR': "Changer l'adresse email",
	},

	PUBLIC_CONFIRM_EMAIL_ADDRESS_CHANGE_SUCCESS: {
		'en-US': 'Your email address has been successfully changed.',
		'de-DE': 'Ihre E-Mail Adresse wurde erfolgreich geändert.',
		'nl-NL': 'Je emailadres is succesvol gewijizigd.',
		'fr-FR': 'Votre adresse email a été changée avec succès.',
	},

	PUBLIC_CONFIRM_EMAIL_ADDRESS_CHANGE_FAILURE: {
		'en-US': 'Email address change could not complete.',
		'de-DE': 'Ihre E-Mail Adresse konnte nicht erfolgreich geändert werden.',
		'nl-NL': 'Je emailadres kon niet succesvol gewijzigd worden.',
		'fr-FR': "L'adresse email n'a pas pu être changée.",
	},

	PUBLIC_CONFIRM_EMAIL_ADDRESS_CHANGE_BTN_HOME: {
		'en-US': 'home',
		'de-DE': 'home',
		'nl-NL': 'home',
		'fr-FR': 'domicile',
	},

	//#endregion Confirm email address change page

	//#region Reset password page
	PUBLIC_RESET_PASSWORD_TITLE: {
		'en-US': 'Reset password',
		'de-DE': 'Passwort zurücksetzen',
		'nl-NL': 'Reset wachtwoord',
		'fr-FR': 'Réinitialiser le mot de passe',
	},
	PUBLIC_RESET_PASSWORD_HEADER: {
		'en-US': 'Reset Password',
		'de-DE': 'Passwort zurücksetzen',
		'nl-NL': 'Reset wachtwoord',
		'fr-FR': 'Réinitialiser le mot de passe',
	},
	PUBLIC_RESET_PASSWORD_CONFIRMATION: {
		'en-US': 'Your password has been reset. You will receive a temporary password via email shortly.',
		'de-DE': 'Ihr Passwort wurde zurückgesetzt. Sie erhalten ein vorläufiges Passwort in Ihrer Mailbox',
		'nl-NL': 'Je wachtwoord is gereset. Je ontvangt een tijdelijk wachtwoord in je mailbox.',
		'fr-FR': 'Votre mot de passe a été réinitialisé. Vous recevrez sous peu un mot de passe temporaire par email.',
	},

	PUBLIC_RESET_PASSWORD_BTN_LOGIN: {
		'en-US': 'LOGIN',
		'de-DE': 'Anmelden',
		'nl-NL': 'LOGIN',
		'fr-FR': 'CONNEXION',
	},

	//#endregion Reset password page

	//#region Reset password error page
	PUBLIC_RESET_PASSWORD_ERROR_TITLE: {
		'en-US': 'Reset password error',
		'de-DE': 'Fehler beim Zurücksetzen des Passworts',
		'nl-NL': 'Reset wachtwoord fout',
		'fr-FR': 'Erreur de réinitialisation du mot de passe',
	},
	PUBLIC_RESET_PASSWORD_ERROR_HEADER: {
		'en-US': 'Reset password error',
		'de-DE': 'Fehler beim Zurücksetzen des Passworts',
		'nl-NL': 'Reset wachtwoord fout',
		'fr-FR': 'Erreur de réinitialisation du mot de passe',
	},
	PUBLIC_RESET_PASSWORD_ERROR_MESSAGE: {
		'en-US': 'Your password has not been reset. Please try again.',
		'de-DE': 'Ihr Passwort wurde nicht zurückgesetzt. Bitte versuchen Sie es erneut.',
		'nl-NL': 'Je wachtwoord is niet gereset. Probeer opnieuw.',
		'fr-FR': "Votre mot de passe n'a pas été réinitialisé. Veuillez réessayer.",
	},
	PUBLIC_RESET_PASSWORD_ERROR_BTN_RESTART: {
		'en-US': 'restart',
		'de-DE': 'nochmals',
		'nl-NL': 'opnieuw',
		'fr-FR': 'redémarrer',
	},

	//#endregion Reset password error page

	GENERIC_CONTACT_CUSTOMER_SUPPORT: {
		'en-US': 'Please contact customer support.',
		'de-DE': 'Nehmen Sie Kontakt auf mit Support.',
		'nl-NL': 'Neem contact op met support.',
		'fr-FR': 'Veuillez contacter le service clientèle.',
	},

	//#region Server error messages

	ERROR_USER_LOCKED_OUT: {
		'en-US': 'Your account has been temporary locked out due to multiple failed login attempts.',
		'de-DE': 'Ihr Account ist vorübergehend gesperrt, weil zu viele Anmeldeversuche fehlgeschlagen sind.',
		'nl-NL': 'Je account is tijdelijk geblokkeerd, omdat er te veel mislukte inlog pogingen zijn gedaan.',
		'fr-FR': 'Votre compte a été temporairement bloqué par suite de l’échec de plusieurs tentatives de connexion.',
	},

	ERROR_INVALID_USERNAME_OR_PASSWORD: {
		'en-US': 'Wrong username or password, please try again',
		'de-DE': 'Falscher Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut.',
		'nl-NL': 'Verkeerde gebruikersnaam of wachtwoord. Probeer opnieuw.',
		'fr-FR': "Nom d'utilisateur ou mot de passe incorrect, veuillez réessayer.",
	},

	ERROR_LOGIN_INVALID_ENDPOINT: {
		'en-US': 'Wrong username or password, please try again',
		'de-DE': 'Falscher Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut.',
		'nl-NL': 'Verkeerde gebruikersnaam of wachtwoord. Probeer opnieuw.',
		'fr-FR': "Nom d'utilisateur ou mot de passe incorrect, veuillez réessayer.",
	},

	ERROR_ACCOUNT_INACTIVE: {
		'en-US': 'User account is not active',
		'de-DE': 'Ihr Konto ist nicht aktiv',
		'nl-NL': 'Je account is niet actief',
		'fr-FR': "Le compte d'utilisateur n'est pas actif",
	},

	ERROR_INVALID_TOKEN: {
		'en-US': 'Activation token is invalid. Please contact customer support.',
		'de-DE': 'Die Aktivierung ist nicht (mehr) gültig. Bitte kontaktieren Sie den Support.',
		'nl-NL': 'Activatie is niet (meer) geldig. Neem contact op met support.',
		'fr-FR': "Le jeton d'activation n'est pas valide. Veuillez contacter le service clientèle.",
	},
	ERROR_TOKEN_REQUIRED: {
		'en-US': 'Activation token is required.',
		'de-DE': 'Aktivierungs-Token ist erforderlich.',
		'nl-NL': 'Activatie token is vereist.',
		'fr-FR': "Un jeton d'activation est nécessaire.",
	},

	ERROR_USERNAME_IN_USE: {
		'en-US': 'Username is already in use.',
		'de-DE': 'Benutzername wird bereits verwendet',
		'nl-NL': 'Gebruikersnaam is al in gebruik',
		'fr-FR': "Le nom d'utilisateur est déjà utilisé.",
	},

	'#ERROR_USERNAME_IN_USE_ALTERNATIVE#': {
		'en-US': 'Consider using ',
		'de-DE': 'vorgeschlagener Nutzername',
		'nl-NL': 'voorgestelde gebruikersnaam ',
		'fr-FR': "Envisagez d'utiliser ",
	},

	ERROR_USER_NOT_FOUND: {
		'en-US': 'User not found.',
		'de-DE': 'Benutzer nicht gefunden.',
		'nl-NL': 'Gebruiker niet gevonden.',
		'fr-FR': 'Utilisateur introuvable.',
	},

	ERROR_UNKNOWN_ACTIVATION_ERROR: {
		'en-US': 'Unknown activation error. Please contact customer support.',
		'de-DE': 'Unbekannter Aktivierungsfehler. Bitte kontaktieren Sie den Support.',
		'nl-NL': 'Onbekende activatie fout. Neem contact op met support.',
		'fr-FR': "Erreur d'activation inconnue. Veuillez contacter le service clientèle.",
	},

	ERROR_INVALID_CURRENT_PASSWORD: {
		'en-US': 'Current pasword invalid. Please try again.',
		'de-DE': 'Falsches aktuelles Passwort.',
		'nl-NL': 'Incorrect huidig wachtwoord.',
		'fr-FR': 'Le mot de passe actuel n’est pas valide. Veuillez réessayer.',
	},

	//#region Field validation errors
	ERROR_PASSWORD_REQUIRED: {
		'en-US': 'The "password" field is required.',
		'de-DE': 'Das Feld "Passwort" ist obligatorisch',
		'nl-NL': 'Het "wachtwoord" veld is verplicht',
		'fr-FR': 'Le champ « mot de passe » est obligatoire.',
	},
	ERROR_INVALID_USERNAME_FORMAT: {
		'en-US':
			'A user name is made up of 4 or more characters. Letters, numbers and the special characters ".-_@" are permitted. Spaces are not allowed.',
		'de-DE':
			'Ein Benutzername besteht aus mindestens 4 Zeichen. Erlaubt sind Buchstaben, Ziffern und die Sonderzeichen ".-_@". Leerzeichen sind nicht erlaubt.',
		'nl-NL':
			'Een gebruikersnaam bestaat uit 4 of meer tekens. Letters, cijfers en de speciale karakters ".-_@" zijn toegestaan. Spaties zijn niet toegestaan.',
		'fr-FR':
			"Un nom d'utilisateur comporte un minimum de 4 caractères. Les lettres, les chiffres et les caractères spéciaux « .-_@ » sont autorisés. Les espaces ne sont pas autorisés.",
	},
	ERROR_INVALID_PASSWORD_LENGTH: {
		'en-US': 'The password field must be at least 12 characters long.',
		'de-DE': 'Das Passwort muss mindestens 12 Zeichen lang sein',
		'nl-NL': 'Het wachtwoord moet minimaal 12 karakters lang zijn',
		'fr-FR': 'Le champ mot de passe doit comporter au moins 12 caractères.',
	},
	ERROR_PASSWORD_CONFIRMATION_REQUIRED: {
		'en-US': 'The "confirm password" field is required.',
		'de-DE': 'Das Feld "Passwort bestätigen" ist obligatorisch',
		'nl-NL': 'Het "bevestig wachtwoord" veld is verplicht',
		'fr-FR': 'Le champ « confirmer le mot de passe » est obligatoire.',
	},

	ERROR_PASSWORD_CONFIRMATION_MISMATCH: {
		'en-US': 'The "password" and "confirm password" fields do not match.',
		'de-DE': 'Die Felder "Passwort" und "Passwort bestätigen" stimmen nicht überein.',
		'nl-NL': 'Het "wachtwoord" en het "bevestig wachtwoord" veld komen niet overeen.',
		'fr-FR': 'Les champs « mot de passe » et « confirmer le mot de passe » ne correspondent pas.',
	},
	ERROR_USERNAME_REQUIRED: {
		'en-US': 'The "username" field is required.',
		'de-DE': 'Das Feld "Nutzername" ist obligatorisch.',
		'nl-NL': 'Het "gebruikersnaam" veld is verplicht.',
		'fr-FR': "Le champ « nom d'utilisateur » est obligatoire.",
	},
	ERROR_PASSWORD_CURRENT_REQUIRED: {
		'en-US': 'The "current password" field is required.',
		'de-DE': 'Das Feld "Aktuelles Passwort" ist obligatorisch.',
		'nl-NL': 'Het "huidige wachtwoord" veld is verplicht.',
		'fr-FR': 'Le champ « mot de passe actuel » est obligatoire.',
	},
	ERROR_PASSWORD_NEW_REQUIRED: {
		'en-US': 'The "new password field" is required.',
		'de-DE': 'Das Feld "neues Passwort" ist obligatorisch.',
		'nl-NL': 'Het "nieuwe wachtwoord" veld is verplicht.',
		'fr-FR': 'Le champ « nouveau mot de passe » est obligatoire.',
	},
	ERROR_ACCOUNT_NOT_FOUND: {
		'en-US': 'Activation not possible, because the account is not found. Please contact support.',
		'de-DE': 'Aktivierung nicht möglich, da das Konto nicht existiert. Bitte kontaktieren Sie den Support.',
		'nl-NL': 'Activatie niet mogelijk, omdat het account niet bestaat. Neem contact op met support.',
		'fr-FR': "Activation impossible, car le compte est introuvable. Veuillez contacter l'assistance.",
	},
	ERROR_ACTIVATION_ORIGIN_REQUIRED: {
		'en-US':
			'Activation not possible, because the activation link is incorrect (incorrect hostname). Please contact support.',
		'de-DE':
			'Aktivierung nicht möglich, da der verwendete Aktivierungslink nicht korrekt ist (falscher Hostname). Bitte kontaktieren Sie den Support.',
		'nl-NL':
			'Activatie niet mogelijk, omdat de gebruikte activatie link niet correct is (incorrecte hostname). Neem contact op met support.',
		'fr-FR':
			"Activation impossible, car le lien d'activation est incorrect (nom d'hôte incorrect). Veuillez contacter l'assistance.",
	},
	ERROR_ACTIVATION_ORIGIN_INVALID: {
		'en-US':
			'Activation not possible, because the activation link is incorrect (account not part of the organisation). Please contact support.',
		'de-DE':
			'Aktivierung nicht möglich, da der verwendete Aktivierungslink falsch ist (Konto gehört nicht zur Organisation). Bitte kontaktieren Sie den Support.',
		'nl-NL':
			'Activatie niet mogelijk, omdat de gebruikte activatie link niet correct is (account behoort niet bij de organisatie). Neem contact op met support.',
		'fr-FR':
			"Activation impossible, car le lien d'activation est incorrect (le compte ne fait pas partie de l'organisation). Veuillez contacter l'assistance.",
	},
	ERROR_ACCOUNT_ALREADY_ACTIVE: {
		'en-US':
			'Activation not possible, because the account for user {{Username}} has already been activated. Click the back button to proceed to the login screen.',
		'de-DE':
			'Aktivierung nicht möglich, da das Konto mit dem Benutzernamen {{Username}} bereits aktiviert ist. Klicken Sie auf die Schaltfläche "Zurück", um zum Anmeldebildschirm zu gelangen.',
		'nl-NL':
			'Activatie niet mogelijk,, omdat het account met gebruikersnaam {{Username}} al is geactiveerd. Klik op de back button om naar het login scherm te gaan.',
		'fr-FR':
			"Activation impossible, car le compte de l'utilisateur {{Username}} a déjà été activé. Cliquez sur le bouton retour pour revenir à l'écran de connexion.",
	},
	ERROR_ACCOUNT_STATE_INVALID: {
		'en-US': 'Activation not possible, because the account is in a invalid state. Please contact support.',
		'de-DE':
			'Aktivierung nicht möglich, da der Status des Kontos nicht korrekt ist. Bitte kontaktieren Sie den Support.',
		'nl-NL':
			'Activatie niet mogelijk, omdat de status van het acount niet correct is. Neem contact op met support.',
		'fr-FR': "Activation impossible, car le compte n’est pas dans un état valide. Veuillez contacter l'assistance.",
	},
	ERROR_ACCOUNT_DELETED: {
		'en-US': 'Activation not possible, because the account no longer exists. Please contact support.',
		'de-DE': 'Aktivierung nicht möglich, da das Konto nicht mehr existiert. Bitte kontaktieren Sie den Support.',
		'nl-NL': 'Activatie niet mogelijk, omdat het account niet meer bestaat. Neem contact op met support.',
		'fr-FR': "Activation impossible, car le compte n'existe plus. Veuillez contacter l'assistance.",
	},

	//#endregion Field validation errors
	//#endregion Server error messages

	//#endregion Public pages

	//#region Learning activities
	LEARNING_ACTIVITY_STATE_NOT_STARTED: {
		'en-US': '',
		'nl-NL': '',
		'fr-FR': 'pas commencé',
	},
	LEARNING_ACTIVITY_STATE_ACTIVE: {
		'en-US': 'active',
		'de-DE': 'aktiv',
		'nl-NL': 'actief',
		'fr-FR': 'actif',
	},
	LEARNING_ACTIVITY_STATE_FAILED: {
		'en-US': 'failed',
		'de-DE': 'fehlgeschlagen',
		'nl-NL': 'niet geslaagd',
		'fr-FR': 'échoué',
	},
	LEARNING_ACTIVITY_STATE_COMPLETED: {
		'en-US': 'completed',
		'de-DE': 'abgeschlossen',
		'nl-NL': 'afgerond',
		'fr-FR': 'terminé',
	},
	LEARNING_ACTIVITY_STATE_PASSED: {
		'en-US': 'passed',
		'de-DE': 'erfolgreich',
		'nl-NL': 'geslaagd',
		'fr-FR': 'passé',
	},
	LEARNING_ACTIVITY_STATE_PLAYED: {
		'en-US': 'played',
		'de-DE': 'gespielt',
		'nl-NL': 'gespeeld',
		'fr-FR': 'consulté',
	},
	LEARNING_ACTIVITY_STATE_DOWNLOADED: {
		'en-US': 'downloaded',
		'de-DE': 'downloaden',
		'nl-NL': 'downloaded',
		'fr-FR': 'téléchargé',
	},
	//#endregion Learning activities

	//#region Generic translations
	GENERIC_BTN_RESET: {
		'en-US': 'reset',
		'de-DE': 'zurücksetzen',
		'nl-NL': 'reset ',
		'fr-FR': 'réinitialiser',
	},
	GENERIC_BTN_BACK: {
		'en-US': 'back',
		'de-DE': 'zurück',
		'nl-NL': 'terug ',
		'fr-FR': 'retour',
	},
	'': {
		'en-US': '',
		'nl-NL': '',
		'fr-FR': '',
	},

	GENERIC_ORIENTATION_WARNING: {
		'en-US': 'For the best user experience, we recommend browsing this application in landscape orientation.',
		'de-DE': 'Für ein optimales Nutzererlebnis empfehlen wir, im Querformat zu arbeiten',
		'nl-NL': 'Voor de beste user experience adviseren wij in landscape modus te werken.',
		'fr-FR':
			'Pour une expérience utilisateur optimale, nous vous recommandons de parcourir cette application en orientation paysage.',
	},
	ERROR_TITLE: {
		'en-US': 'An error occurred',
		'de-DE': 'Es ist ein Fehler aufgetreten',
		'nl-NL': 'Er is een fout opgetreden',
		'fr-FR': "Une erreur s'est produite",
	},
	ERROR_PAGE_HOMEPAGE_LINK: {
		'en-US': 'Click the following link to return to the main page: ',
		'de-DE': 'Klicken Sie auf den folgenden Link, um zur Hauptseite zurückzukehren: ',
		'nl-NL': 'Klik op de volgende link om terug te gaan naar de hoofdpagina: ',
		'fr-FR': 'Cliquez sur le lien suivant pour revenir à la page principale : ',
	},
	ERROR_PAGE_RETURN_LINK: {
		'en-US': 'Click the following link to try again:',
		'de-DE': 'Klicken Sie auf den folgenden Link, um es erneut zu versuchen:',
		'nl-NL': 'Klik op de volgende link om het opnieuw te proberen:',
		'fr-FR': 'Cliquez sur le lien suivant pour réessayer :',
	},
	//#endregion Generic translations

	//#region Email Template Macros
	EMAIL_TEMPLATE_MACRO_FIRST_NAME: {
		'en-US': 'First name of the user',
		'nl-NL': 'Voornaam van de gebruiker',
		'de-DE': 'Vorname des Benutzers',
		'fr-FR': "Prénom de l'utilisateur",
	},
	EMAIL_TEMPLATE_MACRO_LAST_NAME: {
		'en-US': 'Last name of the user',
		'nl-NL': 'Achternaam van de gebruiker',
		'de-DE': 'Nachname des Benutzers',
		'fr-FR': "Nom de l'utilisateur",
	},
	EMAIL_TEMPLATE_MACRO_USERNAME: {
		'en-US': 'Username associated with the account',
		'nl-NL': 'Gebruikersnaam behorend bij het account',
		'de-DE': 'Dem Konto zugeordneter Benutzername',
		'fr-FR': "Nom d'utilisateur associé au compte",
	},
	EMAIL_TEMPLATE_MACRO_EMAIL_ADDRESS: {
		'en-US': 'Email address associated with the account',
		'nl-NL': 'E-mailadres behorend bij het account',
		'de-DE': 'E-Mail-Adresse, die mit dem Konto verknüpft ist',
		'fr-FR': 'Adresse email associée au compte',
	},
	EMAIL_TEMPLATE_MACRO_ORGANIZATION_NAME: {
		'en-US': 'Name of the organisation the account belongs to',
		'nl-NL': 'Organisatienaam behorend bij het account',
		'de-DE': 'Name der Organisation, zu der das Konto gehört',
		'fr-FR': "Nom de l'organisation à laquelle le compte appartient",
	},
	EMAIL_TEMPLATE_MACRO_FORGOT_PASSWORD_LINK: {
		'en-US': 'Link for resetting a password',
		'nl-NL': 'Link voor wachtwoord reset',
		'de-DE': 'Link zum Zurücksetzen eines Passworts',
		'fr-FR': 'Lien pour réinitialiser un mot de passe',
	},
	EMAIL_TEMPLATE_MACRO_SET_PASSWORD_LINK: {
		'en-US': 'Link for setting the account password',
		'nl-NL': 'Link voor aanmaken wachtwoord',
		'de-DE': 'Link zum Festlegen des Kontopassworts',
		'fr-FR': 'Lien pour définir le mot de passe du compte',
	},
	EMAIL_TEMPLATE_MACRO_TEMPORARY_PASSWORD: {
		'en-US': 'Temporary password generated for account',
		'nl-NL': 'Tijdelijk wachtwoord voor account',
		'de-DE': 'Temporäres Passwort für Konto generiert',
		'fr-FR': 'Mot de passe temporaire généré pour le compte',
	},
	EMAIL_TEMPLATE_MACRO_CHANGE_EMAIL_LINK: {
		'en-US': 'Link for confirming the change of the email address associated with an account',
		'nl-NL': 'Link voor bevestiging van wijzigen e-mailadres',
		'de-DE': 'Link zur Bestätigung der Änderung der mit einem Konto verknüpften E-Mail-Adresse',
		'fr-FR': "Lien pour confirmer le changement de l'adresse email associée à un compte",
	},
	EMAIL_TEMPLATE_MACRO_LEARNING_ACTIVITY_NAME: {
		'en-US': 'Name of the learning activity',
		'nl-NL': 'Naam van de leeractiviteit',
		'de-DE': 'Name der Lernaktivität',
		'fr-FR': "Nom de l'activité d'apprentissage",
	},
	EMAIL_TEMPLATE_MACRO_LEARNING_ACTIVITY_LINK: {
		'en-US': 'Link to the learning activity',
		'nl-NL': 'Link naar de leeractiviteit',
		'de-DE': 'Link zur Lernaktivität',
		'fr-FR': "Lien vers l'activité d'apprentissage",
	},
	EMAIL_TEMPLATE_MACRO_LEARNING_ACTIVITY_TYPE: {
		'en-US': 'The type of learning activity',
		'nl-NL': 'Leeractiviteit type',
		'de-DE': 'Die Art der Lernaktivität',
		'fr-FR': "Le type d'activité d'apprentissage",
	},
	EMAIL_TEMPLATE_MACRO_LEARNING_ACTIVITY_STATE: {
		'en-US': 'The users progress state in the learning activity',
		'nl-NL': 'Leeractiviteit voortgang status van de gebruiker',
		'de-DE': 'Der Fortschrittsstatus des Benutzers in der Lernaktivität',
		'fr-FR': "L'état d'avancement des utilisateurs dans l'activité d'apprentissage",
	},
	EMAIL_TEMPLATE_MACRO_CREATE_ACCOUNT_LINK: {
		'en-US': 'Link for completing the account creation process',
		'nl-NL': 'Link voor afronden van het account aanmaak proces',
		'de-DE': 'Link zum Abschließen des Kontoerstellungsprozesses',
		'fr-FR': 'Lien pour compléter le processus de création de compte',
	},
	EMAIL_TEMPLATE_MACRO_ADMINISTRATION_LINK: {
		'en-US': 'Link to the administration application',
		'nl-NL': 'Link naar de admin applicatie',
		'de-DE': 'Link zur Verwaltungsanwendung',
		'fr-FR': "Lien vers l'application d'administration",
	},
	EMAIL_TEMPLATE_MACRO_PORTAL_LINK: {
		'en-US': 'Link to the learning activity portal application',
		'nl-NL': 'Link naar de portal',
		'de-DE': 'Link zur Portalanwendung für Lernaktivitäten',
		'fr-FR': "Lien vers l'application du portail des activités d'apprentissage",
	},
	EMAIL_TEMPLATE_MACRO_PLATFORM_LINK: {
		'en-US': 'Link to the platform',
		'nl-NL': 'Link naar het platform',
		'de-DE': 'Link zur Plattform',
		'fr-FR': 'Lien vers la plateforme',
	},
	EMAIL_TEMPLATE_MACRO_CUSTOM_FIELD1: {
		'en-US': 'User custom field 1',
		'nl-NL': 'User custom field 1',
		'de-DE': 'Benutzerdefiniertes Benutzerfeld 1',
		'fr-FR': "Champ personnalisé de l'utilisateur 1",
	},
	EMAIL_TEMPLATE_MACRO_CUSTOM_FIELD2: {
		'en-US': 'User custom field 2',
		'nl-NL': 'User custom field 2',
		'de-DE': 'Benutzerdefiniertes Benutzerfeld 2',
		'fr-FR': "Champ personnalisé de l'utilisateur 2",
	},
	EMAIL_TEMPLATE_MACRO_CUSTOM_FIELD3: {
		'en-US': 'User custom field 3',
		'nl-NL': 'User custom field 3',
		'de-DE': 'Benutzerdefiniertes Benutzerfeld 3',
		'fr-FR': "Champ personnalisé de l'utilisateur 3",
	},
	EMAIL_TEMPLATE_MACRO_CUSTOM_FIELD4: {
		'en-US': 'User custom field 4',
		'nl-NL': 'User custom field 4',
		'de-DE': 'Benutzerdefiniertes Benutzerfeld 4',
		'fr-FR': "Champ personnalisé de l'utilisateur 4",
	},
	EMAIL_TEMPLATE_MACRO_CUSTOM_FIELD5: {
		'en-US': 'User custom field 5',
		'nl-NL': 'User custom field 5',
		'de-DE': 'Benutzerdefiniertes Benutzerfeld 5',
		'fr-FR': "Champ personnalisé de l'utilisateur 5",
	},
	EMAIL_TEMPLATE_MACRO_COMPLETION_DATE: {
		'en-US': 'Completion date of activity',
		'nl-NL': 'Afgerond op',
		'de-DE': 'Abschlussdatum der Aktivität',
		'fr-FR': "Date d'achèvement de l'activité",
	},
	EMAIL_TEMPLATE_MACRO_VALIDITY_END_DATE: {
		'en-US': 'Validity end date',
		'nl-NL': 'Geldig tot',
		'de-DE': 'Enddatum der Gültigkeit',
		'fr-FR': 'Date de fin de validité',
	},

	//#endregion Email Template Macros

	//#region DeHeus custom translations
	//#region Portal
	PORTAL_DEHEUS_MORE_ON_THE_FEED_ACADEMY: {
		'en-US': 'MORE ABOUT THE FEED ACADEMY',
		'nl-NL': 'MEER OVER DE FEED ACADEMY',
	},
	PUBLIC_DEHEUS_ABOUT_FEED_ACADEMY: {
		'en-US': 'ABOUT FEED ACADEMY',
		'nl-NL': 'OVER DE FEED ACADEMY',
	},
	PUBLIC_DEHEUS_ABOUT_US: {
		'en-US': 'ABOUT US',
		'nl-NL': 'OVER ONS',
	},
	PUBLIC_DEHEUS_CONTACT: {
		'en-US': 'CONTACT',
		'nl-NL': 'CONTACT',
	},

	//#endregion Portal

	//#endregion DeHeus custom translations

	//#region ICALT custom translations
	//#region Portal
	PORTAL_ICALT_PLAYER_LOGOUT: {
		'de-DE': 'Abmelden',
		'nl-NL': 'Uitloggen',
	},
	PORTAL_ICALT_LEARNING_ACTIVITIES_TITLE: {
		'nl-NL': 'Welkom bij jouw ICALT-leeromgeving',
	},
	PORTAL_ICALT_ACTIVITY_BUTTON_OPEN: {
		'de-DE': 'Start',
		'nl-NL': 'Start',
	},
	PORTAL_ICALT_TESTS_BUTTON_OPEN: {
		'nl-NL': 'Start poging ',
	},
	PORTAL_ICALT_INSTRUMENT: {
		'nl-NL': 'ICALT-instrument',
	},
	PORTAL_ICALT_PDF_DOWNLOAD: {
		'nl-NL': 'Download hier het ICALT-instrument',
	},
	PORTAL_ICALT_DOWNLOAD: {
		'nl-NL': 'Download PDF',
	},
	//#endregion Portal
	//#endregion ICALT custom translations

	//#region External account creation

	EXTERNAL_ACCOUNT_CREATION_STATUS_TITLE: {
		'en-US': 'Account creation status',
		'de-DE': 'Status der Kontoerstellung',
		'nl-NL': 'Status aanmaken account',
		'fr-FR': 'Statut de la création du compte',
	},

	EXTERNAL_ACCOUNT_CREATION_ERROR_MESSAGE_CODE: {
		'en-US': 'Login error, please contact support. Error message code: ',
		'de-DE': 'Anmeldefehler, bitte kontaktieren Sie den Support. Fehlercode: ',
		'nl-NL': 'Login fout, neem contact op met support. Fout code: ',
		'fr-FR': "Erreur de connexion, veuillez contacter l’assistance. Code du message d'erreur: ",
	},

	EXTERNAL_ACCOUNT_CREATION_EMAIL_ADDRESS_REQUIRED: {
		'en-US': 'Your e-mail address is not configured for your corporate account, which is required.  ',
		'de-DE': 'Für Ihr Unternehmenskonto wurde keine E-Mail-Adresse konfiguriert.',
		'nl-NL': 'Er is geen e-mailadres geconfigureerd voor uw corporate account.',
		'fr-FR':
			"Votre adresse email n'est pas configurée pour votre compte d'entreprise, or cette opération est obligatoire.",
	},
	EXTERNAL_ACCOUNT_CREATION_FIRST_NAME_REQUIRED: {
		'en-US': 'Your given name is not configured for your corporate account, which is required.',
		'de-DE': 'Es wurde kein Vorname für Ihr Firmenkonto konfiguriert.',
		'nl-NL': 'Er is geen voornaam geconfigureerd voor uw corporate account.',
		'fr-FR': "Votre prénom n'est pas configuré pour votre compte d'entreprise, or cette opération est obligatoire.",
	},

	EXTERNAL_ACCOUNT_CREATION_LAST_NAME_REQUIRED: {
		'en-US': 'Your surname is not configured for your corporate account, which is required.',
		'de-DE': 'Für Ihr Firmenkonto wurde kein Nachname konfiguriert.',
		'nl-NL': 'Er is geen achternaam geconfigureerd voor uw corporate account.',
		'fr-FR': "Votre nom n'est pas configuré pour votre compte d'entreprise, or cette opération est obligatoire.",
	},

	EXTERNAL_ACCOUNT_CREATION_USERNAME_REQUIRED: {
		'en-US': 'Your username is not configured for your corporate account, which is required.',
		'de-DE': 'Es wurde kein Benutzername für Ihr Unternehmenskonto konfiguriert.',
		'nl-NL': 'Er is geen gebruikersnaam geconfigureerd voor uw corporate account.',
		'fr-FR':
			"Votre nom d'utilisateur n'est pas configuré pour votre compte d'entreprise, or cette opération est obligatoire.",
	},

	EXTERNAL_ACCOUNT_CREATION_REMOTE_ID_REQUIRED: {
		'en-US': 'Your corporate account is missing information required by our application.',
		'de-DE': 'In Ihrem Geschäftskonto fehlen die für unsere Anwendung erforderlichen Informationen.',
		'nl-NL': 'Uw zakelijke account is ontbrekende informatie vereist door onze applicatie.',
		'fr-FR': "Il manque à votre compte d'entreprise des informations requises par notre application.",
	},

	EXTERNAL_ACCOUNT_CREATION_GENERIC_ERROR: {
		'en-US': 'Failed to retrieve external login info',
		'de-DE': 'Anmeldefehler, bitte kontaktieren Sie den Support.',
		'nl-NL': 'Login fout, neem contact op met support.',
		'fr-FR': 'Échec de la récupération des informations de connexion externes',
	},

	EXTERNAL_AUTHENTICATION_ERROR: {
		'en-US': 'Unable to login, please contact customer support',
		'de-DE': 'Anmeldefehler, bitte kontaktieren Sie den Support',
		'nl-NL': 'Login fout, neem contact op met support',
		'fr-FR': 'Connexion impossible, veuillez contacter le service clientèle',
	},

	//#endregion External account creation
	GENERIC_AUTENTICATION_ERROR: {
		'en-US': 'Unable to sign in, pleace contact customer support',
		'de-DE': 'Anmeldefehler, bitte kontaktieren Sie den Support',
		'nl-NL': 'Login fout, neem contact op met support',
		'fr-FR': 'Ouverture de session impossible, veuillez contacter le service clientèle.',
	},
};
