import { IHttpService, IPromise } from 'angular';
import { ComponentRegistration, createStyle } from '~root/commons';
import { OrganisationModel } from '@base/models';

import serverSettings from '../jediServerSettings';
import { CustomSettingsModel, SettingsModel } from './CustomSettingsModel';

export const JediCustomSettingsRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediCustomSettings', ['$http', JediCustomSettingsService]);
	},
};
const ClassNameCustomCss = 'custom-css';
const BackgroundCssVariable = '--main-background';
const BluredBackgroundCssVariable = '--main-background-blurred';
export class JediCustomSettingsService {
	constructor(private $http: IHttpService) {}

	public customSettings: CustomSettingsModel = null;

	loadCustomSettings(): IPromise<CustomSettingsModel> {
		if (this.customSettings) {
			return Promise.resolve(this.customSettings);
		}
		return this.$http.get<CustomSettingsModel>(serverSettings.CustomSettings).then(response => response.data);
	}

	private getOrganisationSettings(customSettings: CustomSettingsModel): SettingsModel {
		if (!customSettings) {
			return null;
		}
		const hostname = serverSettings.AppCustomHostname;
		if ((hostname || '').trim().length > 0) {
			if (customSettings.organisationsCustomSettings) {
				const settings = customSettings.organisationsCustomSettings[hostname];
				if (settings) {
					settings.termsLink = settings.termsLink || customSettings.defaultTermsLink;
					settings.privacyStatementLink =
						settings.privacyStatementLink || customSettings.defaultPrivacyStatementLink;

					return settings;
				}
			}
		}
		const defaultSettings = {
			termsLink: customSettings.defaultTermsLink,
			privacyStatementLink: customSettings.defaultPrivacyStatementLink,
			downloadLink: customSettings.downloadLink,
			copyrightLink: customSettings.copyrightLink,
			manualLinks: {
				administrator: customSettings.manualLinks.administrator,
				operator: customSettings.manualLinks.operator,
			},
		};
		return defaultSettings;
	}

	loadOrganisationCustomSettings(): IPromise<SettingsModel> {
		return this.loadCustomSettings().then(settings => this.getOrganisationSettings(settings));
	}

	//region custom backgroung configuration

	setCustomStyles(organisation: OrganisationModel, path: string) {
		// apply custom CSS for current location
		let hierarchyPath = '';
		if (path.includes('player')) {
			hierarchyPath = path.replace('/player/activity', '');
		} else if (path.includes('portal')) {
			hierarchyPath = path.replace('/portal/content', '');
		}

		if (hierarchyPath) {
			this.setPortalBackground(hierarchyPath, '/player/activity/');
		}

		this.setCustomCss(organisation.portalCustomCSS);
	}

	private setBackgroundCssVariables(container) {
		const r = document.querySelector(':root') as HTMLElement;

		r.style.setProperty(BackgroundCssVariable, `url(${container.RegularBackground})`);
		r.style.setProperty(BluredBackgroundCssVariable, `url(${container.BlurredBackground})`);
	}

	private setPortalBackground(activityPathOverride: string, hierarchyPrefix: string): void {
		const customStylings = serverSettings.AppTheme.CustomStylings;
		if (!customStylings || !customStylings.length) {
			return;
		}

		const containerId = this.getCustomizedContainerId(activityPathOverride, hierarchyPrefix);

		const containerSettings = customStylings.find(styling => styling.Container === containerId);
		if (!containerSettings || !containerSettings.RegularBackground || !containerSettings.BlurredBackground) {
			return;
		}

		this.setBackgroundCssVariables(containerSettings);
	}

	private getCustomizedContainerId(activityPathOverride: string, hierarchyPrefix: string): string {
		const customStylings = serverSettings.AppTheme.CustomStylings;
		const token = (hierarchyPrefix || '').toLowerCase();
		const uriPath = (window.location + '').toLowerCase().split(token)[1] || 'root';
		const pathname = activityPathOverride || uriPath;
		const parts = pathname.split('/').reverse();
		const containerId = parts.find(part => customStylings.some(styling => styling.Container === part)) || 'root';
		return containerId;
	}

	public setPublicBackground(): void {
		const customStyling = serverSettings.AppTheme.PublicBackground;

		if (!customStyling || !customStyling.RegularBackground || !customStyling.BlurredBackground) {
			return;
		}

		this.getCustomPublicStylesheetFromTemplate(customStyling);
	}

	private getCustomPublicStylesheetFromTemplate(customStyling) {
		const r = document.querySelector(':root') as HTMLElement;

		r.style.setProperty(BackgroundCssVariable, `url(${customStyling.RegularBackground})`);
		r.style.setProperty(BluredBackgroundCssVariable, `url(${customStyling.BlurredBackground})`);
	}

	//region set custom css
	setCustomCss(customCss: string): void {
		if (customCss) {
			createStyle(customCss, ClassNameCustomCss, true);
		}
	}
}
