import * as logger from '~root/logger';

export function b64EncodeUnicode(str: string): string {
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
			return String.fromCharCode(parseInt(p1, 16));
		})
	);
}

export function b64DecodeUnicode(str: string): string {
	try {
		return decodeURIComponent(
			Array.prototype.map
				.call(atob(str), function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
	} catch (error) {
		logger.debug('unable to decode input', error);
	}
}
