import * as angular from 'angular';
import { JediConfig } from 'app-legacy/commons';
import { SearchApiService } from '../searchService';

const JediSearchComponent = {
	style: require('./style.scss'),
	register: function (ngModule) {
		ngModule.directive('jediSearch', JediSearchDirective);
	},
};

export default JediSearchComponent;

/// defines a generic component for searching with suggestions
// attributes used:
// data-reload - evaluates when the suggestions are cleared
// data-select - evaluates when a suggestion is selected from the autocomplete
// data-url - url used to retrieve suggestions
// data-urlBuilder - evaluated against filter value for generating the url used to retrieve suggestions
// data-filter - evaluated when enter is pressed or after data-select
// data-model-change - evaluated when the model for this directive changes
const JediSearchDirective = [
	'$http',
	'$timeout',
	'$log',
	'searchService',

	function ($http, $timeout, $log, searchService: SearchApiService) {
		return {
			restrict: 'E',
			require: '^?ngModel',
			replace: true,
			template: require('./jediSearch.html'),
			scope: {
				modelChange: '&',
				select: '&',
				urlBuilder: '&',
				filter: '&',
				reload: '&',
				selection: '=?',
				suggestion: '=?',
				initialValue: '<?',
				// // attributes
				// url: '<',
				// placeholder: '<',
				// queryParams: '<?',
				// actionAdd: '<',
				// actionAddDisable: '<',
			},
			link: function (scope, element, attributes) {
				$timeout(function () {
					scope.placeholder = attributes.placeholder;
					scope.actions = {};

					scope.$on(JediConfig.events.clearFilter, function (event) {
						scope.suggestion = '';
						scope._suggestion = '';
						scope.selection = {};
					});

					var url = attributes.url;
					var urlBuilder = attributes.urlBuilder;
					scope.suggestion = '';
					scope._suggestion = '';
					scope.selection = {};
					const initial = scope.initialValue;
					if (typeof initial === 'object') {
						if (initial.item) {
							scope.selection = initial.item;
						}

						const suggestion = initial.suggestion || initial.value;
						if (suggestion) {
							scope.suggestion = suggestion;
							scope._suggestion = suggestion;
						}
					}

					if (attributes.actionAdd) {
						scope.actions['add'] = {
							isDisabled: function () {
								return scope.$parent.$eval(attributes.actionAddDisable);
							},
							show: true,
							run: function () {
								// TODO: the model.selection should be processed to check from the retrieved suggestion list as well
								// model.selection = $filter('filter")($scope.suggestionItems, {name: scope.suggestion}, true)[0]
								// on the further improvement, an external service will be responsible for creating different types of objects that will be passed to this directive.
								var model = {};
								if ((scope.suggestion || '').trim().length > 0) {
									model = {
										name: scope.suggestion,
										id: -1,
									};
								} else {
									model = scope.selection;
								}
								scope.$parent.$eval(attributes.actionAdd, { model: model });
								scope._reload();
							},
						};
					}

					// if (attributes.modelChange) {
					// 	scope.$watch('suggestion', function(newValue, oldValue) {});
					// 	scope.$watch('selection', function(newValue, oldValue) {});
					// }

					scope.$watch('_suggestion', function (newValue, oldValue) {
						if (attributes.modelChange) {
							scope.$parent.$eval(attributes.modelChange, { newValue: newValue, oldValue: oldValue });
						}
						if ($.isEmptyObject(scope.selection)) {
							scope.suggestion = newValue;
						} else if ((scope.selection.name || '').trim().length > 0 && scope.selection.name != newValue) {
							scope.suggestion = newValue;
							scope.selection = {};
						} else {
							scope.suggestion = '';
						}
					});

					scope._reload = function () {
						scope.suggestion = '';
						scope._suggestion = '';
						scope.selection = {};
						scope.modelChange({ value: '' });
						if (scope.reload) {
							scope.reload();
						}
					};
					scope._select = function (item, model, label) {
						scope.selection = item;
						scope.suggestion = '';
						scope._suggestion = item ? (item.name ? item.name : label) : label;
						if (attributes.select) {
							const event = {
								item: item,
								model: model,
								label: label,
								value: scope.suggestion,
							};
							scope.$parent.$eval(attributes.select, event);
						}
					};

					scope._keyDown = function ($event) {
						var $this = $(element).find('.dropdown-menu');
						var selectedElement = $this.find('li.active');
						var items = $this.find('li');
						var idx = items.index(selectedElement);

						if ($event.keyCode == 40) {
							//key down
							if ($this.length > 0) {
								if ((idx >= 0 && idx <= 3) || idx === items.length - 1) {
									$this.scrollTop(0);
								} else if (idx > 3) {
									$this.scrollTop(idx * selectedElement.height());
								}
							}
							return false;
						}

						if ($event.keyCode == 38) {
							//key up
							if ($this.length > 0) {
								if (idx === 0) {
									$this.scrollTop((items.length - 1) * selectedElement.height());
								} else {
									$this.scrollTop((idx - 1) * selectedElement.height());
								}
							}
							return false;
						}

						if ($event.keyCode == 13) {
							if (scope.selection && scope._suggestion == scope.selection.name) {
								scope._select(scope.selection);
							} else {
								scope._select(null, null, scope._suggestion);
							}
						}
					};

					scope.getSuggestions = async function (filterValue) {
						if (typeof urlBuilder === 'function') {
							url = urlBuilder(filterValue);
						} else if (typeof urlBuilder === 'string') {
							url = scope.$parent.$eval(urlBuilder);
						}
						let params = { query: filterValue };
						if (attributes.queryParams) {
							const extraParams = scope.$parent.$eval(attributes.queryParams);
							if (extraParams) {
								params = angular.merge(extraParams, params);
							}
						}
						if (typeof url !== 'string') {
							return Promise.reject('search component suggesions URL invalid');
						}

						const data = await searchService.getGeneric<any>(url, params);

						if (attributes.itemFilter) {
							const newItems = scope.$parent.$eval(attributes.itemFilter, { items: data.items });
							if (Array.isArray(newItems)) {
								return newItems;
							} else {
								$log.warning('invalid filter expression for jedi-search');
							}
						}
						return data.items;
					};
				}, 0);
			},
		};
	},
];
