/* eslint-disable class-methods-use-this */
import { ComponentType, NoopScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent } from './confirm/confirm.component';

import { ConfirmDialogOptions, ConfirmDialogResult } from './confirm/ConfirmDialogOptions';

function disableScrollLock() {
	dev.log('disable scroll lock');
	document.body.classList.remove('dialog-scroll-lock');
}
function enableScrollLock() {
	dev.log('enable scroll lock');
	document.body.classList.add('dialog-scroll-lock');
}

@Injectable()
export class DialogService {
	constructor(private matDialog: MatDialog, private viewportRuler: ViewportRuler) {}

	public showConfirm(options: ConfirmDialogOptions): Promise<ConfirmDialogResult> {
		const result = this.matDialog.open<ConfirmComponent, ConfirmDialogOptions, ConfirmDialogResult>(
			ConfirmComponent,
			{
				data: options,
				disableClose: true,
				autoFocus: true,
				scrollStrategy: new NoopScrollStrategy(),
			}
		);
		this.applyScrollLock(result);
		return result.afterClosed().toPromise();
	}

	private applyScrollLock<T>(result: MatDialogRef<T, any>) {
		const $opened = result.afterOpened().subscribe(() => enableScrollLock());
		const $closed = result.afterClosed().subscribe(() => {
			disableScrollLock();
			$closed.unsubscribe();
			$opened.unsubscribe();
		});
	}

	open<T, D = any, R = any>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
		const actualConfig: MatDialogConfig<D> = {
			...{
				// scrollStrategy: new BlockScrollStrategy(this.viewportRuler, document),
				scrollStrategy: new NoopScrollStrategy(),
				panelClass: 'app-panel-dialog',
			},
			...config,
		};

		try {
			const result = this.matDialog.open(component, actualConfig);
			this.applyScrollLock(result);
			return result;
		} catch (error) {
			disableScrollLock();
		}
		return null;
	}
}
