import { CultureModel, OrganisationModel } from '@base/models';
import { ComponentRegistration } from '~root/commons';
import { JediConfig } from './config/config';
import { IHttpService, IRootScopeService, ILocationService, ILogService, IWindowService, IPromise } from 'angular';
import { JediAuthService } from './jediAuth/jediAuthService';

export const ContextManagerServiceRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('jediContext', ContextManagerService);
	},
};

export enum ContextType {
	Corporate = 'corporate',
	Platform = 'platform',
	Account = 'account',
	Participant = 'participant',
	Anonymous = 'anonymous',
}

export class AppContext {
	static Anonymous = new AppContext(ContextType.Anonymous);
	constructor(public type: ContextType, public organisation?: OrganisationModel) {}
}

export class ContextManagerService {
	static $inject = ['$http', '$rootScope', '$window', '$location', '$log', 'jediAuth'];

	constructor(
		private $http: IHttpService,
		private $rootScope: IRootScopeService,
		private $window: IWindowService,
		private $location: ILocationService,
		private $log: ILogService,
		private jediAuth: JediAuthService
	) {}
	// private _context: AppContext = AppContext.Anonymous;
	private _context: AppContext = null;

	getCurrentContext(): AppContext {
		return this._context;
	}

	getAvailableCultures(): CultureModel[] {
		return this._context?.organisation?.localizationSettings?.supportedCultures;
	}

	private updateCurrentContext(context: AppContext) {
		this._context = context;
		// NOTE: hidden dependency for jediLocalization
		this.$rootScope.organisation = context ? context.organisation : undefined;
	}

	// return a promise that will resolve when the current context is loaded
	async loadContext(organisationId: string): Promise<AppContext> {
		// cache
		if (this._context?.organisation?.id == +organisationId) {
			return this._context;
		}

		if (organisationId) {
			const context = await this.promiseContext(this.getContextForOrganisation(organisationId));
			return context;
			// return this.promiseContext(this.getContextForOrganisation(organisationId));
		}
		const user = await this.jediAuth.loadUser();
		const context = this.getContextForUser(user);

		return this.promiseContext(context);
	}

	private getContextForUser(user): AppContext {
		if (!user) {
			return AppContext.Anonymous;
		}
		if (!this.$location.path().toLowerCase().includes('administration')) {
			return new AppContext(ContextType.Participant, new OrganisationModel(user.organisationId));
		}

		if (user.organisationId && (this.jediAuth.isCorporateAdmin() || this.jediAuth.isCorporateUser())) {
			return new AppContext(ContextType.Corporate, new OrganisationModel(user.organisationId));
		}
		if ((!user.organisationId && this.jediAuth.isPlatformAdmin()) || this.jediAuth.isSysadmin()) {
			return new AppContext(ContextType.Platform);
		}
		return AppContext.Anonymous;
	}

	private getContextForOrganisation(organisationId): AppContext {
		const context = {
			type: ContextType.Corporate,
			organisation: new OrganisationModel(organisationId),
		};
		return context;
	}

	private async promiseContext(context: AppContext): Promise<AppContext> {
		if (context.type == ContextType.Corporate || context.type === ContextType.Participant) {
			const key = context.organisation.id;

			// load
			this.$rootScope.$broadcast(JediConfig.events.contextLoadStart, context);
			const response = await this.$http.get<OrganisationModel>(JediConfig.api.organisation.item(key));
			try {
				context.organisation = response.data;
				this.updateCurrentContext(context);
				this.$rootScope.$broadcast(JediConfig.events.contextLoadComplete, context);
			} catch (error) {
				this.$log.warn('unable to change context' + response?.data);
				this.$location.path('/');
			}

			return context;
		} else if (context.type === ContextType.Platform) {
			// platform context
			this.updateCurrentContext(context);
			return context;
		}
	}

	setCurrentContext(context: AppContext): IPromise<AppContext> {
		return this.promiseContext(context);
	}

	changeContext(context: AppContext): void {
		this._context = context;
		if (context.type === ContextType.Participant) {
			// full page reload required when changing to portal
			this.$window.location.href = '/portal/content/root';
			return;
		}

		let location = '';
		if (context.type === ContextType.Corporate) {
			if (context.organisation?.id) {
				location = '/administration/organisations/' + context.organisation.id;
			}
		} else if (context.type === ContextType.Platform) {
			location = '/administration/organisations/overview';
		}

		this.$location.path(location);
	}

	currentUserIsParent(): boolean {
		const user = this.jediAuth.jediAuthStore.getLoggedInUser();
		const organisation = this.getCurrentContext().organisation;
		if (user && user.organisation && organisation) {
			if (organisation.parentId == user.organisation.id) {
				return true;
			}
			const isCorporate = this.jediAuth.isCorporateAdmin() || this.jediAuth.isCorporateUser();
			if (
				organisation.id == user.organisation.id &&
				(organisation.organisationUnitCount > 0 || organisation.oUs.length)
			) {
				if (isCorporate) {
					return true;
				}
			}
		}
		return false;
	}
}
