export class UserAgentModel {
	constructor(
		public name?: string,
		public version?: string,
		public device?: DeviceType,
		public orientation?: DeviceOrientationType,
		public os?: OSType
  ) {}
  isMobileOrTablet(){
    return this.device === DeviceType.Mobile || this.device === DeviceType.Tablet;
  }
  isDesktop(){
    return this.device === DeviceType.Desktop;
  }
}

export enum BrowserType {
	Chrome = 'chrome',
	Firefox = 'firefox',
	InternetExplorer = 'ie',
	Opera = 'opera',
	Edge = 'edge',
	Safari = 'safari',
	Unknown = 'unknown'
}
export enum OSType {
	IOS = 'ios',
	Android = 'android',
	Windows = 'windows',
	Linux = 'linux',
	OSX = 'osx',
	Unknown = 'unknown'
}

export enum DeviceType {
	Tablet = 'tablet',
	Mobile = 'mobile',
	Desktop = 'desktop',
	Unknown = 'unknown'
}

export enum DeviceOrientationType {
	Portrait = 'portrait',
	Landscape = 'landscape',
	Unkown = 'unknown'
}
