const OrientationWarningComponent = {
	register: function (ngModule) {
		ngModule.directive('jediOrientationWarning', OrientationWarningDirective);
	},
	style: require('./style.scss')
};

export default OrientationWarningComponent;

const OrientationWarningDirective = [
	'$rootScope',
	function ($rootScope) {
		return {
			restrict: 'EA',
			//replace: true,
			template: require('./jediOrientationWarning.html'),
			link: function (scope, element, attributes) {
				$('#btn-close-orientation-warning').on('click', function (e) {
					$('#orientation-warning').hide();
					$('body').addClass('position-absolute');
				});
			}
		};
	}
];
