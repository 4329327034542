import { UserActivityProgressNode, ProviderCategoryEnum, ViewDisplayType } from '@base/models';
import { ActivityNavigationDetails } from './ActivityNavigationDetails';
import { JediHierarchyServiceLogic } from './jediHierarchy';
import { UserHierarchyMapperService } from './hierarchyMapper';

export enum Direction {
	Prev = 1,
	Next,
}
/**
 * Get relative URIs for content items related to playerURI
 * @param root Root Node
 * @param playerUri Player URI for current node
 */
export function getActivityNavigationDetails(
	userHierarchy: JediHierarchyServiceLogic,
	userHierarchyMapper: UserHierarchyMapperService,
	root: UserActivityProgressNode,
	playerUri: string
): ActivityNavigationDetails {
	const path = playerUri.toLowerCase().split('/player/activity/')[1];
	const nodes = userHierarchy.getHierarchyPath(root, 'root/' + path);

	// Expecting the path to be an LA
	if (!nodes || nodes.length < 3 || !nodes[nodes.length - 1].isActivity) {
		return null;
	}

	const location: ActivityNavigationDetails = {
		home: nodes[0].parentRelation.hierarchyPath,
		rootContainer: nodes[1].parentRelation.hierarchyPath,
		parentContainer: nodes[nodes.length - 2].parentRelation.hierarchyPath,
		nextSibling: null,
		prevSibling: null,
	};

	// If organisation viewDisplay = Default, than we know to expect internal root containers and need to go back to root level.
	if (nodes[0].displaySettings.masterDisplay.viewDisplay === ViewDisplayType.Default) {
		location.parentContainer = location.home;
		location.rootContainer = location.home;
	}
	// If in an ExtendedContainer we need the parent of the parentContainer.
	if (nodes[nodes.length - 3].displaySettings.masterDisplay.viewDisplay === ViewDisplayType.ExtendedGrid) {
		location.parentContainer = nodes[nodes.length - 3].parentRelation.hierarchyPath;
	}

	// calculate next/prev siblings
	const currentNode = userHierarchy.getHierarchyNode(root, 'root/' + path);
	const orderedSiblings = nodes[nodes.length - 2].members.sort(
		userHierarchyMapper.orderActivities.bind(userHierarchyMapper)
	);
	location.nextSibling = getSiblingPath(orderedSiblings, currentNode, Direction.Next) || location.parentContainer;
	location.prevSibling = getSiblingPath(orderedSiblings, currentNode, Direction.Prev) || location.parentContainer;

	return location;
}

function indexOfWithAttr(array, attr, value) {
	for (var i = 0; i < array.length; i += 1) {
		if (array[i][attr] === value) {
			return i;
		}
	}
	return -1;
}

function isElearningType(node: UserActivityProgressNode) {
	return (
		node.providerCategory === ProviderCategoryEnum.LocalXApi ||
		node.providerCategory === ProviderCategoryEnum.ExternalXApi
	);
}

function getSiblingPath(
	orderedSiblings: UserActivityProgressNode[],
	node: UserActivityProgressNode,
	direction: Direction
) {
	let path: string = null;
	const nodeIndex = indexOfWithAttr(orderedSiblings, 'id', node.id);
	if (orderedSiblings.length === 1) {
		return path;
	}
	if (nodeIndex === 0 && direction === Direction.Prev) {
		return path;
	}
	if (nodeIndex === orderedSiblings.length - 1 && direction === Direction.Next) {
		return path;
	}
	const sibling = direction === Direction.Next ? orderedSiblings[nodeIndex + 1] : orderedSiblings[nodeIndex - 1];
	path = isElearningType(sibling) ? '/player/activity' + sibling.parentRelation.hierarchyPath : null;
	return path;
}
