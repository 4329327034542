export enum ActivationState {
	None = '',
	Active = 'Active',
	Inactive = 'Inactive',
	Archived = 'Archived',
}

export class EntityModel {
	id?: number | string;

	name?: string;

	organisationId?: Number;
}

export function entityEquals(a, b) {
	if (a === b) return true;
	const ea = a as EntityModel;
	const eb = b as EntityModel;

	if (ea?.id === eb?.id && ea?.id) {
		return true;
	}
	if (ea?.name === eb?.name && ea?.name) {
		return true;
	}
	return false;
}
