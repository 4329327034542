//TODO: UserActivationState and ConfigFiels.userActivationStates should be separated but it implies more changes
//They are coupled because the logic and the UI is coupled into usersManagementComponenet.ts line 197
export enum UserActivationStates {
	None = 'none',
	Created = 'created',
	Invited = 'invited',
	ScheduledInvite = 'scheduled invite',
	Active = 'active',
	Suspended = 'suspended',
}

const ConfigFields = {
	applicationAreas: [
		{ text: 'area - All', value: 'None' },
		{ text: 'Player', value: 'Player' },
		{ text: 'Administration', value: 'Administration' },
		{ text: 'Platform context', value: 'PlatformContext' },
		{ text: 'General', value: 'GeneralContext' },
		{ text: 'Account', value: 'AccountMangement' },
		{ text: 'Users', value: 'UserManagement' },
		{ text: 'Organisation', value: 'OrganisationManagement' },
		{ text: 'Notifications', value: 'Notifications' },
	],
	operations: [
		{ text: 'operation - All', value: 'None' },
		{ text: 'Create', value: 'Create' },
		{ text: 'Retrieve', value: 'Retrieve' },
		{ text: 'Update', value: 'Update' },
		{ text: 'Delete', value: 'Delete' },
	],
	userActivationStates: [
		{
			text: UserActivationStates.None,
			value: 'None',
			rawValue: 0,
			tooltip: 'User account has been sent an invitation',
		},
		{
			text: UserActivationStates.Created,
			value: 'Created',
			rawValue: 1,
			tooltip: 'User account has been created, user did not log into application',
		},
		{
			text: UserActivationStates.ScheduledInvite,
			value: 'ScheduledInvite',
			rawValue: 5,
			tooltip: 'User account has been scheduled to recive an invitation',
		},
		{
			text: UserActivationStates.Invited,
			value: 'Invited',
			rawValue: 0,
			tooltip: 'User account has been sent an invitation',
		},
		{
			text: UserActivationStates.Active,
			value: 'Active',
			rawValue: 2,
			tooltip: 'User account has logged into the application',
		},
		{
			text: UserActivationStates.Suspended,
			value: 'Suspended',
			rawValue: 4,
			tooltip: 'User cannot log into the application',
		},
	],
	groupStates: [
		{ text: 'empty groups', value: 'emptyGroups', rawValue: 0, tooltip: '' },
		{ text: 'no user members', value: 'noUserMembers', rawValue: 1, tooltip: '' },
	],
	learningActivityUserProgressStates: [
		{ text: 'not started', value: 'NotStarted', rawValue: 0, tooltip: '' },
		{ text: 'active', value: 'Active', rawValue: 0, tooltip: '' },
		{ text: 'completed', value: 'Completed', rawValue: 0, tooltip: '' },
		{ text: 'passed', value: 'Passed', rawValue: 0, tooltip: '' },
		{ text: 'failed', value: 'Failed', rawValue: 0, tooltip: '' },
	],
	progressStates: [
		{ text: 'not started', value: 'None', rawValue: 0, tooltip: '' },
		{ text: 'in progress', value: 'Launched', rawValue: 0, tooltip: '' },
		{ text: 'in progress', value: 'Attempted', rawValue: 0, tooltip: '' },
		{ text: 'passed', value: 'Passed', rawValue: 0, tooltip: '' },
		{ text: 'completed', value: 'Completed', rawValue: 0, tooltip: '' },
		{ text: 'failed', value: 'Failed', rawValue: 0, tooltip: '' },
		{ text: 'waived', value: 'Waived', rawValue: 0, tooltip: '' },
	],
	finalStates: [
		{ text: 'passed', value: 'Passed', rawValue: 0, tooltip: '' },
		{ text: 'completed', value: 'Completed', rawValue: 0, tooltip: '' },
		{ text: 'failed', value: 'Failed', rawValue: 0, tooltip: '' },
		{ text: 'waived', value: 'Waived', rawValue: 0, tooltip: '' },
	],

	organisationActivationStates: [
		{ text: 'none', value: 'None', rawValue: 0, tooltip: 'Organisation is in an incomplete configuration state' },
		{
			text: 'created',
			value: 'Created',
			rawValue: 1,
			tooltip: 'Organisation is created but it cannot provide access to learning content',
		},
		{
			text: 'active',
			value: 'Active',
			rawValue: 2,
			tooltip: 'Organisation is created, users are active, learning content is accessible',
		},
		{
			text: 'suspended',
			value: 'Suspended',
			rawValue: 4,
			tooltip: 'Access to learning content is denied regardless of billing plan options',
		},
	],
	// localization format mapping for dates from AngularJS to MomentJS
	angularToMomentLocalizedFormats: {
		// custom format
		full: 'LLLL LT',

		// custom format
		long: 'LLL LT',

		// equivalent to 'MMM d, y h:mm:ss a' for en_US locale (e.g. Sep 3, 2010 12:05:08 PM)
		medium: 'LL LT',

		// equivalent to 'M/d/yy h:mm a' for en_US locale (e.g. 9/3/10 12:05 PM)
		short: 'L LT', // momentjs has 'L' = 01/06/2015 and 'l' = 1/6/2015

		// equivalent to 'EEEE, MMMM d, y' for en_US locale (e.g. Friday, September 3, 2010)
		fullDate: 'LLLL',

		// equivalent to 'MMMM d, y' for en_US locale (e.g. September 3, 2010)
		longDate: 'LLL',

		// equivalent to 'MMM d, y' for en_US locale (e.g. Sep 3, 2010)
		mediumDate: 'LL',

		// equivalent to 'M/d/yy' for en_US locale (e.g. 9/3/10)
		shortDate: 'L',

		// equivalent to 'h:mm:ss a' for en_US locale (e.g. 12:05:08 PM)
		mediumTime: 'LTS',

		// equivalent to 'h:mm a' for en_US locale (e.g. 12:05 PM)
		shortTime: 'LT',
	},
	jediIntroStepsOptions: {
		steps: [
			{
				intro: {
					'en-US':
						"<div class='intro-js-title'>Hello {{user.firstName}}!</div><div class='intro-js-content-text'>Welcome to this online learning platform. Follow my quick tour to see your different options.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Hallo {{user.firstName}}!</div><div class='intro-js-content-text'>Welkom op dit online leerplatform. Volg mijn quick tour om de opties te bekijken.</div>",
					'de-DE':
						"<div class='intro-js-title'>Hallo {{user.firstName}}!</div><div class='intro-js-content-text'>Willkommen auf dieser Online-Lernplattform. Folgen Sie meiner kurzen Tour, um Ihre verschiedenen Optionen zu sehen.</div>",
				},
			},
			{
				element: '.icon-settings',
				intro: {
					'en-US':
						'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Check your settings to select a language, see a FAQ, open this quick tour and logout.</div>',
					'nl-NL':
						'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Bekijk je settings om een taal te selecteren, een FAQ en deze quick tour te openen en om uit te loggen.</div>',
					'de-DE':
						'<div class="settings-logo"></div><div class="intro-js-title">Settings</div><div class="intro-js-content-text">Überprüfen Sie Ihre Einstellungen, um eine Sprache auszuwählen, sehen Sie sich eine FAQ an, öffnen Sie diese Kurztour und melden Sie sich ab.</div>',
				},
				position: 'left',
			},
			{
				element: '#learning-activity-display',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Learning Activities</div><div class='intro-js-content-text'>These learning activities are selected for you. You can open and close them whenever you want. I will show you your progress.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Deze leeractiviteiten zijn voor jou geselecteerd. Je kunt ze openen en sluiten wanneer je wilt. Ik laat je zien hoe ver je bent.</div>",
					'de-DE':
						"<div class='intro-js-title'>Leeractiviteiten</div><div class='intro-js-content-text'>Diese Lernaktivitäten werden für Sie ausgewählt. Sie können sie jederzeit öffnen und schließen. Ich zeige dir deine Fortschritte.</div>",
				},
				position: 'right',
				tooltipClass: 'customIntro customIntro-small',
			},
			{
				element: '.filter-wrapper',
				intro: {
					'en-US':
						"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>You can filter your learning activities by selecting a category and/or your status.</div>",
					'nl-NL':
						"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>Je kunt je leeractiviteiten filteren door een categorie en/of status te selecteren.</div>",
					'de-DE':
						"<div class='intro-js-title'>Filter</div><div class='intro-js-content-text'>Sie können Ihre Lernaktivitäten filtern, indem Sie eine Kategorie und/oder Ihren Status auswählen.</div>",
				},
				position: 'bottom',
				tooltipClass: 'customIntro customIntro-small',
			},
			{
				intro: {
					'en-US':
						"<div class='finish-logo'></div><div class='intro-js-title'>You are ready to start now</div><div class='intro-js-content-text'>I wish you a pleasant learning experience!</div>",
					'nl-NL':
						"<div class='finish-logo'></div><div class='intro-js-title'>Je bent nu klaar om te starten</div><div class='intro-js-content-text'>Ik wens je prettige leerervaringen toe!</div>",
					'de-DE':
						"<div class='finish-logo'></div><div class='intro-js-title'>Je bent nu klaar om te starten</div><div class='intro-js-content-text'>Ich wünsche Ihnen ein angenehmes Lernerlebnis!</div>",
				},
			},
		],

		showStepNumbers: false,
		exitOnOverlayClick: true,
		exitOnEsc: true,
		nextLabel: '',
		// prevLabel: '<span style="color:green">Previous</span>',
		prevLabel: '',
		skipLabel: '',
		doneLabel: '',
		tooltipClass: 'customIntro',
	},
};
export default ConfigFields;
