import { ProviderCategoryEnum, ProviderTypeEnum } from '@base/models';

export interface ProviderTypeViewModel {
	providerType?: ProviderTypeEnum;

	name: string;

	providerCategory: ProviderCategoryEnum;

	description: string;

	defaultImg: string;

	label: string;

	fileType: string;

	cssImgClass: string;
}

export const CreateActivityTypes: Array<ProviderTypeViewModel> = [
	{
		name: 'Local static website',
		providerCategory: ProviderCategoryEnum.StaticWebsite,
		description: 'ZIP archive that contains an "index.html" file in the root',
		defaultImg: 'elearningProviderType.png',
		label: 'local website',
		fileType: 'archive',
		cssImgClass: 'elearning-provider-image',
	},
	{
		name: 'Local xAPI module',
		providerCategory: ProviderCategoryEnum.LocalXApi,
		description:
			'xAPI based output of Lectora, Articulate Storyline/Rise, Captivate, Courseware or iSpring hosted locally on Philpaper',
		defaultImg: 'elearningProviderType.png',
		label: 'module file',
		fileType: 'archive',
		cssImgClass: 'elearning-provider-image',
	},
	{
		name: 'External xAPI module',
		providerCategory: ProviderCategoryEnum.ExternalXApi,
		description: 'xAPI enabled application provider, like Phil-Q, hosted externally',
		defaultImg: 'elearningProviderType.png',
		label: 'module file',
		fileType: 'archive',
		cssImgClass: 'elearning-provider-image',
	},
	{
		name: 'Video',
		providerType: ProviderTypeEnum.VideoFile,
		providerCategory: ProviderCategoryEnum.Video,
		description:
			'Video which will be hosted in Philpaper using the default Philpaper video player. Accepted formats: MP4',
		defaultImg: 'videoProviderType.png',
		label: 'video file',
		fileType: 'video',
		cssImgClass: 'video-provider-image',
	},
	{
		name: 'Video (Vimeo)',
		providerType: ProviderTypeEnum.VideoVimeo,
		providerCategory: ProviderCategoryEnum.Video,
		description: 'Video file which is hosted on Vimeo using the default Philpaper video player',
		defaultImg: 'videoVimeoProviderType.png',
		label: 'vimeo url',
		fileType: 'url',
		cssImgClass: 'video-vimeo-provider-image',
	},
	{
		name: 'Document',
		providerType: ProviderTypeEnum.Document,
		providerCategory: ProviderCategoryEnum.Document,
		description: 'Documents like pdf, docx, pptx, xlsx, zip',
		defaultImg: 'documentProviderType.png',
		label: 'document file',
		fileType: 'document',
		cssImgClass: 'document-provider-image',
	},
	{
		name: 'LTI',
		providerCategory: ProviderCategoryEnum.LTI,
		description: 'Learning Tools Interoperability course',
		defaultImg: 'elearningProviderType.png',
		label: 'LTI',
		fileType: 'archive',
		cssImgClass: 'elearning-provider-image',
	},
];
