import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { JediConfig } from 'app-legacy/commons';
import { LoadingPanelService } from 'app-legacy/ajs-interceptors/jediLoadingPanel/jediLoadingPanelComponent';

@Injectable()
export class LoadingProgressHttpInterceptor implements HttpInterceptor {
	baseUri = JediConfig.api.base;

	constructor(private jediLoadingPanel: LoadingPanelService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.startsWith(this.baseUri)) {
			return next.handle(req);
		}
		this.jediLoadingPanel.show('loading ...');
		return next.handle(req).pipe(
			finalize(() => {
				this.jediLoadingPanel.hide();
			}),
			catchError(e => {
				this.jediLoadingPanel.hide();
				return throwError(e);
			})
		);
	}
}
