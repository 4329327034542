import { ComponentRegistration } from '~root/commons';
import { JediClientStorageService } from './ClientStorage/jediClientStorage';
import { StorageType } from './ClientStorage/StorageType';

export const JediPackageActionsRegistration: ComponentRegistration = {
	register: function (ngModule) {
		// ngModule.provider('jediPackageActions', JediPackageService);
		ngModule.service('jediPackageActions', JediPackageService);
	},
};

const storageTypes = [StorageType.CookieStorage, StorageType.LocalStorage, StorageType.SessionStorageNoPack];

export class JediPackageService {
	static $inject = ['jediClientStorage'];
	constructor(private jediClientStorage: JediClientStorageService) {}

	clearLectoraContentInfo() {
		const lectoraKey = 'Lectora2:';
		storageTypes.forEach(storageType => {
			const lectoraKeys = this.jediClientStorage.getKeys(storageType, lectoraKey);
			if (lectoraKeys.length > 0) {
				lectoraKeys.forEach(el => {
					this.jediClientStorage.remove(el, storageType);
				});
			}
		});
	}

	cleanUpLectoraState() {
		// check if package type is Lectora
		const lectoraKey = 'Lectora2';
		let storageType = null;
		let lectoraState = null;
		storageTypes.forEach(e => {
			if (!lectoraState) {
				storageType = e;
				lectoraState = this.jediClientStorage.get(lectoraKey, storageType);
			}
		});
		if (!lectoraState) {
			return;
		}
		// TrivantisEPS = 'F' | 'T'
		// for lectora packages, on specific browsers (mobile safari)
		const lectoraProperties = this.deserializeLectoraProperties(lectoraState);
		if (lectoraProperties && lectoraProperties.TrivantisEPS) {
			const eps = lectoraProperties.TrivantisEPS;
			if (eps == 'T' || eps == 'F') {
				if (lectoraProperties.TrivantisSCORMTimer != '0') {
					lectoraProperties.TrivantisSCORMTimer = '0';
					//lectoraProperties.TrivantisEPS = 'F';
					const serialized = this.serializeLectoraProperties(lectoraProperties);
					this.jediClientStorage.set(lectoraKey, serialized, storageType);
				}
			}
		}
	}

	private deserializeLectoraProperties(serialized): any {
		const ls = serialized.split('|');
		const lectoraProperties = {};
		ls.forEach(item => {
			if (item) {
				const parts = item.split('=');
				lectoraProperties[parts[0]] = parts[1];
			}
		});
		return lectoraProperties;
	}

	private serializeLectoraProperties(lectoraProperties) {
		let serialized = '';
		Object.keys(lectoraProperties).forEach(key => {
			serialized += key + '=' + lectoraProperties[key] + '|';
		});

		return serialized;
	}
}
