import { Provider } from '@angular/core';

import {
	JediAuthStore,
	JediAuthService,
	ContextManagerService,
	JediCacheFactory,
	JediClientStorageService,
	JediStatementGeneratorService,
	LocalizationProvider,
	NavigationService,
	JediPackageService,
	JediWindowMessengerService,
	JediUserSettingsService,
	JediHierarchyServiceLogic,
	UiStateStorageService,
	UserHierarchyMapperService,
} from 'app-legacy/commons';

import { SearchApiService, AdminService, JediTablePagerService } from 'app-legacy/admin/commons';
import { LoadingPanelService } from 'app-legacy/ajs-interceptors/jediLoadingPanel/jediLoadingPanelComponent';
import { GroupActionsService } from 'app-legacy/admin/modules/groups/GroupActionsService';
import { AngularJsLogger } from './ajs-logger';

/**
 * Creates an Angular Provider for an AngularJS service
 * @param serviceName AngularJS injection token declared for service
 * @param service Type definition of AngularJS service
 * @returns upgraded Angular Provider
 */
export function createProvider(serviceName: string, service: any): Provider {
	return {
		provide: service,
		useFactory: injector => injector.get(serviceName),
		deps: ['$injector'],
	};
}

// TODO: these upgraded services should be moved to their corresponding NgModule and converted to actual Angular+ services
export const providers: Provider[] = [
	createProvider('jediStatementGenerator', JediStatementGeneratorService),
	createProvider('groupActionsService', GroupActionsService),
	createProvider('searchService', SearchApiService),

	createProvider('jediAuthStore', JediAuthStore),
	createProvider('jediContext', ContextManagerService),
	createProvider('jediAuth', JediAuthService),

	createProvider('$log', AngularJsLogger),
	createProvider('jediLoadingPanel', LoadingPanelService),
	createProvider('jediClientStorage', JediClientStorageService),
	createProvider('jediAdminService', AdminService),
	createProvider('jediTablePagerService', JediTablePagerService),
	createProvider('jediCacheFactory', JediCacheFactory),
	createProvider('jediLocalization', LocalizationProvider),
	createProvider('navigationService', NavigationService),
	createProvider('jediPackageActions', JediPackageService),
	createProvider('jediWindowMessenger', JediWindowMessengerService),
	createProvider('jediUserSettings', JediUserSettingsService),
	createProvider('jediHierarchy', JediHierarchyServiceLogic),
	createProvider('uiStateStorage', UiStateStorageService),
	createProvider('userHierarchyMapper', UserHierarchyMapperService),
];
