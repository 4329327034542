import ErrorComponent from './error/ErrorController';
import GenericComponent from './generic/GenericController';
import RedirectComponent from './redirect/RedirectComponent';

function CommonsModuleRouting($routeProvider) {
	$routeProvider
		// routes
		.when('/notfound', GenericComponent)
		.when('/error', ErrorComponent)
		.when('/', RedirectComponent);
}

export default CommonsModuleRouting;
