import moment from 'moment';

/**
 * when getting the JSON body response from the server, the UTC strings are being parsed to local time as a Date object.
 * To work with date object that has the actual UTC values (for year, month, date, etc.)
 * this will create a local date which has the values of the original UTC string.
 * @param d
 * @returns
 */
export function convertToAdjustedDateFromUtcDateValues(d: Date) {
	if (!d) return d;

	const date = new Date();
	date.setFullYear(d.getUTCFullYear());
	date.setMonth(d.getUTCMonth());
	date.setDate(d.getUTCDate());
	date.setHours(d.getUTCHours());
	date.setMinutes(d.getUTCMinutes());
	date.setSeconds(d.getUTCSeconds());
	return date;
}

/**
 * when a date object is being serialized to be sent within a HTTP request, it is converted to a UTC string.
 * a date object uses local time, so the UCT string will be date converted to different values for hours, minutes, etc.
 * In order to get a UTC string that preserve the exact values for hours, minute, etc. a local date will be created which will be serialized as UTC string with the exact desired values.
 * @param d
 * @returns
 */
export function convertToAdjustedDateFromLocalDateValues(d: Date) {
	if (!d) return d;

	const time = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
	const date = new Date(time);
	return date;
}

export function convertToAdjustedDateFromStringDateValues(d: string) {
	const utcDate = moment.utc(d);
	const date = utcDate.local().toDate();
	return date;
}
