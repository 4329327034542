/* eslint-disable no-param-reassign */

import * as logger from '~root/logger';

import { LocalizationProvider, JediUserSettingsService, ContextManagerService } from 'app-legacy/commons';

import { UserAuthorizationResult } from './UserAuthorizationResult';
import { JediAuthService } from './jediAuthService';

export function ensureUserAuthorization(permissions) {
	return [
		'$q',
		'jediAuth',
		'jediLocalization',
		'jediUserSettings',
		function (
			$q,
			jediAuth: JediAuthService,
			jediLocalization: LocalizationProvider,
			jediUserSettings: JediUserSettingsService
		) {
			const deferred = $q.defer();

			jediAuth.loadUser().then(
				user => {
					const authResult = jediAuth.authorize(user, permissions);
					if (authResult === UserAuthorizationResult.Authorized) {
						jediLocalization.updateSupportedCultures(user.supportedCultures);
						jediLocalization.setCurrentUiCulture(user.culture);
						jediUserSettings.setLocation();
						logger.warn('authorization passed');
						deferred.resolve(user);
					} else {
						// old behavior redirect user to homepage, only for Admin and AdminAppUser roles
						deferred.reject('unauthorized');
						logger.error('user not authorized', user, permissions);
						jediAuth.logout(authResult);
					}
				},
				error => {
					deferred.reject(error);
					jediAuth.logout();
					logger.error('user authorization error', error);
				}
			);

			return deferred.promise;
		},
	];
}

export const authenticationResolvers = {
	user: [
		'jediAuth',
		'$window',
		function (jediAuth, $window) {
			// user should always be loaded
			return jediAuth.loadUser().catch(() => {
				$window.location.href = '/account/logout';
			});
		},
	],
	context: [
		'jediContext',
		'jediAuth',
		'$stateParams',
		function (jediContext: ContextManagerService, jediAuth: JediAuthService, $stateParams) {
			return jediAuth.loadUser().then(user => {
				return jediContext.loadContext($stateParams.organisationId);
			});
		},
	],
};
