/* eslint-disable no-prototype-builtins */
import * as angular from 'angular';
import structuredClone from '@ungap/structured-clone';
import { IRootScopeService, IAngularEvent, ISCEService } from 'angular';

import * as logger from '~root/logger';
import {
	CultureModel,
	LocalizedTranslationTable,
	GlobalTranslationTable,
	OrganisationModel,
	LocaleChangeEvent,
	UiLocalizedTranslationTable,
} from '@base/models';

import serverSettings from '../jediServerSettings';
import { b64DecodeUnicode, b64EncodeUnicode } from '../base64';

import { JediClientStorageService, StorageType } from '../services/ClientStorage';
import { JediConfig } from '../config/config';
import { ContextManagerService } from '../jediContextService';
import { ResourceTranslations, ResourceTranslationTable, ResourceTranslationValue } from './translations';

// localization module for client-side UI
// events:
// - broadcasted:
// -- jedi:locale:change
export class LocalizationProvider {
	static $inject = ['$rootScope', 'jediClientStorage', '$sce', 'jediContext'];
	constructor(
		private $rootScope: IRootScopeService,
		private jediClientStorage: JediClientStorageService,
		private $sce: ISCEService,
		private jediContext: ContextManagerService
	) {}

	supportedCultures = ['en-US', 'nl-NL'];

	onChange(callback: (e: IAngularEvent, data: LocaleChangeEvent) => void): () => void {
		return this.$rootScope.$on(JediConfig.events.localeChange, callback);
	}

	updateSupportedCultures(cultures: CultureModel[]): void {
		if (cultures && cultures.length > 0) {
			this.supportedCultures = cultures.map(c => c.id);
		}
	}

	cookieName = 'jedi.language';
	defaultCulture = this.supportedCultures[0];

	// convert from organisation localization table format to resource localization table format:
	// table[culture][localizationKey] -> mapped[localizationKey][culture]
	mapOrganisationLocalizationTable(organisation: OrganisationModel): ResourceTranslationTable {
		const table = organisation?.localizationSettings?.localizations || {};
		const mapped = {};
		Object.keys(table).forEach(culture => {
			Object.keys(table[culture]).forEach(localizationKey => {
				if (!mapped[localizationKey]) {
					mapped[localizationKey] = {};
				}
				mapped[localizationKey][culture] = b64DecodeUnicode(table[culture][localizationKey]);
			});
		});
		return mapped;
	}

	// TODO: move this method from here
	updateOrganizationLocalizations(organisation: OrganisationModel): OrganisationModel {
		if (typeof organisation.portalCustomCSS === 'string') {
			// $sce.trust... converts the value to an object to be used for injecting content. The method only accepts string as input
			organisation.portalCustomCSS = this.$sce.trustAsHtml(organisation.portalCustomCSS);
		}
		const localizations = this.getDecodedLocalizations(organisation.localizationSettings.localizations);
		organisation.localizationSettings.localizations = localizations;

		return organisation;
	}
	private organisationMap = {};

	getLocalizationTable(organisation: OrganisationModel): ResourceTranslationTable {
		if (organisation && organisation.id) {
			const key = organisation.id;
			if (!this.organisationMap) {
				this.organisationMap = {};
			}
			if (!this.organisationMap[key]) {
				const orgLocalizations = this.mapOrganisationLocalizationTable(organisation);
				const localizationTable = { ...ResourceTranslations };
				Object.keys(orgLocalizations).forEach(localizationKey => {
					Object.keys(orgLocalizations[localizationKey]).forEach(culture => {
						if (!localizationTable[localizationKey]) {
							localizationTable[localizationKey] = {};
						}
						const localized = orgLocalizations[localizationKey][culture];
						if ((localized || '').trim().length > 0) {
							localizationTable[localizationKey][culture] = localized;
						}
					});
				});
				this.organisationMap[organisation.id] = localizationTable;
			}
			if (this.organisationMap && this.organisationMap[organisation.id]) {
				return this.organisationMap[organisation.id];
			}
		}
		return ResourceTranslations;
	}

	// resolve a resource translation where the 'resourceName' contains a single tokenized translation key
	getPartialLocalizationResource(
		localizationTable: ResourceTranslationTable,
		resourceName: string
	): ResourceTranslationValue {
		const tokenMatch = (resourceName || '').match(/(#.+#)/i);
		if (tokenMatch) {
			const resourceKey = tokenMatch.find(item => !!localizationTable[item]);
			const resource = angular.copy(localizationTable[resourceKey]);
			if (resource) {
				Object.keys(resource).forEach(function (culture) {
					const original = resource[culture];
					resource[culture] = resourceName.replace(resourceKey, original);
				});
			}
			return resource;
		}
		return undefined;
	}
	resolveResourceTranslation(resourceName: string): string {
		const uiCulture = this.getCurrentUiCulture();
		const organisation = this.jediContext.getCurrentContext()?.organisation;

		if ((resourceName || '').trim().length <= 0) {
			return '';
		}
		const localizationTable = this.getLocalizationTable(organisation);

		const resource =
			this.getPartialLocalizationResource(localizationTable, resourceName) || localizationTable[resourceName];
		let localized = '';
		if (resource && resource[uiCulture]) {
			localized = resource[uiCulture];
		} else if (resource && resource[this.defaultCulture]) {
			localized = resource[this.defaultCulture];
		}
		if ((localized || '').trim().length < 1 && !localizationTable.hasOwnProperty(resourceName)) {
			localized = resourceName;
		}
		return localized;
	}

	getLocalizedText(resourceName: string, model: any | null): string {
		if (resourceName && typeof resourceName !== 'string') {
			return resourceName;
		}
		const localized = this.resolveResourceTranslation(resourceName);
		if (model) {
			const parsed = parseTemplate(localized, model);
			return parsed || localized;
		}
		return localized;
	}

	// return the ISO for the currently selected language, defaults to english
	getCurrentUiCulture(): string {
		const enforcedCulture = this.getEnforcedCulture();
		if (enforcedCulture) {
			return enforcedCulture;
		}

		const languageCookie = this.jediClientStorage.get(this.cookieName, StorageType.CookieStorage);
		// if there is a language cookie set
		if (languageCookie && this.supportedCultures.includes(languageCookie)) {
			return languageCookie;
		}
		// use the default organisation language
		if (serverSettings.AppOrganisation) {
			const orgDefaultCulture = serverSettings.AppOrganisation.DefaultCulture;
			if (orgDefaultCulture && this.supportedCultures.includes(orgDefaultCulture)) {
				return orgDefaultCulture;
			}
		}
		return this.defaultCulture;
	}

	getEnforcedCulture(): string {
		if (serverSettings.AppCustomEndpointName === 'icalt') {
			return 'nl-NL';
		}
		if (serverSettings.AppCustomEndpointName === 'aitrap') {
			return 'en-US';
		}
		return null;
	}

	// store the index of the selected culture in a cookie
	// TODO:
	//  refactor setCurrentUiCulture for the cases when supported cultures does not include a specific culture
	//  and it should use the default one
	setCurrentUiCulture(cultureObj: CultureModel | string): void {
		const enforcedCulture = this.getEnforcedCulture();
		const culture = enforcedCulture || (typeof cultureObj === 'string' ? cultureObj : cultureObj.id) || '';

		const oldUiCulture = this.getCurrentUiCulture() || '';

		if (this.supportedCultures.includes(culture)) {
			this.jediClientStorage.set(this.cookieName, culture, StorageType.CookieStorage, 365);
			this.$rootScope.currentUserCulture = culture;
			if (oldUiCulture.toLowerCase() !== culture.toLowerCase()) {
				this.$rootScope.$broadcast(
					JediConfig.events.localeChange,
					new LocaleChangeEvent(oldUiCulture, culture)
				);
			}
		}
	}

	//#region localization model mapping
	// return a new version of localizationTable, where
	//  empty localization keys are removed
	//  keys from uiLocalizations are copied in their corresponding cultures
	mergeLocalizations(
		cultures: CultureModel[],
		apiLocalizationTables: GlobalTranslationTable,
		uiLocalizations: UiLocalizedTranslationTable[]
	): GlobalTranslationTable {
		const mergeTable = angular.copy(apiLocalizationTables || {});
		uiLocalizations.forEach(localization => {
			// only valid api localizations should be updated from UI
			if (!localization.culture) {
				return null;
			}
			const cultureId = localization.culture.id;
			if (!(cultures || []).some(c => c.id === cultureId)) {
				return null;
			}
			Object.keys(localization.keys || {}).forEach(localizationKey => {
				const localizedContent = (localization.keys[localizationKey] || '').trim();

				if (!mergeTable.hasOwnProperty(cultureId)) {
					mergeTable[cultureId] = {};
				}
				mergeTable[cultureId][localizationKey] = b64EncodeUnicode(localizedContent);
			});
		});
		return mergeTable;
	}

	getCurrentLocalizationDecoded(localizationTable: GlobalTranslationTable): LocalizedTranslationTable {
		const culture = this.getCurrentUiCulture();

		if (!localizationTable || !culture || !localizationTable[culture]) {
			return {};
		}
		const decodedTable = angular.copy(localizationTable[culture]);

		Object.keys(decodedTable).forEach(key => {
			if ((decodedTable[key] || '').trim().length > 0) {
				decodedTable[key] = b64DecodeUnicode(decodedTable[key]);
			} else {
				delete decodedTable[key];
			}
		});

		return decodedTable;
	}

	getDecodedLocalizations(localizationTable: GlobalTranslationTable): GlobalTranslationTable {
		if (!localizationTable) {
			return {};
		}
		const decodedTable = angular.copy(localizationTable);
		Object.keys(decodedTable).forEach(culture => {
			Object.keys(decodedTable[culture]).forEach(key => {
				if (decodedTable[culture] && (decodedTable[culture][key] || '').trim().length > 0) {
					decodedTable[culture][key] = b64DecodeUnicode(decodedTable[culture][key]);
				}
			});
		});
		return decodedTable;
	}

	mapUiLocalizations(
		cultures: CultureModel[],
		apiLocalizationTables: GlobalTranslationTable
	): UiLocalizedTranslationTable[] {
		const al = angular.copy(apiLocalizationTables || {});
		const localizations = [];
		// only provided cultures should be used for UI localizations
		cultures.forEach(culture => {
			if (!al.hasOwnProperty(culture.id)) {
				al[culture.id] = new LocalizedTranslationTable();
			}
			const localized: UiLocalizedTranslationTable = {
				culture: culture,
				keys: new LocalizedTranslationTable(),
				enabled: true,
			};

			Object.keys(al[culture.id]).forEach(key => {
				if (al[culture.id][key]) {
					localized.keys[key] = b64DecodeUnicode(al[culture.id][key]);
				}
			});
			localizations.push(localized);
		});
		return localizations;
	}

	//#endregion localization model mapping

	createDecodedLocalizations(
		apiLocalizations: GlobalTranslationTable,
		valueTransformer: (input: string) => string
	): GlobalTranslationTable {
		if (!apiLocalizations) return null;
		const model: GlobalTranslationTable = structuredClone(apiLocalizations);
		Object.keys(model).forEach(culture => {
			Object.keys(model[culture]).forEach(key => {
				let decoded = b64DecodeUnicode(model[culture][key]);
				decoded = valueTransformer(decoded);
				if (decoded) model[culture][key] = decoded;
			});
		});
		return model;
	}

	createEncodedLocalizations(
		localizations: GlobalTranslationTable,
		valueTransformer: (input: string) => string
	): GlobalTranslationTable {
		if (!localizations) return null;
		const encoded: GlobalTranslationTable = structuredClone(localizations);
		Object.keys(encoded).forEach(culture => {
			if (encoded[culture]) {
				Object.keys(encoded[culture]).forEach(key => {
					if (!encoded[culture][key] || encoded[culture][key] === '<p></p>') {
						delete encoded[culture][key];
					} else {
						let value = encoded[culture][key];
						value = valueTransformer(value);
						encoded[culture][key] = b64EncodeUnicode(value);
					}
				});
			}
		});
		return encoded;
	}
}

function parseTemplate(template: string, scope: any) {
	const pattern = /{{(.*?)}}/gm;
	const tokenized = template.match(pattern) || [];
	let output = template + '';
	const tokensNotFound = [];

	tokenized.forEach(token => {
		const propertyName = token.replace(/{*}*/gm, '');
		const propertyValue = getDescendantProp(scope, propertyName);
		if (propertyValue) {
			output = output.replace(token, propertyValue);
		} else {
			tokensNotFound.push(token);
		}
	});
	if (tokensNotFound.length) {
		logger.warn(`localization template processing found empty tokens: ${tokensNotFound.join(',')}`);
	}
	return output;
}

function getDescendantProp(obj: any, desc: string) {
	const arr = desc.split('.');
	while (arr.length) {
		obj = obj[arr.shift()];
	}
	return obj;
}
