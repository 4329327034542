import * as WebfontLoader from 'webfontloader';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';

@Injectable({ providedIn: 'root' })
export class BootstrapLegacyService {
	constructor(private upgrade: UpgradeModule, private location: Location) {
		// empty
	}

	public doBootstrap(): void {
		const uri = this.location.path().toLowerCase();
		if (uri.startsWith('/admin')) {
			WebfontLoader.load({
				monotype: {
					projectId: '930e37c0-20cc-44d7-b9e2-cfccdcfbc5e4',
					version: 20190322, // (optional, flushes the CDN cache)
					loadAllFonts: true, // (optional, loads all project fonts)
				},
			});

			import('../app-legacy/admin/app.angular').then(module => {
				this.bootstrap(module.default.name);
			});
		} else if (uri.startsWith('/portal')) {
			WebfontLoader.load({
				google: {
					families: ['Saira Semi Condensed'],
				},
			});
			import('../app-legacy/portal/app.angular').then(module => {
				this.bootstrap(module.default.name);
			});
		} else if (uri.startsWith('/player')) {
			WebfontLoader.load({
				google: {
					families: ['Saira Semi Condensed'],
				},
			});
			import('../app-legacy/portal/app.angular').then(module => {
				this.bootstrap(module.default.name);
			});
		} else {
			WebfontLoader.load({
				google: {
					families: ['Saira Semi Condensed'],
				},
			});

			import('../app-legacy/public/app.angular').then(module => {
				this.bootstrap(module.PublicApp.name);
			});
		}
	}

	private bootstrap(moduleName: string): void {
		this.upgrade.bootstrap(document.body, [moduleName], { strictDi: true });
		const { urlService } = this.upgrade.injector.get(UIRouter);

		this.upgrade.ngZone.run(() => {
			urlService.listen();
			urlService.sync();
		});
	}
}
