import * as logger from '~root/logger';
import { ICacheObject } from 'angular';

export class ApiHttpCache implements ICacheObject {
	private timeout: number;
	private timers = new Map<string, any>();
	private keys = new Set<string>();
	private cache: ICacheObject;
	constructor(cache: ICacheObject, timeoutMilliseconds = 5000) {
		this.cache = cache;
		this.timeout = timeoutMilliseconds;
	}
	info(): { id: string; size: number } {
		return this.cache.info();
	}
	destroy(): void {
		this.removeAll();
	}
	get<T>(key) {
		const value = this.cache.get<T>(key);
		return value;
	}
	put<T>(key: string, val?: T): T {
		this.cache.put(key, val);
		this.keys.add(key);

		if (this.timers.has(key)) {
			clearTimeout(this.timers[key]);
		}
		this.timers.set(
			key,
			setTimeout(() => {
				logger.info(`removing expired key ${key}`);
				this.remove(key);
			}, this.timeout)
		);
		return val;
	}
	remove(key) {
		this.cache.remove(key);
		this.keys.delete(key);
		clearTimeout(this.timers[key]);
		this.timers.delete(key);
	}
	removeAll(pattern = null) {
		if (!pattern) {
			this.cache.removeAll();
		}
		for (const sk of this.keys) {
			if (sk.match(pattern)) {
				this.remove(sk);
			}
		}
	}
	delete() {
		//no op here because this is standalone, not a part of $cacheFactory
	}
}
