<div *ngIf="isActivity && model" [formGroup]="editSettingsFg" class="settings-container">
	<div>
		<div class="form-group">
			<mat-checkbox color="primary" formControlName="enableCertificateCreation">enable certificates</mat-checkbox>
		</div>
		<div class="form-group">
			<mat-checkbox color="primary" formControlName="enableAutomaticIssuing">enable auto issue</mat-checkbox>
		</div>
		<div class="form-group">
			<mat-checkbox color="primary" formControlName="enableAutomaticSending">enable automatic sending</mat-checkbox>
		</div>
	</div>

	<div class="form-group email-template" *ngIf="emailTemplates$ | async as emailTemplates">
		<label>email template</label>
		<app-dropdown formControlName="emailTemplate" [options]="emailTemplates"></app-dropdown>
		<p class="warning">If no email template is provided, organisation or system default will be used</p>
	</div>

	<div class="form-group">
		<span>valid for</span>
		<div class="validity-container" formGroupName="validity">
			<div class="field">
				<label for="validity-years">years</label>
				<input id="validity-years" class="form-control" type="number" min="0" max="255" formControlName="years" />
			</div>

			<div class="field">
				<label for="validity-months">months</label>
				<input id="validity-months" class="form-control" type="number" min="0" max="1000" formControlName="months" />
			</div>
			<div class="field">
				<label for="validity-days">days</label>
				<input id="validity-days" class="form-control" type="number" min="0" max="1000" formControlName="days" />
			</div>
		</div>
		<p class="warning">
			The validity date is calculated in years/months/days from the time of completion of the learning activity.<br/>
            First, the years are added. Then the months, and finally the days. <br/>
            This setting is only used to show a validity date on the exported certificate.
		</p>
	</div>
</div>

<ng-container
	*ngIf="(!isActivity || (isActivity && model?.enableCertificateCreation)) && localizedCertificateTemplates"
>
	<app-localized-editor
		[componentType]="localizedEditorType"
		title="certificate template"
		[localized]="localizedCertificateTemplates"
		(changed)="onUpdateCertificateTemplates($event)"
	></app-localized-editor>
</ng-container>

<div>
	<button
		type="button"
		class="btn btn-primary big"
		[disabled]="!hasChanges"
		(click)="save()"
	>
		save
	</button>
	<button type="button" class="btn btn-secondary" (click)="cancel()">cancel</button>
</div>
