/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { JediAuthService } from 'app-legacy/commons';
import { getTinyMceOptions, TinyMceOptions } from './getTinyMCEOptions';
import { EditTextService } from './edit-text.service';

export enum EditorType {
	Small = 'sm',
	Medium = 'md',
	Large = 'lg',
}

const invisibleCharacters = [
	// non-breaking space (UTF-8) is not a visible space
	'\u{A0}',
];

export function normalizeEditorText(value: string): string {
	if (!value) return value;
	const normalized = invisibleCharacters.reduce((p, c) => p.replace(c, ''), value);
	return normalized;
}

@Component({
	selector: 'app-edit-text',
	templateUrl: './edit-text.component.html',
	styleUrls: ['./edit-text.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => EditTextComponent),
			multi: true,
		},
	],
})
export class EditTextComponent implements OnInit, ControlValueAccessor {
	tinymceOptions: TinyMceOptions = null;

	public id: string = (new Date().getTime() + Math.trunc(10000 * Math.random())).toString();

	@Input()
	type = EditorType.Small;

	@Input()
	disabled = false;

	// optional output used in AngularJS
	@Output()
	textChanged: EventEmitter<string>;

	// optional input used in AngularJS
	@Input()
	initialText: string;

	@Input()
	changeId: string;

	set textValue(value: any) {
		const normalized = normalizeEditorText(value);
		this.value = normalized;
		this.changeEmitter(normalized);
		if (this.changeId) {
			this.editTextService.emit(this.changeId, normalized);
		}
		this.textChanged?.emit(normalized);
	}

	get textValue() {
		return normalizeEditorText(this.value);
	}

	private value: string;

	private changeEmitter: (v: string) => void = (_v: string) => {};

	constructor(private jediAuth: JediAuthService, private editTextService: EditTextService) {}

	ngOnInit() {
		const mce = getTinyMceOptions(this.jediAuth);

		if (this.type === EditorType.Small) {
			this.tinymceOptions = mce.genericInfoOptions;
		}

		if (this.type === EditorType.Large) {
			this.tinymceOptions = mce.defaultOptions;
		}

		// ensure tinyMCE instances are unique
		this.tinymceOptions.selector = `#${this.id}`;

		if (this.initialText) {
			this.textValue = this.initialText;
		}
	}

	writeValue(obj: any): void {
		this.textValue = obj;
	}

	registerOnChange(fn: any): void {
		this.changeEmitter = fn;
	}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
