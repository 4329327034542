import { IHttpService, IHttpResponse } from 'angular';
import { ComponentRegistration } from '~root/commons';

import { JediQueryBuilder, JediConfig } from 'app-legacy/commons';
import {
	ActivityRelationModel,
	OrganisationActivityRelationModel,
	EmailTemplateModel,
	ApiListResponseModel,
	ExportStatementsTemplateModel,
	ExportStatementsOptions,
	UserBaseModel,
} from '@base/models';

import {
	ProgressReportingApiQueryOptions,
	UserProgressReportPage,
	LearningActivityApiQueryOptions,
	noActivityPlaceholder,
	overallProgressVersion,
} from '../models';
import { ActivityVersionDataModel, ActivityVersionModel, UserActivityRelationModel } from 'app/admin/activities/models';

export const AdminServiceRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediAdminService', AdminService);
	},
};

const baseUri = JediConfig.api.base.replace('/+$', '/');

export interface ActivityHierarchyTableModel {
	relations: ActivityRelationModel[];
	activities: ActivityVersionDataModel[];
}

export class AdminService {
	static $inject = ['$http', '$q'];

	constructor(private $http: IHttpService) {}

	async getUserActivityRelationModel(
		user: UserBaseModel,
		activity: ActivityVersionDataModel,
		version: ActivityVersionModel
	): Promise<UserActivityRelationModel> {
		let url = `${baseUri}learning/users/${user.id}/activities/${activity.id}`;
		if (version?.id !== overallProgressVersion.id) {
			url = `${baseUri}learning/users/${user.id}/activities/${activity.id}/versions/${version.id}`;
		}

		const response = await this.$http<UserActivityRelationModel>({
			method: 'GET',
			url: url,
			loadingMessage: true,
		});
		return response.data;
	}

	getProgressReportingQuery(params, skipPaging, skipBaseUrl, apiFilters: ProgressReportingApiQueryOptions): string {
		if (!apiFilters || !apiFilters.filter) {
			return null;
		}

		const builder = new JediQueryBuilder(params, null, null, skipPaging, apiFilters.filter);

		if (!builder) {
			return null;
		}

		if (skipBaseUrl) {
			return builder.build(null);
		}
		return builder.build(JediConfig.api.learning.participants.reports);
	}

	async getProgressReportEntries(
		params,
		skipPaging,
		skipBaseUrl,
		apiFilters: ProgressReportingApiQueryOptions
	): Promise<UserProgressReportPage> {
		const queryUrl = this.getProgressReportingQuery(params, skipPaging, skipBaseUrl, apiFilters);
		if (!queryUrl) {
			throw new Error('invalid query URL for progress reporting entries');
		}

		const response = await this.$http<UserProgressReportPage>({
			method: 'GET',
			url: queryUrl,
			loadingMessage: true,
		});
		return response.data;
	}

	async getActivityHierarchyModel(organisationId: number): Promise<ActivityHierarchyTableModel> {
		const options = new LearningActivityApiQueryOptions();
		options.filter.organisationId = organisationId;
		options.filter.showContainers = 'true';
		options.itemsPerPage = 300;
		const [relations, apiActivities] = await Promise.all([
			this.getActivityRelations(organisationId),
			this.getActivities(options),
		]);

		const rootRelations: ActivityRelationModel[] = relations
			.filter(r => !r.parentRelationId)
			.map(r => {
				const m = new ActivityRelationModel();
				m.accessOrder = 0;
				m.displayOrder = 0;
				m.hierarchyPath = `root/${r.parentContainerId}`;
				m.childId = r.parentContainerId;
				m.parentContainerId = null;
				m.parentRelationId = null;
				m.relationId = null;
				return m;
			});

		const hierarchy = {
			relations: relations.concat(rootRelations),
			activities: apiActivities,
		};

		return hierarchy;
	}

	async getActivityRelations(organisationId: number): Promise<Array<ActivityRelationModel>> {
		const response = await this.$http<Array<ActivityRelationModel>>({
			method: 'GET',
			url: `${baseUri}/learning/activities/relations/organisations/${organisationId}`,
			loadingMessage: true,
		});
		return response.data;
	}

	async getActivityRelationHierarchy(organisationId: number): Promise<OrganisationActivityRelationModel> {
		const response = await this.$http<OrganisationActivityRelationModel>({
			method: 'GET',
			url: `${baseUri}/learning/activities/organisations/${organisationId}/relations`,
			loadingMessage: true,
		});
		return response.data;
	}

	async getActivities(options: LearningActivityApiQueryOptions): Promise<Array<ActivityVersionDataModel>> {
		const url = JediConfig.api.learning.activities.dropdownView(
			options.filter.organisationId,
			options.filter.showContainers
		);
		const response = await this.$http<Array<ActivityVersionDataModel>>({
			method: 'GET',
			url: url,
			loadingMessage: true,
		});
		const activities = this.convertApiActivities(response.data);

		return activities;
	}

	getVersionName(version): string {
		const label = `${version.versionName || version.versionNumber} ${version.isActive ? ' - active' : ''}`;
		return label;
	}

	convertApiActivities(newActivities): Array<ActivityVersionDataModel> {
		if (!newActivities) {
			return [noActivityPlaceholder];
		}
		const mappedNew = newActivities.map(activity => {
			if (!activity.versions) {
				activity.versions = [overallProgressVersion];
			}
			if (!activity.versions.find(v => v.id === overallProgressVersion.id)) {
				activity.versions = [overallProgressVersion, ...activity.versions];
			}
			activity.versions.forEach(version => (version.versionName = this.getVersionName(version)));
			return activity;
		});
		return mappedNew;
	}

	async getUserEmailTemplates(organisationId: number): Promise<Array<EmailTemplateModel>> {
		const uri = `${baseUri}/organisations/${organisationId}/emailTemplates`;
		const response = await this.$http.get<Array<EmailTemplateModel>>(uri);
		return response.data;
	}
	async getAllEmailTemplates(organisationId: number): Promise<Array<EmailTemplateModel>> {
		const uri = `${baseUri}organisations/${organisationId}/emailTemplates/list`;
		const response = await this.$http.get<Array<EmailTemplateModel>>(uri);
		return response.data;
	}

	async exportStatements(options: ExportStatementsOptions): Promise<any> {
		const uri = `${baseUri}/participants/reports/exportstatements`;
		return await this.$http({
			method: 'POST',
			url: uri,
			data: options,
		});
	}

	async getExportStatementsTemplates(organisationId: number): Promise<Array<ExportStatementsTemplateModel>> {
		const uri = `${baseUri}/organisations/${organisationId}/exportStatementsTemplates`;
		const response = await this.$http.get<Array<ExportStatementsTemplateModel>>(uri);
		return response.data;
	}

	async getPagedItems(
		itemsPerPage: number,
		pageCallback: (page: ApiListResponseModel<any>) => void = null,
		getPage: (currentPage: number, itemsPerPage: number) => Promise<IHttpResponse<ApiListResponseModel<any>>>
	): Promise<ApiListResponseModel<any>> {
		let totalPages = null;

		let currentPage = 1;
		const items = [];
		let totalResponse = new ApiListResponseModel();

		do {
			const pageResponse = await getPage(currentPage, itemsPerPage);
			const page = pageResponse.data;

			if (page) {
				if (typeof pageCallback === 'function' && pageCallback) {
					pageCallback(page);
				}
				currentPage++;

				items.push(...page.items);
				if (page.count) {
					totalPages = Math.ceil(page.count / itemsPerPage);
				}
				totalResponse = page;
			}
		} while (totalResponse && totalPages && currentPage <= totalPages);
		totalResponse.items = items;
		return totalResponse;
	}

	/** @deprecated method used for retrieving paged version of learning activities for reporting dropdown view has been converted to a flat call. Kept as usage example of paged query. */
	async getPagedActivitiesShort(
		options: LearningActivityApiQueryOptions,
		pageCallback: (page: ApiListResponseModel<any>) => void = null
	): Promise<Array<any>> {
		const response = await this.getPagedItems(
			options.itemsPerPage || 20,
			pageCallback,
			async (currentPage, itemsPerPage) => {
				return await this.getActivitiesPage(options, currentPage, itemsPerPage);
			}
		);
		return response.items;
	}

	async getActivitiesPage(options: LearningActivityApiQueryOptions, currentPage: number, itemsPerPage: number) {
		const builder = new JediQueryBuilder(null, null, {
			count: itemsPerPage,
			page: currentPage,
		});
		builder.filters.organisationId = options.filter.organisationId;
		builder.filters.showContainers = options.filter.showContainers;

		const query = builder.build(
			JediConfig.api.learning.activities.dropdownView(
				options.filter.organisationId,
				options.filter.showContainers
			)
		);

		const pageResponse = await this.$http<ApiListResponseModel<any>>({
			method: 'GET',
			url: query,
			loadingMessage: true,
		});
		return pageResponse;
	}
}
