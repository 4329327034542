/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-continue */
import * as logger from '~root/logger';

/**
 * @summary Graph component definitions and traversal algorithms
 */
export namespace Graph {
	export interface Node<T> {
		members?: Node<T>[];
		[key: string]: any;
	}

	export class Visitor<T> {
		node: T;

		parent?: T = null;

		depth: number;
	}

	export type NodeAction<T> = (node: T, depth: number, parent: T) => void;
	export type NodeCondition<T> = (node: T, depth: number, parent: T) => boolean;

	export interface TraversalOptions<T> {
		nodes: T[];
		getChildren: (node: T) => T[];
		action?: NodeAction<T>;
		condition?: NodeCondition<T>;
		getNodeKey: (n: T) => string;
		skipVisitedError?: boolean;
		throwOnActionError?: boolean;
	}

	/**
	 * max stack size limits traversal in case of self references
	 */
	const maxStackSize = 10000;

	/**
	 * @summary iterative postorder traversal of non binary tree which applies the provided function
	 * @param nodes root nodes
	 * @param getChildren function that returns the children of a node
	 * @param fn action applied on traversal to each node
	 * @param getNodeKey function that returns an unique identifier for each node
	 */
	export function traverseGeneric<T>(options: TraversalOptions<T>) {
		if (!options || !options.nodes || !options.action || !options.getChildren) {
			throw new Error('all parameters are required for traversal');
		}

		const { nodes, getNodeKey, getChildren, action } = options;

		const stack: Array<Visitor<T>> = [];
		const nodeMap = {};
		// eslint-disable-next-line no-return-assign
		nodes.forEach(node => (nodeMap[getNodeKey(node)] = node));
		Object.keys(nodeMap).forEach(key => stack.push({ node: nodeMap[key], depth: 0 }));
		const visited: { [key: string]: boolean; [key: number]: boolean } = {};
		while (stack.length > 0) {
			const item = stack.pop();
			const [node, depth, parent] = [item.node, item.depth, item.parent];
			const nodeKey = getNodeKey(node);
			if (visited[nodeKey]) {
				if (options.skipVisitedError) {
					continue;
				} else {
					throw new Error(`node ${nodeKey} already visited`);
				}
			}
			visited[nodeKey] = true;

			applyNodeAction(options, action, node, depth, parent);

			const children = getChildren(node);

			if (children?.length > 0) {
				if (stack.length + children.length > maxStackSize) {
					throw new Error(`graph traversal limit reached at node ${nodeKey}`);
				}
				const visitedNodes = children.filter(n => n && visited[nodeKey]);
				if (visitedNodes.length > 0) {
					logger.warn(`already visited: ${visitedNodes.map(n => getNodeKey(n)).join(',')}`);
				}

				children
					.filter(n => n && !visited[getNodeKey(n)])
					.forEach(n =>
						stack.push({
							parent: node,
							node: n,
							depth: depth + 1,
						})
					);
			}
		}
	}

	/**
	 * @summary iterative postorder traversal of non binary tree which applies the provided function	 *
	 */
	export function traverseForest<T extends Node<T>>(nodes: T[], fn: NodeAction<T>) {
		return traverseGeneric<T>({
			nodes,
			getChildren: n => n.members as T[],
			action: fn,
			getNodeKey: n => n.id,
		});
	}

	export function traverse<T extends Node<T>>(node: T, fn: NodeAction<T>) {
		return traverseGeneric<T>({
			nodes: [node],
			action: fn,
			getChildren: n => n.members as T[],
			getNodeKey: n => n.id,
		});
	}

	export function find<T extends Node<T>>(node: T, condition: NodeCondition<T>) {
		return findGeneric<T>({
			nodes: [node],
			condition,
			getChildren: n => n.members as T[],
			getNodeKey: n => n.id,
		});
	}

	/**
	 * @summary iterative postorder traversal of non binary tree which applies the provided function
	 * @param nodes root nodes
	 * @param getChildren function that returns the children of a node
	 * @param fn action applied on traversal to each node
	 * @param getNodeKey function that returns an unique identifier for each node
	 */
	export function findGeneric<T>(options: TraversalOptions<T>): { node: T; depth: number } {
		if (!options || !options.nodes || !options.condition || !options.getChildren) {
			throw new Error('all parameters are required for traversal');
		}

		const { nodes, getNodeKey, getChildren, condition } = options;

		const stack: Array<Visitor<T>> = [];
		const nodeMap = {};
		// eslint-disable-next-line no-return-assign
		nodes.forEach(node => (nodeMap[getNodeKey(node)] = node));
		Object.keys(nodeMap).forEach(key => stack.push({ node: nodeMap[key], depth: 0 }));
		const visited: { [key: string]: boolean; [key: number]: boolean } = {};
		while (stack.length > 0) {
			const item = stack.pop();
			const [node, depth, parent] = [item.node, item.depth, item.parent];
			const nodeKey = getNodeKey(node);
			if (visited[nodeKey]) {
				if (options.skipVisitedError) {
					continue;
				} else {
					throw new Error(`node ${nodeKey} already visited`);
				}
			}

			if (condition(node, depth, parent)) {
				return { node, depth };
			}

			visited[nodeKey] = true;

			const children = getChildren(node);

			if (children?.length > 0) {
				if (stack.length + children.length > maxStackSize) {
					throw new Error(`graph traversal limit reached at node ${nodeKey}`);
				}
				const visitedNodes = children.filter(n => n && visited[nodeKey]);
				if (visitedNodes.length > 0) {
					logger.warn(`already visited: ${visitedNodes.map(n => getNodeKey(n)).join(',')}`);
				}

				children
					.filter(n => n && !visited[getNodeKey(n)])
					.forEach(n =>
						stack.push({
							parent: node,
							node: n,
							depth: depth + 1,
						})
					);
			}
		}

		return { node: null, depth: -1 };
	}
}

function applyNodeAction<T>(
	options: Graph.TraversalOptions<T>,
	action: Graph.NodeAction<T>,
	node: T,
	depth: number,
	parent: T
) {
	if (!options.throwOnActionError) {
		action(node as T, depth, parent as T);
	} else {
		try {
			action(node as T, depth, parent as T);
		} catch (error) {
			logger.error(`unable to process node: ${node}`, error);
		}
	}
}
