import * as logger from '~root/logger';

const JediHelperComponent = {
	register: function (ngModule) {
		ngModule.provider('jediHelper', JediHelperService);
	},
};

export default JediHelperComponent;

const JediHelperService = function () {
	this.$get = [
		'$http',
		'$location',
		function ($http, $location) {
			this._$http = $http;
			this._$location = $location;
			return this;
		},
	];

	this.filterDate = function (date) {
		var newDate = new Date(date);
		newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
		return newDate;
	};

	this.doubleEach = function (collectionA, collectionB, callback) {
		if (
			typeof collectionA != 'undefined' &&
			typeof collectionB != 'undefined' &&
			collectionA.length &&
			collectionB.length
		) {
			collectionA.forEach(function (itemA) {
				collectionB.forEach(function (itemB) {
					callback(itemA, itemB);
				});
			});
		}
	};

	//#region CSV data export

	this.convertObjectArray = function (data, keys, columnDelimiter, lineDelimiter) {
		var result, ctr, keys, columnDelimiter, lineDelimiter;

		if (data == null || !data.length) {
			return null;
		}

		columnDelimiter = columnDelimiter || ',';
		lineDelimiter = lineDelimiter || '\n';

		keys = keys || Object.keys(data[0]);

		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) {
					result += columnDelimiter;
				}

				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	};

	this.downloadObjectArray = function (data, keys, filename, columnDelimiter, lineDelimiter, mimeType) {
		var csvData = this.convertObjectArray(data, keys, columnDelimiter, lineDelimiter);
		this.download(csvData, filename);
	};

	this.download = function (content, fileName, mimeType) {
		fileName = fileName || 'download.csv';
		const aLink = document.createElement('a');
		mimeType = mimeType || 'application/octet-stream';

		if (navigator.msSaveBlob) {
			// IE10
			return navigator.msSaveBlob(new Blob([content], { type: mimeType }), fileName);
		} else if ('download' in aLink) {
			// html5 A[download]
			aLink.href = 'data:' + mimeType + ',' + encodeURIComponent(content);
			aLink.setAttribute('download', fileName);
			document.body.appendChild(aLink);
			setTimeout(function () {
				aLink.click();
				document.body.removeChild(aLink);
			}, 66);
			return true;
		} else {
			// do iframe dataURL download (old ch+FF):
			const iframe = document.createElement('iframe');
			document.body.appendChild(iframe);
			iframe.src = 'data:' + mimeType + ',' + encodeURIComponent(content);

			setTimeout(function () {
				document.body.removeChild(iframe);
			}, 333);
			return true;
		}
	};

	//#endregion CSV data export
};
