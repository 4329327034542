import 'bootstrap/js/src/collapse';

import { IController, ILocationService, IScope } from 'angular';
import { ComponentRegistration } from '~root/commons';
import {
	JediNotificationMessageServiceRegistration,
	JediNotificationMessageService,
} from './JediNotificationMessagesService';
import { LocalizationProvider } from '../jediLocalization/jediLocalizationProvider';
import { NotificationMessageType, UserBaseModel, NotificationMessage } from '@base/models';
import { JediAuthService } from '../jediAuth/jediAuthService';

export const JediNotificationsComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('jediMaintenanceMessages', {
			controller: JediMaintenanceMessagesController,
			template: require('./jediMaintenanceMessages.html'),
			bindings: {
				showMessages: '<',
			},
		});
		JediNotificationMessageServiceRegistration.register(ngModule);
	},
	style: require('./style.scss'),
};

class JediMaintenanceMessagesController implements IController {
	public showMessages = false;

	systemMessages: NotificationMessage[];
	language: string;

	private user: UserBaseModel;

	static $inject = ['$scope', 'jediAuth', '$location', 'jediNotificationMessages', 'jediLocalization'];
	isPublic: boolean;
	constructor(
		private $scope: IScope,
		private jediAuth: JediAuthService,
		private $location: ILocationService,
		private jediNotificationMessages: JediNotificationMessageService,
		private jediLocalization: LocalizationProvider
	) {}

	async $onInit() {
		this.jediNotificationMessages.resetMessages();
		const path = this.$location.path();

		this.isPublic = path.toLowerCase().startsWith('/account');

		if (!this.isPublic) {
			this.jediAuth.getLoggedInUser().then(user => (this.user = user));
		}

		await this.displayMessages();
		this.$scope.$apply();
	}

	close() {
		this.showMessages = false;
		this.jediNotificationMessages.getUpdatedMessages().then(messages => {
			if (this.user) {
				this.jediNotificationMessages.updateStoredMessage(
					messages,
					NotificationMessageType.System,
					this.user.id
				);
			} else {
				this.jediNotificationMessages.updateStoredMessage(messages, NotificationMessageType.System);
			}
		});
	}

	filterMessages(messages) {
		const currentCulture = this.jediLocalization.getCurrentUiCulture();
		const filtered = messages.filter(message => !message.cultureId || message.cultureId === currentCulture);
		return filtered;
	}

	async displayMessages() {
		let messages: NotificationMessage[] = null;
		try {
			messages = await this.jediNotificationMessages.getUpdatedMessages();

			this.systemMessages = this.jediNotificationMessages.configureMessages(
				messages,
				NotificationMessageType.System
			);
		} catch (error) {
			this.handleError(error);
		}

		if (this.isPublic) {
			this.showMessages = this.shouldDisplayMessages();
			return;
		}

		if (this.user) {
			this.systemMessages = this.jediNotificationMessages.configureMessages(
				messages,
				NotificationMessageType.System,
				this.user.id
			);
			this.language = this.user.culture;
		}
		this.showMessages = this.shouldDisplayMessages();
	}

	private shouldDisplayMessages() {
		return (
			this.systemMessages &&
			this.systemMessages.length > 0 &&
			(this.$location.path() || '').toLowerCase().indexOf('player') === -1
		);
	}

	private handleError(error) {
		this.$location.path(`/error?message=${error}`);
	}
}
