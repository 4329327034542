/* eslint-disable class-methods-use-this */
import { AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
	LearningActivityFileUploadOptions,
	FileUploadResult,
	MediaResourceModel,
	PackageMediaResource,
	MediaResourceType,
} from '@base/models';
import { Subscription } from 'rxjs';
import { FileUploadComponent } from '../file-upload/file-upload.component';

let InstanceCounter = 0;

@Component({
	selector: 'app-file-edit',
	templateUrl: './file-edit.component.html',
	styleUrls: ['./file-edit.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => FileEditComponent),
			multi: true,
		},
	],
})
export class FileEditComponent implements ControlValueAccessor, OnInit, AfterViewInit {
	@Input()
	/**
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
	 */
	public accept: string = null;

	@Input()
	public resourceType: MediaResourceType = null;

	@Input()
	public uploadOptions: LearningActivityFileUploadOptions;

	@Input()
	public showFilename = true;

	@Input()
	public showOldFilename = false;

	@Input()
	public enableReset = false;

	public value: MediaResourceModel;

	@Input()
	public initialValue: MediaResourceModel;

	@ViewChild(FileUploadComponent) uploadComponent: FileUploadComponent;

	onChange: (media: MediaResourceModel | PackageMediaResource) => void;

	onTouched: any;

	isDisabled: boolean;

	errors: string[];

	warnings: string[];

	// model binding works only after ngAfterViewInit()
	isViewInitialized: boolean;

	isWarningVisible = false;

	id: number;

	subscription: Subscription = null;

	constructor() {
		// eslint-disable-next-line no-plusplus
		this.id = InstanceCounter++;
	}

	ngOnInit() {
		dev.log(`instance ${this.id} file-edit: init`);
	}

	ngAfterViewInit() {
		dev.log(`instance ${this.id} file-edit: afterViewInit`);
		this.isViewInitialized = true;
	}

	writeValue(obj: MediaResourceModel): void {
		// NOTE: binding might require this, however for "edit-activity > custom background" this is not working
		// if (!this.isViewInitialized) return;

		dev.log(`instance ${this.id} file-edit: writeValue`, obj);

		this.value = obj;
		if (!obj || this.value?.id !== obj?.id) {
			this.uploadComponent?.reset();
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	onUpload($event: FileUploadResult) {
		this.warnings = $event.validationWarnings;
		this.errors = $event.validationErrors;

		if (this.errors?.length) {
			return;
		}

		this.value = $event.resource;
		if ($event.metadata) {
			const resource: PackageMediaResource = { ...$event.resource, ...{ metadata: $event.metadata } };
			this.writeValue(resource);
			this.onChange(resource);
		} else {
			this.writeValue($event.resource);
			this.onChange($event.resource);
		}
	}

	reset() {
		this.value = this.initialValue;
		this.onChange(this.initialValue);
		this.errors = [];
		this.warnings = [];
		this.uploadComponent?.reset();
	}

	remove() {
		this.value = null;
		this.onChange(null);
		this.errors = [];
		this.warnings = [];
		this.uploadComponent?.reset();
	}

	showWarnings() {
		this.isWarningVisible = !this.isWarningVisible;
	}
}
