/* eslint-disable max-classes-per-file */
export enum ViewDisplayType {
	Default = 'Default',
	SimpleGrid = 'SimpleGridDisplay',
	ExtendedGrid = 'ExtendedGridDisplay',
}
export class DisplayModel {
	viewDisplay: ViewDisplayType = null;

	showNewFlag = true;

	showProgress = true;

	showDescription = true;

	showGenericInfo = true;
}

export class MasterDisplayModel extends DisplayModel {
	showFilter = true;

	showNavigationWidget = true;

	showSupportWidget = true;
}

export class DisplaySettings {
	masterDisplay: MasterDisplayModel = new MasterDisplayModel();

	childDisplay: DisplayModel = new DisplayModel();
}
