import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
	providedIn: 'root',
})
export class RouteParamsService {
	constructor(private uiRouter: UIRouter) {}

	params() {
		return this.uiRouter.globals.params;
	}
}
