const TablePagingComponent = {
	register: function(ngModule) {
		ngModule.directive('jediTablePaging', TablePagingController);
	}
};

export default TablePagingComponent;

const TablePagingController = [
	'$rootScope',
	function() {
		return {
			restrict: 'E',
			template: require('./ngTablePaging.html'),
			link: function(scope, element, attributes) {
				scope.changePage = function(page) {
					if (scope.pagingOnChange) {
						scope.pagingOnChange(page);
					}
				};
				if (!scope.pagingCounts) {
					scope.pagingCounts = scope.$parent.$parent.$parent.params.settings().counts;
				}
				scope.dropdownOptions = [];
				scope.selectedPageCounts = { text: '10', value: 10 };
				if (scope.selectedCount) {
					scope.selectedPageCounts = {
						text: '' + scope.selectedCount,
						value: scope.selectedCount
					};
				}
				for (var i = 0; i < scope.pagingCounts.length; i++) {
					scope.dropdownOptions.push({
						text: '' + scope.pagingCounts[i],
						value: scope.pagingCounts[i]
					});
				}
			},
			scope: {
				pagingCounts: '=counts',
				pagingOnChange: '=change',
				selectedCount: '=selected'
			}
		};
	}
];
