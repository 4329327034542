import * as angular from 'angular';
import { IHttpService, IPromise } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { FileUploadResult } from '@base/models';

export const FileUploadComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			// registrations
			.service('jediFileUpload', ['$http', FileUploadService]);
	},
};

export class FileUploadService {
	constructor(private $http: IHttpService) {}

	uploadFile(file: Blob, uploadUrl: string, method: 'PATCH' | 'POST' | 'PUT' = 'POST'): IPromise<FileUploadResult[]> {
		if (typeof file == 'undefined') {
			return Promise.reject<any>('file undefined');
		}
		if (!uploadUrl) {
			return Promise.reject<any>('uploadUrl required');
		}

		const fd = new window.FormData();

		fd.append('file', file);

		const requestConfig = {
			transformRequest: angular.identity.bind(this),
			headers: { 'Content-Type': undefined },
			loadingMessage: true,
		};

		if ((method || '').toLowerCase() == 'patch') {
			return this.$http.patch<FileUploadResult[]>(uploadUrl, fd, requestConfig).then(response => response.data);
		} else {
			return this.$http.post<FileUploadResult[]>(uploadUrl, fd, requestConfig).then(response => response.data);
		}
	}
}
