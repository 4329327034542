import Auth from 'app-legacy/admin/routeGuard';
import { JediConfig } from 'app-legacy/commons';

//define(['app'], function (app) {
const GenericComponent = {
	register: function (ngModule) {
		ngModule.controller('genericMainController', GenericController);
	},
	routeDef: {
		controller: 'genericMainController',
		template: require('./NotFound.html'),
		resolve: Auth.guard(),
	},
};

export default GenericComponent;

const GenericController = [
	'$scope',
	'$stateParams',
	'$http',
	'jediErrorHandler',
	'$log',
	function ($scope, $stateParams, $http, jediErrorHandler, $log) {
		$scope.message = 'This generic controller provides information about the current context';

		var parseObject = function (object) {
			var items = [];
			for (var prop in object) {
				// eslint-disable-next-line no-prototype-builtins
				if (object.hasOwnProperty(prop) && typeof object[prop] !== 'undefined') {
					if (typeof object[prop] === 'object') {
						items.push({ name: prop, value: parseObject(object[prop]) });
					} else {
						items.push({ name: prop, value: object[prop] });
					}
				}
			}
			return items;
		};
		$scope.data = { routeParams: $stateParams };
		$scope.info = parseObject($scope.data);

		$scope.tools = {
			throwError: function () {
				$http
					.get(JediConfig.api.base + 'services/error')
					.then(response => $log.info('api error caught ' + response.data))
					.catch(jediErrorHandler.handleError);
			},
			throwError500: function () {
				$http
					.get(JediConfig.api.base + 'services/error500')
					.then(response => $log.info('api error caught ' + response.data))
					.catch(jediErrorHandler.handleError);
			},
		};
	},
];
