<ng-container *ngIf="options$ | async as items" >
    <ng-container *ngIf="items && items.length">
    <div [ngClass]="{'with-border': selectType === 'form'}" >
        <app-dropdown-select [selectType]="selectType" [ngModel]="selectedOption" (ngModelChange)="onChangeSelection($event)">
            <app-dropdown-select-option 
                *ngFor="let option of items" 
                [value]="value(option)" 
                [viewValue]="viewValue(option)">
            </app-dropdown-select-option>
        </app-dropdown-select>
    </div>
    </ng-container>
</ng-container>