import { IHttpProvider, IRequestConfig, IHttpResponse, IHttpInterceptor } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { JediErrorHandlerService } from 'app-legacy/commons';
import { LoadingPanelService } from 'app-legacy/ajs-interceptors/jediLoadingPanel/jediLoadingPanelComponent';

export const JediAdminHttpComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.config([
			'$httpProvider',
			function ($httpProvider: IHttpProvider) {
				$httpProvider.interceptors.push('jediAdminHttpInterceptor');
			},
		]);

		ngModule.factory('jediAdminHttpInterceptor', [
			'jediLoadingPanel',
			'jediErrorHandler',
			(jediLoadingPanel: LoadingPanelService, jediErrorHandler: JediErrorHandlerService) => {
				const interceptor = new JediAdminHttpInterceptorFactory(jediLoadingPanel, jediErrorHandler);
				return interceptor.create();
			},
		]);
	},
	name: 'jediAdminAppHttpInterceptor',
};

export class JediAdminHttpInterceptorFactory {
	constructor(private jediLoadingPanel: LoadingPanelService, private jediErrorHandler: JediErrorHandlerService) {}
	create(): IHttpInterceptor {
		const jediLoadingPanel = this.jediLoadingPanel;
		const jediErrorHandler = this.jediErrorHandler;
		const interceptor: IHttpInterceptor = {
			request: function (config: IRequestConfig) {
				jediLoadingPanel.show();
				return config;
			},
			response: function (response: IHttpResponse<any>) {
				jediLoadingPanel.hide();
				return response;
			},
			responseError: function (rejection) {
				jediErrorHandler.handleError(rejection);
				return Promise.reject(rejection);
			},
		};
		return interceptor;
	}
}
