import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSharedModule } from 'app/admin/shared';

import { NotificationsModule } from '../notifications/notifications.module';
import { EditNotificationsComponent } from './edit-notifications/edit-notifications.component';

@NgModule({
	declarations: [EditNotificationsComponent],
	imports: [CommonModule, NotificationsModule, AdminSharedModule],
	providers: [],
})
export class OrganisationsModule {}
