import { IModule } from 'angular';
import { ComponentRegistration } from '~root/commons';

import LocalizeDirective from './jediLocalizeDirective';
import LocalizeFilter from './localizeFilter';
import { LocalizationProvider } from './jediLocalizationProvider';

const LocalizationComponent: ComponentRegistration = {
	register: function (ngModule: IModule) {
		ngModule
			//registrations
			.filter('localize', LocalizeFilter)
			.directive('jediLocalize', LocalizeDirective)
			.service('jediLocalization', LocalizationProvider);
	},
};

export default LocalizationComponent;
