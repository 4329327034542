import moment from 'moment';

import TinCan from '~root/vendor/tincanWrapper';
import { ComponentRegistration } from '~root/commons';

import { UserActivityProgressNode, UserActivityAuthorization, UserBaseModel } from '@base/models';

import serverSettings from '../jediServerSettings';
import { LrsCredentials } from 'models/LrsCredentials';

export const JediTinCanClientRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediTinCanClient', ['$q', JediTinCanClientService]);
	},
};

export class JediTinCanClientService {
	constructor(private $q) {}

	private moreLinksUsed;

	private createLRS(credentials: LrsCredentials) {
		const lrs = new TinCan.LRS({
			endpoint: credentials.tinCanEndpoint,
			username: credentials.tinCanEndpointKey,
			password: credentials.tinCanEndpointSecret,
			allowFail: false,
			version: '1.0.1',
		});
		return lrs;
	}

	query(credentials: LrsCredentials, settings) {
		const lrs = this.createLRS(credentials);
		lrs.queryStatements(settings);
	}

	more(credentials: LrsCredentials, settings) {
		const lrs = this.createLRS(credentials);
		lrs.moreStatements(settings);
	}

	sendStatement(credentials: LrsCredentials, statement): Promise<any> {
		const deferred = this.$q.defer();
		const lrs = this.createLRS(credentials);
		lrs.saveStatement(statement, {
			callback: function (error, result) {
				if (!error && result) {
					deferred.resolve(result);
				} else {
					deferred.reject(error);
				}
			},
		});
		return deferred.promise;
	}

	private queryMore(statements, lrs, credentials, more) {
		const def = this.$q.defer();
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		this.moreLinksUsed = [];
		const _queryMore = function (statements, lrs, credentials, more) {
			const continuation = '/' + serverSettings.ProxyLrsPath + more;
			lrs.moreStatements({
				url: continuation,
				callback: function (error, result) {
					if (result && result.statements) {
						statements = statements.concat(result.statements);
						if (result.more && self.moreLinksUsed.indexOf(result.more) < 0) {
							self.moreLinksUsed.push(result.more);
							_queryMore(statements, lrs, credentials, result.more);
						} else {
							def.resolve(statements);
						}
					} else {
						def.reject({ error, result });
					}
				},
			});
		};
		_queryMore(statements, lrs, credentials, more);
		return def.promise;
	}

	queryPromise(credentials: LrsCredentials, params) {
		const deferred = this.$q.defer();
		const lrs = this.createLRS(credentials);
		lrs.queryStatements({
			params: params,
			callback: function (error, result) {
				if (result && result.statements) {
					result.lrs = lrs;
					deferred.resolve(result);
				} else {
					deferred.reject(`error querying ${lrs}. reason: ${JSON.stringify(error)}`);
				}
			},
		});
		return deferred.promise;
	}

	queryUnpagedPromise(credentials: LrsCredentials, params) {
		const deferred = this.$q.defer();
		let statements = [];

		this.queryPromise(credentials, params).then(result => {
			statements = statements.concat(result.statements);
			if ((result.more || '').trim().length > 0) {
				this.queryMore(statements, result.lrs, credentials, result.more).then(
					deferred.resolve,
					deferred.reject
				);
			} else {
				deferred.resolve(statements);
			}
		}, deferred.reject);
		return deferred.promise;
	}

	async getStatementsForChildren(
		user: UserBaseModel,
		container: UserActivityProgressNode,
		authorization: UserActivityAuthorization
	) {
		const agent = new TinCan.Agent({
			account: new TinCan.AgentAccount({
				name: user.id,
				homePage: authorization.actorHomepage.replace(/\/$/, ''),
			}),
		});

		const statementsFrom = moment().subtract(serverSettings.StatementProcessingDelay, 'seconds').toJSON();

		const lrsCredentials = {
			tinCanEndpoint: container.lrsEndpoint,
			tinCanEndpointKey: authorization.authorizationKey,
			tinCanEndpointSecret: authorization.authorizationSecret,
		};

		const queryParams = {
			agent: agent,
			since: statementsFrom,
			format: 'ids',
			ascending: false,
		};

		const statements = await this.queryUnpagedPromise(lrsCredentials, queryParams);
		return statements;
	}
}
