import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JediCacheFactory } from 'app-legacy/commons';
import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	constructor(private cacheFactory: JediCacheFactory) {
		// 5 min cache timeout
	}

	get cache() {
		return this.cacheFactory.get();
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.method !== 'GET') {
			this.cache.removeAll();
			return next.handle(req);
		}
		const cachedResponse: HttpResponse<any> = this.getFromCache(req);
		if (cachedResponse && cachedResponse instanceof HttpResponse) {
			return of(cachedResponse.clone());
		}
		return next.handle(req).pipe(
			tap(stateEvent => {
				if (stateEvent instanceof HttpResponse) {
					this.setCache(req, stateEvent.clone());
				}
			}),
			share()
		);
	}

	private setCache(req: HttpRequest<any>, res: HttpResponse<any>) {
		const key = req.url;
		this.cache.put(key, res);
	}

	private getFromCache(req: HttpRequest<any>): HttpResponse<any> {
		const key = req.url;
		return this.cache.get(key);
	}
}
