import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	ContextManagerService,
	JediConfig,
	JediStatementGeneratorService,
	serverSettings,
	TinCanVerbState,
	VerbStates,
} from 'app-legacy/commons';
import { Operation, OperationResult, ParticipantModel, StatementDetails, UserBaseModel } from '@base/models';
import { AngularJsLogger } from 'app/ajs-logger';
import { DialogService } from 'app/admin/shared';
import { AdminService } from 'app-legacy/admin/commons';
import { ActivityVersionDataModel, ActivityVersionModel, UserActivityRelationModel } from 'app/admin/activities/models';
import { ParticipantEntry } from 'app-legacy/admin/models';

export interface GenerateStatementDialogComponentOptions {
	user: UserBaseModel;
	activity: ActivityVersionDataModel;
	version?: ActivityVersionModel;
	participant?: ParticipantEntry | ParticipantModel;
}

@Component({
	selector: 'app-generate-statement-dialog',
	templateUrl: './generate-statement-dialog.component.html',
	styleUrls: ['./generate-statement-dialog.component.scss'],
})
export class GenerateStatementDialogComponent implements OnInit {
	public user: UserBaseModel;

	public activity: ActivityVersionDataModel;

	public participant: UserActivityRelationModel;

	public version: ActivityVersionModel;

	public organisation: any;

	public rootContainer: any;

	public parentContainer: any;

	public isLoading: boolean;

	public activityTypeCourse: any;

	public versionDisplayName: any;

	public selectedState: any;

	public availableStates = [];

	isSaving = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: GenerateStatementDialogComponentOptions,
		private dialogRef: MatDialogRef<GenerateStatementDialogComponent>,
		private jediStatementGenerator: JediStatementGeneratorService,
		private jediContext: ContextManagerService,
		private logger: AngularJsLogger,
		private dialog: DialogService,
		private adminApi: AdminService
	) {
		this.user = data.user;
		this.activity = data.activity;
		this.version = data.version || {};
		this.organisation = this.jediContext.getCurrentContext().organisation;

		this.isLoading = true;
	}

	async ngOnInit(): Promise<void> {
		this.isLoading = true;
		const userActivityModel = await this.adminApi.getUserActivityRelationModel(
			this.user,
			this.activity,
			this.version
		);
		this.updateState(userActivityModel);
		this.isLoading = false;
	}

	public getViewValue(state: TinCanVerbState) {
		return state?.text || 'none';
	}

	private updateState(data: UserActivityRelationModel) {
		this.participant = data;
		this.rootContainer = data.rootContainer;
		this.parentContainer = data.parentContainer;
		this.version = data.version;
		this.activity = data.activity;

		if (this.activity.isActivity) {
			const { providerCategory } = this.version.provider;
			this.activityTypeCourse = JediConfig.tincan.tinCanProviderTypes[providerCategory];
			this.availableStates = [
				...JediConfig.tincan.tinCanProviderTypeSuccessStates[providerCategory],
				VerbStates.Attempted,
			];
			this.versionDisplayName =
				(this.version.versionName || this.version.versionNumber) + (this.version.isActive ? ' - active' : '');
		} else {
			this.activityTypeCourse = 'http://adlnet.gov/expapi/activities/module';
			if (this.activity.isQuiz) {
				this.availableStates = [VerbStates.Passed, VerbStates.Failed, VerbStates.Waived, VerbStates.Attempted];
			} else {
				this.availableStates = [VerbStates.Completed, VerbStates.Waived, VerbStates.Attempted];
			}
		}
		this.selectedState = angular.copy(this.availableStates[0]);
	}

	public cancel() {
		this.closeDialog(Operation.cancelled());
	}

	public async createStatement() {
		const result = await this.dialog.showConfirm({
			message: "This action can't be undone! Are you sure you want to generate statement?",
		});
		if (!result.confirmed) {
			this.closeDialog(Operation.cancelled());
			return;
		}

		if (this.isLoading) {
			this.logger.warn('still waiting to retrive learning activity details');
			return;
		}

		if (typeof this.organisation === 'undefined') {
			this.logger.warn('organisation data is required');
			return;
		}

		if (
			!this.participant?.id ||
			this.participant.id === JediConfig.defaults.emptyGuid ||
			!this.participant.authorization?.authorizationToken
		) {
			this.logger.warn('invalid participant, cannot generate statement');
			return;
		}
		// eslint-disable-next-line compat/compat
		const lrsEndpointUri = new URL(this.organisation.tinCanEndpoint);
		const lrsEndpoint = serverSettings.ProxyEndpoint + lrsEndpointUri.pathname + lrsEndpointUri.search;
		const lrsCredentials = this.jediStatementGenerator.getLrsCredentials(
			lrsEndpoint,
			this.participant.authorization.authorizationToken
		);
		try {
			this.isSaving = true;
			const statementDetails = this.createStatementDetails(this.participant.id);
			await this.jediStatementGenerator.sendStatement(lrsCredentials, statementDetails);
			this.logger.info('statement saved');
			this.closeDialog(Operation.success());
		} catch {
			this.logger.warn('failed to save statement');
			this.closeDialog(Operation.error('failed to save statement'));
		} finally {
			this.isSaving = false;
		}
	}

	private closeDialog(result: OperationResult) {
		this.dialogRef.close(result);
	}

	private createStatementDetails(participantId): StatementDetails {
		const details = new StatementDetails();
		details.isActivity = this.activity.isActivity;
		details.userId = this.user.id;
		details.participantId = participantId;
		details.actorHomepage = this.organisation.tinCanActorHomepage.replace(/\/$/, '');
		if (details.isActivity) {
			details.tinCanId = this.version?.lmsActivityId;
			details.container = {
				tinCanId: this.parentContainer?.tinCanId,
				name: this.parentContainer?.name,
			};
		} else {
			details.tinCanId = this.activity.containerTincanId;
		}
		details.activityName = this.activity.name;
		details.rootContainer = {
			tinCanId: this.rootContainer.tinCanId,
			name: this.rootContainer.name,
		};
		details.activityType = this.activityTypeCourse;
		details.state = this.selectedState;

		return details;
	}
}
