export interface UiLabel<T> {
	// field used in some legacy components (should be refactored out, only name should be kept)
	text?: string;
	name: string;
	value: T;
}

export class UiFilters {
	[key: string]: any;
}
export class ItemSearchModel {
	id?: string | null;
	name?: string;
	selected?: boolean | string;
	value?: any;
	label?: any;
	text?: string;
	rawValue?: number;
}

export class UiSearchItem extends ItemSearchModel {
	item?: ItemSearchModel[] | ItemSearchModel;
	initial?: any;
	tooltip?: string;
	[key: string]: any;
}

export class LabelModel {
	public text?: string;
	public value?: any;
}

export class CustomFieldLabel extends LabelModel {
	public property?: string;
}

export class ItemFilter {
	public item: {
		name: string;
	};
}

export function getApiQueryString(searchItem: UiSearchItem): string | string[] {
	if (!searchItem) return '';
	if (Array.isArray(searchItem.item)) {
		const values = searchItem.item
			.map(item => (item.value || '').trim())
			.filter(value => value && value.length > 0);
		if (values.length > 0) {
			return values;
		}

		const names = searchItem.item.map(item => (item.name || '').trim()).filter(name => name && name.length > 0);
		if (names.length > 0) {
			return names;
		}

		const ids = searchItem.item.map(item => (item.id || '').trim()).filter(id => id && id.length > 0);
		if (ids.length > 0) {
			return ids;
		}
	}
	const item: any = searchItem.item || searchItem;

	return item.id || item.name || item.value || item.label;
}

export function getUiSearchItemFromRouteQuery(input: string | Array<any>): UiSearchItem {
	const item = new UiSearchItem();

	if (input) {
		item.name = Array.isArray(input) ? input.join(',') : '' + (input || '');
	}
	return item;
}
