<app-file-edit
		[ngModel]="currentValue"
		[initialValue]="initialValue"
		(ngModelChange)="onUpload($event)"
		[showFilename]="true"
		[showOldFilename]="true"
		resourceType="certificate"
		accept=".docx"
		[enableReset]="true"
	>
</app-file-edit>
<p class="warning">
    Upload your Word (.docx) file. It can't have more than 2 pages. Please embed specific fonts you use.
</p>
    