import { JediClientStorageService, StorageType } from 'app-legacy/commons';
import { TablePagingOptions, TablePagingOptionsMap } from 'app/admin/shared';

export class JediTablePagerService {
	static $inject = ['jediClientStorage'];
	constructor(private storage: JediClientStorageService) {}

	public setCurrent(current: TablePagingOptions): void {
		const options = this.storage.getObject<TablePagingOptionsMap>('table-options', StorageType.LocalStorage) || {};
		const currentPath = window.location.pathname;
		options[currentPath] = current;
		this.storage.setObject('table-options', options, StorageType.LocalStorage);
	}

	public getCurrent(): TablePagingOptions {
		const options = this.storage.getObject<TablePagingOptionsMap>('table-options', StorageType.LocalStorage) || {};
		const currentPath = window.location.pathname;

		if (!options[currentPath]) {
			return { pageSize: 10 };
		}
		const currentOptions = options[currentPath];
		return currentOptions;
	}
}
