import * as angular from 'angular';

const JediHttpPoller = [
	function() {
		var self = this;

		this.$get = [
			'$q',
			'$log',
			'$interval',
			'$http',
			function($q, $log, $interval, $http) {
				self.$q = $q;
				self.$log = $log;
				self.$interval = $interval;
				self.$http = $http;
				return self;
			}
		];

		/**
		 * @callback pollingResolveCallback
		 * @param {Object} - the item retrieved by polling
		 * @returns {Boolean} - true if the polling was successfull and it should stop, false otherwise
		 */
		/**
		 * Wrapper object to provide details about the timeout polling mechanism
		 * @typedef HttpPollingPromise
		 * @param {Promise} timeoutPromise - the promise that will be used for resolving or rejecting the polling
		 * @param {Object} intervalId - the angular interval ID generated for polling
		 * @param {Number} milliseconds - the time interval used to poll
		 * @param {function} cancelInterval - function used to cancel the generated interval
		 * @param {Boolean} timedOut - state of the polled timeout
		 */

		/** Create a polling mechanism that can be used to check if another $http request has actually completed, when the server keeps it pending
		 * @param {Object} $scope - Angular $scope in which the poller is created, used for registering $interval removal
		 * @param {string} url - the URL used to poll for changes of the related $http request
		 * @param {function} funcResolveSuccess - the function used to evaluate successful completion of polling
		 * @param {number} [intervalTime=5000] - the interval used for polling
		 * @param {number} [maxPolls=0] - the maximum number of requests to trigger before rejecting, overrides @param funcResolveSuccess
		 * @returns {HttpPollingPromise} - information about the generated interval and timeout promise
		 */
		this.create = function($scope, url, funcResolveSuccess, intervalTime, maxPolls) {
			var MaxFailedPollingRequests = 5;
			var DefaultIntervalTime = 5000;

			var removeInterval = function($interval, intervalId) {
				if (angular.isDefined(intervalId)) {
					$interval.cancel(intervalId);
					intervalId = null;
				}
			};
			var timeoutDeferred = self.$q.defer();
			var errorCount = 0;
			var pollCount = 0;
			var intervalData: any = {
				timeoutPromise: {},
				intervalId: {},
				milliseconds: intervalTime || DefaultIntervalTime,
				hasTimedOut: false,
				hasError: false,
				error: '',
				_name: '',
				clearInterval: function() {
					removeInterval(self.$interval, intervalData.intervalId);
				}
			};
			var setError = function(errMessage) {
				intervalData.hasError = true;
				intervalData.error = errMessage;
			};

			intervalData.intervalId = self.$interval(function() {
				self.$http
					.get(url)
					.then(response => {
						let data = response.data;
						if (maxPolls > 0) {
							pollCount++;
							if (pollCount > maxPolls) {
								self.$log.debug('maximum poll count reached, rejecting timeout promise');
								intervalData.clearInterval();
								intervalData.hasError = true;
								intervalData.error = 'maximum poll count reached';
								timeoutDeferred.reject('maximum poll count reached');
								return;
							}
						}
						if (typeof funcResolveSuccess == 'function') {
							var result = funcResolveSuccess(data);
							if (result == true) {
								self.$log.debug('resolved polling for ' + intervalData._name);
								intervalData.clearInterval();
								intervalData.hasTimedOut = true;
								timeoutDeferred.resolve(data, 'polling timeout finished');
							} else {
								self.$log.debug('still polling for ' + intervalData._name);
							}
						}
					})
					.catch(() => {
						errorCount++;
						if (errorCount > MaxFailedPollingRequests) {
							intervalData.hasError = true;
							intervalData.error = 'max failed poll requests reached';
							self.$log.debug('rejected polling for ' + intervalData._name);
							intervalData.clearInterval();
							timeoutDeferred.reject('error count exceeds polling limit');
						}
					});
			}, intervalData.milliseconds);
			intervalData._name =
				'' + url + ' every ' + intervalData.milliseconds + ' ms, using iterval ID = ' + intervalData.intervalId.$$intervalId;
			self.$log.debug('started polling for ' + intervalData._name);
			intervalData.timeoutPromise = timeoutDeferred.promise;

			$scope.$on('$destroy', function() {
				intervalData.clearInterval();
			});
			return intervalData;
		};
	}
];

export default JediHttpPoller;
