import * as logger from '~root/logger';
import { IController, IWindowService } from 'angular';

import { ComponentRegistration } from '~root/commons';
import { JediCustomSettingsService, JediAuthService } from 'app-legacy/commons';

export const AccountMenuComponent: ComponentRegistration = {
	register: function (ngModule) {
		// ngModule.directive('jediAccountMenu', AccountMenuDirective);
		ngModule.component('jediAccountMenu', {
			controller: AccountMenuController,
			template: require('./jediAccountMenu.html'),
		});
	},
	name: 'jediAccountMenu',
};

class AccountMenuController implements IController {
	static $inject = ['$scope', 'jediAuth', 'jediCustomSettings', '$window'];
	user: any;
	manualLinks: { administrator?: string; operator?: string };

	showAccountMenu: boolean;
	// = serverSettings.EnableSingleSignOn
	showLogoff: boolean;
	showManualLink: boolean;
	manualLinkHref: string;

	constructor(
		private $scope,
		private jediAuth: JediAuthService,
		private jediCustomSettings: JediCustomSettingsService,
		private $window: IWindowService
	) {}

	async $onInit() {
		this.user = await this.jediAuth.getLoggedInUser();
		const settings = await this.jediCustomSettings.loadOrganisationCustomSettings();
		if (!settings) {
			return;
		}
		if (!settings.manualLinks) {
			logger.warn('manualLinks are not defined');
			return;
		}

		if (!settings.manualLinks.operator) {
			logger.warn('manual link for operator is not defined');
			return;
		}

		if (!settings.manualLinks.administrator) {
			logger.warn('administrator link for operator is not defined');
			return;
		}
		const role = this.user?.maxRole?.label;
		this.manualLinkHref = settings.manualLinks[role];
		this.$scope.$apply();
	}

	toggleMenu() {
		this.showAccountMenu = !this.showAccountMenu;
	}
	logout() {
		this.toggleMenu();
		this.$window.location.href = '/account/logout';
	}
}
