/* eslint-disable @typescript-eslint/no-throw-literal */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularJsLogger } from 'app/ajs-logger';
import { JediConfig, getErrorResponse } from 'app-legacy/commons';
import { HttpErrorRulesService } from './http-error-rules.service';

@Injectable()
export class ErrorHandlerHttpInterceptor implements HttpInterceptor {
	private baseUri = JediConfig.api.base;

	constructor(private logger: AngularJsLogger, private errorRulesService: HttpErrorRulesService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.startsWith(this.baseUri)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(catchError(error => this.handleError(req, error)));
	}

	handleError(request: HttpRequest<any>, error: HttpErrorResponse) {
		if (this.handleIgnoreRuled(request, error)) {
			return throwError(error);
		}

		if (request.responseType === 'blob' && error.error instanceof Blob) {
			return from(
				Promise.resolve(error).then(async x => {
					// eslint-disable-next-line @typescript-eslint/no-throw-literal
					const errorResponse: HttpErrorResponse = {
						message: x.message,
						name: x.name,
						ok: x.ok,
						type: x.type,
						error: JSON.parse(await x.error.text()),
						headers: x.headers,
						status: x.status,
						statusText: x.statusText,
						url: x.url ?? undefined,
					};
					this.displayErrorMessage(errorResponse);
					throw new HttpErrorResponse(errorResponse);
				})
			);
		}
		this.displayErrorMessage(error);

		// should always return a `throwError()`
		return throwError(error);
	}

	handleIgnoreRuled(request: HttpRequest<any>, error: HttpErrorResponse): boolean {
		const errorRules = this.errorRulesService.GetRules();
		return errorRules.some(rule => rule.rule(request, error) === true);
	}

	private displayErrorMessage(error: HttpErrorResponse) {
		if (error?.status === 401 || error?.status === 403) {
			// handled by other interceptors
		} else if (error?.status === 404) {
			this.logger.warn('No resouce found.');
		} else if (error?.status >= 500) {
			const response = error?.error || error;
			const message = getErrorResponse(response);
			// eslint-disable-next-line no-console
			console.error(message);
			this.logger.warn(
				'Internal error ocurred, please try again. If error persists, please contact a system administrator.'
			);
		} else if (error?.status === 0) {
			this.logger.warn('We were unable to send this request. Most likely you are offline.');
		} else {
			const response = error?.error || error;
			const message = getErrorResponse(response);
			this.logger.warn(message);
		}
	}
}
