/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Component,
	OnInit,
	ComponentFactoryResolver,
	Input,
	Type,
	ViewChild,
	ViewContainerRef,
	ComponentRef,
	Output,
	EventEmitter,
} from '@angular/core';

import { CultureModel, UiLocalizedContent, UiLocalizedModel } from '@base/models';

import { ContextManagerService } from 'app-legacy/commons';

import { LocalizedComponentHostDirective } from './localized-component-host.directive';
import { LocalizedComponent, LocalizedComponentState } from './ILocalizedComponent';

@Component({
	selector: 'app-localized-editor',
	templateUrl: './localized-editor.component.html',
	styleUrls: ['./localized-editor.component.scss'],
})
export class LocalizedEditorComponent<T> implements OnInit {
	cultures: Array<CultureModel>;

	selectedCulture: CultureModel;

	@Input()
	public localized = new UiLocalizedContent<T>();

	@Output()
	public changed = new EventEmitter<UiLocalizedContent<T>>();

	/**
	 * type of child component that will be rendered for the selected culture
	 */
	@Input()
	componentType: Type<any>;

	@ViewChild(LocalizedComponentHostDirective, { static: true }) componentHost: LocalizedComponentHostDirective;

	private componentFactory: any;

	private viewContainerRef: ViewContainerRef;

	private componentRef: ComponentRef<LocalizedComponent<T>>;

	private componentStateMap = new Map<CultureModel, LocalizedComponentState<T>>();

	constructor(
		private jediContext: ContextManagerService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		dev.log('localized-editor: onInit');
		const org = this.jediContext.getCurrentContext();
		this.cultures = org.organisation?.localizationSettings?.supportedCultures || [];

		this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentType);
		this.viewContainerRef = this.componentHost.viewContainerRef;
		// eslint-disable-next-line prefer-destructuring
		this.selectedCulture = this.cultures[0];

		this.createLocalizedComponent();
	}

	private createLocalizedComponent() {
		if (this.componentRef) {
			this.storeComponentState(this.componentRef.instance);
			this.componentRef.destroy();
			this.componentRef = null;
		}

		const localization = this.getCurrentLocalizedData();
		this.viewContainerRef.clear();

		this.componentRef = this.viewContainerRef.createComponent<LocalizedComponent>(this.componentFactory);
		if (!this.componentRef?.instance) {
			return;
		}

		dev.log('create localized component with data', localization);
		this.restoreComponentState(this.componentRef.instance, localization);

		this.componentRef.instance.registerOnChange(this.onChildDataChanged.bind(this));
	}

	private storeComponentState(component: LocalizedComponent<T>) {
		if (!component) {
			return;
		}
		const state = component.getState();
		this.componentStateMap.set(state.culture, state);
	}

	private restoreComponentState(component: LocalizedComponent<T>, localization: UiLocalizedModel<T>) {
		if (!this.componentStateMap.has(localization.culture)) {
			const defaultValue = localization.data ?? component.getDefaultState();
			this.componentStateMap.set(localization.culture, {
				currentValue: defaultValue,
				initialValue: defaultValue,
				disabled: false,
				culture: localization.culture,
			});
		}

		const componentState = this.componentStateMap.get(localization.culture);

		component.setState(componentState);
	}

	private getCurrentLocalizedData(): UiLocalizedModel<T> {
		const cultureId = this.selectedCulture?.id;
		if (!cultureId) {
			return null;
		}

		if (!this.localized) {
			return null;
		}

		if (!this.localized.get(cultureId)) {
			this.localized.set(cultureId, { culture: this.selectedCulture, enabled: true, data: null });
		}
		return this.localized.get(cultureId);
	}

	private onChildDataChanged($event: UiLocalizedModel<T>) {
		if (!this.localized) {
			this.localized = new UiLocalizedContent<T>();
		}

		this.localized.set($event.culture?.id, $event);

		this.changed.emit(this.localized);
	}

	selectCulture(culture: CultureModel) {
		this.selectedCulture = culture;
		this.createLocalizedComponent();
	}
}
