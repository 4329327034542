import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './auth.interceptor';
import { ErrorHandlerHttpInterceptor } from './error-handler.interceptor';
import { JsonDateParserInterceptor } from './json-date-parser.interceptor';
import { CacheInterceptor } from './cache.interceptor';
import { HttpErrorRulesService } from './http-error-rules.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
		// // XSRF isn't used due to API and SPA being on different domains
		// HttpClientXsrfModule.withOptions({
		// 	cookieName: 'XSRF-TOKEN',
		// 	headerName: 'X-XSRF-TOKEN',
		// }),
	],
	providers: [
		HttpErrorRulesService,
		// declaring order of HttpInterceptors will be the same as processing order
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerHttpInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JsonDateParserInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
	],
})
export class CoreModule {}
