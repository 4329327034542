import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DropdownSelectType } from './types';

/**
 * Wrapper for dropdown adaptation taken from http://prideparrot.com/blog/archive/2019/3/how_to_create_custom_dropdown_cdk
 */
@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => DropdownComponent),
			multi: true,
		},
	],
})
export class DropdownComponent<T> implements OnInit, ControlValueAccessor {
	@Input() public options: Observable<T[]> | T[];

	options$: Observable<T[]>;

	@Input() public selectType: DropdownSelectType = 'default';

	@Input() public disabled = false;

	selectedOption: any;

	@Input() public value: (item: T) => any = item => item;

	@Input() public viewValue: (item: T) => any = item => (item as any)?.name || (item as any)?.text || 'none';

	private onChangeFn: (value: any) => void = () => {};

	ngOnInit(): void {
		if (typeof (this.options as Observable<T[]>).subscribe === 'function') {
			this.options$ = this.options as Observable<T[]>;
		} else {
			this.options$ = of(this.options as T[]);
		}
	}

	writeValue(obj: any): void {
		this.selectedOption = obj;
	}

	onChangeSelection($event: any) {
		this.selectedOption = $event;
		if (typeof this.onChangeFn === 'function') {
			this.onChangeFn($event);
		}
	}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	onTouchFn: () => void = () => {};

	registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
