/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file */
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JediConfig, toQueryString } from 'app-legacy/commons';
import { LearningActivityFileUploadOptions, FileUploadResult, MediaResourceType } from '@base/models';
import { map } from 'rxjs/operators';

@Injectable()
export class FileUploadService {
	private baseUri = `${JediConfig.api.base.replace(/\/$/, '')}/upload`;

	constructor(private http: HttpClient) {}

	public upload(
		file: File,
		resourceType: MediaResourceType,
		learningActivityOptions?: LearningActivityFileUploadOptions
	) {
		const formData = new FormData();
		const uri = this.computeUri(resourceType, learningActivityOptions);

		formData.append(file.name, file);

		return this.http
			.post<FileUploadResult[]>(uri, formData, {
				reportProgress: true,
				observe: 'events',
			})
			.pipe(
				map(e => {
					if (e.type !== HttpEventType.Response) {
						return e as HttpEvent<FileUploadResult>;
					}
					const responseBody = e.body ? e.body[0] : null;
					const clone = e.clone({ body: responseBody });
					return clone;
				})
			);
	}

	private computeUri(resourceType: MediaResourceType, learningActivityOptions?: LearningActivityFileUploadOptions) {
		if (learningActivityOptions != null) {
			return `${this.baseUri}/activity-content${toQueryString(learningActivityOptions)}`;
		}
		if (resourceType === 'certificate') {
			return `${this.baseUri}/certificate`;
		}
		return this.baseUri;
	}
}
