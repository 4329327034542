import { Component, OnInit, Inject } from '@angular/core';
import { AngularJsLogger } from 'app/ajs-logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProgressReportEntry } from 'app-legacy/admin/models';
import { BaseActivityModel, Operation, OperationResult } from 'models';
import { UserActivationState } from 'app-legacy/admin/modules/users/UserActivationStateEnum';
import { IssueRevokeCertificatesDialogComponentOptions } from './IssueRevokeCertificatesDialogComponentOptions';
import { CertificatesLogicService } from '../certificates-logic.service';

export type UiStateAction = 'issue' | 'revoke' | 'reissue';

export interface UiStateCertificateCreation {
	action: UiStateAction;
	activity: BaseActivityModel;
	selection: UserProgressReportEntry[];
	toIssue: UserProgressReportEntry[];
	toRevoke: UserProgressReportEntry[];
	toReissue: UserProgressReportEntry[];
}

export function getCertificateCreationState(
	activity: BaseActivityModel,
	users: UserProgressReportEntry[]
): OperationResult<UiStateCertificateCreation> {
	if (!activity) return Operation.error('no activity selected');
	if (!users || users.length < 1) return Operation.error('no users selected');
	if (!activity.isCertificateEnabled) {
		return Operation.error('certificates must be enabled for activity');
	}
	if (!users.every(u => u.user.activationState === UserActivationState.Active)) {
		return Operation.error('all selected users must be active');
	}
	const state: UiStateCertificateCreation = {
		action: 'issue',
		activity,
		selection: users,
		toIssue: users.filter(u => !u.certificate),
		toReissue: users.filter(u => u.certificate?.state === 'Expired' || u.certificate?.state === 'Revoked'),
		toRevoke: users.filter(u => u.certificate?.state === 'Issued'),
	};
	if (state.toReissue.length === users.length) {
		state.action = 'reissue';
		return Operation.success(state);
	}
	if (state.toIssue.length === users.length) {
		state.action = 'issue';
		return Operation.success(state);
	}
	if (state.toRevoke.length === users.length) {
		state.action = 'revoke';
		return Operation.success(state);
	}
	return Operation.error('not all users are in the same state');
}

function isDate(input): boolean {
	if (!input) return false;
	try {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const temp = new Date(input);
		return true;
	} catch (e) {
		return false;
	}
}

@Component({
	selector: 'app-issue-certificates-dialog',

	templateUrl: './issue-revoke-certificates-dialog.component.html',

	styleUrls: ['./issue-revoke-certificates-dialog.component.scss'],
})
export class IssueRevokeCertificatesDialogComponent implements OnInit {
	selectedUsers: UserProgressReportEntry[];

	activity: BaseActivityModel;

	selectedIssueDate: Date = new Date();

	uiState: UiStateCertificateCreation;

	action?: UiStateAction;

	uiError: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IssueRevokeCertificatesDialogComponentOptions,
		private dialogRef: MatDialogRef<IssueRevokeCertificatesDialogComponentOptions>,
		private certificatesLogic: CertificatesLogicService,
		private logger: AngularJsLogger
	) {
		// empty
	}

	ngOnInit(): void {
		this.selectedUsers = this.data.participants;
		this.activity = this.data.activity;

		const stateResult = getCertificateCreationState(this.activity, this.selectedUsers);

		if (stateResult.isSuccess()) {
			this.uiState = stateResult.value as UiStateCertificateCreation;
			this.action = this.uiState.action;
		} else if (stateResult.isError()) {
			this.uiError = stateResult.message;
		}
	}

	isChangeEnabled() {
		return this.activity.isCertificateEnabled && this.uiState && !this.uiError;
	}

	cancel() {
		this.dialogRef.close();
	}

	async issueRevokeCertificates(): Promise<void> {
		if (!this.uiState || this.uiError) {
			return;
		}
		if (this.uiState.action === 'issue') {
			if (!isDate(this.selectedIssueDate)) {
				this.logger.warn('please select issue date');
				return;
			}
			await this.certificatesLogic.issueCertificate(this.activity, this.uiState.toIssue, this.selectedIssueDate);
		}
		if (this.uiState.action === 'reissue') {
			if (!isDate(this.selectedIssueDate)) {
				this.logger.warn('please select issue date');
				return;
			}
			await this.certificatesLogic.reissueCertificates(
				this.activity,
				this.uiState.toReissue,
				this.selectedIssueDate
			);
		}
		if (this.uiState.action === 'revoke') {
			await this.certificatesLogic.revokeCertificates(this.activity, this.uiState.toRevoke);
		}
		this.logger.info('operation completed successfully');
		this.dialogRef.close();
	}
}
