import { Component, OnInit, Inject } from '@angular/core';
import { AngularJsLogger } from 'app/ajs-logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProgressReportEntry } from 'app-legacy/admin/models';
import { UiActivityEditModel } from 'app/admin/activities/models';
import { SendCertificatesDialogComponentOptions } from './SendCertificatesDialogComponentOptions';
import { CertificatesLogicService } from '../certificates-logic.service';

@Component({
	selector: 'app-send-certificates-dialog',

	templateUrl: './send-certificates-dialog.component.html',

	styleUrls: ['./send-certificates-dialog.component.scss'],
})
export class SendCertificatesDialogComponent implements OnInit {
	selectedUsers: UserProgressReportEntry[];

	applicableUsers: UserProgressReportEntry[];

	activity: UiActivityEditModel;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SendCertificatesDialogComponentOptions,
		private dialogRef: MatDialogRef<SendCertificatesDialogComponent>,
		private certificatesLogic: CertificatesLogicService,
		private logger: AngularJsLogger
	) {
		// empty
	}

	ngOnInit(): void {
		this.selectedUsers = this.data.users;
		this.activity = this.data.activity;
		this.applicableUsers = this.data.users.filter(u => u?.certificate?.state === 'Issued');
	}

	enableSendCertificates(): boolean {
		return this.applicableUsers.length > 0 && this.activity.isCertificateEnabled;
	}

	cancel() {
		this.dialogRef.close();
	}

	send() {
		const users = this.applicableUsers.map(e => e.user);
		this.certificatesLogic.sendCertificatesForUsers(+this.data.activity.id, users).then(() => {
			this.dialogRef.close();
			this.logger.info('certificate e-mails queued successfully');
		});
	}
}
