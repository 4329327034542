import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { AdminSharedModule } from 'app/admin/shared';

import { OverviewNotificationsComponent } from './overview-notifications/overview-notifications.component';
import { EditNotificationsDialogComponent } from './edit-notifications-dialog/edit-notifications-dialog.component';
import { NotificationsDialogService } from './notifications-dialog.service';
import { NotificationsLogicService } from './notifications-logic.service';
import { NotificationsOrganisationUriService } from './notifications-organisation-uri.service';
import { NotificationsLearningUriService } from './notifications-learning-uri.service';

@NgModule({
	declarations: [OverviewNotificationsComponent, EditNotificationsDialogComponent],
	imports: [CommonModule, MatDialogModule, FormsModule, MatTableModule, AdminSharedModule],
	exports: [OverviewNotificationsComponent],
	providers: [
		NotificationsLogicService,
		NotificationsDialogService,
		NotificationsOrganisationUriService,
		NotificationsLearningUriService,
	],
})
export class NotificationsModule {
	// empty
}
