import { Injectable } from '@angular/core';
import { IDropdownSelect } from './dropdown-select/IDropdownSelect';

@Injectable()
export class DropdownSelectService<T> {
	private _id = Math.trunc(10000 * Math.random());

	private select: IDropdownSelect<T>;

	public register(select: IDropdownSelect<T>) {
		if (this.select) {
			throw new Error('select already registered');
		}
		this.select = select;
	}

	public getSelect(): IDropdownSelect<T> {
		return this.select;
	}
}
