import { ensureUserAuthorization } from '../jediAuth/ensureUserAuthorization';

const RouteResolverRegistration = {
	register: function (ngModule) {
		//Must be a provider since it will be injected into module.config()
		ngModule.provider('routeResolver', RouteResolverService);
	},
};

export default RouteResolverRegistration;

const RouteResolverService = function () {
	this.$get = [];

	// create a config object for setting/getting the directories for the route dependencies
	this.routeConfig = (function () {
		var viewsDirectory = '',
			controllersDirectory = '',
			directivesDirectory = '',
			baseDirectory = '',
			permissionLevels = '';

		// set the directories for the different resource types
		var setBaseDirectories = function (baseDir, viewsDir, controllersDir, directivesDir) {
				baseDirectory = baseDir;
				viewsDirectory = viewsDir;
				controllersDirectory = controllersDir;
				directivesDirectory = directivesDir;
			},
			getViewsDirectory = function () {
				return viewsDirectory;
			},
			getControllersDirectory = function () {
				return controllersDirectory;
			},
			getDirectivesDirectory = function () {
				return directivesDirectory;
			},
			setPermissionLevels = function (levels) {
				permissionLevels = levels;
			},
			getPermissionLevels = function () {
				return permissionLevels;
			},
			getBaseDirectory = function () {
				return baseDirectory;
			};

		return {
			setBaseDirectories: setBaseDirectories,
			getControllersDirectory: getControllersDirectory,
			getViewsDirectory: getViewsDirectory,
			getDirectivesDirectory: getDirectivesDirectory,
			setPermissionLevels: setPermissionLevels,
			getPermissionLevels: getPermissionLevels,
			getBaseDirectory: getBaseDirectory,
		};
	})();

	this.route = (function (routeConfig) {
		// resolves a route and returns a route definition that will be used by the route provider to dinamically load resources
		var resolve = function (
			controllerName,
			moduleName,
			scriptDependencies,
			viewName,
			permissions,
			viewOverrideUrl,
			navigationSelector,
			resolvers,
			settings
		) {
			if (typeof controllerName == 'object') {
				var obj = controllerName;
				controllerName = obj.controller;
				moduleName = obj.module;
				scriptDependencies = obj.dependencies;
				viewName = obj.view;
				permissions = obj.permissions;
				viewOverrideUrl = obj.templateOverrideUrl;
				navigationSelector = obj.navigationSelector;
				resolvers = obj.resolve;
				settings = obj.settings;
			}
			controllerName = moduleName + controllerName.substring(0, 1).toUpperCase() + controllerName.substring(1);

			var routeDef: any = {};

			// .html file that will be set in the ng-view from index.html
			if ((viewOverrideUrl || '').trim().length > 0) {
				routeDef.templateUrl = viewOverrideUrl;
			} else {
				routeDef.templateUrl =
					routeConfig.getBaseDirectory() + moduleName + routeConfig.getViewsDirectory() + viewName + '.html';
			}

			// the controller that will be loaded and used
			routeDef.controller = controllerName + 'Controller';

			routeDef.settings = settings;
			routeDef.permissions = permissions;
			routeDef.name = controllerName;
			routeDef.reloadOnSearch = false;
			if (typeof navigationSelector != 'undefined') {
				routeDef.navigationSelector = navigationSelector;
			}

			// resolve the route by using the set dependencies
			if (typeof resolvers != 'undefined') {
				routeDef.resolve = resolvers || {};
			} else {
				routeDef.resolve = {};
			}

			routeDef.resolve.load = [
				'$q',
				'$rootScope',
				function ($q, $rootScope) {
					var dependencies = [
						//routeConfig.getBaseDirectory() + moduleName + routeConfig.getControllersDirectory() + controllerName + 'Controller.js'
					];

					if (scriptDependencies) {
						for (var i = 0; i < scriptDependencies.length; i++) {
							var dependancy = scriptDependencies[i];
							if (dependancy.indexOf('/') <= 0) {
								dependancy =
									routeConfig.getBaseDirectory() +
									moduleName +
									routeConfig.getControllersDirectory() +
									dependancy;
							}
							//dependencies.push(dependancy + '.js');
						}
					}
					return true;
				},
			];

			routeDef.resolve.loadAuthenticatedUser = ensureUserAuthorization(routeDef.permissions);

			return routeDef;
		};

		// require the given dependencies using require.js's `require` method
		function resolveDependencies($q, $rootScope, dependencies) {
			var defer = $q.defer();
			// require(dependencies, function () {
			//     defer.resolve();
			//     $rootScope.$apply();
			// });

			return defer.promise;
		}

		return {
			resolve: resolve,
			userAuthenticationFactory: ensureUserAuthorization,
		};
	})(this.routeConfig);
};
