import { Component, Input } from '@angular/core';
import { MediaResourceModel } from '@base/models';

@Component({
	selector: 'app-file-view',
	templateUrl: './file-view.component.html',
	styleUrls: ['./file-view.component.scss'],
})
export class FileViewComponent {
	@Input()
	resource: MediaResourceModel;

	@Input()
	enableDetails = false;

	@Input()
	placeholder = 'no file';

	@Input()
	title = '';

	showDetails = false;
}
