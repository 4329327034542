export enum ProgressState {
	None = 'None',
	NotStarted = 'NotStarted',
	Completed = 'Completed',
	Passed = 'Passed',
	Failed = 'Failed',
	Waived = 'Waived',
	Attempted = 'Attempted',
	Launched = 'Launched',
	Experienced = 'Experienced',
	Played = 'Played',
}

export const DefaultFinalStates: ProgressState[] = [
	ProgressState.Passed,
	ProgressState.Completed,
	/** From 'Waived' state can transition to 'Passed' or 'Completed' */
	// ProgressState.Waived,
	/** From 'Failed' state can transition to 'Passed' or 'Waived' */
	// ProgressState.Failed
];

export const AllStates: ProgressState[] = Object.keys(ProgressState).map(k => ProgressState[k]);

export class ParticipantModel {
	id?: string;

	creationDate?: string;

	lastActivityDate?: string;

	lastUpdate?: string;

	progressDate?: string;

	isOverallProgress?: boolean;

	progressState?: ProgressState = ProgressState.None;

	userId?: string;

	learningActivityVersionId?: number;
}
