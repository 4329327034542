/* eslint-disable @typescript-eslint/no-use-before-define */
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Type } from '@angular/core';

import { IDirectiveFactory } from 'angular';
import { ModuleRegistration, CreateModuleOptions, IRouteDefinition, ComponentRegistration } from './types';
import { camelize } from './helpers';

export function registerDowngradedComponents(ngModule: ModuleRegistration, settings: CreateModuleOptions) {
	if (settings.routes) {
		const stack = [...settings.routes];
		while (stack.length) {
			const current = stack.pop();
			if (current) {
				if (current.ngComponent) {
					createDowngradedComponentForRoute(ngModule, current);
				}

				if (current.children) {
					stack.push(...current.children);
				}
			}
		}
	}
}
const appPrefix = 'downgrade';

function createDowngradedComponentForRoute(ngModule: ModuleRegistration, route: IRouteDefinition) {
	if (!route.ngComponent) return;

	// note: `route.ngComponent.name` is the name of the Class type, which is mangled on optimized builds. It must not be used.

	const moduleName = ngModule.name.replace('.', '-');

	// if multiple routes use the same component, there should be unique registrations
	const routeName = route.path?.replace(/\/.*/gi, '').replace(/\?.*/gi, '');
	const component = `${moduleName}-${routeName}`.replace(/([A-Z])/g, '-$1');
	const htmlName = `${appPrefix}-${component}`.toLowerCase();

	const downgradedComponentName = camelize(htmlName.replace(/-/gi, ' '));
	dev.log(`registered downgraded component: ${downgradedComponentName}`);
	ngModule.directive(
		downgradedComponentName,
		downgradeComponent({ component: route.ngComponent }) as IDirectiveFactory
	);

	// eslint-disable-next-line no-param-reassign
	route.component = {
		routeDef: {
			template: `<${htmlName}></${htmlName}>`,
			resolve: route.resolve,
		},
	};
}

export function createDowngradedInjectable(name: string, angular10Injectable: Type<any>): ComponentRegistration {
	const registration: ComponentRegistration = {
		register(ngModule) {
			ngModule.factory(name, downgradeInjectable(angular10Injectable));
		},
	};
	return registration;
}
