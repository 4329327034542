/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import { ControlValueAccessor } from '@angular/forms';
import { CultureModel, UiLocalizedModel } from '@base/models';

export interface LocalizedComponentState<T> {
	disabled: boolean;

	culture: CultureModel;

	currentValue: T;

	initialValue: T;
}
let instanceCounter = 0;

export abstract class LocalizedComponent<T = any> implements ControlValueAccessor {
	protected id: number;

	culture: CultureModel;

	public currentValue: T;

	public initialValue: T;

	private onChange: ($event: UiLocalizedModel<T>) => void;

	disabled: boolean;

	constructor() {
		// eslint-disable-next-line no-plusplus
		this.id = instanceCounter++;
	}

	abstract getDefaultState(): T;

	public getState(): LocalizedComponentState<T> {
		return {
			disabled: this.disabled,
			initialValue: this.initialValue,
			currentValue: this.currentValue,
			culture: this.culture,
		};
	}

	public setState(value: LocalizedComponentState<T>) {
		if (!value) return;
		this.initialValue = value.initialValue;
		this.disabled = value.disabled;
		this.currentValue = value.currentValue;
		this.culture = value.culture;
	}

	abstract writeValue(obj: T): void;

	registerOnChange(fn: ($eventData: UiLocalizedModel<T>) => void): void {
		this.onChange = fn;
	}

	protected onChangeValue(newValue: T) {
		const localized: UiLocalizedModel = {
			culture: this.culture,
			data: newValue,
			enabled: !this.disabled,
		};
		this.onChange(localized);
	}

	onTouch: () => void = () => {};

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
