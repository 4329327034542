<div class="modal-header">
    <h3 class="modal-title">generate statement</h3>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group-sm col-md-8">
            <label>learning activity</label>
            <input type="text" class="form-control" readonly="readonly" value="{{activity.name}}" />
        </div>
    </div>
    <div class="row" *ngIf="activity.isActivity">
        <div class="form-group-sm col-md-8">
            <label>learning activity version</label>
            <input type="text" class="form-control" readonly="readonly" value="{{versionDisplayName}}" />
        </div>
    </div>
    <div class="row">
        <div class="form-group-sm col-md-8">
            <label>user</label>
            <input type="text" class="form-control" readonly="readonly" value="{{user.firstName}} {{user.lastName}}" />
        </div>
    </div>
	<div class="dropdown">
		<label>verb</label>
		<app-dropdown [disabled]="isLoading"
            [viewValue]="getViewValue"
            *ngIf="!isLoading" 
            [options]="availableStates"
            selectType="form"
            [(ngModel)]="selectedState">
        </app-dropdown>
	</div>
</div>
<div class="modal-footer">

    <button class="btn btn-primary big"
            (click)="createStatement()"
            [disabled] ="!rootContainer || isLoading || isSaving"
            >
        save
    </button>
    <button class="btn btn-secondary" (click)="cancel()" [disabled]="isLoading || isSaving">cancel</button>
</div>
