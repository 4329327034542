<div class="input-wrapper">
    <div class ="input"  #dropreference>
        <input #input 
        placeholder="Please select an option" 
        [disabled]="disabled" 
        [value]="displayText" 

        readonly 
        (click)="showDropdown()" 
        (blur)="onTouchedFn()"
        autocomplete="off"
        >
        <span class="dropdown-arrow" (click)="onDropMenuIconClick($event)">
           
        </span>

        <span class="dropdown-arrow-separator"></span>
        <app-dropdown-select-panel [reference]="dropreference" #dropdownComp>
            <div class="dropdown-options-container">
                <ng-content select="app-dropdown-select-option"></ng-content>
            </div>
        </app-dropdown-select-panel>
    </div>
</div>