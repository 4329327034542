import { IStorage } from './IStorage';
import { MozillaCookieStore } from './MozillaCookieStore';
export class CookieStorageAdapter implements IStorage {
	private mozLib = new MozillaCookieStore();
	get(key: string) {
		const raw = this.mozLib.getItem(key);
		return raw;
	}
	set(key: string, value: any, days?: number) {
		const raw = value;
		let expiration = days;
		if (days) {
			// convert days to seconds
			expiration = 24 * 60 * 60 * days;
		} else {
			expiration = Infinity;
		}
		return this.mozLib.setItem(key, raw, expiration, '/');
	}
	remove(key: string) {
		return this.mozLib.removeItem(key, '/');
	}
	clear() {
		return this.mozLib.keys().forEach(k => this.remove(k));
	}
	getKeys(keyFilter: string) {
		const keys = this.mozLib.keys().filter(item => item.includes(keyFilter));
		return keys;
	}
}
