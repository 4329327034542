import { Injectable } from '@angular/core';

import { DialogService } from 'app/admin/shared';
import { BaseActivityModel } from 'models';
import { UserProgressReportEntry } from 'app-legacy/admin/models';

import { SendCertificatesDialogComponent } from './send-certificates-dialog/send-certificates-dialog.component';
import { SendCertificatesDialogComponentOptions } from './send-certificates-dialog/SendCertificatesDialogComponentOptions';
import { UiActivityEditModel } from '../activities/models';
import { IssueRevokeCertificatesDialogComponent } from './issue-revoke-certificates-dialog/issue-revoke-certificates-dialog.component';
import { IssueRevokeCertificatesDialogComponentOptions } from './issue-revoke-certificates-dialog/IssueRevokeCertificatesDialogComponentOptions';

@Injectable()
export class CertificateDialogService {
	constructor(private dialog: DialogService) {
		// empty
	}

	openSendCertificatesDialog(activity: UiActivityEditModel, users: Array<UserProgressReportEntry>) {
		const results = this.dialog.open<SendCertificatesDialogComponent, SendCertificatesDialogComponentOptions>(
			SendCertificatesDialogComponent,
			{
				data: { activity, users },
			}
		);
		return results;
	}

	openIssueCertificatesDialog(activity: BaseActivityModel, users: Array<UserProgressReportEntry>) {
		const results = this.dialog.open<
			IssueRevokeCertificatesDialogComponent,
			IssueRevokeCertificatesDialogComponentOptions
		>(IssueRevokeCertificatesDialogComponent, { data: { activity, participants: users } });
		return results;
	}
}
