import { CategoryModel } from './CategoryModel';

export enum NotificationMessageType {
	Daily = 'daily',
	System = 'system',
	Regular = 'regular',
}

export class NotificationMessage {
	id: number;

	message: string;

	displayDate: Date;

	organisationId?: number;

	category: CategoryModel;

	cultureId: string;

	type: NotificationMessageType;
}
