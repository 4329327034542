export enum DisplayEnforePolicyEnum {
	ShowAll = 'ShowAll',
	ShowNonAccessibleItems = 'ShowNonAccessibleItems',
	HideNonAccessibleItems = 'HideNonAccessibleItems',
}

export enum ContainerPolicyEnum {
	Optional = 'Optional',
	XOutOf = 'XOutOf',
	MarkedMandatory = 'MarkedMandatory',
}

export enum AccessEnforcePolicyEnum {
	None = 'None',
	HardEnforce = 'HardEnforce',
}

export class ContainerPolicyModel {
	containerPolicy: ContainerPolicyEnum;
	accessEnforcePolicy: AccessEnforcePolicyEnum;
	displayEnforcePolicy: DisplayEnforePolicyEnum;
	requiredItemsCount: number;
}
