import JediHttpCsrfHandler from './jediHttpCsrfHandler';
import JediHttpInterceptor from './jediHttpInterceptor';
import JediHttpPoller from './JediHttpPoller';
import { JediCacheFactory } from './jediCacheFactory';

const HttpComponent = {
	register: function (ngModule) {
		ngModule
			// registrations
			.service('jediHttpCsrfHandler', JediHttpCsrfHandler)
			.provider('jediHttpInterceptor', JediHttpInterceptor)
			.provider('jediHttpPoller', JediHttpPoller)
			.service('jediCacheFactory', JediCacheFactory);
	},
};

export default HttpComponent;
