/* eslint-disable */
import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { IRouteDefinition, ModuleRegistration } from './types';
import { RouteStateNode } from './RouteStateNode';
import { convertHtmlTemplateToComponentName, graphTraverseInDepth } from './helpers';

const ModuleStateName = 'module';

export class UiRouterNgRouteConverter {
	private nodes = new Array<RouteStateNode>();

	constructor(private ngModule: ModuleRegistration, private base: string) {}

	public getNodes(): RouteStateNode[] {
		return this.nodes;
	}

	public getNode(stateName: string): RouteStateNode {
		return this.nodes.find(n => n.state.name === stateName);
	}

	public createStates(): Ng1StateDeclaration[] {
		const route: IRouteDefinition = {
			// path: this.base,
			path: `/${this.base || ''}/`.replace(/\/+/gi, '/'),
			module: this.ngModule,
		};

		const normalizedStates = this.normalizeRoutes([route]);

		graphTraverseInDepth(
			normalizedStates,
			g => g.children,
			node => {
				// required for children
				node.state = this.convertToState(node);

				node.fullUrl = getFullNodeUri(node);
				this.nodes.push(node);
			}
		);
		// routes contain redirect URIs, convert to redirect states:
		this.nodes.forEach(node => this.updateRedirectToState(node, this.nodes));

		const states = this.nodes.map(n => n.state);
		return states;
	}

	private updateRedirectToState(node: RouteStateNode, nodelist: RouteStateNode[]) {
		const redirectUri = node?.route?.redirectTo?.toLowerCase();
		if (!redirectUri) {
			return;
		}
		const redirectStates = nodelist.filter(n => n.fullUrl?.toLowerCase().endsWith(redirectUri));
		if (redirectStates.length === 1) {
			node.state.redirectTo = redirectStates[0].state.name;
			return;
		}

		if (!redirectUri.startsWith('/')) {
			if (node.children) {
				const rn = node.children.find(c => c.fullUrl?.toLowerCase().endsWith(redirectUri));
				// child = child || node.children.find(c => c.state?.name?.includes(redirectUri));
				if (rn) {
					node.state.redirectTo = rn.state?.name;
				}
			}
		}
		// else {
		// 	const rn = nodelist.find(n => n.state?.name.endsWith(redirectState));
		// 	if (rn) {
		// 		node.state.redirectTo = rn.state.name;
		// 	}
		// }
	}

	private normalizeRoutes(routes: Array<IRouteDefinition>): Array<IRouteDefinition> {
		const states = new Array<IRouteDefinition>();

		for (const route of routes) {
			const nr = { ...route };
			if (nr.children && nr.children.length) {
				nr.children = this.normalizeRoutes(nr.children);
			}

			if (nr.module) {
				const moduleChildRoutes = this.normalizeRoutes(nr.module.routes);
				nr.children = [...(nr.children || []), ...moduleChildRoutes];
			}

			states.push(nr);
		}

		return states;
	}

	private convertToState(node: RouteStateNode): Ng1StateDeclaration {
		const { route, parent } = node;

		let stateDef: Ng1StateDeclaration = {};

		if (route.component) {
			stateDef = this.createForComponent(node);
		} else if (route.module) {
			stateDef = this.createForModule(node);
		} else {
		}

		const stateName = this.getStateName(node);
		if (stateName) {
			stateDef.name = stateName;
		}
		const stateUrl = this.getStateUrl(node);
		if (stateUrl) {
			stateDef.url = stateUrl;
		}

		return stateDef;
	}

	private getStateUrl(node: RouteStateNode) {
		const parent = node.parent;
		let url = `/${node.route.path}`.replace(/\/+/gi, '/');
		if (parent?.state) {
			if (parent?.state.url?.endsWith(url)) {
				// parent is module, child is default/layout/base
				return null;
			}
			if (parent?.route?.module) {
				return null;
			}
		} else {
			// empty root state should not contain url
			// if (url === '/') return null;
		}

		// convert url parts to lowercase, except url parameters
		url = url
			.split('/')
			.map(p => (/}|\:/gi.test(p) ? p : p.toLowerCase()))
			.join('/');
		// if parent state ends in '/', current relative url starting with slash leads to invalid (url, state) mapping
		// full uri becomes '/parent//child' instead of '/parent/child'
		const parentUrl = parent?.fullUrl || parent?.state?.url || '';
		if (parentUrl.endsWith('/') && url.startsWith('/')) {
			url = url.substr(1);
		}
		return url;
	}

	private getStateName(node: RouteStateNode): string {
		const parent = node.parent;
		let name = '';

		if (node.route.module) {
			name = ngToStateName(node.route.module.name);
		} else {
			name = convertPathToStateName(node.route.path);
		}

		if (parent) {
			if (parent.state) {
				name = convertPathToStateName(`${parent.state.name}.${name}`);
			}
			if (parent.route?.module) {
				name = `${name}.${ModuleStateName}`;
			}
		}

		return name;
	}

	private createForComponent(node: RouteStateNode): Ng1StateDeclaration {
		const stateDef: Ng1StateDeclaration = {
			resolve: null,
		};
		const component = node.route?.component;

		if (!component?.routeDef) {
			return stateDef;
		}

		if (component.routeDef.resolve) {
			stateDef.resolve = { ...stateDef.resolve, ...component.routeDef.resolve };
		}

		if (typeof component.routeDef.template === 'string' && !component.routeDef.controller) {
			stateDef.component = convertHtmlTemplateToComponentName(component.routeDef.template);
		} else {
			stateDef.controller = component.routeDef.controller;
			stateDef.template = component.routeDef.template;
		}

		return stateDef;
	}

	private createForModule(node: RouteStateNode): Ng1StateDeclaration {
		const ngModule = node.route.module;
		const stateDef: Ng1StateDeclaration = {
			// abstract: true,
			name: convertPathToStateName(`${node.parent?.state.name || ''}.${ngToStateName(ngModule.name)}`),
		};

		stateDef.redirectTo = `${stateDef.name}.${ModuleStateName}`;

		return stateDef;
	}
}

function getFullNodeUri(node: RouteStateNode): string {
	let n = node;
	let uri = '';
	while (n) {
		uri = (n.state?.url || '') + uri;
		n = n.parent;
	}
	return uri;
}

function convertPathToStateName(routePath: string): string {
	// ui-router has 2 formats for uri parameters:
	// - '/base/:param/something'
	// - '/base/{param}/something'
	// both are equivalent, but second form allows regexp and restrictions:
	// - '/base/{param:int}/something'

	let converted = (routePath || '').toLowerCase();
	const pattern = /\{([^{}]*)\}/gi;
	if (pattern.test(converted)) {
		// remove uri parameter formats: '{param:int}' => '{param}'
		// eg: '/base/{param:int}/something/{param2:string}' => '/base/{param}/something/{param2}';
		converted.match(pattern).forEach(m => (converted = converted.replace(m, m.replace(/:.*\}/gi, '}'))));
	}
	// route params should be part of resulting state name
	converted = converted
		// '/base/{param}/something' => 'base-param.something'
		.replace(/\/\{|\/:/gi, '-')
		// remove parameter format
		.replace(/\{|\:|\}/gi, '')
		// convert slashes to dots '/' => '.'
		.replace(/\//gi, '.')
		// strip leading/trailing '.'
		.replace(/^\.+/gi, '')
		.replace(/\.+$/gi, '')
		// remove duplicates
		.replace(/\.+/gi, '.');
	// converted = uriToStateName(converted);
	return converted;
}
function ngToStateName(ngName: string): string {
	const name = (ngName || '')
		// only words after last dot (.)
		.replace(/.*\.(.*)/gi, '$1')
		.replace('module', '')
		// remove any duplicate "."
		.replace(/\.+/gi, '.');
	return name;
}
