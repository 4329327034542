/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

/**
 * adapted from https://medium.com/inato/expressive-error-handling-in-typescript-and-benefits-for-domain-driven-design-70726e061c86
 */
export interface IOperation {
	isSuccess(): boolean;
	isError(): boolean;
	isCancelled(): boolean;
}
export type OperationResult<T = any> = OperationError | OperationSuccess<T> | OperationCancelled;

export class OperationCancelled implements IOperation {
	isSuccess(): this is OperationSuccess {
		return false;
	}

	isError(): this is OperationError {
		return false;
	}

	isCancelled(): this is OperationCancelled {
		return true;
	}
}

export class OperationError implements IOperation {
	readonly message: string;

	constructor(message: string) {
		this.message = message;
	}

	isSuccess(): this is OperationSuccess {
		return false;
	}

	isError(): this is OperationError {
		return true;
	}

	isCancelled(): this is OperationCancelled {
		return false;
	}
}

export class OperationSuccess<T = any> {
	constructor(public value: T) {}

	isSuccess(): this is OperationSuccess<T> {
		return true;
	}

	isError(): this is OperationError {
		return false;
	}

	isCancelled(): this is OperationCancelled {
		return false;
	}
}
export const Operation = {
	success<T = any>(value: T = null) {
		return new OperationSuccess<T>(value);
	},
	error(message: string): OperationResult {
		return new OperationError(message);
	},
	cancelled() {
		return new OperationCancelled();
	},
};

// export function

// // export const success = (value: T): OperationResult<T> => new OperationSuccess<T>(value);
// export const
