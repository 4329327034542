import { Injectable } from '@angular/core';
import { BlockScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { DialogService } from 'app/admin/shared';

import { IntermediateCreateActivityDialogComponent } from './intermediate-create-activity/intermediate-create-activity.component';

@Injectable()
export class ActivityActionsService {
	constructor(private dialog: DialogService, private viewPortRuler: ViewportRuler) {
		// empty
	}

	showCreateActivityDialog() {
		this.dialog.open<IntermediateCreateActivityDialogComponent>(IntermediateCreateActivityDialogComponent, {
			scrollStrategy: new BlockScrollStrategy(this.viewPortRuler, document),
			panelClass: 'app-panel-dialog-md',
			hasBackdrop: true,
		});
	}
}
