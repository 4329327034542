const JediElementsComponent = {
	register: function (ngModule) {
		ngModule.directive('toggleElement', function () {
			return {
				restrict: 'A',
				link: function (scope, element, attributes) {
					$(element[0]).on('click', function () {
						$(attributes.toggleElement).toggle();
					});
				}
			};
		});

		ngModule.directive('hideElement', function () {
			return {
				restrict: 'A',
				link: function (scope, element, attributes) {
					$(element[0]).on('click', function () {
						$(attributes.hideElement).hide();
					});
				}
			};
		});

		ngModule.directive('toggleItem', function () {
			var toggleClassOpen = 'toggle-open';
			var toggleClassClosed = 'toggle-closed';
			var hide = function (element) {
				$(element).hide();
				$(element).addClass(toggleClassClosed);
				$(element).removeClass(toggleClassOpen);
			};
			function show(element, display?) {
				display = display || 'inline';
				$(element).css('display', display);
				$(element).addClass(toggleClassOpen);
				$(element).removeClass(toggleClassClosed);
			}
			function isOpen(element) {
				return $(element).is('.' + toggleClassOpen);
			}
			function isClosed(element) {
				return $(element).is('.' + toggleClassClosed);
			}
			function getTarget(scope, element, attributes) {
				var target = $(element[0])
					.parent()
					.find(attributes.toggleItem);
				if (!target || target.length != 1) {
					target = $(attributes.toggleItem);
				}
				return target;
			}
			return {
				restrict: 'A',
				link: function (scope, element, attributes) {
					var source = $(element[0]);
					if (attributes.toggleInitial == 'closed') {
						hide(getTarget(scope, element, attributes));
					} else if (attributes.toggleInitial == 'open') {
						show(getTarget(scope, element, attributes));
					}

					source.on('click', function () {
						var target = getTarget(scope, element, attributes);
						if (!target || target.length != 1) {
							return;
						}
						if (target.is('.' + toggleClassOpen)) {
							source.addClass(toggleClassOpen);
							source.removeClass(toggleClassClosed);
							hide(target);
						} else {
							source.addClass(toggleClassClosed);
							source.removeClass(toggleClassOpen);
							show(target);
						}
					});
				}
			};
		});
	}
};
export default JediElementsComponent;
