export default {
	configure: ['$provide',function($provide) {
		$provide.decorator('$exceptionHandler', [
			'$delegate',
			'$injector',
			function($delegate, $injector) {
				return function(exception, cause) {
					var $location = $injector.get('$location');
					var userSettings = $injector.get('jediUserSettings');
					userSettings.clearLocation();
					var originalLocation = $location.url();
					var params = [];
					if (exception) {
						params.push('exception=' + exception);
					}
					if (cause) {
						params.push('cause=' + cause);
					}
					if (originalLocation) {
						params.push('location=' + originalLocation);
					}
					$location.url('/error' + (params.length > 0 ? '?' : '') + params.join('&'));
					$delegate(exception, cause);
				};
			}
		]);
	}],
	register: function() {}
};
