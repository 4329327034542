import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ActivityHierarchyModule } from './activity-hierarchy/activity-hierarchy.module';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { PortalLayoutComponent } from './portal-layout/portal-layout.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SharedPortalComponentsModule } from '../shared-portal-components/shared-portal-components.module';

// TODO: since there are 2 separate things named "portal", this could be renamed to 'dashboard'

@NgModule({
	declarations: [FaqPageComponent, PortalLayoutComponent, ErrorPageComponent],
	imports: [CommonModule, ActivityHierarchyModule, SharedModule, SharedPortalComponentsModule],
})
export class ActivityPortalModule {}
