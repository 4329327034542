import { IHttpService } from 'angular';
import moment from 'moment';

import * as logger from '~root/logger';
import { ComponentRegistration, Graph } from '~root/commons';
import {
	UserActivityProgressNode,
	ViewDisplayType,
	UserActivityProgressUpdateModel,
	UserActivityAuthorization,
	ActivityProgress,
	ProgressState,
	AvailabilityDisplayPolicy,
	AvailabilitySettings,
	AvailabilityStatus,
} from '@base/models';

import { UserHierarchyMapperService } from './hierarchyMapper';
import { ProgressCalculationService } from './progressCalculation';
import { ActivityNavigationDetails } from './ActivityNavigationDetails';
import { getActivityNavigationDetails } from './activityNavigationService';
import { ActivityProgressMapper } from './ActivityProgressMapper';
import { JediAuthService } from '../jediAuth/jediAuthService';
import { JediTinCanClientService } from '../services/jediTinCanClient';
import { JediConfig } from '../config/config';
import { data } from 'jquery';

export const JediHierarchyRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediHierarchy', JediHierarchyServiceLogic);
	},
};
const progressCalculationService = new ProgressCalculationService();
const progressMapper = new ActivityProgressMapper();

export class JediHierarchyServiceLogic {
	static $inject = ['$http', 'jediAuth', 'userHierarchyMapper', 'jediTinCanClient'];
	constructor(
		private $http: IHttpService,
		private jediAuth: JediAuthService,
		private userHierarchyMapper: UserHierarchyMapperService,
		private jediTinCanClient: JediTinCanClientService
	) {}

	//#region caching

	private _hierarchyRoot: UserActivityProgressNode = null;
	private _hierarchyChangeTimestamp = null;
	private _hierarchyCheckTimestamp = null;
	private _hierarchyCheckDebounce = 300;

	// update the API provided user hierarchy with states for provided versions
	// updateModel - see ParticipantProgressUpdateModel API data model for definition
	private updateHierarchyProgressFromApi(
		hierarchy: UserActivityProgressNode,
		updateModel: UserActivityProgressUpdateModel
	) {
		let isChanged = false;
		const updates = updateModel.items;
		// update model contains only hierarchy leafs, progress needs to be propagated to containers
		if (updateModel.isUpdated && updates && updates.length > 0) {
			Graph.traverse(hierarchy, node => {
				const updated = updates.find(e => e.versionId === node.versionId);
				if (updated) {
					node.progress.progressState = updated.progressState;
					isChanged = true;
				}
			});
			if (isChanged) {
				this.updateHierarchyProgressModel(hierarchy);
			}
		}
		return isChanged;
	}

	private updateHierarchyProgressModel(node: UserActivityProgressNode) {
		// update all parent progress
		Graph.traverse(node, item => {
			if (!item.isActivity) {
				item.progress = progressCalculationService.calculate(item);
				progressMapper.updateProgressMapping(item, item.progress);
			}
		});
	}

	async getUserHierarchy(): Promise<UserActivityProgressNode> {
		const user = await this.jediAuth.loadUser();
		const hierarchy = await this.getHierarchy(user.organisation, user);
		return hierarchy;
	}

	private async getProgressUpdate(userId): Promise<UserActivityProgressUpdateModel> {
		const timestamp = moment.utc(this._hierarchyChangeTimestamp).format('YYYYMMDDHHmmss');
		const progressUri = JediConfig.api.learning.activities.progressChangedForUser(userId, timestamp);
		const response = await this.$http.get<UserActivityProgressUpdateModel>(progressUri, { cache: false });
		return response.data;
	}

	private async getApiHierarchy(userId): Promise<any[]> {
		const activitiesUri = JediConfig.api.learning.activities.itemsForUser(userId);
		try {
			const response = await this.$http.get(activitiesUri, { cache: true });
			return response.data as any[];
		} catch (error) {
			logger.error('No learning activities available', error);
			return [];
		}
	}

	//#region
	private async getHierarchy(organisation, user): Promise<UserActivityProgressNode> {
		const userId = user.id;
		if (!this._hierarchyRoot) {
			const apiHierarchy = await this.getApiHierarchy(userId);
			const hierarchy = this.userHierarchyMapper.convertUserHierarchy(organisation, apiHierarchy);
			this.updateHierarchyProgressModel(hierarchy);

			Graph.traverse(hierarchy, item => {
				item.availabilityStatus = this.getAvailabilityStatus(item.availabilitySettings);
				item.isAvailable = item.availabilityStatus !== 'AvailableSoon' && item.availabilityStatus !== 'Expired';

				const isVisibleByAvailability =
					item.isAvailable ||
					item.availabilitySettings.displayPolicy ===
						AvailabilityDisplayPolicy.VisibleNotAccessibleWhenUnavailable;
				item.visible = item.visible && isVisibleByAvailability;
			});

			this._hierarchyRoot = hierarchy;
			this._hierarchyChangeTimestamp = new Date();
			this._hierarchyCheckTimestamp = new Date();
			return this._hierarchyRoot;
		}

		const lastUpdateDiff = moment().diff(this._hierarchyCheckTimestamp, 'milliseconds');

		if (lastUpdateDiff < this._hierarchyCheckDebounce) {
			return this._hierarchyRoot;
		}
		logger.info(`update from API. last update diff: ${lastUpdateDiff}`);
		this._hierarchyCheckTimestamp = new Date();
		const update = await this.getProgressUpdate(userId);
		if (!update.isUpdated) {
			return this._hierarchyRoot;
		}
		const changed = this.updateHierarchyProgressFromApi(this._hierarchyRoot, update);
		if (changed) {
			this._hierarchyChangeTimestamp = new Date();
		}

		return this._hierarchyRoot;
	}

	private getAvailabilityStatus(availabilitySettings: AvailabilitySettings): AvailabilityStatus {
		if (!availabilitySettings.periodEnabled) {
			return 'None';
		}

		const startDate = availabilitySettings.startDate;
		const endDate = availabilitySettings.endDate;
		const currentDate = new Date();
		if (currentDate >= endDate) {
			return 'Expired';
		}
		if (currentDate < startDate) {
			return 'AvailableSoon';
		}
		if (currentDate >= startDate && currentDate <= endDate) {
			const daysLeft = moment(endDate).diff(moment(currentDate), 'days') + 1;
			if (daysLeft <= 1) {
				return 'LastDayAvailable';
			}
			if (daysLeft <= 5) {
				return 'FinalDaysAvailable';
			}
			return 'Available';
		}
		return 'None';
	}

	getNodeForCategory(category: string, rootArray: UserActivityProgressNode[]) {
		const normalizedCategory = (category || '').toLowerCase();
		const elements = rootArray.filter(node => {
			if (!node || !node.categories || !node.categories.length) {
				return false;
			}

			return node.categories.some(cat => cat.toLowerCase().includes(normalizedCategory));
		});

		if (elements.length > 0 && elements[0]) {
			return elements[0];
		}
		return null;
	}

	getBreadcrumbs(root, path) {
		let breadcrumbs = this.getHierarchyPath(root, path);
		if (breadcrumbs.length === 1 && breadcrumbs[0].name.trim().length === 0) {
			return null;
		}
		breadcrumbs = breadcrumbs.filter(item => item.isContainer);
		if (breadcrumbs.length > 1) {
			for (let i = 0; i < breadcrumbs.length; i++) {
				if (breadcrumbs[i].displaySettings.masterDisplay.viewDisplay === ViewDisplayType.ExtendedGrid) {
					if (i + 1 <= breadcrumbs.length - 1) {
						breadcrumbs[i + 1].notClickable = true;
						i++;
					}
				}
			}
		}
		return breadcrumbs;
	}

	getHierarchyNode(hierarchy: UserActivityProgressNode, path): UserActivityProgressNode {
		// trim starting and ending backslashes
		const ancestors = this.getHierarchyPath(hierarchy, path);
		if (!ancestors || !ancestors.length) {
			return null;
		}
		const item = ancestors[ancestors.length - 1];
		// Video and Documents should go their parent container
		if (item.providerCategory == 'VideoProviderType' || item.providerCategory == 'DocumentProviderType') {
			return ancestors[ancestors.length - 2];
		} else {
			return item;
		}
	}

	private getAncestors(node: UserActivityProgressNode, parts: string[]): UserActivityProgressNode[] {
		const ids = parts.map(a => a);
		let id = ids.shift();
		let currentNode: UserActivityProgressNode = null;
		let siblings = [node];
		const ancestors: UserActivityProgressNode[] = [];
		while (siblings && siblings.length) {
			currentNode = siblings.find(s => s.id == id);
			if (currentNode) {
				ancestors.push(currentNode);
				siblings = currentNode.members;
				id = ids.shift();
			} else {
				siblings = [];
			}
		}
		return ancestors;
	}

	// get the nodes in the "path" param as an array, by traversing a graph starting at "node"
	getHierarchyPath(node: UserActivityProgressNode, path): UserActivityProgressNode[] {
		const pathParts = splitUrlPath(path);
		return this.getAncestors(node, pathParts);
	}

	getLeafContentItems(node: UserActivityProgressNode): UserActivityProgressNode[] {
		const mappedActivities = [];
		Graph.traverse(node, item => {
			if (item.isActivity) {
				mappedActivities.push(item);
			}
		});
		return mappedActivities;
	}

	/**
	 * Get relative URIs for content items related to playerURI
	 * @param root Root Node
	 * @param playerUri Player URI for current node
	 */
	getCurrentLocationDetails(root: UserActivityProgressNode, playerUri: string): ActivityNavigationDetails {
		const details = getActivityNavigationDetails(this, this.userHierarchyMapper, root, playerUri);
		return details;
	}

	/**
	 * retrieve the first child that is not complete
	 * or null if all complete
	 * @param node Container
	 */
	getCurrentAccessibleChild(node: UserActivityProgressNode): UserActivityProgressNode {
		if (!node || node.inFinalState()) {
			return null;
		}

		const incompleteChildren = node.members.filter(c => !c.inFinalState());
		const ordered = incompleteChildren.sort((a, b) => (a.accessOrder > b.accessOrder ? 1 : -1));
		return ordered[0] || null;
	}

	/**
	 * retrieve the first child that is not yet started
	 * or null if all have 1 or more attempts
	 * or null when the container is in FinalState
	 * @param node Container
	 */
	getCurrentNotStartedChild(node: UserActivityProgressNode): UserActivityProgressNode {
		if (!node || node.inFinalState()) {
			return null;
		}
		// Temporary way of doing things until DEV-8397 s implemented
		const incompleteChildren = node.members.filter(c => c.progress.progressState === ProgressState.None);
		const ordered = incompleteChildren.sort((a, b) => (a.accessOrder > b.accessOrder ? 1 : -1));
		return ordered[0] || null;
	}

	/**
	 * aggregate container children with associated finished statements from LRS
	 */

	private async getChildrenStatements(container: UserActivityProgressNode) {
		const user = await this.jediAuth.loadUser();
		const resp = await this.$http.get<any>(JediConfig.api.learning.activities.userRootContainersAuth);
		const auth = new UserActivityAuthorization(resp.data.actorHomepage, resp.data.authorizationToken);

		const statements: any[] = await this.jediTinCanClient.getStatementsForChildren(user, container, auth);

		return statements;
	}

	async updateChildrenProgressFromLrs(container: UserActivityProgressNode): Promise<UserActivityProgressNode[]> {
		const statements = await this.getChildrenStatements(container);

		const newlyFinishedChildren = container.members
			.filter(child => !child.inFinalState())
			.map(child => ({
				content: child,
				statements: statements.filter(s => isStatementFor(s, child)),
			}))
			.filter(e => e.statements.length > 0);

		const updatedChildren = newlyFinishedChildren.map(entry => {
			entry.content.progress = entry.content.progress || new ActivityProgress();
			const progress = mapProgressFromStatements(entry.statements);
			if (progress) {
				entry.content.progress.progressState = progress;
			}
			return entry.content;
		});

		return updatedChildren;
	}
}

// split a relative url 'path' into an array
function splitUrlPath(path) {
	const trimmed = (path || '').replace(/^\/*/, '').replace(/\/*$/g, '');
	return trimmed.split('/');
}

function mapProgressFromStatements(statements: any[]): ProgressState {
	if (statements && statements.length > 0) {
		if (verbExistsInStatements(statements, ProgressState.Failed)) {
			return ProgressState.Failed;
		} else if (verbExistsInStatements(statements, ProgressState.Passed)) {
			return ProgressState.Passed;
		} else if (verbExistsInStatements(statements, ProgressState.Waived)) {
			return ProgressState.Waived;
		} else if (verbExistsInStatements(statements, ProgressState.Experienced)) {
			return ProgressState.Experienced;
		} else if (verbExistsInStatements(statements, ProgressState.Attempted)) {
			return ProgressState.Attempted;
		} else if (verbExistsInStatements(statements, ProgressState.Launched)) {
			return ProgressState.Launched;
		}
	}
	return null;
}

function verbExistsInStatements(statements: any[], progress: ProgressState): boolean {
	const lowerProgress = progress.toLowerCase();
	return statements.some(s => s.verb?.id?.toLowerCase().includes(lowerProgress));
}

function isStatementFor(statement, content: UserActivityProgressNode) {
	return statement?.context?.registration === content?.participantId && statement?.target?.id == content?.tinCanId;
}
