import { IController } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { MediaResourceModel } from '@base/models';

export const ViewResourceComponentResgistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			// registrations
			.component('appViewResource', {
				template: require('./view.html'),
				controller: ViewResourceController,
				bindings: {
					resource: '<',
					enableDetails: '<',
				},
			});
	},
	name: 'appViewResource',
	style: require('./style.scss'),
};

export class ViewResourceController implements IController {
	public resource: MediaResourceModel;
	public showDetails = false;
	public enableDetails = false;
}
