import * as angular from 'angular';
import { IController, ILogService, IScope } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { MediaResourceModel } from '@base/models';
import { JediConfig } from 'app-legacy/commons';
import { FileUploadService } from '../fileUploadService';

export const EditResourceComponentRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('appEditResource', {
			template: require('./view.html'),
			controller: EditResourceController,
			bindings: {
				resource: '=',
				extensions: '@',
				resourceType: '@',
				onUpdate: '&',
			},
		});
	},
	name: 'appEditResource',
	style: require('./style.scss'),
};

export class EditResourceController implements IController {
	// binding for input resources
	public resource: MediaResourceModel;
	// binding for output resource
	public onUpdate: ({ resource: MediaResourceModel }) => void;

	private defaultResource: MediaResourceModel;
	public extensions: string;
	public resourceType: string;
	public resourceFile: Blob;
	// callback from file-upload directive to clear the internal uploaded file
	public clearFile: () => void;
	private id = `file-resource-upload-${getRandomInt(2 << 16)}`;
	warnings: string[];

	static $inject = ['$scope', 'jediFileUpload', '$log'];
	constructor(private $scope: IScope, private jediFileUpload: FileUploadService, private $log: ILogService) {}

	public $onInit() {
		this.defaultResource = angular.copy(this.resource);
	}

	public emitUpdate() {
		if (typeof this.onUpdate === 'function') {
			this.onUpdate({ resource: this.resource });
		}
	}

	public getId() {
		return this.id;
	}
	private clear() {
		if (typeof this.clearFile === 'function') {
			this.clearFile();
		}
	}

	public async triggerUpload(file: Blob, clearFile: () => void) {
		if (typeof clearFile === 'function') {
			this.clearFile = clearFile;
		}
		const uri = this.resolveUri();
		const result = await this.jediFileUpload.uploadFile(file, uri);
		if (result && result.length > 0) {
			if (result.some(item => item.validationErrors && item.validationErrors.length > 0)) {
				const errors = result.reduce((a, i) => [...a, ...i.validationErrors], []);
				this.$log.warn(JSON.stringify(errors));
				this.clear();
			} else {
				this.warnings = result[0].validationWarnings;
				this.resource = angular.copy(result[0].resource);
			}
			this.emitUpdate();
			// angularjs has no $digest of async methods
			this.$scope.$apply();
		}
	}
	private resolveUri() {
		if (this.resourceType === 'certificate') {
			return JediConfig.api.utilities.uploadPathCertificate;
		}
		return JediConfig.api.utilities.uploadPath;
	}

	public reset() {
		this.resource = angular.copy(this.defaultResource);
		this.clear();
		this.emitUpdate();
	}

	public remove() {
		this.resource = null;
		this.clear();
		this.emitUpdate();
	}
}

function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max));
}
