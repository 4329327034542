import { ActivityVersionDataModel, ActivityVersionModel } from 'app/admin/activities/models';

const noVersionItem = new ActivityVersionModel();
noVersionItem.versionName = 'no versions';
const overallProgressVersion = { id: 'overall_progress', versionName: 'overall progress' };

const noActivityPlaceholder = new ActivityVersionDataModel();
noActivityPlaceholder.name = 'no learning activities';
noActivityPlaceholder.versions = [noVersionItem];

const emptyVersionItem = new ActivityVersionModel();
emptyVersionItem.versionName = '';
// const emptyActivityPlaceholder = { name: 'select activity', versions: [{ versionName: '' }] };
const emptyActivityPlaceholder = new ActivityVersionDataModel();
emptyActivityPlaceholder.name = 'select activity';
emptyActivityPlaceholder.versions = [noVersionItem];

export { noActivityPlaceholder, emptyActivityPlaceholder, overallProgressVersion };
