import { DisplaySettings } from './DisplaySettings';
import { LocalizationTable } from './LocalizationTable';
import { FileUploadModel } from './FileUploadModel';
import { MediaResourceModel } from './MediaResourceModel';
import { LrsCredentials } from './LrsCredentials';

export class OrganisationModel implements LrsCredentials {
	constructor(public id?: number) {}

	name?: string;

	categoryIndicatorMap: any;

	localizationSettings: LocalizationTable;

	emailAddress: string;

	emailDisplayName: string;

	notificationReceivers: string;

	shortName: string;

	activationState: any;

	defaultActivePeriod: any;

	customHostname: string;

	customContentFolder: string;

	defaultCulture: string;

	hasExternalIdentityProvider: boolean;

	enableSelfRegistration: boolean;

	enableAutoActivation: boolean;

	enableSelfRegistrationCustomFields: boolean;

	customField1Name: string;

	customField2Name: string;

	customField3Name: string;

	customField4Name: string;

	customField5Name: string;

	defaultCustomField: number;

	displaySettings: DisplaySettings = new DisplaySettings();

	portalCustomCSS: string;

	playerCustomCSS: string;

	portalLogo: MediaResourceModel;

	customBackground: FileUploadModel | MediaResourceModel;

	customPublicBackground: FileUploadModel | MediaResourceModel;

	parentId: number;

	organisationUnitCount: number;

	oUs: Array<any>;

	parentOrganisation?: OrganisationModel;

	tinCanEndpoint: string;

	tinCanEndpointKey: string;

	tinCanEndpointSecret: string;
}
