export class ContainerProgressState {
	completed: number = 0;
	passed: number = 0;
	failed: number = 0;
	waived: number = 0;
	none: number = 0;
	active: number = 0;

	get completionCount() {
		return this.completed + this.passed + this.waived;
	}
}
