import { GenericErrorModel } from './GenericErrorModel';
import { FieldErrorsModel } from './FieldErrorModel';
import { FieldErrorMap } from './FieldErrorMap';

export class ValidationResult {
	public hasErrors = false;

	public generic?: GenericErrorModel[] = [new GenericErrorModel()];

	public fields?: FieldErrorsModel[] | FieldErrorMap = new FieldErrorMap();

	public static NoError(): ValidationResult {
		return { hasErrors: false };
	}

	public static GenericError(message: string): ValidationResult {
		return { hasErrors: true, generic: [{ message }] };
	}

	public static FieldError(field: string, message: string): ValidationResult {
		return { hasErrors: true, fields: [{ field, errors: [message] }] };
	}
}
