import * as logger from '~root/logger';
import { ComponentRegistration } from '~root/commons';
import { MediaResourceModel, ProviderTypeEnum } from '@base/models';

import { ActivityProviderEditModel } from 'app/admin/activities/models';
import { IVideoPlayerEventHandler, VideoPlayerEventType } from './VideoPlayerEvent';
import { VideoPlayerOptions } from './VideoPlayerOptions';

export const VideoPlayerServiceRegistration: ComponentRegistration = {
	register: function (ngModule) {
		logger.debug('register VideoPlayerService in ' + ngModule.name);
		// the name registered here (case sensitive) must be used as the name of the parameter in service injection
		ngModule.factory('videoPlayerService', function () {
			return new VideoPlayerService();
		});
	},
};
export class VideoPlayerService {
	private handlers: IVideoPlayerEventHandler[] = [];

	public showPlayerForProvider(provider: ActivityProviderEditModel, title: string): VideoPlayerService {
		const options: VideoPlayerOptions = { uri: '', title: title };

		if (provider.providerType === ProviderTypeEnum.VideoFile) {
			options.uri = provider.activityContent.virtualPath;
		} else if (provider.providerType == ProviderTypeEnum.VideoVimeo) {
			options.uri = provider.tinCan.startupEndpoint;
		} else {
			throw new Error('unsupported provider');
		}
		return this.showPlayer(options);
	}
	public showPlayerForMedia(resource: MediaResourceModel, title: string): VideoPlayerService {
		const options: VideoPlayerOptions = { uri: resource?.virtualPath, title };
		return this.showPlayer(options);
	}

	public showPlayer(options: VideoPlayerOptions): VideoPlayerService {
		return this.trigger('onPlayerShow', options);
	}

	public closePlayer($event: any): VideoPlayerService {
		return this.trigger('onPlayerClose', $event);
	}

	public initVideo($event: any): VideoPlayerService {
		return this.trigger('onVideoInit', $event);
	}

	public playVideo($event: any): VideoPlayerService {
		return this.trigger('onVideoPlay', $event);
	}

	public pauseVideo($event: any): VideoPlayerService {
		return this.trigger('onVideoPause', $event);
	}

	public completeVideo($event: any): VideoPlayerService {
		return this.trigger('onVideoComplete', $event);
	}

	public error($event: Event): VideoPlayerService {
		return this.trigger('onError', $event);
	}

	public register(handler: IVideoPlayerEventHandler): void {
		logger.info('registered video player handler', handler);
		if (this.handlers.indexOf(handler) < 0) {
			this.handlers.push(handler);
		}
	}

	public unregister(handler: IVideoPlayerEventHandler): void {
		const idx = this.handlers.indexOf(handler);
		if (idx > -1) {
			this.handlers.splice(idx, 1);
			logger.info('unregistered video player handler', handler);
		}
	}

	private trigger(eventType: VideoPlayerEventType, eventData: any = null) {
		for (const handler of this.handlers) {
			if (typeof handler[eventType] === 'function') {
				logger.info(`video player service, event type: ${eventType} trigger handler`, handler);
				handler[eventType](eventData);
			}
		}
		return this;
	}
}
