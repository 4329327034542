<div class="tab-container">
    <div class="tab-item"
    [ngClass]="{selected: culture === selectedCulture}" 
    (click)="selectCulture(culture)"
    *ngFor="let culture of cultures" 
    >    
        <div >{{ culture.name}} </div>
    </div>
</div>

<ng-template appLocalizedComponentHost></ng-template>