<div class="instance-{{id}}">
<ng-container *ngIf="value && value.virtualPath && resourceType === 'image'">
	<div class="media-content" >
        <img [src]="value.virtualPath" />
    </div>
</ng-container>

<div class="view">

    <ng-container *ngIf="!value">
        <span>no file selected</span>
    </ng-container>

    <ng-container *ngIf="!showOldFilename && showFilename">
        <div *ngIf="value">
            <span>current file</span>
            <app-file-view [resource]="value"></app-file-view>
        </div>
    </ng-container>

    <ng-container *ngIf="showFilename && showOldFilename">
        <ng-container *ngIf="value && value.id === initialValue?.id">
            <div>
                <span>current file</span>
                <app-file-view [resource]="value"></app-file-view>
            </div>
        </ng-container>
    
        <div class="view-compare" *ngIf="value && value.id !== initialValue?.id">
            <div>
                <span>new file</span>
                <app-file-view [resource]="value"></app-file-view>
            </div>
            <div>
                <span>old file</span>
                <app-file-view [resource]="initialValue"></app-file-view>
            </div>
        </div>
    </ng-container>
</div>

<app-file-upload 
    [resourceType]="resourceType" 
    [uploadOptions]="uploadOptions" 
    (fileUploaded)="onUpload($event)"
    [accept]="accept"
     #uploadComponent>
            
     <button *ngIf="enableReset" class="btn btn-secondary" (click)="reset()">reset</button>
    <button class="btn btn-secondary" (click)="remove()">remove</button>
    <button *ngIf="warnings && warnings.length"class="btn btn-secondary" (click)="showWarnings()">{{isWarningVisible ? 'hide warnings' : 'show warnings'}}</button>
</app-file-upload>

<ng-container *ngIf="warnings && isWarningVisible">
    <p class="warning" *ngFor="let warn of warnings" >{{ warn }}</p>
</ng-container>

<ng-container *ngIf="errors">
    <p class="error" *ngFor="let error of errors">{{ error }}</p>
</ng-container>
</div>