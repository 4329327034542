import { LocalizationProvider } from './jediLocalizationProvider';
import { JediConfig } from '../config/config';

const JediLocalizeDirective = [
	'$rootScope',
	'jediLocalization',
	function ($rootScope, jediLocalization: LocalizationProvider) {
		function traverse(element, maxLevel, callback) {
			if (maxLevel > 0) {
				$(element)
					.children()
					.each(function (idx, child) {
						traverse(child, maxLevel - 1, callback);
					});
			}
			if ($(element).children().length == 0) {
				if (typeof callback == 'function') {
					callback(element, maxLevel);
				}
			}
		}
		var keyName = 'data-localization-key';
		return {
			restrict: 'A',
			replace: false,
			link: function (scope, element, attributes) {
				traverse(element, 5, function (e) {
					// this will set the attribute as the localization key, when localization is enabled,
					if (!$(e).attr(keyName)) {
						$(e).attr(keyName, ($(e).text() || '').trim());
					}
				});

				function applyLocalization(localeChange?) {
					traverse(element, 5, function (e) {
						var localizationKey = ($(e).attr(keyName) || '').trim();
						if (localizationKey.length < 1) {
							// single-character content is used as symbol, shouldn't be replaced
							return;
						}

						var localizedText = this.jediLocalization.getLocalizedText(localizationKey);
						if ((localizedText || '').trim().length > 1) {
							$(e).text(localizedText);
						} else if (localizationKey.indexOf('{{') > -1 && localizationKey.indexOf('}}') > -1) {
							//scope.eval works with angular expressions not with HTML syntax for angular expressions
							var ngText = scope.$eval(localizationKey.replace('{{', '').replace('}}', ''));
							$(e).text(ngText);
						}
					});
				}
				applyLocalization();
				$rootScope.$on(JediConfig.events.localeChange, (event, data) => {
					applyLocalization(data);
				});
			},
		};
	},
];
export default JediLocalizeDirective;
