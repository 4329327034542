import * as logger from '~root/logger';
export class UserActivityAuthorization {
	userId: string;
	learningActivityId: number;

	actorHomepage: string;
	authorizationToken: string;
	authorizationKey: string;
	authorizationSecret: string;

	constructor(actorHomepage: string, token: string) {
		this.actorHomepage = actorHomepage;
		this.authorizationToken = token;
		try {
			const tokenParts = atob(token).split(':');
			this.authorizationKey = tokenParts[0];
			this.authorizationSecret = tokenParts[1];
		} catch (error) {
			logger.error('unable to parse token', error);
		}
	}
}
