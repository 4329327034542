import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { createProvider } from 'app/ajs-upgraded-providers';
import { VideoPlayerService } from 'app-legacy/commons';

import { Ng1PortalComponentsModule } from 'app/portal/ng1ComponentsModule';
import { VideoPlayerContainerDirective } from './video-player/video-player.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TooltipPanelComponent } from './tooltip/tooltip-panel.component';
import { LocalizePipe } from './localization/localizePipe';
import { SafeUrlPipe } from './url-sanitize/safe-url-pipe';
import { SafeHtmlPipe } from './html-sanitize/safe-html-pipe';
import { BroadcastBridgeService } from './broadcast-bridge.service';
import { NgInitDirective } from './ng-init/ng-init.directive';

@NgModule({
	declarations: [
		VideoPlayerContainerDirective,
		TooltipDirective,
		TooltipPanelComponent,
		LocalizePipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		NgInitDirective,
	],
	exports: [
		VideoPlayerContainerDirective,
		TooltipDirective,
		LocalizePipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		Ng1PortalComponentsModule,
		NgInitDirective,
	],
	imports: [CommonModule, OverlayModule, PortalModule, Ng1PortalComponentsModule],
	providers: [createProvider('videoPlayerService', VideoPlayerService), BroadcastBridgeService],
})
export class SharedModule {}
