/* eslint-disable @typescript-eslint/no-use-before-define */
import * as angular from 'angular';

import { StateProvider, TransitionService, UIRouter, UrlService } from '@uirouter/angularjs';
// import { Visualizer } from '@uirouter/visualizer';

import { ModuleRegistration } from './types';

import { UserAgentDetector } from './UserAgentDetector';
import { UiRouterNgRouteConverter } from './UiRouterNgRouteConverter';

import { ModuleStyleManager } from './ModuleStyleManager';

export function bootstrapClient(document: Document, regModule: ModuleRegistration) {
	UserAgentDetector.Bootstrap(document);
	angular.bootstrap(document.body, [regModule.name], {
		// strictDi: isDevelopment === false,
		strictDi: false,
	});
}

export function boopstrapApp(ngModule: ModuleRegistration, path: string, resolveAlways = null) {
	// other common boostrapping should be applied here

	bootstrapRouting(ngModule, path, resolveAlways);
}

function bootstrapRouting(ngModule: ModuleRegistration, path: string, resolveAlways = null) {
	if (!ngModule.routes) {
		throw new Error(`ngModule.routes required`);
	}

	const adapter = new UiRouterNgRouteConverter(ngModule, path);
	const states = adapter.createStates();

	const styleBuilder = new ModuleStyleManager(ngModule, adapter);
	styleBuilder.bootstrap();

	// register ui-router states
	ngModule.config([
		'$stateProvider',
		'$urlServiceProvider',
		'$uiRouterProvider',
		'$urlRouterProvider',
		function (
			$stateProvider: StateProvider,
			$urlServiceProvider: UrlService,
			$uiRouterProvider: UIRouter,
			$urlRouterProvider
		): void {
			$urlServiceProvider.deferIntercept();

			// register all states

			if (resolveAlways) {
				states.forEach(state => {
					// eslint-disable-next-line no-param-reassign
					state.resolve = { ...state.resolve, ...resolveAlways };
				});
			}
			states.forEach(state => $stateProvider.state(state));

			$urlRouterProvider.rule(function ($injector, $location) {
				// what this function returns will be set as the $location.url
				const locationPath = $location.path();
				const normalized = locationPath.toLowerCase();
				if (locationPath !== normalized) {
					// instead of returning a new url string, I'll just change the $location.path directly
					// so I don't have to worry about constructing a new url string and so a new state change is not triggered
					$location.replace().path(normalized);
				}
				// because we've returned nothing, no state change occurs
			});
		},
	]);

	// register style resolver for state transitions

	ngModule.run([
		'$uiRouter',
		'$urlService',
		'$transitions',
		function ($uiRouter: UIRouter, $urlService: UrlService, $transitions: TransitionService): void {
			// $uiRouter.plugin(Visualizer);
			$urlService.config.caseInsensitive(true);
			$transitions.onEnter({}, transition => {
				const from = transition.from();
				const to = transition.to();

				styleBuilder.transitionStyles(from, to);
			});
		},
	]);
}
