import * as utils from '~root/commons';

import { EditTextComponent } from 'app/admin/shared/edit-text/edit-text.component';
import { EditTextService } from 'app/admin/shared/edit-text/edit-text.service';

import { MediaResourcesModuleRegistration } from 'app-legacy/admin/modules/mediaResources/mediaResources.module';
import { CommonsModule } from 'app-legacy/commons';
import CommonsModuleRouting from './commons-routing.module';

import ErrorComponent from './error/ErrorController';
import GenericComponent from './generic/GenericController';
import { AccountMenuComponent } from './jediAccountMenu/jediAccountMenu';
import WarningMessageComponent from './warningMessage/warningMessageComponent';
import { JediAdminHttpComponentRegistration } from './jediAdminAppHttpInterceptor';
import { ContextManagerComponentRegistration } from './jediContextManager/jediContextManagerComponent';
import { NavigationComponent } from './jediNavigationMenu/jediNavigationMenu';
import JediSearchComponent from './jediSearch/jediSearchComponent';
import JediTablePaging from './jediTablePaging/jediTablePaging';
import RedirectComponent from './redirect/RedirectComponent';
import SortableTable from './sortableTable/sortableTable';

import { EditCertificateSettingsComponentRegistration, CertificateServiceRegistration } from './certificateTemplates';
import { SearchApiServiceRegistration } from './searchService';

import { AdminServiceRegistration } from './AdminApiService';
import { TablePagerComponentRegistration } from './table-pager/TablePagerComponent';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

export const AdminCommonsModule = utils.createModule({
	name: 'jedi.admin.commons',
	routing: CommonsModuleRouting,
	registrations: [
		SearchApiServiceRegistration,
		RedirectComponent,
		EditCertificateSettingsComponentRegistration,
		CertificateServiceRegistration,
		ErrorComponent,
		GenericComponent,
		AccountMenuComponent,
		WarningMessageComponent,
		ContextManagerComponentRegistration,
		NavigationComponent,
		JediTablePaging,
		SortableTable,
		JediAdminHttpComponentRegistration,
		JediSearchComponent,
		AdminServiceRegistration,
		TablePagerComponentRegistration,
		CertificateServiceRegistration,
	],
	requires: [MediaResourcesModuleRegistration, CommonsModule],
});

AdminCommonsModule
	// downgrade
	.directive('appEditText', downgradeComponent({ component: EditTextComponent, outputs: ['text-changed'] }))
	.factory('appEditTextService', downgradeInjectable(EditTextService) as any);
