import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import moment from 'moment';

@Component({
	selector: 'app-timepicker',
	templateUrl: './timepicker.component.html',
	styleUrls: ['./timepicker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TimepickerComponent implements OnInit, OnChanges {
	@Input() date: Date;

	@Output() dateChange = new EventEmitter<Date>();

	public time: string;

	ngOnInit(): void {
		if (!this.date) {
			this.time = '';
			return;
		}
		this.time = moment(this.date).format('HH:mm').toString();
	}

	public onChange(value: string) {
		this.time = value;
		this.date = moment(this.date)
			.set({ hour: parseInt(this.time.split(':')[0], 10), minute: parseInt(this.time.split(':')[1], 10) })
			.toDate();
		this.dateChange.emit(this.date);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.date.currentValue) {
			this.time = '';
			return;
		}
		if (changes.date.currentValue && !this.time) {
			this.time = '00:00';
		}
	}
}
