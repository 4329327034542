export class ActivityRelationModel {
	relationId: string | number = 0;
	isMandatory: boolean = false;
	accessOrder: number = 0;
	displayOrder: number = 0;
	parentRelationId: string | number = null;
	parentContainerId: string | number = null;
	childId: string | number = 0;
	hierarchyPath: string = '/';
}
