import { Component, Input, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

const extractLastDayDuration = (date: Date): moment.Duration => {
	if (!date) return moment.duration(0);

	const diff = moment(date).diff(moment(date).startOf('day'));
	const interval = moment.duration(diff);
	return interval;
};

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerComponent),
			multi: true,
		},
	],
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
	date: Date;

	@Input() label: string | undefined;

	@Input() includeTime: boolean | undefined;

	public shortDateValue: moment.Moment;

	emitValueAccessorChange: any = () => {};

	onTouched: any = () => {};

	ngOnInit(): void {
		if (this.date) this.shortDateValue = moment(this.date);
		else this.shortDateValue = null;
	}

	public onShortDateChange(event) {
		const interval = extractLastDayDuration(this.date);
		this.shortDateValue = moment(event.value);
		this.date = this.shortDateValue.add(interval).toDate();
		this.emitValueAccessorChange(this.date);
	}

	public onDateChange(date: Date) {
		this.setDate(date);
		this.emitValueAccessorChange(date);
	}

	public setDate(date: Date) {
		this.date = date;

		if (date) this.shortDateValue = moment(date);
		else this.shortDateValue = null;
	}

	public onClear(event: Event) {
		event.stopPropagation();
		this.onDateChange(null);
	}

	/**
	 * programatically called from outside of the component using the form control object
	 * @param value
	 */
	writeValue(value: any): void {
		if (!value) {
			this.setDate(null);
			return;
		}
		this.setDate(value);
	}

	/**
	 * it's called by Angular forms to register callbacks that updates the outside form control
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.emitValueAccessorChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
