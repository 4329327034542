import moment from 'moment';
import { IHttpService } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { UserBaseModel, NotificationMessage, NotificationMessageType } from '@base/models';

import serverSettings from '../jediServerSettings';
import { JediConfig } from '../config/config';
import { LocalizationProvider } from '../jediLocalization/jediLocalizationProvider';
import { StorageType, JediClientStorageService } from '../services/ClientStorage';

export const JediNotificationMessageServiceRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('jediNotificationMessages', [
			'$http',
			'jediClientStorage',
			'jediLocalization',
			JediNotificationMessageService,
		]);
	},
};

const messageTypes = [NotificationMessageType.Daily, NotificationMessageType.System, NotificationMessageType.Regular];
const storageOptions = {
	key: JediConfig.app.cookies.maintenanceMessages,
	type: StorageType.CookieStorage,
};

export class JediNotificationMessageService {
	private messages: NotificationMessage[] = undefined;

	constructor(
		private $http: IHttpService,
		private jediClientStorage: JediClientStorageService,
		private jediLocalization: LocalizationProvider
	) {}

	hasMessages(): boolean {
		return this.messages && this.messages.length > 0;
	}

	async getUpdatedMessages(user?: UserBaseModel | null): Promise<NotificationMessage[]> {
		if (this.messages === undefined) {
			const lastShown = moment().format('YYYYMMDDHHmmss').toUpperCase();
			const url = JediConfig.api.platform.notificationMessages(lastShown, serverSettings.AppOrganisationId);
			const response = await this.$http.get<NotificationMessage[]>(url);
			this.messages = response.data.map(item => {
				item.displayDate = moment(item.displayDate).toDate();
				return item;
			});
			return this.getUpdatedMessages(user);
		} else {
			if (typeof user !== 'undefined') {
				this.messages = this.messages.map(item => {
					if (item.type.toLowerCase() === 'daily') {
						item.message = item.message.replace('{firstName}', user.firstName);
						item.message = item.message.replace('{lastName}', user.lastName);
					}
					return item;
				});
			}
		}
		return this.messages;
	}

	resetMessages(): void {
		this.messages = undefined;
	}

	updateStoredMessage(messages: NotificationMessage[], messageType: NotificationMessageType, userId?: string): void {
		if (messageTypes.includes(messageType)) {
			let messagesCookie = this.jediClientStorage.get(storageOptions.key, storageOptions.type);
			messagesCookie = !messagesCookie ? {} : JSON.parse(messagesCookie);

			if (typeof userId != 'undefined') {
				if (typeof messagesCookie[userId] == 'undefined') {
					messagesCookie[userId] = {};
				}
				messagesCookie[userId][messageType] = [];
			} else {
				messagesCookie[messageType] = [];
			}

			messages.forEach(el => {
				if (el && el.type.toLowerCase() === messageType) {
					if (typeof userId != 'undefined') {
						messagesCookie[userId][messageType].push(el.id);
					} else {
						messagesCookie[messageType].push(el.id);
					}
				}
			});

			messagesCookie = JSON.stringify(messagesCookie);
			this.jediClientStorage.set(storageOptions.key, messagesCookie, storageOptions.type, 356);
		}
	}

	filterMessagesByCulture(message: NotificationMessage): boolean {
		return (
			message && (message.cultureId == null || message.cultureId === this.jediLocalization.getCurrentUiCulture())
		);
	}

	clearStoredMessages(): void {
		this.jediClientStorage.remove(storageOptions.key, storageOptions.type);
	}

	configureMessages(messages: NotificationMessage[], messageType: NotificationMessageType, userId?: string): any[] {
		if (messageTypes.includes(messageType)) {
			var messagesCookie = this.jediClientStorage.get(storageOptions.key, storageOptions.type);
			messagesCookie = !messagesCookie ? {} : JSON.parse(messagesCookie);

			if (typeof userId != 'undefined') {
				//new user cookie initialization
				if (typeof messagesCookie[userId] === 'undefined') {
					messagesCookie[userId] = {};
					messagesCookie[userId][messageType] = [];
				}

				//existing user cookie initialization
				if (typeof messagesCookie[userId][messageType] === 'undefined') {
					messagesCookie[userId][messageType] = [];
				}
			} else {
				if (typeof messagesCookie[messageType] === 'undefined') {
					messagesCookie[messageType] = [];
				}
			}

			const displayedMessages = [];
			if (typeof messages !== 'undefined' && messages.length > 0) {
				messages.forEach(message => {
					if (message?.type?.toLowerCase() === messageType) {
						if (typeof userId !== 'undefined') {
							if (messagesCookie[userId][messageType].indexOf(message.id) < 0) {
								displayedMessages.push(message);
							}
						} else if (messagesCookie[messageType].indexOf(message.id) < 0) {
							displayedMessages.push(message);
						}
					}
				});
			}
			return displayedMessages;
		}
	}
}
