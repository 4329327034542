import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { RouteParamsService } from 'app/route-params.service';

import { JediAuthService } from 'app-legacy/commons';
import { ProviderCategoryEnum } from 'models';
import { CreateActivityTypes, ProviderTypeViewModel } from './CreateActivityTypes';

@Component({
	selector: 'app-send-certificates-dialog',

	templateUrl: './intermediate-create-activity.component.html',

	styleUrls: ['./intermediate-create-activity.component.scss'],
})
export class IntermediateCreateActivityDialogComponent {
	constructor(
		private dialogRef: MatDialogRef<IntermediateCreateActivityDialogComponent>,
		private location: Location,
		private routeParams: RouteParamsService,
		private jediAuth: JediAuthService
	) {
		// empty
		this.providerTypes = CreateActivityTypes;
		if (!jediAuth.isSysadmin()) {
			this.providerTypes = CreateActivityTypes.filter(s => s.providerCategory !== ProviderCategoryEnum.LTI);
		}
	}

	providerTypes = CreateActivityTypes;

	createActivity(providerType: ProviderTypeViewModel) {
		this.dialogRef.close();
		const oid = this.routeParams.params().organisationId;
		let url = `/administration/organisations/${oid}/activities/new`;
		if (providerType.providerType) {
			url += `?providerType=${providerType.providerType}&providerCategory=${providerType.providerCategory}`;
		} else {
			url += `?providerCategory=${providerType.providerCategory}`;
		}
		this.location.go(url);
	}

	cancel() {
		this.dialogRef.close();
	}
}
