import { IQService, IRootScopeService, IWindowService, IHttpService } from 'angular';
import * as logger from '~root/logger';
import { UserAuthModel, RoleModel } from '@base/models';
import { RolePermissions, RoleLabels } from './permissions';
import { UserAuthorizationResult } from './UserAuthorizationResult';
import { JediConfig } from '../config/config';
import { JediAuthStore } from './jediAuthStore';
import { ComponentRegistration } from '~root/commons';

function equals(a: string, b: string) {
	return (a || '').trim().toLowerCase() === (b || '').trim().toLowerCase();
}

export const JediAuthServiceRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('jediAuth', JediAuthService);
	},
};

export class JediAuthService {
	private permissions = RolePermissions;
	static $inject = ['$http', '$window', '$rootScope', '$q', 'jediAuthStore'];
	constructor(
		private $http: IHttpService,
		private $window: IWindowService,
		private $rootScope: IRootScopeService,
		private $q: IQService,
		public jediAuthStore: JediAuthStore
	) {}

	configureRoles(roles: RoleModel[]): void {
		const originalPermissions = this.getPermissionsArray();
		const newPermissions = { ...originalPermissions };

		for (var i = 0; i < originalPermissions.length; i++) {
			newPermissions[i].id = roles.find(role => equals(role.name, originalPermissions[i].name))?.id;
			newPermissions[i].label = RoleLabels.find(label => equals(label.name, newPermissions[i].name))?.label;
		}

		this.setPermissionsArray(newPermissions);
	}

	getPermissionsArray() {
		const array = Object.keys(this.permissions).map(key => this.permissions[key]);
		return array;
	}

	setPermissionsArray(permissions): void {
		for (var i = 0; i < permissions.length; i++) {
			Object.keys(this.permissions).forEach(p => {
				if (permissions[i].name.toLowerCase() === p.toLowerCase()) {
					this.permissions[p] = permissions[i];
				}
			});
		}
	}

	private userPromise = null;

	async getLoggedInUser(): Promise<UserAuthModel> {
		if (this.userPromise != null) {
			return this.userPromise.promise;
		}

		const deferred = this.$q.defer<UserAuthModel>();
		this.userPromise = deferred;

		try {
			const user = this.jediAuthStore.getLoggedInUser();
			if (user && user.id) {
				deferred.resolve(user);
			} else {
				this.$rootScope.$broadcast(JediConfig.events.currentUserLoadStart);

				try {
					const response = await this.$http.get<any>(JediConfig.api.account, { cache: true });

					const filteredUser = this.filterUser(response.data);
					this.jediAuthStore.setLoggedInUser(filteredUser);

					this.$rootScope.$broadcast(
						JediConfig.events.currentUserLoaded,
						this.jediAuthStore.getLoggedInUser()
					);
					this.$rootScope.$broadcast(JediConfig.events.currentUserLoadComplete);

					deferred.resolve(this.jediAuthStore.getLoggedInUser());
				} catch (error) {
					this.jediAuthStore.setLoggedInUser(undefined);
					deferred.reject(error);
					this.$rootScope.$broadcast(JediConfig.events.currentUserLoadComplete);
				}
			}
		} catch (err) {
			this.jediAuthStore.setLoggedInUser(undefined);
			this.$rootScope.$broadcast(JediConfig.events.currentUserLoadComplete);
			deferred.reject(err);
		}
		return deferred.promise;
	}

	isLoggedInUserTokenDefined(): boolean {
		return this.jediAuthStore.getLoggedInUserToken() !== undefined;
	}

	getLoggedInUserToken(): Promise<any> {
		const userToken = this.jediAuthStore.getLoggedInUserToken();
		if (typeof userToken === 'undefined') {
			return Promise.reject('token not found');
		} else {
			return Promise.resolve(userToken);
		}
	}

	setLoggedInUser(user: UserAuthModel): void {
		this.jediAuthStore.setLoggedInUser(user);
	}

	async loadUser() {
		try {
			const token = await this.getLoggedInUserToken();
			const user = await this.getLoggedInUser();
			// TODO: should move to this.getLoggedInUser
			const filtered = this.filterUser(user);
			this.setLoggedInUser(filtered);
			return Promise.resolve(filtered);
		} catch (error) {
			logger.error(error);
			this.setLoggedInUser(undefined);
			return Promise.reject(error);
		}
	}

	getRoleValue(name: string): number {
		const roleKey = Object.keys(this.permissions).find(k => this.permissions[k].name === name);
		return this.permissions[roleKey]?.value;
	}

	filterUser(user: UserAuthModel): UserAuthModel {
		if (typeof user === 'undefined') {
			return undefined;
		}
		if (!user.roles || !user.roles.length) {
			return user;
		}
		const roles = [];
		let maxRole = { value: -1 };
		if (typeof user.maxRole != 'undefined') {
			return user;
		}
		var p = this.getPermissionsArray();
		for (var i = 0; i < user.roles.length; i++) {
			var role = { name: user.roles[i], value: this.getRoleValue(user.roles[i]) };
			role = p.find(e => e.name === user.roles[i]);
			// role = this.$filter('filter')(p, { name: user.roles[i] }, true)[0];
			if (typeof role != 'undefined') {
				roles.push(role);
				if (role.value > maxRole.value) {
					maxRole = role;
				}
			}
		}
		user.roles = roles;
		user.maxRole = maxRole;
		return user;
	}

	revertUserFilter(user: UserAuthModel): UserAuthModel {
		var roles = [];
		if (!user.roles || !user.roles.length) {
			return user;
		}
		for (var i = 0; i < user.roles.length; i++) {
			if (typeof user.roles[i].name === 'string') {
				roles.push(user.roles[i].name);
			}
		}
		user.roles = roles;
		return user;
	}

	isUserLoaded(): boolean {
		return typeof this.jediAuthStore.getLoggedInUser() !== 'undefined';
	}

	async hasLoggedIn(): Promise<boolean> {
		const response = await this.$http.get<boolean>(JediConfig.api.hasLoggedIn, { cache: true });
		return response.data;
	}

	isSysadmin(): boolean {
		return this.hasRole('SystemAdministrator');
	}

	isPlatformAdmin(): boolean {
		return this.hasRole('PlatformAdministrator');
	}

	isCorporateAdmin(): boolean {
		return this.hasRole('Administrator');
	}

	isCorporateUser(): boolean {
		return this.hasRole('AdminApplicationUser');
	}

	hasRole(roleName: string): boolean {
		var user = this.jediAuthStore.getLoggedInUser();
		const result = user?.roles?.find(role => role.name === roleName);
		return typeof result !== 'undefined';
	}

	authorize(user: UserAuthModel, permissions): UserAuthorizationResult {
		if (typeof user == 'undefined') {
			logger.warn('jediAuth.authorize failed: user undefined');
			return UserAuthorizationResult.Unauthenticated;
		}
		if (typeof permissions === 'undefined') {
			return UserAuthorizationResult.Authorized;
		}
		const requiredPermissions = Array.isArray(permissions) ? permissions : [permissions];

		const hasPermission = requiredPermissions.some(permission => checkRole(user, permission));
		return hasPermission ? UserAuthorizationResult.Authorized : UserAuthorizationResult.Unauthorized;
	}

	logout(authorizationResult: UserAuthorizationResult = null): void {
		if (this.userPromise != null) {
			this.userPromise = null;
		}

		this.setLoggedInUser(undefined);
		const returnUrl = encodeURIComponent(window.location.href);

		let logoutUrl = `/account/login?action=logout&returnUrl=${returnUrl}`;
		if (
			(authorizationResult !== null && authorizationResult == UserAuthorizationResult.Unauthorized) ||
			returnUrl.toLowerCase().includes('account/login')
		) {
			// using returnURl for an unauthorized request will trigger a redirect loop
			logoutUrl = `/account/login?action=logout`;
		}
		logger.warn('logging out');
		this.$window.location.href = logoutUrl;
	}
}

function checkRole(user, role) {
	const roleValue = (role || {}).value || -1;
	const hasRole = ((user || {}).roles || []).some(ur => ur.value >= roleValue);
	return hasRole;
}
