declare var jediServerSettings: any;

// global external object jediServerSettings

// const serverSettings = jediServerSettings;
// TODO: must be loaded dynamically from server since it depends on the hostname and authenticated user

export const serverSettings: any = jediServerSettings;
// const serverSettings: any = {
// 	APIBasePath: 'https://splintt-api.code40.local/api/',
// 	AppBasePath: 'http://splintt-app.code40.local/',
// 	ProxyEndpoint: 'https://splintt-proxy.code40.local/lrs',
// 	ProxyLrsPath: 'lrs',
// 	ProxyLRS: 'https://cloud.scorm.com/',
// 	ProxyEnable: true,
// 	Learning: { ActivitiesPath: '/Media/Activities', PackagesPath: '/Media/Packages' },
// 	TinCan: { DefaultAuthorizationServiceURL: 'https://splintt-api.code40.local/api/services/authorize' },
// 	DisableQueryEncoding: true,
// 	DeploymentConfigurationString: 'some sample text',
// 	AppBuildTimestamp: '202008281341190000',
// 	AppBuildDate: '2020-08-28T13:41:19+00:00',
// 	AppBuildConfig: 'Unkown',
// 	AppIsCustomEndpoint: false,
// 	AppCustomEndpointName: '',
// 	AppTheme: {
// 		TokenOriginalBackground: 'ORIGINAL_BACKGROUND_IMAGE',
// 		TokenBlurredBackground: 'BLURRED_BACKGROUND_IMAGE',
// 		CustomFrontend: '',
// 		CustomStylings: [],
// 		PublicBackground: null,
// 		TemplateRules: [],
// 	},
// 	IsWebDeployment: true,
// 	ClearOldCookies: false,
// 	DisableClientCacheBusting: false,
// 	EnableSingleSignOn: false,
// 	Version: '1.0.0.6e9819b5c',
// 	ApplicationInsightsKey: '1111',
// 	CustomSettings: '/Media/Settings/customSettings.json',
// 	StatementProcessingDelay: 600,
// 	LearningActivityGenericInfoMaximumLength: 100,

// 	ClientFontsProjectId: '11111',
// 	EnableSelfRegistration: false,
// 	AppCustomHostname: '',
// 	AppOrganisationId: null,
// 	RecaptchaId: '11111',
// 	AppOrganisation: null,
// };

export default serverSettings;

/**
 *
 */
