import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { JediConfig, JediAuthStore, JediAuthService, JediClientStorageService, StorageType } from 'app-legacy/commons';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	private baseUri = JediConfig.api.base;

	public publicEndpoints = ['/api/platform/languages'];

	constructor(
		private jediAuthStore: JediAuthStore,
		private jediAuthService: JediAuthService,
		private clientStorage: JediClientStorageService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestUrl = req.url;
		if (!requestUrl.startsWith(this.baseUri)) {
			return next.handle(req);
		}

		const userToken = this.jediAuthStore.getLoggedInUserToken();
		if (userToken && userToken.access_token) {
			const cloned = req.clone({ setHeaders: { Authorization: `Bearer ${userToken.access_token}` } });
			return next.handle(cloned).pipe(catchError(error => this.handleError(error)));
		}

		// public endpoints shouldn't be authorized
		if (this.publicEndpoints.some(endpoint => requestUrl.includes(endpoint))) {
			return next.handle(req);
		}

		this.jediAuthService.logout();
		return next.handle(req);
	}

	handleError(error: HttpErrorResponse) {
		if (error?.status === 401 || error?.status === 403) {
			this.jediAuthService.logout();
			this.clientStorage.clearExcept(StorageType.CookieStorage, ['jedi.version', 'jedi.messages']);
		}
		return throwError(error);
	}
}
