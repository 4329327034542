import { IController } from 'angular';
import { UserAgentDetector, ComponentRegistration } from '~root/commons';

const WarningMessageComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule
			// registrations
			.component('warningMessage', {
				controller: WarningMessagesController,
				template: require('./warningMessage.html'),
			});
	},
	style: require('./style.scss'),
};

export default WarningMessageComponent;

class WarningMessagesController implements IController {
	public showWarning = false;

	$onInit() {
		const detector = new UserAgentDetector();
		const agent = detector.getUserAgent();
		this.showWarning = agent.name == 'IE' && agent.version == '11';
	}
}
