/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserBaseModel, ApiResultModel, BaseActivityModel } from '@base/models';
import { JediAuthService, JediConfig } from 'app-legacy/commons';
import { CertificateSettingsModel, CertificateTemplateSettingsApiModel } from 'app-legacy/admin/commons';
import { UserProgressReportEntry } from 'app-legacy/admin/models';
import { IssueCertificatesRequest, ChangeCertificateStateRequest, CertificateDetailsModel } from './models';

@Injectable()
export class CertificatesLogicService {
	// trim trailing slashes
	private baseUri = JediConfig.api.base.replace(/\/+$/gi, '');

	constructor(private httpClient: HttpClient, private jediAuth: JediAuthService) {}

	async issueCertificate(
		activity: BaseActivityModel,
		users: UserProgressReportEntry[],
		issueDate: Date
	): Promise<void> {
		const request: IssueCertificatesRequest = {
			issueDate,
			activityId: +activity.id,
			userIds: users.map(u => u.user.id),
		};
		await this.httpClient.post(`${this.baseUri}/certificates`, request).toPromise();
	}

	async revokeCertificates(_activity: BaseActivityModel, users: UserProgressReportEntry[]): Promise<void> {
		const request: ChangeCertificateStateRequest = {
			certificateCodes: users.map(p => p.certificate?.code),
			state: 'Revoked',
		};
		await this.httpClient.patch(`${this.baseUri}/certificates`, request).toPromise();
	}

	async reissueCertificates(
		_activity: BaseActivityModel,
		users: UserProgressReportEntry[],
		reissueDate: Date
	): Promise<void> {
		const request: ChangeCertificateStateRequest = {
			certificateCodes: users.map(p => p.certificate?.code),
			state: 'Issued',
			issueDate: reissueDate,
		};
		await this.httpClient.patch(`${this.baseUri}/certificates`, request).toPromise();
	}

	sendCertificatesForUsers(activityId: number, users: Array<UserBaseModel>) {
		const request = { activityId, userIds: users.map(u => u.id) };
		return this.httpClient.post(`${this.baseUri}/participants/certificates`, request).toPromise();
	}

	getActivitySettings(activityId: number): Promise<CertificateSettingsModel> {
		if (!this.jediAuth.isPlatformAdmin()) {
			return Promise.resolve({});
		}

		const uri = `${this.baseUri}/learning/activities/${activityId}/certificateSettings`;
		return this.httpClient.get<CertificateSettingsModel>(uri).toPromise();
	}

	saveActivitySettings(activityId: number, settings: CertificateSettingsModel) {
		if (!this.jediAuth.isPlatformAdmin()) {
			return Promise.reject(new Error('unauthorized'));
		}

		const uri = `${this.baseUri}/learning/activities/${activityId}/certificateSettings`;

		const apiModel = this.mapToApiModel(settings);
		return this.httpClient.patch(uri, apiModel).toPromise();
	}

	mapToApiModel(settings: CertificateSettingsModel) {
		const apiModel: CertificateTemplateSettingsApiModel = {
			certificateTemplates: settings.certificateTemplates?.map(ct => ({
				certificateId: ct.certificate?.id,
				cultureId: ct.culture?.id,
			})),
			enableAutomaticSending: settings.enableAutomaticSending,
			enableCertificateCreation: settings.enableCertificateCreation,
			enableAutomaticIssuing: settings.enableAutomaticIssuing,
			emailTemplateId: settings.emailTemplate?.id,
			validity: settings.validity,
		};

		if (apiModel?.validity) {
			if (!apiModel.validity.years) {
				apiModel.validity.years = 0;
			}
			if (!apiModel.validity.months) {
				apiModel.validity.months = 0;
			}
			if (!apiModel.validity.days) {
				apiModel.validity.days = 0;
			}

			if (!apiModel.validity || JSON.stringify(apiModel.validity) === '{}') {
				delete apiModel.validity;
			}
		}

		return apiModel;
	}

	getOrganisationSettings(organisationId: number): Promise<CertificateSettingsModel> {
		if (!this.jediAuth.isPlatformAdmin()) {
			return Promise.resolve({});
		}

		const uri = `${this.baseUri}/organisations/${organisationId}/certificateSettings`;
		return this.httpClient.get<CertificateSettingsModel>(uri).toPromise();
	}

	saveOrganisationSettings(organisationId: number, settings: CertificateTemplateSettingsApiModel) {
		if (!this.jediAuth.isPlatformAdmin()) {
			return Promise.reject(new Error('unauthorized'));
		}
		const uri = `${this.baseUri}/organisations/${organisationId}/certificateSettings`;
		return this.httpClient.patch<ApiResultModel>(uri, settings).toPromise();
	}

	public async getCertificateForUser(activityId: string | number, userId: string): Promise<Blob> {
		const uri = `${JediConfig.api.base.replace(/\/+$/gi, '')}/certificates/user/${userId}/activity/${activityId}`;

		const response = await this.httpClient.get(uri, { responseType: 'blob' }).toPromise();

		return response;
	}

	public async getCertificateDetails(certificateNumber: string): Promise<CertificateDetailsModel> {
		const uri = `${this.baseUri}/certificates/${certificateNumber}`;

		const response = this.httpClient.get<CertificateDetailsModel>(uri).toPromise();

		return response;
	}
}
