import * as logger from '~root/logger';

export function invokeFn(self, invokable, injections, logInvocation: boolean = false) {
	if (typeof invokable === 'undefined') {
		return;
	}
	let _injections = [];
	if (typeof injections !== 'undefined') {
		_injections = injections.slice ? injections : [injections];
	}

	if (typeof invokable === 'function') {
		logInvocation && log(invokable, self, _injections);
		invokable.apply(self, _injections);
	} else if (invokable.slice) {
		const fn = invokable[invokable.length - 1];
		if (typeof fn === 'function') {
			logInvocation && log(invokable, self, _injections);
			fn.apply(self, _injections);
		}
	}
}

function stringy(fn) {
	const trim = a => a.substr(0, 50).replace(/\r\n\t/, ' ');
	if (typeof fn === 'function') {
		return trim(fn + '');
	}
	return trim(JSON.stringify(fn));
}

function log(fn, scope, args) {
	return;
	logger.debug(`calling ${stringy(fn)} on ${stringy(scope)} using ${stringy(args)}`);
}
