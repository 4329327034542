import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminSharedModule } from 'app/admin/shared';

import { SendCertificatesDialogComponent } from './send-certificates-dialog/send-certificates-dialog.component';
import { IssueRevokeCertificatesDialogComponent } from './issue-revoke-certificates-dialog/issue-revoke-certificates-dialog.component';
import { CertificateDialogService } from './certificates-dialog.service';
import { CertificatesLogicService } from './certificates-logic.service';
import { EditCertificateSettingsComponent } from './edit-certificate-settings/edit-certificate-settings.component';
import { EditCertificateSettingsLocalizedComponent } from './edit-certificate-settings/edit-certificate-settings-localized.component';
import { CertificateCheckComponent } from './certificate-check/certificate-check.component';

@NgModule({
	declarations: [
		IssueRevokeCertificatesDialogComponent,
		SendCertificatesDialogComponent,
		EditCertificateSettingsComponent,
		EditCertificateSettingsLocalizedComponent,
		CertificateCheckComponent,
	],
	imports: [CommonModule, MatDialogModule, FormsModule, ReactiveFormsModule, AdminSharedModule],
	exports: [
		IssueRevokeCertificatesDialogComponent,
		SendCertificatesDialogComponent,
		EditCertificateSettingsComponent,
		EditCertificateSettingsLocalizedComponent,
	],
	providers: [CertificateDialogService, CertificatesLogicService],
})
export class CertificatesModule {
	// empty
}
