import { IController, IModule } from 'angular';

const ContentTruncateComponent = {
	register: function (ngModule: IModule) {
		ngModule.component('jediContentTruncate', {
			controller: ContentTruncateController,
			template: require('./contentTruncateView.html'),
			bindings: {
				ngModel: '<',
				maxChars: '<'
			}
		});
	},
	style: require('./style.scss')
};

export default ContentTruncateComponent;
class ContentTruncateController implements IController {
	public ngModel: string;
	public maxChars: string;

	public shouldTruncate: boolean = false;
	public isOpen: boolean = false;
	$onInit() {
		let chars = parseInt(this.maxChars) || 0;
		let html = this.ngModel || '';
		this.shouldTruncate = html.length > chars;
	}
	open() {
		this.isOpen = true;
	}
	close() {
		this.isOpen = false;
	}
}
