import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	ApiResultModel,
	NotificationEntryModel,
	NotificationSettingsModel,
	NotificationsEventType,
} from '@base/models';
import { RouteParamsService } from 'app/route-params.service';
import moment from 'moment';
import { NotificationsOrganisationUriService } from './notifications-organisation-uri.service';
import { NotificationsLearningUriService } from './notifications-learning-uri.service';
import { INotificationsUriService } from './INotificatiosUriService';

@Injectable()
export class NotificationsLogicService {
	constructor(
		private httpClient: HttpClient,
		private organisationUriService: NotificationsOrganisationUriService,
		private learningUriService: NotificationsLearningUriService,
		private route: RouteParamsService
	) {}

	private getUriService(): INotificationsUriService {
		if (this.route.params().learningActivityId === undefined) {
			return this.organisationUriService;
		}
		return this.learningUriService;
	}

	public async getNotificationTypes(): Promise<NotificationsEventType[]> {
		const uri = this.getUriService().getNotificationsTypeUri();
		const response = await this.httpClient.get<NotificationsEventType[]>(uri).toPromise();
		return response;
	}

	public async getNotificationsSettings(): Promise<NotificationSettingsModel> {
		const uri = this.getUriService().getNotificationsSettingsUri();
		const response = await this.httpClient.get<NotificationSettingsModel>(uri).toPromise();
		const notificationsSettings: NotificationSettingsModel = {
			notifications: response.notifications.map(n => ({
				name: n.name,
				emailTemplate: n.emailTemplate,
				type: n.type,
				jobId: n.jobId,
				triggerPeriod: n.triggerPeriod,
				scheduledAt: moment.utc(n.scheduledAt).local().toDate(),
			})),
		};
		return notificationsSettings;
	}

	public async updateNotificationsSettings(
		notificationsSettings: NotificationSettingsModel
	): Promise<NotificationSettingsModel> {
		const uri = this.getUriService().getNotificationsSettingsUri();
		const apiUpdateModel = {
			notifications: notificationsSettings.notifications.map((n: NotificationEntryModel) => ({
				emailTemplateId: n.emailTemplate.id,
				name: n.name,
				triggerPeriod: n.triggerPeriod,
				type: n.type,
				jobId: n.jobId,
				scheduledAt: moment.utc(n.scheduledAt),
			})),
		};
		const response = await this.httpClient
			.put<ApiResultModel | NotificationSettingsModel>(uri, apiUpdateModel)
			.toPromise();

		if (response && ApiResultModel.hasError(response)) {
			// error is displayed by global http interceptor
			return null;
		}
		const apiSettings = response as NotificationSettingsModel;

		const updatedNotifications: NotificationSettingsModel = {
			notifications: apiSettings.notifications.map((n: NotificationEntryModel) => ({
				name: n.name,
				emailTemplate: n.emailTemplate,
				triggerPeriod: n.triggerPeriod,
				type: n.type,
				jobId: n.jobId,
				scheduledAt: moment.utc(n.scheduledAt).local().toDate(),
			})),
		};

		return updatedNotifications;
	}
}
