export class ProgressReportingApiQueryFields {
	user?: string | string[] = null;

	tagName?: string | string[] = null;

	userId?: string = null;

	group?: string | string[] = null;

	activityId?: string = null;

	versionId?: string = null;

	organisationId?: string = null;

	activityName?: string = null;

	state?: string = null;

	progressDate?: string = null;

	lastActivityDate?: string = null;

	overallProgress?: boolean = null;

	showAuthorization?: boolean = null;

	onlyDirectGroupMembers?: boolean = null;

	expiredCertificatesAll?: boolean = null;

	expiredCertificatesStartDate?: string = null;

	expiredCertificatesEndDate?: string = null;
}
