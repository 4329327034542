import * as angular from 'angular';
import * as logger from '~root/logger';

import { ContainerPolicyEnum, UserActivityProgressNode, ActivityProgress, ProgressState } from '@base/models';
import { ActivityProgressMapper } from './ActivityProgressMapper';
import { ContainerProgressState } from './ContainerProgressState';

const progressMapper = new ActivityProgressMapper();

export class ProgressCalculationService {
	calculate(activity: UserActivityProgressNode): ActivityProgress {
		if (!activity) {
			logger.error('invalid element for progress calculation');
			return null;
		}
		if (activity.id == 'root' && activity.isActivity) {
			return null;
		}

		if (!activity.members || !activity.progress || !activity.policyModel || activity.isActivity) {
			logger.error('invalid element for progress calculation: ' + activity.name);
			return null;
		}

		if (activity.inFinalState()) {
			logger.info(`element ${activity.name} is in the final state`);
			return activity.progress;
		}
		const membersNotOptional = activity.members.filter(
			child => child.isActivity || child.policyModel.containerPolicy !== ContainerPolicyEnum.Optional
		);

		if (membersNotOptional.length == 0) {
			logger.info(`element ${activity.name} has no non-optional children`);
			return null;
		}

		const progress = angular.copy(activity.progress);
		const policy = activity.policyModel;
		if (!policy) {
			return activity.progress;
		}

		// if current `container progress state` is newer that child progress, use `container progress state`
		const latestChildProgressDate = maxValue(activity.members, v => v.progress.progressDate);
		if (latestChildProgressDate < progress.progressDate) {
			return angular.copy(activity.progress);
		}

		switch (policy.containerPolicy) {
			case ContainerPolicyEnum.Optional: {
				progress.progressState = ProgressState.None;
				break;
			}
			case ContainerPolicyEnum.XOutOf: {
				const state = this.getContainerProgressCount(membersNotOptional);

				if (policy.requiredItemsCount <= state.completionCount) {
					progress.progressState = getFinalState(activity.isQuiz, true);
				} else if (membersNotOptional.length - state.failed < policy.requiredItemsCount) {
					progress.progressState = getFinalState(activity.isQuiz, false);
				} else {
					progress.progressState = ProgressState.None;
				}
				break;
			}
			case ContainerPolicyEnum.MarkedMandatory: {
				progress.progressState = ProgressState.None;
				const state = this.getContainerProgressCount(
					membersNotOptional.filter(child => child.parentRelation.isMandatory)
				);

				const atLeastOneMandatory = membersNotOptional.some(item => item.parentRelation.isMandatory);
				if (!atLeastOneMandatory) {
					throw new Error(
						`${activity.name} has MarkedMandatory policy, but it doesn't have any LA's with isMandatory flag on true!`
					);
				}
				if (state.completionCount == membersNotOptional.length) {
					progress.progressState = getFinalState(activity.isQuiz, true);
				} else if (state.failed === membersNotOptional.length) {
					progress.progressState = getFinalState(activity.isQuiz, false);
				} else if (state.completionCount + state.failed >= membersNotOptional.length) {
					progress.progressState = getFinalState(activity.isQuiz, false);
				}
				break;
			}
			default: {
				progress.progressState = ProgressState.None;
				break;
			}
		}
		if (progress.progressState === ProgressState.None) {
			const notStarted = [undefined, null, ProgressState.None, ProgressState.NotStarted];
			const hasStarted = !activity.members.some(m => notStarted.includes(m.progress?.progressState));
			if (hasStarted) {
				progress.progressDate = latestChildProgressDate;
				progress.progressState = ProgressState.Attempted;
			}
		}

		return progress;
	}

	getContainerProgressCount(activities: UserActivityProgressNode[]): ContainerProgressState {
		const state = new ContainerProgressState();
		const stateCountMap: { [key in ProgressState]?: number } = {};
		if (!activities || !activities.length) {
			return state;
		}
		activities.forEach(a => stateCountMap[a.progress.progressState]++);

		activities
			.filter(a => a.progress)
			.forEach(activity => {
				if (progressMapper.isCompleted(activity)) {
					state.completed++;
				}
				if (progressMapper.isPassed(activity)) {
					state.passed++;
				}
				if (progressMapper.isFailed(activity)) {
					state.failed++;
				}
				if (progressMapper.isWaived(activity)) {
					state.waived++;
				}
				if (progressMapper.isNone(activity)) {
					state.none++;
				}
				if (progressMapper.isActive(activity)) {
					state.active++;
				}
			});

		return state;
	}
}
function getFinalState(isQuiz: boolean, success: boolean): ProgressState {
	if (!isQuiz) {
		return ProgressState.Completed;
	}
	return success ? ProgressState.Passed : ProgressState.Failed;
}

function maxValue<T, V>(args: T[], valueFn: (v: T) => V) {
	const max = args.reduce((acc, val) => (acc > valueFn(val) ? acc : valueFn(val)), valueFn(args[0]));
	return max;
}
