import Auth from 'app-legacy/admin/routeGuard';

const ErrorComponent = {
	register: function (ngModule) {
		ngModule.controller('errorMainController', ErrorController);
	},
	routeDef: {
		controller: 'errorMainController',
		template: require('./ErrorView.html'),
		resolve: Auth.guard(),
	},
};

export default ErrorComponent;

const ErrorController = [
	function () {
		// empty constructor
	},
];
