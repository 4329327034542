/* eslint-disable max-classes-per-file */
export class EmailTemplateGroupModel {
	groupType: EmailTemplateTypeEnum;

	id: number;

	isSystem: boolean;

	name: string;

	subtypes: string[];
}

export enum EmailTemplateTypeEnum {
	PerActivityCertificate = 'ActivityCompletionCertificate',
	GeneralPurpose = 'GeneralPurpose',
}

export class EmailTemplateModel {
	group?: EmailTemplateGroupModel;

	isBodyHtml?: boolean;

	isDefault?: boolean;

	isSystem?: boolean;

	organisationId?: number;

	type?: EmailTemplateTypeEnum;

	constructor(public name: string, public id: number = -1) {}
}
