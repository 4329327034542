import { IHttpService } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { ApiListResponseModel, GroupModel, CategoryModel } from '@base/models';
import { JediConfig, ContextManagerService } from 'app-legacy/commons';

export const SearchApiServiceRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('searchService', SearchApiService);
	},
};

function getEmpty<T>() {
	const response: ApiListResponseModel<T> = {
		count: 0,
		items: [],
		options: {
			filter: {},
			sorting: {},
			states: [],
		},
	};
	return response;
}
const baseUri = JediConfig.api.base.replace(/\/+$/, '/');

export class SearchApiService {
	static $inject = ['$http', 'jediContext'];
	constructor(private $http: IHttpService, private jediContext: ContextManagerService) {}

	async getGeneric<T>(url: string, params: any): Promise<ApiListResponseModel<T>> {
		const response = await this.$http.get<ApiListResponseModel<T>>(url, { params: params });
		return response.data;
	}

	async getGroupSuggestions(input: string): Promise<ApiListResponseModel<GroupModel>> {
		if (input?.length < 3) {
			return getEmpty<GroupModel>();
		}

		const ctx = this.jediContext.getCurrentContext();
		const oid = ctx.organisation?.id;
		const url = `${baseUri}organisations/${oid}/suggestions/groups?query=${input}`;
		const response = await this.$http.get<ApiListResponseModel<GroupModel>>(url);
		return response.data;
	}

	async getCategorySuggestions(input: string): Promise<ApiListResponseModel<CategoryModel>> {
		if (input?.length < 3) {
			return getEmpty<CategoryModel>();
		}
		const ctx = this.jediContext.getCurrentContext();
		const oid = ctx.organisation?.id;
		const url = `${baseUri}organisations/${oid}/categories/suggestions?query=${input}`;
		const response = await this.$http.get<ApiListResponseModel<CategoryModel>>(url);
		return response.data;
	}
}
