import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { AdminSharedModule } from 'app/admin/shared';

import { IntermediateCreateActivityDialogComponent } from './intermediate-create-activity/intermediate-create-activity.component';
import { ActivityActionsService } from './activity-actions.service';
import { EditActivityComponent } from './edit-activity/edit-activity.component';

import { ActivityLogicService } from './activity-logic.service';
import { EditActivityLocalizationComponent } from './edit-activity-localization/edit-activity-localization.component';

import { ViewProviderComponent } from './view-provider/view-provider.component';
import { CreateProviderComponent } from './create-provider/create-provider.component';
import { CertificatesModule } from '../certificates/certificates.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { ActivityMapper } from './activity-mapper';
import { LtiOutcomeSettingsComponent } from './lti-outcome/lti-outcome.component';

@NgModule({
	declarations: [
		IntermediateCreateActivityDialogComponent,
		EditActivityComponent,
		EditActivityLocalizationComponent,
		ViewProviderComponent,
		CreateProviderComponent,
		LtiOutcomeSettingsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		AdminSharedModule,
		CertificatesModule,
		NotificationsModule,
	],
	exports: [IntermediateCreateActivityDialogComponent],
	providers: [ActivityActionsService, ActivityLogicService, ActivityMapper],
})
export class ActivitiesModule {}
