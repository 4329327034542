import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { providers } from 'app/ajs-upgraded-providers';
import { AdminSharedModule } from 'app/admin/shared';

import { EditGroupComponent } from './edit-group/edit-group.component';
import { GroupLogicService } from './group-logic.service';

@NgModule({
	declarations: [EditGroupComponent],
	providers: [...providers, GroupLogicService],
	imports: [CommonModule, AdminSharedModule],
})
export class GroupsModule {}
