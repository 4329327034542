import * as angular from 'angular';
import moment from 'moment';
import { IRootScopeService } from 'angular';
import * as logger from '~root/logger';
import { ComponentRegistration } from '~root/commons';

import { StorageType, JediClientStorageService } from '../services/ClientStorage';

const empty = JSON.stringify({});
function isEmptyObject(obj) {
	return JSON.stringify(obj) === empty;
}

const ApiTokenStorage = StorageType.CookieStorage;
const jediApiTokenKey = 'jedi.api.token';

export const JediAuthStoreRegistration: ComponentRegistration = {
	register(ngModule) {
		ngModule.service('jediAuthStore', JediAuthStore);
	},
};

export class UserTokenModel {
	['.expires']: string;
	['.issued']: string;
	access_token: string;
	expires_in: number;
	refresh_token: string;
	refresh_token_expires_in: number;
	refresh_token_issue_date: string;
	token_type: string;
	userName: string;
}
export class JediAuthStore {
	static $inject = ['$rootScope', 'jediClientStorage'];
	constructor(private $rootScope: IRootScopeService, private jediClientStorage: JediClientStorageService) {}

	getLoggedInUser() {
		if (isEmptyObject(this.$rootScope.user)) {
			return undefined;
		}
		return this.$rootScope.user;
	}

	isTokenExpired(userToken: UserTokenModel): boolean {
		const now = moment();
		if (userToken['.expires']) {
			const tokenExpireDate = moment(userToken['.expires']);
			return now > tokenExpireDate;
		}
		if (userToken['.issued'] && userToken.expires_in) {
			const issueDate = moment(userToken['.issued']);
			const tokenExpireDate = issueDate.add(userToken.expires_in, 'ms');
			return now > tokenExpireDate;
		}
		return true;
	}

	getLoggedInUserToken(): UserTokenModel {
		try {
			const encodedToken = this.jediClientStorage.get(jediApiTokenKey, ApiTokenStorage);
			if (encodedToken == null) {
				return undefined;
			}
			const decodedToken = atob(encodedToken);
			const userToken = JSON.parse(decodedToken);
			if (this.isTokenExpired(userToken)) {
				this.jediClientStorage.remove(jediApiTokenKey, ApiTokenStorage);
				return undefined;
			}

			const currentUser = angular.extend(this.getLoggedInUser() || {}, userToken);
			if (isEmptyObject(currentUser)) {
				return undefined;
			}
			return currentUser;
		} catch (err) {
			logger.error(err);
			return undefined;
		}
	}

	setLoggedInUserToken(userToken) {
		try {
			if (this.isTokenExpired(userToken)) {
				this.jediClientStorage.remove(jediApiTokenKey, ApiTokenStorage);
				return false;
			}
			const decodedToken = JSON.stringify(userToken);
			const encodedToken = btoa(decodedToken);

			if (this.jediClientStorage.get(jediApiTokenKey, ApiTokenStorage)) {
				this.jediClientStorage.remove(jediApiTokenKey, ApiTokenStorage);
			}
			this.jediClientStorage.set(jediApiTokenKey, encodedToken, ApiTokenStorage);
			return true;
		} catch (err) {
			logger.error(err);
			return false;
		}
	}

	setLoggedInUser(user) {
		this.$rootScope.user = angular.extend(this.$rootScope.user || {}, user);
	}

	clearLoggedInUserToken() {
		this.jediClientStorage.remove(jediApiTokenKey, ApiTokenStorage);
	}
	clearLoggedInUser() {
		this.$rootScope.user = undefined;
	}
}
