/* eslint-disable max-classes-per-file */
export class LocalizationTable {
	supportedCultures?: CultureModel[];

	localizations: GlobalTranslationTable;
}

export class LocalizedTranslationTable {
	[localizationKey: string]: string;
}

export class GlobalTranslationTable {
	[cultureIso: string]: LocalizedTranslationTable;
}

export class CultureModel {
	id?: string;

	name?: string;

	nativeName?: string;

	englishName?: string;
}

export class CultureUiModel extends CultureModel {
	public shortName?: string;
}

export class UiLocalizedTranslationTable {
	culture: CultureModel;

	keys: LocalizedTranslationTable;

	enabled = true;
}

export class UiLocalizedModel<T = any> {
	culture: CultureModel;

	enabled = true;

	data: T;
}

// entries of type <culture_id, {culture: CultureModel, enabled: bool, data: T}>
export class UiLocalizedContent<T = any> extends Map<string, UiLocalizedModel<T>> {}

export function convertTranslationsApiToUi(translationTable: GlobalTranslationTable): UiLocalizedContent {
	const content = new UiLocalizedContent();
	if (!translationTable) return content;
	Object.keys(translationTable).forEach(cultureId => {
		content.set(cultureId, {
			culture: { id: cultureId },
			data: translationTable[cultureId],
			enabled: false,
		});
	});
	return content;
}
export function convertTranslationsUiToApi(localizedContent: UiLocalizedContent): GlobalTranslationTable {
	const table: GlobalTranslationTable = {};
	localizedContent?.forEach(m => {
		table[m.culture.id] = m.data as LocalizedTranslationTable;
	});
	return table;
}

export class LocaleChangeEvent {
	constructor(public oldCulture: string, public newCulture: string) {}
}
