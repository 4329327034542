/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/**
 * adapted from https://blog.angular-university.io/angular-file-upload/
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LearningActivityFileUploadOptions, FileUploadResult, MediaResourceType } from '@base/models';
import { getFilesizeLabel } from '~root/commons/helpers';

import { FileUploadService } from './file-upload.service';

declare type UploadAcceptedTypeMap = {
	[key in MediaResourceType]: string;
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
 */
const UploadAcceptTypes: UploadAcceptedTypeMap = {
	image: '.jpeg, .jpg, .png, .gif, .svg', // accept=".jpeg, .jgp, .png, .gif,, .svg"
	package: '.zip', // accept=".zip"
	video: 'video/mp4', // accept="video/mp4; video/avi"
	document: '.pdf, .xlsx, .pptx, .docx, .zip',
	import: '.csv, .json, .xlsx',
	certificate: '.docx',
};

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
	@Input()
	label = 'upload';

	@Input()
	showProgress = true;

	@Input()
	resourceType: MediaResourceType;

	@Input()
	uploadOptions: LearningActivityFileUploadOptions;

	@Output()
	fileUploaded = new EventEmitter<FileUploadResult>();

	@ViewChild('fileUpload')
	fileInput: ElementRef;

	fileName = '';

	@Input()
	accept: string = null;

	acceptedExtensions: string = null;

	public progressStyle = {
		width: '0px',
	};

	statusLabel: string;

	set uploadProgress(value) {
		this._uploadProgress = clamp(value);
		this.progressStyle = {
			width: `${this._uploadProgress}%`,
		};
	}

	isUploadInProgress = false;

	get uploadProgress() {
		return this._uploadProgress;
	}

	private _uploadProgress = 0;

	uploadSub: Subscription;

	constructor(private fileService: FileUploadService) {}

	ngOnInit() {
		this.acceptedExtensions = this.resolveAcceptField(this.accept, this.resourceType);
	}

	private resolveAcceptField(accept: string, resourceType: MediaResourceType) {
		const resourceAccept = UploadAcceptTypes[resourceType];
		if (!resourceAccept) {
			throw new Error(`Component input error: unsupported resourceType: ${resourceType}`);
		}

		if (!accept) {
			return resourceAccept;
		}

		const validExtensions = resourceAccept.split(',').map(e => e.trim());
		const acceptExtensions = this.accept.split(',').map(e => e.trim());
		if (acceptExtensions.some(a => !validExtensions.includes(a))) {
			throw new Error(
				`Unsupported extensions in (${this.accept}). Only (${resourceAccept}) extensions are allowed for resourceType = ${this.resourceType}`
			);
		}
		return accept;
	}

	onFileSelected(event) {
		const file: File = event.target?.files[0];

		if (!file) {
			return;
		}

		this.fileName = file.name;

		const upload$ = this.fileService
			.upload(file, this.resourceType, this.uploadOptions)
			.pipe(finalize(() => this.finalize()));

		if (this.uploadSub) {
			this.uploadSub.unsubscribe();
			this.uploadSub = null;
		}
		this.uploadSub = upload$.subscribe(result => this.processUpload(result));
	}

	private processUpload(result: HttpEvent<FileUploadResult>) {
		if (result.type === HttpEventType.UploadProgress) {
			this.isUploadInProgress = true;
			this.uploadProgress = Math.round(100 * (result.loaded / result.total));
			this.statusLabel = `uploading ${getFilesizeLabel(result.loaded)}/${getFilesizeLabel(result.total)}`;
			return;
		}

		// clear upload "in progress" and status after upload completes
		this.statusLabel = '';
		this.isUploadInProgress = false;

		if (result.type === HttpEventType.Response) {
			const media = result.body;
			this.fileUploaded.emit(media);
		}
	}

	cancelUpload() {
		this.uploadSub.unsubscribe();
		this.finalize();
	}

	private finalize() {
		this.uploadProgress = null;
		this.uploadSub = null;
	}

	reset() {
		this.finalize();
		this.fileName = null;
		// https://stackoverflow.com/a/40165524/2101026
		this.fileInput.nativeElement.value = '';
	}
}

function clamp(value) {
	if (value > 100) return 100;
	if (value < 0) return 0;
	return value;
}
