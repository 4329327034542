import { route } from 'angular';
import { ComponentRegistration } from '~root/commons';
import { StorageType } from './ClientStorage/StorageType';

export const UiStateStorageServiceRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('uiStateStorage', ['jediClientStorage', '$stateParams', UiStateStorageService]);
	},
};

export class UiStateStorageService {
	private storageType = StorageType.SessionStorageNoPack;
	private storageKeyPrefix = 'uiState';

	constructor(private jediClientStorage, private $stateParams: route.IRouteParamsService) {}

	private contextKey(key) {
		return `${this.storageKeyPrefix}.organisation.${this.$stateParams.organisationId}.${key}`;
	}

	private get(key: string) {
		if (key) {
			return JSON.parse(this.jediClientStorage.get(this.contextKey(key), this.storageType));
		}
		return null;
	}

	private set(key: string, value: any): void {
		if (key && value) {
			this.jediClientStorage.set(this.contextKey(key), JSON.stringify(value), this.storageType);
		}
		return;
	}

	public clear() {
		this.jediClientStorage.remove(this.storageKeyPrefix, this.storageType);
	}

	public uiElements = {
		admin: {
			reporting: {
				getNavigationActivationStateFilter: () => this.get('admin.reporting.navigationActivationStateFilter'),
				setNavigationActivationStateFilter: (value: string) =>
					this.set('admin.reporting.navigationActivationStateFilter', value),
				getNavigationDrawer: (): boolean => Boolean(this.get('admin.reporting.navigationDrawer')),
				setNavigationDrawer: (value: boolean) => this.set('admin.reporting.navigationDrawer', value.toString()),
				getProgressPerActivityFilters: () => this.get('admin.reporting.progressPerActivityFilters'),
				setProgressPerActivityFilters: value => this.set('admin.reporting.progressPerActivityFilters', value),
			},
		},
		portal: {
			getCategoryFilter: () => this.get(`${this.storageKeyPrefix}.portal.categoriesFilter`),
			setCategoryFilter: (value: any[]) => this.set(`${this.storageKeyPrefix}.portal.categoriesFilter`, value),
			getStateFilter: () => this.get(`${this.storageKeyPrefix}.portal.stateFilter`),
			setStateFilter: (value: any[]) => this.set(`${this.storageKeyPrefix}.portal.stateFilter`, value),
		},
		player: {},
	};
}
