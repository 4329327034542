/* eslint-disable max-classes-per-file */
export class ChangeCertificateStateRequest {
	certificateCodes: Array<string>;

	state: CompletionCertificateState;

	// only when state is changed to 'issued'
	issueDate?: Date;
}

export class IssueCertificatesRequest {
	activityId: number;

	userIds: Array<string>;

	issueDate: Date;
}

/**
 * API model for certificate state
 */
export type CompletionCertificateState = 'Revoked' | 'Issued' | 'Expired' | 'None';
/**
 * API model for certificate
 */
export class CompletionCertificateModel {
	code: string;

	userId: number;

	activityId: number;

	state: CompletionCertificateState;

	expiryDate: Date;

	issueDate: Date;
}

export class CertificateDetailsModel {
	code: string;

	organisationId: number;

	issuedBy: string;

	learningActivityId: number;

	learningActivityName: string;

	userId: string;

	issuedTo: string;

	issueDate: Date;

	expiryDate: Date;

	state: CompletionCertificateState = 'None';
}
