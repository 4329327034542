import * as logger from '~root/logger';
import { JediHierarchyServiceLogic } from '../jediHierarchy';
import { JediAuthService } from '../jediAuth/jediAuthService';
import { ComponentRegistration } from '~root/commons';

const JediWindowMessengerComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('jediWindowMessenger', JediWindowMessengerService);
	},
};

export default JediWindowMessengerComponent;

export class JediWindowMessengerService {
	private port = null;
	private hierarchy = null;

	private _onToggle: (value: boolean) => void;

	static $inject = ['jediHierarchy'];
	constructor(private jediHierarchy: JediHierarchyServiceLogic) {}

	public onToggle(fn: (value: boolean) => void) {
		this._onToggle = fn;
	}

	init() {
		window.addEventListener('message', event => {
			if (event && event.ports && event.ports[0]) {
				this.port = event.ports[0];
				this.port.onmessage = this.onMessage.bind(this);
			}
		});

		this.jediHierarchy.getUserHierarchy().then(root => {
			this.hierarchy = root;
			const location = this.jediHierarchy.getCurrentLocationDetails(this.hierarchy, window.location.pathname);
			sessionStorage.setItem('laLocationOrigin', JSON.stringify(location));
		});
	}

	onMessage(event) {
		// If not same origin (event.origin has value) then exit.

		if (event.origin) {
			logger.warn('Not same origin. Exit.');
			return;
		}
		const commands = {
			exit: 1,
			exitToContainer: 2,
			exitToHome: 3,
			exitToNext: 4,
			exitToPrev: 5,
			exitToRootContainer: 6,
		};

		const location = this.jediHierarchy.getCurrentLocationDetails(this.hierarchy, window.location.pathname);
		if (!location) {
			logger.warn('No valid location oject. Exit.');
			return;
		}

		var message = event.data;
		if (message.command) {
			switch (message.command) {
				case commands.exit:
					this.redirect(message, location.parentContainer);
					break;
				case commands.exitToContainer:
					logger.warn('Not implemented. Exit.');
					break;
				case commands.exitToHome:
					this.redirect(message, location.home);
					break;
				case commands.exitToNext:
					this.redirect(message, location.nextSibling);
					break;
				case commands.exitToPrev:
					this.redirect(message, location.prevSibling);
					break;
				case commands.exitToRootContainer:
					this.redirect(message, location.rootContainer);
					break;
				default:
					logger.warn('Invalid command received. Exit.');
					return;
			}
		}
	}

	redirect(message, location) {
		if (message.params.hasDelay) {
			this.redirectWithDelayTo(location);
		} else {
			window.location.href = location;
		}
	}

	redirectWithDelayTo(location) {
		this._onToggle(true);
		setTimeout(() => {
			this._onToggle(false);
			window.location.href = location;
		}, 3000);
	}
}
