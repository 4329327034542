import { Graph } from '~root/commons';
import { ProgressState, DefaultFinalStates } from './ParticipantModel';
import { ActivityProgress } from './ActivityProgress';
import { ActivityRelationModel } from './ActivityRelation';
import { StatementDetails } from './StatementDetails';
import { GlobalTranslationTable } from './LocalizationTable';
import { UserActivityAuthorization } from './UserActivityAuthorization';
import { ProgressStateUiInfo } from './ProgressStateUiInfo';
import { BaseActivityModel, ProviderCategoryEnum, ProviderTypeEnum } from './ActivityModel';

// values generated by API
export type UserCertificateState = 'None' | 'Issued' | 'Revoked' | 'Expired';
export type AvailabilityStatus =
	| 'None'
	| 'AvailableSoon'
	| 'Available'
	| 'FinalDaysAvailable'
	| 'LastDayAvailable'
	| 'Expired';

export interface UserCertificateModel {
	code: string;
	userId: string;
	learningActivityId: string;
	issueDate: Date;
	expiryDate?: Date;
	state: UserCertificateState;
}

export class UserActivityProgressNode extends BaseActivityModel implements Graph.Node<UserActivityProgressNode> {
	members?: UserActivityProgressNode[] = [];

	// policyModel: Policy;
	isActivity = true;

	isQuiz?: boolean = false;

	name: string;

	progress: ActivityProgress = new ActivityProgress();

	parentRelation: ActivityRelationModel = new ActivityRelationModel();

	participantAuthorization: UserActivityAuthorization;

	// finalStates: string;
	// id: number | string;

	localizations: GlobalTranslationTable = null;

	versionId?: number;

	isContainer?: boolean;

	relationId: number;

	title?: string;

	categories: string[] = [];

	accessOrder = 0;

	//
	url: string = null;

	providerCategory?: ProviderCategoryEnum;

	providerType?: ProviderTypeEnum;

	endpointPath?: string;

	/**
	 * TinCan LRS endpoint
	 */
	lrsEndpoint: string;

	statementDetails: StatementDetails;

	satisfiesPageFilter = true;

	visible = true;

	isMandatory = false;

	isNew = false;

	hasCategoryExtra?: boolean = false;

	displayState: ProgressState;

	isActive: boolean;

	isSucceded: boolean;

	isFailed: boolean;

	isNotStarted: boolean;

	progressStateInfo: ProgressStateUiInfo;

	state: ProgressState;

	notClickable?: boolean = false;

	laIndex: number;

	participantId: string;

	tinCanId: string;

	activityArchive_Id: any;

	rootContainer: UserActivityProgressNode;

	parent: UserActivityProgressNode;

	ltiLaunch?: LtiLaunchRequest = null;

	isAvailable = false;

	certificate?: UserCertificateModel = null;

	availabilityStatus: AvailabilityStatus = 'None';

	inFinalState() {
		if (!this.progress) {
			return false;
		}
		const finalStates =
			this.finalStates && this.finalStates !== ProgressState.None ? this.finalStates : DefaultFinalStates;

		return finalStates.indexOf(this.progress.progressState) > -1;
	}
}

export interface LtiLaunchRequest {
	endpoint: string;
	parameters: [{ key: string; value: string }];
}
