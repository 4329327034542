import * as angular from 'angular';
import serverSettings from '../jediServerSettings';
import { StorageType } from '../services/ClientStorage';
import { ApiHttpCache } from './ApiHttpCache';
import { JediConfig } from '../config/config';
import { JediCacheFactory } from './jediCacheFactory';

const JediHttpInterceptor = [
	function () {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		var self = this;
		let apiCache: ApiHttpCache = null;
		this.$get = [
			'$window',
			'$q',
			'$log',
			'$location',
			'$rootScope',
			'jediClientStorage',
			'jediAuthStore',
			'jediHttpCsrfHandler',
			'$cacheFactory',
			'jediCacheFactory',
			function (
				$window,
				$q,
				$log,
				$location,
				$rootScope,
				jediClientStorage,
				jediAuthStore,
				jediHttpCsrfHandler,
				$cacheFactory,
				jediCacheFactory: JediCacheFactory
			) {
				//#region fields
				this.$rootScope = $rootScope;
				this.$location = $location;
				this.$window = $window;
				this.$q = $q;
				this.$log = $log;
				this.jediClientStorage = jediClientStorage;
				this.jediAuthStore = jediAuthStore;
				this.jediHttpCsrfHandler = jediHttpCsrfHandler;

				apiCache = jediCacheFactory.get();

				return this;
			},
		];

		var _interceptors = {
			request: [],
			requestError: [],
			response: [],
			responseError: [],
		};

		var _options = {
			enableQueryAuth: false,
			logoutOnErrorRequests: true,
		};
		self.options = function (opts) {
			if (typeof opts == 'undefined') {
				return angular.copy(_options);
			}
			Object.keys(_options)
				.filter(p => opts[p])
				.forEach(p => {
					_options[p] = angular.copy(opts[p]);
				});
		};

		function requireAuthentication(config) {
			// var x = ''.indexOf()
			// identify jedi.API urls
			var jediApiUrl = serverSettings.APIBasePath;
			var url = config.url.toLowerCase();
			if (url.indexOf(jediApiUrl) < 0) {
				return false;
			}

			if (
				JediConfig.api.anonymousPaths.some(path => url.indexOf(path.uri) > -1 && path.method == config.method)
			) {
				return false;
			}
			return true;
		}

		/// add authorization headers specific to Jedi API authorization
		self.prepareAuthentication = function (config) {
			// if ie8 or ie9
			// set additional authorization as query string parameter
			// var authToken = JSON.parse($cookies['jedi.api.token']);
			// var auth = 'Bearer ' + authToken.access_token;
			if (!requireAuthentication(config)) {
				return config;
			}
			delete config.headers['X-Requested-With'];
			var user: any = {};
			try {
				user = self.jediAuthStore.getLoggedInUserToken();
				config.headers['Authorization'] = 'Bearer ' + user.access_token;
				// config.withCredentials = true;
			} catch (error) {
				self.$rootScope.$emit(JediConfig.events.userLogout);
			}

			if (_options.enableQueryAuth) {
				var newUrl = config.url;
				if (newUrl.lastIndexOf('/') == newUrl.length - 1) {
					newUrl = newUrl.substring(0, newUrl.length - 1);
				}
				if (typeof config.headers.Authorization != 'undefined') {
					var a = document.createElement('a');
					a.href = newUrl;
					if ((a.search || '').trim().length > 0) {
						newUrl += '&';
					} else {
						newUrl += '?';
					}
					newUrl += 'auth=' + user.access_token;
					delete config.headers.Authorization;
					config.url = newUrl;
				}
			}

			return config;
		};

		self.request = function (config) {
			if (config.url.includes('/api/')) {
				config.cache = apiCache;

				// invalidate entire cache when any change is requested
				// changes on some endpoints affect others ie:
				// HTTP PATCH /api/organisations/{id}/updateParents will change response for
				// HTTP GET /api/organisations/{id}
				// should be done on HttpRequest, not HttpResponse
				if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(config.method)) {
					apiCache.removeAll();
				}
			}

			self.jediHttpCsrfHandler.applyRequestXsrf(config);

			config = self.prepareAuthentication(config);

			if (typeof _interceptors.request != 'undefined' && _interceptors.request.length > 0) {
				for (var i = 0; i < _interceptors.request.length; i++) {
					// eslint-disable-next-line prefer-rest-params
					config = _interceptors.request[i].apply(null, arguments);
				}
			}
			return config;
		};

		self.response = function (result) {
			self.jediHttpCsrfHandler.applyResponseXsrf(result);

			return result;
		};

		self.responseError = function (rejection) {
			apiCache.removeAll();
			var response = rejection;
			var config = rejection.config;

			if (!_options.logoutOnErrorRequests) {
				return self.$q.reject(response);
			}

			if (typeof config.errorHandler == 'function') {
				config.errorHandler(rejection);
			} else {
				if (response.status === 401 || response.status == 403) {
					// if the response is 401 (Unauthorized) or 403 (Forbidden) logout the user and show login panel
					// remove all cookies except notification cookie
					self.jediClientStorage.clearExcept(StorageType.CookieStorage, ['jedi.version', 'jedi.messages']);
					// clear the onbeforeunload check to allow location change in for error without user confirmation
					self.$window.onunload = self.$window.onbeforeunload = null;
					if (self.jediAuthStore.getLoggedInUser() != undefined) {
						self.jediAuthStore.clearLoggedInUserToken();
						self.jediAuthStore.clearLoggedInUser();
						self.$rootScope.$emit(JediConfig.events.userLogout);
					}

					return self.$q.reject(response);
				} else if (response.status == 404) {
					self.$location.path('/notfound');
				}
			}
			return self.$q.reject(response);
		};

		self.interceptors = function (interceptionType) {
			return _interceptors[interceptionType];
		};
	},
];
export default JediHttpInterceptor;
