<input type="file" class="file-input" [accept]="acceptedExtensions" (change)="onFileSelected($event)" #fileUpload />

<!-- {{fileName || "No file uploaded yet."}} -->

<div *ngIf="showProgress">
	<div [ngClass]="{ 'hidden-progress': !isUploadInProgress }">
		<div class="app-progress-container"><div class="app-progress-content" [ngStyle]="progressStyle"></div></div>
		<!-- whitespace forces content to be rendered, but hidden, this keeps the layout from changing when parent is hidden -->
		<div class="app-progress-status">{{ statusLabel }} &nbsp;</div>
	</div>
</div>
<div class="actions">
	<button class="btn btn-primary" type="button" (click)="fileUpload.click()">
		{{ label }}
	</button>
	<ng-content></ng-content>
</div>
