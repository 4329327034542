import { ValidationResult } from './ValidationResult';

export class ResponseModel<T> {
	public isSuccess: boolean;

	public isRedirect? = false;

	public redirect?: string;

	public model?: T | any;

	public errors?: ValidationResult;
}
