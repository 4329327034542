import { IStorage } from './IStorage';
export class WindowStorageAdapter implements IStorage {
	constructor(private _store: Storage) {}
	get(key: string): any {
		const raw = this._store.getItem(key);
		return raw;
	}
	set(key: string, value: any) {
		return this._store.setItem(key, value);
	}
	remove(key: string) {
		return this._store.removeItem(key);
	}
	clear() {
		return this._store.clear();
	}
	getKeys(keyFilter = ''): string[] {
		const keys = [];
		for (let i = 0; i < this._store.length; i++) {
			const key = this._store.key(i);
			if (!keyFilter || key.indexOf(keyFilter) === 0) {
				keys.push(key);
			}
		}
		return keys;
	}
}
