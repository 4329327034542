import * as angular from 'angular';
import moment from 'moment';
import * as logger from '~root/logger';

import {
	UserActivityProgressNode,
	ActivationState,
	OrganisationModel,
	ActivityRelationModel,
	DisplaySettings,
	ContainerPolicyModel,
	UserActivityAuthorization,
	StatementDetails,
	ActivityTinCanModel,
	AvailabilitySettings,
} from '@base/models';
import { ComponentRegistration, Graph } from '~root/commons';
import { HierarchyValidationService } from './HierarchyValidationService';
import { LocalizationProvider } from '../jediLocalization/jediLocalizationProvider';
import { ActivityProgressMapper } from './ActivityProgressMapper';
import { convertToAdjustedDateFromStringDateValues } from 'app/shared/dateHelpers';

export const UserHierarchyMapperServiceRegistration: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.service('userHierarchyMapper', UserHierarchyMapperService);
	},
};

const progressMapper = new ActivityProgressMapper();

const validator = new HierarchyValidationService();
const rootId = 'root';
export class ApiNode implements Graph.Node<ApiNode> {
	[key: string]: any;
}

export class UserHierarchyMapperService {
	static $inject = ['jediLocalization'];
	constructor(public jediLocalization: LocalizationProvider) {}
	private _nodes: { [key: string]: ApiNode } = {};
	private getApiNode(node: UserActivityProgressNode): ApiNode {
		if (!node) return null;
		return this._nodes[node.id];
	}
	private setApiNode(item: ApiNode) {
		if (item && item.id) {
			this._nodes[item.id] = angular.copy(item);
		}
	}
	// the entire hierarchy display is dependendant on current culture (some LAs aren't visible on some Cultures)
	convertUserHierarchy(organisation: OrganisationModel, data: any[]): UserActivityProgressNode {
		// validate hierarchy
		const apiRoot = this.createApiRoot(organisation, data);

		const nodes: { [key: string]: UserActivityProgressNode } = {};

		// linearize api nodes to mapped models
		Graph.traverse(apiRoot, (node, depth, parent) => {
			const model = new UserActivityProgressNode();
			this.convertDataTypes(node);

			this.setApiNode(node);
			model.id = node.id;
			nodes[node.id] = model;
			// relations need to be created prior to using the model updates
			if (model.id != apiRoot.id) {
				model.rootContainer = nodes[rootId];
				model.parent = nodes[parent.id] || nodes[rootId];
				model.parent.members = [...model.parent.members, model];
			}

			this.updateNodelModel(model);
		});
		const modelRoot = nodes[rootId];

		let errors = [];
		Graph.traverse(modelRoot, node => (errors = errors.concat(validator.validateNodeDisplaySettings(node))));
		if (errors.length > 0) {
			logger.error(errors);
		}

		return modelRoot;
	}
	private convertDataTypes(node: ApiNode) {
		if (node.progress) {
			if (typeof node.progress.progressDate === 'string') {
				node.progress.progressDate = new Date(node.progress.progressDate);
			}
		}
		if (typeof node.creationDate === 'string') {
			node.creationDate = moment.utc(node.creationDate).toDate();
		}
		if (typeof node.lastUpdate === 'string') {
			node.lastUpdate = moment.utc(node.lastUpdate).toDate();
		}
	}

	private createApiRoot(organisation: OrganisationModel, data: any[]) {
		const apiRoot = new UserActivityProgressNode();
		apiRoot.id = rootId;
		apiRoot.name = 'Online Academy';
		apiRoot.displayName = 'Online Academy';
		apiRoot.members = data;
		apiRoot.isActivity = false;
		apiRoot.organisation_Id = organisation.id;
		apiRoot.activationState = ActivationState.Active;
		apiRoot.displaySettings = organisation.displaySettings;
		apiRoot.localizationSettings = organisation.localizationSettings;
		apiRoot.availabilitySettings = new AvailabilitySettings();
		const root = apiRoot as ApiNode;
		return root;
	}

	private updateNodelModel(node: UserActivityProgressNode) {
		const item = this.getApiNode(node);
		if (!item) {
			return null;
		}

		this.updateNodeBase(node);

		if (item.isActivity) {
			this.updateContent(node);
		} else {
			this.updateContainer(node);
			const basePath = '/portal/content/root';
			if (node.parentRelation.hierarchyPath == '/') {
				node.parentRelation.hierarchyPath = basePath;
			} else {
				node.parentRelation.hierarchyPath = basePath + node.parentRelation.hierarchyPath;
			}
		}
	}

	private getActivityLocalization(item: ApiNode | UserActivityProgressNode) {
		if (!item) {
			return {};
		}
		if (item.localizations) {
			const culture = this.jediLocalization.getCurrentUiCulture();
			return item.localizations[culture] || {};
		}
		if (item.localizationSettings && item.localizationSettings.localizations) {
			return this.jediLocalization.getCurrentLocalizationDecoded(item.localizationSettings.localizations);
		}

		return {};
	}

	private getLearningActivityTitle(item: UserActivityProgressNode): string {
		return this.getLocalizedView(item).title;
	}

	private getLocalizedView(node: UserActivityProgressNode) {
		const item = this.getApiNode(node);
		const localization = this.getActivityLocalization(item) || {};
		const localized = {
			title: localization.LEARNING_ACTIVITY_DISPLAY_NAME || item.displayName || item.name,
			description: localization.LEARNING_ACTIVITY_DESCRIPTION || item.description,
			genericInfo: localization.LEARNING_ACTIVITY_GENERIC_INFO || item.genericInfo,
		};
		return localized;
	}

	private updateNodeBase(node: UserActivityProgressNode) {
		const item = this.getApiNode(node);

		node.name = item.name;
		node.displayName = item.displayName;
		node.resetUserActivityState = item.resetUserActivityState;
		node.id = item.id;

		node.cultureId = item.cultureId;
		node.displayOrder = item.displayOrder;
		node.displaySettings = item.displaySettings || new DisplaySettings();
		node.activationState = item.activationState;
		node.isCertificateEnabled = item.isCertificateEnabled;
		node.certificate = item.certificate;

		if (item.hierarchyPath) {
			node.hierarchyPath = item.hierarchyPath;
		} else {
			node.hierarchyPath = item.parentRelation.hierarchyPath;
		}

		node.tinCanId = item.tinCanId;
		node.participantId = item.participantId;
		node.finalStates = item.finalStates;

		node.isActivity = item.isActivity;
		node.organisation_Id = item.organisation_Id;
		node.availabilitySettings = this.mapAvailabilitySettings(item.availabilitySettings);

		node.categories = (item.categories || []).map(c => c.name);
		node.isNew = moment.utc().diff(node.creationDate, 'days') < 3;
		node.state = item.state;

		if (item.parentRelation) {
			node.parentRelation = item.parentRelation;
			node.relationId = parseInt(`${item.parentRelation.relationId}`);
			node.isMandatory = item.parentRelation.isMandatory;
			node.accessOrder = item.parentRelation.accessOrder || node.accessOrder;
			node.displayOrder = item.parentRelation.displayOrder || node.displayOrder;
		}

		node.lrsEndpoint = item.lrsEndpoint;

		node = progressMapper.updateProgressMapping(node, item.progress);

		node.parentRelation = item.parentRelation || new ActivityRelationModel();

		node.resetUserActivityState = item.resetUserActivityState;
		node.displayOrder = item.displayOrder;
		node.isQuiz = item.isQuiz;
		node.url = item.url;

		node.participantAuthorization = this.mapNodeAuthorization(item);

		if (item.localizationSettings && item.localizationSettings.localizations) {
			node.localizations = this.jediLocalization.getDecodedLocalizations(item.localizationSettings.localizations);
		} else {
			node.localizations = {};
		}

		node.title = item.title;
		node.description = item.description;
		node.genericInfo = item.genericInfo;

		this.updateLocalized(node);

		return node;
	}

	public updateLocalized(node: UserActivityProgressNode) {
		const localized = this.getLocalizedView(node);
		node.title = localized.title;
		node.genericInfo = localized.genericInfo;
		node.description = localized.description;
	}

	private mapAvailabilitySettings(availabilitySettings: any): AvailabilitySettings {
		const mappedAvailabilitySettings = new AvailabilitySettings();

		mappedAvailabilitySettings.displayPolicy = availabilitySettings.displayPolicy;
		mappedAvailabilitySettings.periodEnabled = availabilitySettings.periodEnabled;

		mappedAvailabilitySettings.startDate = convertToAdjustedDateFromStringDateValues(
			availabilitySettings.startDate
		);
		mappedAvailabilitySettings.endDate = convertToAdjustedDateFromStringDateValues(availabilitySettings.endDate);

		return mappedAvailabilitySettings;
	}

	private mapNodeAuthorization(item: ApiNode): UserActivityAuthorization {
		const apiAuth = item.participantAuthorization;
		if (!apiAuth) {
			return null;
		}
		const auth = new UserActivityAuthorization(apiAuth.actorHomepage, apiAuth.authorizationToken);
		auth.userId = apiAuth.userId;
		auth.learningActivityId = apiAuth.learningActivityId;
		return auth;
	}

	private updateContainer(node: UserActivityProgressNode) {
		const item = this.getApiNode(node);
		if (!item) {
			return;
		}

		node.policyModel = new ContainerPolicyModel();
		const policy = item.policyModel;
		if (policy) {
			node.policyModel.accessEnforcePolicy = policy.accessEnforcePolicy;
			node.policyModel.containerPolicy = policy.containerPolicy;
			node.policyModel.displayEnforcePolicy = policy.displayEnforcePolicy;
			node.policyModel.requiredItemsCount = policy.requiredItemsCount;
		}
		node.members = [];
		node.isContainer = true;
		node.isActivity = false;
	}

	private updateContent(node: UserActivityProgressNode) {
		const item = this.getApiNode(node);
		if (!item) {
			return;
		}

		node.url = '/Player/activity' + item.parentRelation.hierarchyPath;
		node.endpointPath = item.endpointPath;

		node.providerCategory = item.providerCategory;
		node.providerType = item.providerType;
		node.isActivity = true;
		node.isContainer = false;

		if (node.providerCategory !== 'None') {
			node.statementDetails = this.mapStatementDetails(item, node);
		}
	}
	private mapStatementDetails(item: any, model: UserActivityProgressNode) {
		const details = new StatementDetails();
		details.lrsEndpoint = item.lrsEndpoint;
		details.activityName = model.name;
		details.versionId = item.versionId;
		details.tinCanId = item.tinCanId;
		details.participantId = item.participantId;
		details.actorHomepage = model.participantAuthorization.actorHomepage.replace(/\/$/, '');
		details.userId = model.participantAuthorization.userId;
		details.participantAuthorization = model.participantAuthorization;
		const path = getNodePath(model);
		// path root is the virtual root, not needed here
		const r = path.length > 1 ? path[1] : path[0];
		const c = model.parent;
		details.rootContainer = new ActivityTinCanModel(r.tinCanId, r.name);
		details.container = new ActivityTinCanModel(c.tinCanId, c.name);
		details.activityPath = model.parentRelation.hierarchyPath;
		return details;
	}

	orderActivities(a: UserActivityProgressNode, b: UserActivityProgressNode) {
		if (a.displayOrder > b.displayOrder) return 1;
		if (a.displayOrder < b.displayOrder) return -1;
		const aTitle = this.getLearningActivityTitle(a);
		const bTitle = this.getLearningActivityTitle(b);
		return aTitle.localeCompare(bTitle);
	}
}

function getNodePath(node: UserActivityProgressNode) {
	const ancestors: UserActivityProgressNode[] = [];
	let current = node;
	while (ancestors.length < 100 && current) {
		ancestors.push(current);
		current = current.parent;
	}
	return ancestors.reverse();
}
