import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogOptions, ConfirmDialogResult } from './ConfirmDialogOptions';

@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
	options: ConfirmDialogOptions;

	constructor(
		private dialogRef: MatDialogRef<ConfirmComponent, ConfirmDialogResult>,
		@Inject(MAT_DIALOG_DATA) data: ConfirmDialogOptions
	) {
		this.options = {
			...data,
			...{
				buttonCancel: 'cancel',
				buttonConfirm: 'confirm',
				title: 'action requires confirmation',
			},
		};

		if (!this.options.message) {
			throw new Error('message is required');
		}
	}

	confirm() {
		this.dialogRef.close({ confirmed: true });
	}

	cancel() {
		this.dialogRef.close({ confirmed: false });
	}
}
