import { Component, Inject, OnInit } from '@angular/core';
import { AngularJsLogger } from 'app/ajs-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { EmailTemplateModel, EmailTemplateTypeEnum, NotificationsEventType, NotificationViewModel } from '@base/models';
import { AdminService } from 'app-legacy/admin/commons';
import { RouteParamsService } from 'app/route-params.service';
import { NotificationsLogicService } from '../notifications-logic.service';

@Component({
	selector: 'app-edit-notifications-dialog',
	templateUrl: './edit-notifications-dialog.component.html',
	styleUrls: ['./edit-notifications-dialog.component.scss'],
})
export class EditNotificationsDialogComponent implements OnInit {
	public isCreate: boolean;

	public emailTemplates: Array<EmailTemplateModel>;

	public selectedEmailTemplate: EmailTemplateModel;

	public eventTypes: Array<NotificationsEventType>;

	public selectedEventType: NotificationsEventType;

	public notificationJobId: string;

	public triggerPeriod;

	public notificationName: string;

	public dialogHeader: string;

	public loaded = false;

	public scheduledAt: FormControl;

	constructor(
		@Inject(MAT_DIALOG_DATA) public entry: NotificationViewModel,
		private dialogRef: MatDialogRef<EditNotificationsDialogComponent>,
		private adminService: AdminService,
		private routeService: RouteParamsService,
		private notificationService: NotificationsLogicService,
		private logger: AngularJsLogger
	) {}

	async ngOnInit() {
		this.isCreate = this.entry == null;
		this.dialogHeader = this.isCreate ? 'Create notification' : 'Edit notification';
		this.emailTemplates = await this.getEmailTemplates();
		this.eventTypes = await this.getEventTypes();
		if (this.isCreate) {
			this.notificationName = '';
			this.scheduledAt = new FormControl(new Date());
		} else {
			const notification = this.entry.data;
			this.notificationName = notification.name;
			this.selectedEmailTemplate = this.emailTemplates.find(et => et.id === notification.emailTemplate?.id);
			this.selectedEventType = this.eventTypes.find(et => et.type === notification.type);
			this.triggerPeriod = notification.triggerPeriod;
			this.notificationJobId = notification.jobId;
			this.scheduledAt = new FormControl(notification.scheduledAt);
		}
		this.loaded = true;
	}

	cancel() {
		this.dialogRef.close();
	}

	save() {
		if (!this.selectedEmailTemplate || !this.selectedEmailTemplate.id) {
			this.logger.warn('please select an email template');
			return;
		}

		if (!this.selectedEventType) {
			this.logger.warn('please select an event type');
			return;
		}

		if ((this.notificationName || '').trim().length < 1) {
			this.logger.warn('please specify notification name');
			return;
		}

		if (this.showTriggerDays())
			if (this.triggerPeriod < 1 || !this.triggerPeriod) {
				this.logger.warn('please set days to trigger to be at least 1');
				return;
			}

		if (this.showScheduleAt()) {
			if (Number.isNaN(this.scheduledAt.value.valueOf())) {
				this.logger.warn('please set valid date');
				return;
			}
			if (new Date() > this.scheduledAt.value) {
				this.logger.warn('please select a date in the future');
				return;
			}
		}

		const model: NotificationViewModel = {
			data: {
				name: this.notificationName,
				emailTemplate: this.selectedEmailTemplate,
				triggerPeriod: this.triggerPeriod,
				type: this.selectedEventType.type,
				jobId: this.notificationJobId,
				scheduledAt: this.scheduledAt.value,
			},
			selected: false,
		};
		this.dialogRef.close(model);
	}

	public showScheduleAt() {
		return this.selectedEventType?.properties.includes('ScheduledAt');
	}

	public showTriggerDays() {
		return this.selectedEventType?.properties.includes('TriggerPeriod');
	}

	private async getEmailTemplates() {
		const { organisationId } = this.routeService.params();
		const userEmails = await this.adminService.getAllEmailTemplates(organisationId);
		const emails = userEmails.filter(t => t.group && t.group.groupType === EmailTemplateTypeEnum.GeneralPurpose);
		return emails;
	}

	private async getEventTypes() {
		return this.notificationService.getNotificationTypes();
	}
}
