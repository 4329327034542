import { IController } from 'angular';
import * as logger from '~root/logger';
import { ComponentRegistration } from '~root/commons';

import { IVideoPlayerEventHandler } from '../VideoPlayerEvent';
import { VideoPlayerService } from '../VideoPlayerService';

export const VideoPlayerContainerComponent: ComponentRegistration = {
	register: function (ngModule) {
		ngModule.component('videoPlayerContainer', {
			controller: VideoPlayerContainerController,
			template: require('./VideoPlayerContainer.html'),
		});
	},
	style: require('./style.scss'),
	name: 'videoPlayerContainer',
};

class VideoPlayerContainerController implements IController, IVideoPlayerEventHandler {
	public videoUri: string;
	public videoTitle: string;

	public isPlayerVisible = false;

	static $inject = ['$timeout', 'videoPlayerService'];

	constructor(public $timeout, public videoPlayerService: VideoPlayerService) {}

	$onInit() {
		this.videoPlayerService.register(this);
	}
	$onDestroy() {
		this.videoPlayerService.unregister(this);
	}

	onPlayerShow($event) {
		this.$timeout(() => {
			logger.info('show video player container');
			this.videoUri = $event.uri;
			this.videoTitle = $event.title;
			this.isPlayerVisible = true;
		});
	}
	onPlayerClose() {
		this.$timeout(() => {
			logger.info('close video player container');
			this.videoTitle = null;
			this.videoUri = null;
			this.isPlayerVisible = false;
		});
	}
}
