import { StateParams } from '@uirouter/core';

import { ensureUserAuthorization, RolePermissions, ContextManagerService } from 'app-legacy/commons';

const Auth = {
	guard: guard,
	permissions: RolePermissions,
};
function guard(selectedPermissions?) {
	const authGuard = ensureUserAuthorization(selectedPermissions);
	return {
		loadUser: authGuard,
		loadContext: [
			'jediContext',
			'$stateParams',
			function (jediContext: ContextManagerService, $stateParams: StateParams) {
				return jediContext.loadContext($stateParams.organisationId);
			},
		],
	};
}
export default Auth;
