const JediHttpCsrfHandler = [
	'$window',
	function ($window) {
		var self = this;
		self.$window = $window;

		var xsrf = {
			// header set by this service
			methods: ['POST', 'PUT', 'DELETE', 'PATCH'],
			headerName: 'X-XSRF-SERVER-TOKEN',
		};

		this.applyRequestXsrf = function (config) {
			if (config.url[0] === '/' && xsrf.methods.some(method => config.method === method)) {
				if (store.token && !config.headers[xsrf.headerName]) {
					config.headers[xsrf.headerName] = store.token;
				}
			}
		};

		var store: any = {};
		this.applyResponseXsrf = function (response) {
			if (response.config.url[0] === '/') {
				if (!xsrf.methods.some(method => response.config.method === method)) {
					var xsrfHeader = response.headers(xsrf.headerName);
					if (xsrfHeader) {
						store.token = xsrfHeader;
					}
				}
			}
		};
		return self;
	},
];
export default JediHttpCsrfHandler;
