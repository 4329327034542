import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiListResponseModel, GroupModelExtended } from '@base/models';
import { JediConfig } from 'app-legacy/commons';

@Injectable()
export class GroupLogicService {
	private baseUri = `${JediConfig.api.base}/groups`;

	constructor(private httpClient: HttpClient) {}

	public async getOrganisationGroups(organisationId: number) {
		const response = await this.httpClient
			.get<ApiListResponseModel<GroupModelExtended>>(`${this.baseUri}?filter.organisationId=${organisationId}`)
			.toPromise();
		return response.items;
	}
}
